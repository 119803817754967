import "./styles.scss";

import cn from "classnames";
import React, { FC } from "react";

import {
  ReactComponent as IconExpand,
} from "../../assets/svg/arrowExpandDown.svg";
import {
  ReactComponent as IconExpandActive,
} from "../../assets/svg/arrowExpandDownActive.svg";

export interface IExpandButtonProps {
  onClick: () => void;
  className?: string;
  isExpanded?: boolean;
}

const ExpandButton: FC<IExpandButtonProps> = ({
  className,
  isExpanded,
  ...props
}) => {
  return (
    <button
      {...props}
      className={cn(
        "expand-button",
        isExpanded && "expand-button--expanded",
        className
      )}
    >
      <IconExpand className="expand-button__icon" />
      <IconExpandActive className="expand-button__icon-active" />
    </button>
  );
};

export default ExpandButton;
