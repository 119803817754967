import "./styles.scss";

import React, { useEffect } from "react";
import { Button, FlexRow, TextField } from "@kit/kit-aiss";
import { FC } from "react";
import { CreateSubThemeModalStore } from "./store";
import { ReactComponent as PlusIcon } from "../../../../assets/svg/plus.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/svg/delete.svg";
import { observer } from "mobx-react";
import Modal from "../../../../components/Modal";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  themId: string;
  loadSubThemas: (themId: string) => Promise<void>;
}

const store = new CreateSubThemeModalStore();

const CreateSubThemeModal: FC<IProps> = ({
  isOpen,
  onClose,
  themId,
  loadSubThemas,
}) => {
  useEffect(() => {
    store.init(themId);
    return () => store.reset();
  }, []);

  const handleSaveClick = () => {
    return store.onCreate()?.then(() => {
      onClose();
      loadSubThemas(themId);
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Добавить новую подтему">
      {store.fields.map((field, idx) => (
        <div key={idx} className="create-sub-theme-modal__field">
          <TextField
            placeholder="Название подтемы"
            name="topic"
            onChange={field.form.changeValue}
            value={field.form.values.topic}
            errorMessage={field.form.errors.topic}
          />
          {idx !== 0 && <DeleteIcon onClick={() => store.deleteField(idx)} />}
        </div>
      ))}
      <div className="create-sub-theme-modal-add" onClick={store.addField}>
        <PlusIcon />
        <span className="create-sub-theme-modal-add__title">Добавить ещё</span>
      </div>
      <FlexRow>
        <Button type="submit" onClick={handleSaveClick}>
          Сохранить
        </Button>
      </FlexRow>
    </Modal>
  );
};

export default observer(CreateSubThemeModal);
