import formatDate from 'date-fns/format';
import ruLocale from 'date-fns/locale/ru';

/**
 * @param date string or Date
 * @param format date format string. https://date-fns.org/v1.30.1/docs/format
 * @returns formatted date string
 */
export const getFormattedDate = (
  date: string | Date,
  format = "eeee dd MMMM"
) => {
  const _date = typeof date === "string" ? new Date(date) : date;
  try {
    return formatDate(_date, format, { locale: ruLocale });
  } catch (error) {
    return null;
  }
};
