import "./styles.scss";
import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layouts/MainLayout";
import { observer } from "mobx-react-lite";
import { CallsStore } from "../Calls/store";
import Typography from "../../components/Typography";
import { FlexColumn, FlexRow } from "@kit/kit-aiss";
import Card from "../../components/Card";
import CrmTable from "../../components/CrmTable";
import GlobalStatusStore from "../../stores/GlobalStatusStore";
import EditCallModal from "./components/popups/EditCallModal";
import sipControllerStore from "../../stores/GlobalStatusStore/SipController";
import TextField from "../../components/TextField";
import Button from "../../components/Button";
import ParametersOperator from "../../components/Parameters_Operator";
import ButtonPauseCalls from "../../components/ButtonPauseCalls";
import InfiniteScroll from "react-infinite-scroll-component";
import Pagination from "../../components/Pagination";
import { monthNames, weekDays } from "../../constants/months";
import authStore from "../AuthPage/store/authStore";
import CreateEmptyCallModal from "../../components/modals/CreateEmptyCallModal";

const store = new CallsStore();

const OperatorCalls = () => {
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const [isOpenCreatePopup, setIsOpenCreatePopup] = useState(false);

  const [callId, setCallId] = useState<number>(0);
  const [tel, setTel] = useState("299");
  // для звонка из статистики сбросов, сейчас не используется
  const [isCall, setIsCall] = useState(false);

  useEffect(() => {
    if (!authStore.role.length) return;
    store.init("operator");
    GlobalStatusStore.createCallModalState.successSubscribers.subscribe(
      store.getRingTableData
    );
    GlobalStatusStore.editCallModalState.closeSubscribers.subscribe(
      store.getRingTableData
    );

    return () => {
      GlobalStatusStore.createCallModalState.successSubscribers.unsubscribe(
        store.getRingTableData
      );
      GlobalStatusStore.editCallModalState.closeSubscribers.unsubscribe(
        store.getRingTableData
      );
      store.reset();
    };
  }, [authStore.role]);

  const handleOpenEditModal = (idx: number) => {
    const id = store.table?.calls[idx].call_info_id;
    const idd = store.table?.calls[idx].call_id;

    if (id) {
      setCallId(id);
      setIsOpenEditPopup(true);
    }

    if (!id && idd) {
      GlobalStatusStore.getCallId(idd);
      GlobalStatusStore.editCallModalState.open();
    }
  };

  const handleCloseEditModal = () => {
    const editedAttr = store.editCallModalStore.form.attribute?.attributes.some(
      (item) => {
        return item.isEditAttribute === true;
      }
    );

    if (store.editCallModalStore.form.isEdit || editedAttr) {
      if (
        confirm(
          "У вас остались несохраненные изменения. Вы точно хотите закрыть модальное окно?"
        )
      ) {
        setIsOpenEditPopup(false);
      }
    } else {
      setIsOpenEditPopup(false);
    }
  };

  const handleMoreLoad = () => {
    store.incrementPageCount();
    store.getRingTableData("operator");
  };

  const handlePlusDate = (date: Date | null) => {
    const r = date!.setDate(date!.getDate() + 1);
    store.onChangeDate(new Date(r), "operator");
  };

  const handleMinusDate = (date: Date | null) => {
    const r = date!.setDate(date!.getDate() - 1);
    store.onChangeDate(new Date(r), "operator");
  };

  const handleChangeDate = (date: Date | null) => {
    store.onChangeDate(date, "operator");
  };

  const handleCreateCall = () => {
    setIsOpenCreatePopup(true);
  };

  const handleCloseCreateCall = (isSuccess?: boolean) => {
    setIsOpenCreatePopup(false);
    if (isSuccess) store.getRingTableData("operator");
  };

  return (
    <MainLayout status={GlobalStatusStore.states.paused}>
      <FlexColumn gap={35} className={"calls-page"}>
        <FlexRow className="operator-calls__header">
          <Typography variant="h1">Мои звонки</Typography>

          <FlexRow isNoFlexChildren>
            {GlobalStatusStore.states.paused && (
              <Button onClick={handleCreateCall}>Создать новый звонок</Button>
            )}
            <Pagination
              title={`${
                weekDays[
                  store.date!.getDay() - 1 > -1 ? store.date!.getDay() - 1 : 6
                ]
              }, ${store.date!.getDate()} ${
                monthNames[store.date!.getMonth()]
              }`}
              onNext={() => {
                handlePlusDate(store.date);
              }}
              onPrev={() => {
                handleMinusDate(store.date);
              }}
              date={store.date}
              onChangeDate={handleChangeDate}
              className="operator"
            />
          </FlexRow>
        </FlexRow>
        <FlexColumn gap={20}>
          <FlexRow>
            <ParametersOperator
              params={store.operatorStatistics}
              handleCall={() => setIsCall((prev) => !prev)}
            />
            <ButtonPauseCalls />
          </FlexRow>
          {isCall && (
            <Card>
              <FlexRow isNoFlexChildren>
                <TextField value={tel} onChange={setTel} />
                <Button
                  className="operator-calls__button"
                  size="medium"
                  onClick={() => sipControllerStore.call(tel)}
                  disabled={GlobalStatusStore.states.paused}
                >
                  Позвонить
                </Button>
              </FlexRow>
            </Card>
          )}

          <Card>
            <div className="calls-table-heading">
              <Typography variant="h4">
                Все звонки за смену ({store.table?.count})
              </Typography>
            </div>
            <InfiniteScroll
              dataLength={(store.table?.calls.length as number) || 0}
              next={handleMoreLoad}
              hasMore={true}
              loader
            >
              <CrmTable
                isLoading={store.isLoading}
                isHoverBg
                isFullWidth
                headers={[
                  {
                    title: "Дата",
                    width: 180,
                    ...store.sortStore.sortFields.get("created_at"),
                  },
                  {
                    title: "Оператор №",
                    width: 150,
                    // ...store.sortStore.sortFields.get(
                    //   "responsible_user_number"
                    // ),
                  },
                  {
                    title: "Линия",
                  },
                  {
                    title: "Тема звонка",
                    width: 400,
                    ...store.sortStore.sortFields.get("topic_title"),
                  },
                  {
                    title: "Статус",
                    width: 150,
                    ...store.sortStore.sortFields.get("status"),
                  },
                  {
                    title: "Реакция",
                  },
                  {
                    title: "Номер",
                    width: 150,
                  },
                ]}
                rows={store.rows}
                onRowClick={handleOpenEditModal}
              />
            </InfiniteScroll>
          </Card>
        </FlexColumn>
      </FlexColumn>

      {isOpenCreatePopup && (
        <CreateEmptyCallModal
          store={store.createEmptyCallModalStore}
          isOpen={isOpenCreatePopup}
          onClose={handleCloseCreateCall}
          onCloseAndAndGoAppeal={handleCloseCreateCall}
        />
      )}

      {isOpenEditPopup && (
        <EditCallModal
          editCallModalStore={store.editCallModalStore}
          isOpen={isOpenEditPopup}
          id={callId}
          onClose={handleCloseEditModal}
        />
      )}
    </MainLayout>
  );
};

export default observer(OperatorCalls);
