import axios, { AxiosInstance } from "axios";

// const URL = "https://api.crm122.aiwoo.info";
// const URL = "https://10.0.16.152:8080/";
// const URL = "https://api-crm122.egov66.ru";

const instance: AxiosInstance = axios.create({
  withCredentials: true,
  baseURL:
    process.env.REACT_APP_BUILD_TARGET === "test"
      ? "https://api.crm122.aiwoo.info"
      : "https://api-crm122.egov66.ru",
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": url,
    // "Access-Control-Allow-Origin": "https://crm122.aiwoo.info", // * или ваш домен
    // "Access-Control-Allow-Origin": "https://10.0.16.152:8080/", // * или ваш домен
    "Access-Control-Allow-Origin":
      process.env.REACT_APP_BUILD_TARGET === "test"
        ? "https://api.crm122.aiwoo.info"
        : "https://api-crm122.egov66.ru/", // * или ваш домен
    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
  },
});

instance.defaults.withCredentials = true;

instance.interceptors.request.use(async (req) => {
  // try catch здесь нужен чтоб подставлять либо новый токен
  // либо старый, можно удалить
  try {
    // await tokenStore.checkToken();
    const token = localStorage.getItem("token");

    if (req && req.headers) {
      if (token) req.headers.Authorization = `${token}`;
    }
  } catch (error: any) {
    const token = localStorage.getItem("token");

    if (req && req.headers) {
      if (token) req.headers.Authorization = `${token}`;
    }
  }
  return req;
});

export default instance;
