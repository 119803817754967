import { makeAutoObservable, runInAction } from "mobx";
import { FormStore } from "../../../../stores/FormStore";
import recordsService from "../../../../services/records";

export class EditModalRecordStore {
  form = new FormStore({ comment: null, user: null, clientPhone: null });
  isLoading = false;
  record: {
    recorded_at: "";
    name: "";
    audio_record: "";
    id: null | number;
    lineName: "";
  } = {
    recorded_at: "",
    name: "",
    audio_record: "",
    id: null,
    lineName: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: number | null) => {
    this.loadRecordInfo(id);
  };

  loadRecordInfo = async (id: number | null) => {
    runInAction(() => {
      this.isLoading = true;
      this.record.id = id;
    });

    recordsService
      .loadRecord(id)
      .then((res) => {
        const { data } = res.data;

        runInAction(() => {
          this.form.changeValue(data.comment, "comment");
          this.form.changeValue(data.client_phone, "clientPhone");
          data.responsible_user &&
            this.form.changeValue(
              `${data.responsible_user.last_name} ${
                data.responsible_user.first_name![0]
              } ${data.responsible_user.middle_name![0]}.`,
              "user"
            );
          this.record.recorded_at = data.recorded_at;
          this.record.audio_record = data.audio_record;
          this.record.lineName = data.call_line.short_name;
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  saveEditedForm = () => {
    const formData = new FormData();
    formData.append("comment", this.form.values.comment || "");
    formData.append("clientPhone", this.form.values.clientPhone || "");

    return recordsService.saveEditedRecord(this.record.id, formData);
  };

  onPlayEvent = (id: number | null) => {
    return recordsService.playEvent(id);
  };

  reset = () => {
    this.form.reset();
    this.isLoading = false;
    this.record = {
      recorded_at: "",
      name: "",
      audio_record: "",
      id: null,
      lineName: "",
    };
  };
}
