import { FormStore } from "@kit/kit-aiss";
import { object, string } from "yup";
import { makeAutoObservable } from "mobx";
import subThemasService from "../../../../../services/subthemas";

const schema = object({
  topic: string()
    .typeError("Введите название подтемы")
    .required("Введите название подтемы"),
});

export class EditSubThemeModalStore {
  form = new FormStore({ topic: null }, schema);

  constructor() {
    makeAutoObservable(this);
  }

  init = (caption: string) => {
    this.form.changeValue(caption, "topic");
  };

  reset = () => {
    this.form.reset();
  };

  onSave = (topicId: string, subtopicId: string) => {
    this.form.validate();
    if (this.form.isValid) {
      return this.editSubTheme(topicId, subtopicId);
    }
  };

  editSubTheme = (topicId: string, subtopicId: string) => {
    const formData = new FormData();
    formData.append("title", this.form.values.topic || "");
    return subThemasService.editSubTheme(topicId, subtopicId, formData);
  };

  disableSubTheme = (id: number | null) => {
    const formData = new FormData();
    formData.append("ids[]", String(id));
    return subThemasService.deactivateSubTheme(formData);
  };

  enableSubTheme = (id: number | null) => {
    const formData = new FormData();
    formData.append("ids[]", String(id));
    return subThemasService.activateSubTheme(formData);
  };
}
