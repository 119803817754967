import "./styles.scss";
import { DatePickerIcon as CrmDatePickerIcon } from "@kit/kit-aiss";
import { IDatePickerProps } from "@kit/kit-aiss/dist/components/DatePicker/index.types";
import { FC } from "react";

const DatePickerIcon: FC<IDatePickerProps> = (props) => {
  return (
    <div className="crm-date-picker-icon">
      <CrmDatePickerIcon {...props} />
    </div>
  );
};

export default DatePickerIcon;
