import { Modal } from "@kit/kit-aiss";
import Button from "../../components/Button";
import React, { useState } from "react";
import "./style.scss";

export const useOnConfirm = (
  title: React.ReactNode,
  btnTitle: string,
  btnHanlder: () => void | Promise<void>
) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const isShowConfirm = () => {
    setIsOpenModal(!isOpenModal);
  };

  const onClickBtn = () => {
    btnHanlder();
    setIsOpenModal(false);
  };

  const ComponentModalConfirm = (
    <Modal
      className="modal-confirm"
      isOpen={isOpenModal}
      onClose={() => setIsOpenModal(false)}
      title={title}
    >
      <Button className="modal-confirm__btn" type="button" onClick={onClickBtn}>
        {btnTitle}
      </Button>
    </Modal>
  );

  return {
    ModalConfirm: ComponentModalConfirm,
    isShowConfirm,
  };
};
