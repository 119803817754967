import { makeAutoObservable, runInAction } from "mobx";
import { FormStore } from "@kit/kit-aiss";
import subThemasService from "../../../../../../services/subthemas";
import themasService from "../../../../../../services/themas";
import { ISelectOption } from "@kit/kit-aiss/dist/components/Select/index.types";
import {
  IAttribute,
  IAttributeValue,
} from "../../../../../Atributes/store/types";
import callsService from "../../../../../../services/calls";
import { callSchema, callSchemaEmpty } from "./validationSchema";
import { STORAGE_KEY_CREATE_CALL } from "../../../../../../constants/storage";
import { CreateCallModalStore } from "../../CreateCallModal/store";
import { stringToNumber } from "../../../../../../utils/stringToNumber";
import sipControllerStore from "../../../../../../stores/GlobalStatusStore/SipController";
import appealsService from "../../../../../../services/appeals";
import GlobalStatusStore from "../../../../../../stores/GlobalStatusStore";
import * as yup from "yup";

const defaultValues = {
  topic: "",
  clientName: "",
  patientName: "",
  socialStatus: null,
  medicalOrganization: "",
  city: "",
  description: "",
  shortAnswer: "",
  result: "",
};

const defaultValuesEmpty = {
  ...defaultValues,
  clientPhone: "",
  callLineId: "",
};

export class CallFormStore {
  form;
  attribute: AttributesStore | null = null;
  subTopic: SubTopicsStore | null = null;
  clientPhone: string | null = null;
  audio_record: string | null = null;
  lineName = "";
  lineNumber?: string;
  passToTfoms?: number;
  //   audio_record_name: string | null = null;
  created_at = "";
  isEdit = false;
  options: {
    topic: ISelectOption[];
    city: ISelectOption[];
    social: ISelectOption[];
    result: ISelectOption[];
    short: ISelectOption[];
    medicalOrganisations: ISelectOption[];
  } = {
    topic: [],
    city: [],
    social: [],
    result: [],
    short: [],
    medicalOrganisations: [],
  };

  type?: "create" | "createEmpty";
  createStore?: CreateCallModalStore;
  appeal_id: string | null = null;
  appeal: {
    data: string | null;
    first_name: string | null;
    last_name: string | null;
    id: number | null;
    type: string;
    middle_name: string | null;
  } = {
    data: null,
    first_name: null,
    last_name: null,
    id: null,
    type: "",
    middle_name: null,
  };
  change_log: any = [];
  links: { date: string; id: number; name: string; user: string }[] = [];

  constructor(
    type?: CallFormStore["type"],
    createStore?: CreateCallModalStore
  ) {
    makeAutoObservable(this);
    this.createStore = createStore;
    this.type = type;
    this.form =
      !type || type === "create"
        ? new FormStore(defaultValues, callSchema)
        : new FormStore(defaultValuesEmpty, callSchemaEmpty);
  }

  init = async (id?: number, type?: string) => {
    await this.loadOrganizations();
    if (this.type === "create" && sipControllerStore.session) {
      this.reset();
    }

    this.loadCreateForm({ id, type }).then(() => {
      if (
        this.type === "create" &&
        !sipControllerStore.session &&
        localStorage.getItem(STORAGE_KEY_CREATE_CALL)
      ) {
        this.loadCallFromStorage();
      }

      if (this.type === "create") {
        this.createStore?.storageSync?.startSync();
      }
    });
  };

  reset = () => {
    this.createStore?.storageSync?.stopSync();
    this.clientPhone = null;
    this.audio_record = null;
    this.created_at = "";
    this.form.reset();
    this.attribute = null;
    this.subTopic = null;
    this.lineName = "";
    this.appeal = {
      data: null,
      first_name: null,
      last_name: null,
      id: null,
      type: "",
      middle_name: null,
    };
    this.isEdit = false;
    this.change_log = [];
    this.lineNumber = undefined;
  };

  loadCreateForm = async (values: { id?: number; type?: string }) => {
    await callsService
      .loadCreateForm(values.id, values.type)
      .then(({ data }) => {
        const cityOptions = data.data.fields.find(
          (field: any) => field.name === "cityId"
        ).options;
        const topicOptions = data.data.fields.find(
          (field: any) => field.name === "topicId"
        ).options;
        const socialOptions = data.data.fields.find(
          (field: any) => field.name === "patientTypeId"
        ).options;
        const resultOptions = data.data.fields.find(
          (field: any) => field.name === "resultId"
        ).options;
        const shortOptions = data.data.fields.find(
          (field: any) => field.name === "answerTypeId"
        ).options;
        runInAction(() => {
          this.options.city = cityOptions.map((option: any) => ({
            text: option.label,
            value: option.value,
          }));
          this.options.topic = topicOptions
            .filter((item: any) => !item.deactivated_at)
            .map((option: any) => ({
              text: option.label,
              value: option.value,
            }));
          this.options.social = socialOptions.map((option: any) => ({
            text: option.label,
            value: option.value,
          }));
          this.options.result = resultOptions.map((option: any) => ({
            text: option.label,
            value: option.value,
          }));
          this.options.short = shortOptions.map((option: any) => ({
            text: option.label,
            value: option.value,
          }));
        });
      });
  };

  loadUncall = (id: number) => {
    callsService.getNoCallForm(String(id)).then(({ data }) => {
      runInAction(() => {
        this.clientPhone = data.data.phone;
        this.created_at = data.data.created_at;
        this.audio_record = data.data.audio_record;
        this.lineName = data.data.call_line?.short_name;
        this.lineNumber = data.data.call_line?.number;
      });
    });
  };

  loadCall = (id: number) => {
    callsService
      .loadCall(String(id))
      .then(({ data }) => {
        const callCardData = data?.data;

        this.form.changeValue(callCardData?.topic_id, "topic");
        this.form.changeValue(callCardData?.description, "description");
        this.form.changeValue(callCardData?.client_full_name, "clientName");
        this.form.changeValue(callCardData?.patient_full_name, "patientName");
        this.form.changeValue(callCardData?.patient_type_id, "socialStatus");
        this.form.changeValue(callCardData?.answer_text, "shortAnswer");
        this.form.changeValue(callCardData?.result_id, "result");
        this.form.changeValue(callCardData?.city_id, "city");
        this.form.changeValue(
          callCardData?.med_organization,
          "medicalOrganization"
        );

        runInAction(() => {
          this.change_log = callCardData?.change_log;
          this.appeal_id = callCardData?.appeal
            ? callCardData?.appeal.id
            : null;
          this.audio_record = callCardData?.audio_record;
          this.created_at = callCardData?.created_at;
          this.clientPhone = callCardData?.clientPhone;
          this.lineNumber = callCardData?.call_line?.number;
          this.passToTfoms = callCardData?.pass_to_tfoms;
          GlobalStatusStore.srcCallId = id;
        });

        if (callCardData?.topic_id) {
          subThemasService
            .loadSubThemas(this.form.values.topic || "", "calls")
            .then(({ data }) => {
              if (data.data.length === 0) return;

              runInAction(
                () => (this.subTopic = new SubTopicsStore(data.data, ""))
              );

              return true;
            })
            .then(() => {
              if (callCardData?.subtopic_id) {
                const subTopic = data.data.subtopic_id;

                subThemasService
                  .loadSubThemas(this.form.values.topic || "", "calls")
                  .then(({ data }) => {
                    runInAction(
                      () =>
                        (this.subTopic = new SubTopicsStore(
                          data.data,
                          subTopic
                        ))
                    );
                  });
              }
            });
        }

        if (callCardData?.attributes_values.length) {
          const attributesValues = callCardData?.attributes_values;
          if (callCardData?.topic_id && !callCardData?.subtopic_id) {
            themasService.loadTopic(callCardData?.topic_id).then(({ data }) => {
              runInAction(() => {
                this.attribute = new AttributesStore(
                  data.data.attributes,
                  attributesValues,
                  true
                );
              });
            });
          }

          if (callCardData?.topic_id && callCardData?.subtopic_id) {
            subThemasService
              .loadSubTheme(callCardData?.topic_id, callCardData?.subtopic_id)
              .then(({ data }) => {
                runInAction(() => {
                  this.attribute = new AttributesStore(
                    data.data.attributes,
                    attributesValues,
                    true
                  );
                });
              });
          }
        }
        runInAction(() => {
          this.clientPhone = callCardData?.client_phone;
          this.lineName = callCardData?.call_line.short_name;
        });
        return callCardData;
      })
      .then((res) => {
        if (res.appeal) {
          appealsService.loadAppeal(res.appeal.id).then(({ data }) => {
            if (data.status === "success" && data.data) {
              runInAction(() => {
                this.appeal!.data = data.data.created_at;
                this.appeal!.first_name = data.data.responsible_user.first_name;
                this.appeal!.last_name = data.data.responsible_user.last_name;
                this.appeal!.id = data.data.id;
                this.appeal!.middle_name =
                  data.data.responsible_user.middle_name;
                this.appeal!.type = "Обращение";

                GlobalStatusStore.setClientInfo(data.data);
              });
            }
          });
        }
      });
    this.loadLinks(id);
  };

  loadLinks = async (id: number) => {
    await callsService.getLinks(id).then((res) => {
      const { data } = res.data;

      if (data) {
        runInAction(() => {
          this.links = data.links.map((item: any) => {
            const obj = {
              date: "",
              id: null,
              name: "",
              user: "",
            };
            obj.date = item.date;
            obj.id = item.id;
            obj.name = item.name;
            obj.user = `${item.author.last_name} ${
              item.author.first_name![0]
            }. ${item.author.middle_name![0]}.`;

            return obj;
          });
        });
      }
    });
  };

  loadCallFromStorage = () => {
    const storageDataString = localStorage.getItem(STORAGE_KEY_CREATE_CALL);

    if (!storageDataString) return;

    const storageData = JSON.parse(storageDataString);

    this.form.setValues({
      topic: stringToNumber(storageData.topicId),
      description: storageData.description,
      clientName: storageData.clientFullName,
      patientName: storageData.patientFullName,
      socialStatus: stringToNumber(storageData.patientTypeId),
      shortAnswer: storageData.answerTypeId,
      result: stringToNumber(storageData.resultId),
      city: stringToNumber(storageData.cityId),
      medicalOrganization: storageData.medOrganization,
    });

    const attributesArray: Record<string, string>[] = [];

    Object.entries(storageData).forEach(([key, value]) => {
      if (key.match(/^attributes/)) {
        const arrayIdx = key.match(/(?<=attributes\[)\d(?=\])/)?.[0];
        const arrayItemKey = key.match(/(?<=attributes\[\d\]\[)\w+(?=\])/)?.[0];

        if (
          typeof arrayIdx === "string" &&
          typeof arrayItemKey === "string" &&
          typeof value === "string"
        ) {
          const finalKey =
            arrayItemKey === "attribute_value" ? "value" : arrayItemKey;

          if (attributesArray[Number(arrayIdx)]) {
            attributesArray[Number(arrayIdx)][finalKey] = value;
          } else {
            attributesArray[Number(arrayIdx)] = { [finalKey]: value };
          }
        }
      }
    });

    if (storageData.subtopicId) {
      const subTopic = storageData.subtopicId;
      subThemasService
        .loadSubThemas(this.form.values.topic || "", "calls")
        .then(({ data }) => {
          runInAction(() => {
            this.subTopic = new SubTopicsStore(data.data, Number(subTopic));
          });
        });
    }

    if (attributesArray?.length) {
      const attributesValues = attributesArray;

      if (storageData.topicId && !storageData.subtopicId) {
        themasService.loadTopic(storageData.topicId).then(({ data }) => {
          runInAction(() => {
            this.attribute = new AttributesStore(
              data.data.attributes,
              attributesValues as unknown as IAttributeValue[]
            );
          });
        });
      }

      if (storageData.topicId && storageData.subtopicId) {
        subThemasService
          .loadSubTheme(storageData.topicId, storageData.subtopicId)
          .then(({ data }) => {
            runInAction(() => {
              this.attribute = new AttributesStore(
                data.data.attributes,
                attributesValues as unknown as IAttributeValue[]
              );
            });
          });
      }
    }
    runInAction(() => {
      this.clientPhone = storageData.client_phone;
    });
  };

  onChangeTopic = (value: string, name?: string) => {
    this.isEdit = true;
    this.form.adapters.topic.onChange(value, name);
    this.form.resetError();

    subThemasService.loadSubThemas(value, "calls").then(({ data }) => {
      if (data.data.length === 0) {
        themasService.loadTopic(value).then(({ data }) => {
          runInAction(() => {
            this.attribute = new AttributesStore(data.data.attributes);
          });
        });
        runInAction(() => (this.subTopic = null));
      } else {
        runInAction(() => {
          this.subTopic = new SubTopicsStore(data.data);
          this.attribute = null;
        });
      }
    });
  };

  onChangeSubTopic = (value: string, name?: string) => {
    this.isEdit = true;
    this.subTopic?.form.adapters.subTopic.onChange(value, name);
    subThemasService
      .loadSubTheme(this.form?.values.topic || "", value)
      .then(({ data }) => {
        runInAction(
          () => (this.attribute = new AttributesStore(data.data.attributes))
        );
      });
  };

  onChangeFormValues = (value: string, name?: string) => {
    this.isEdit = true;
    this.form.changeValue(value, name);
  };

  changeEditStatus = (isEdit: boolean) => {
    this.isEdit = isEdit;
  };

  loadOrganizations = () => {
    return themasService.loadOrganizations().then(({ data }) => {
      runInAction(() => {
        this.options.medicalOrganisations = data.data.map((item: any) => {
          const obj = { text: "", value: "" };
          obj.text = item;
          obj.value = item;

          return obj;
        });
      });
    });
  };
}

export class SubTopicsStore {
  form = new FormStore({ subTopic: null });
  options: ISelectOption[] = [];

  constructor(subTopics: any[], formValue?: string | number) {
    makeAutoObservable(this);
    this.options = subTopics
      .filter((item) => !item.deactivated_at)
      .map((subTopic) => ({
        text: subTopic.title,
        value: subTopic.id,
      }));

    if (formValue) {
      this.form.changeValue(formValue, "subTopic");
    }
  }
}

export class AttributesStore {
  attributes: AttributeStore[] = [];

  constructor(
    attributes: IAttribute[],
    attributesValues?: IAttributeValue[],
    fromNet?: boolean
  ) {
    makeAutoObservable(this);

    this.attributes = attributes.map(
      (attribute) => new AttributeStore(attribute)
    );

    if (attributesValues) {
      for (let i = 0; i < this.attributes.length; i++) {
        const selectedAttribute = attributesValues.find(
          (attribute) =>
            this.attributes[i].attribute.id === Number(attribute.attribute_id)
        );

        if (selectedAttribute && this.attributes[i].attribute.type === 2) {
          const value = Number(selectedAttribute.value);
          this.attributes[i].changeAttributeValue(value, "attribute", fromNet);
        }

        // date
        if (selectedAttribute && this.attributes[i].attribute.type === 3) {
          const value = new Date(selectedAttribute.value);
          this.attributes[i].changeAttributeValue(value, "attribute", fromNet);
        }

        if (
          selectedAttribute &&
          this.attributes[i].attribute.type !== 2 &&
          this.attributes[i].attribute.type !== 3
        ) {
          this.attributes[i].changeAttributeValue(
            selectedAttribute.value,
            "attribute",
            fromNet
          );
        }
      }
    }
  }
}

const attributeFormValidationSchema = yup.object().shape({
  attribute: yup
    .string()
    .typeError("Необходимо заполнить поле")
    .min(1, "Необходимо заполнить поле"),
});

class AttributeStore {
  form = new FormStore({ attribute: null }, attributeFormValidationSchema);
  attribute: IAttribute;
  options: ISelectOption[] = [];
  attributesValues: IAttributeValue | null = null;
  isEditAttribute = false;

  constructor(attribute: IAttribute) {
    makeAutoObservable(this);
    this.attribute = attribute;
    this.options = attribute.options.map((options) => ({
      text: options.name,
      value: options.id,
    }));
  }

  changeAttributeValue = (
    value: string | Date | null | number,
    name?: string,
    fromNet?: boolean
  ) => {
    this.form.changeValue(value, name);
    this.isEditAttribute = !fromNet ? true : false;
  };

  changeIsEditState = (isEditAttribute: boolean) => {
    this.isEditAttribute = isEditAttribute;
  };

  reset = () => {
    this.isEditAttribute = false;
  };
}
