import "./styles.scss";

import React, { FC } from "react";
import { FlexColumn, FlexRow, H2 } from "@kit/kit-aiss";
import { IEditCallModalProps } from "./types";
import Form from "../Form";
import Button from "../../../../../components/Button";
import { observer } from "mobx-react-lite";
import Modal from "../../../../../components/Modal";
import Typography from "../../../../../components/Typography";
import sipControllerStore from "../../../../../stores/GlobalStatusStore/SipController";
import { ReactComponent as IconPhone } from "../../../../../assets/svg/phone.svg";
import GlobalStatusStore from "../../../../../stores/GlobalStatusStore";
import CrmTable from "../../../../../components/CrmTable";
import StorySwitcher from "../../../../../components/StorySwitcher";
import authStore from "../../../../AuthPage/store/authStore";
import { getFormattedDate } from "../../../../../utils/date/getFormattedDate";
import ReactAudioPlayer from "react-h5-audio-player";
import { toJS } from "mobx";
import { DownloadLink } from "../../../../../components/DownloadLink";

const EditCallModal: FC<IEditCallModalProps> = ({
  editCallModalStore,
  isOpen,
  onClose,
  id,
}) => {
  const [type, setType] = React.useState("connection");

  const handleSave = () => {
    console.log("store.form.form", toJS(editCallModalStore.form.form.values));
    editCallModalStore.form.form.validate();
    console.log(
      "editCallModalStore.form.form.isValid",
      editCallModalStore.form.form.isValid
    );

    if (!editCallModalStore.form.form.isValid) return;
    editCallModalStore.onSave(id)?.then(() => {
      onClose();
      editCallModalStore.loadCalls!("operator");
    });
  };

  const handleRecall = () => {
    if (editCallModalStore.form.clientPhone) {
      onClose();
      sipControllerStore.recall(
        editCallModalStore.form.clientPhone,
        editCallModalStore.form.lineNumber
      );
      GlobalStatusStore.setState("isOutGoing", true);
    }
  };

  const handleLinkClick = (id: string | null) => {
    GlobalStatusStore.setAppealId(Number(id));
    GlobalStatusStore.editAppealModalState.open();
    onClose();
  };

  const handleSwitch = (type: string) => {
    setType(type);
  };

  const [date, time] = editCallModalStore.form.created_at.split(" ");
  const [year, ,] = date.split("-");

  const result = getFormattedDate(date as unknown as Date, "dd MMMM");

  const handleCreateAppeal = async () => {
    onClose();
    GlobalStatusStore.resetClientInfo();
    GlobalStatusStore.setClientInfo({
      id,
      client_phone: editCallModalStore.form.clientPhone,
    });
    GlobalStatusStore.setState("isCreateAppeal", true);
    GlobalStatusStore.createAppealModalState.open();
  };

  return (
    <Modal
      id={"edit-call-modal"}
      title={
        <>
          <FlexRow className="edit-call-modal__header" isNoFlexChildren>
            <FlexRow gap={18}>
              <IconPhone className="edit-call-modal__header-call-icon" />
              <Typography variant="h2">Звонок</Typography>
            </FlexRow>

            <FlexRow
              className="edit-call-modal__header-actions"
              isNoFlexChildren
            >
              {authStore.role[0] === "operator" && (
                <Button
                  onClick={handleRecall}
                  disabled={
                    !editCallModalStore.form.clientPhone ||
                    GlobalStatusStore.states.paused
                  }
                  size="small"
                  className="edit-call-modal__header-actions-call"
                >
                  <IconPhone />
                  Перезвонить
                </Button>
              )}
            </FlexRow>
          </FlexRow>
          <FlexRow className="edit-call-modal__header--info">
            <FlexColumn gap={8}>
              <Typography
                color="color3"
                className="edit-call-modal__header--info_sub"
              >
                Дата/Время
              </Typography>
              <Typography variant="h1">
                {getFormattedDate(date, "dd.MM")}.{year} {time}
              </Typography>
            </FlexColumn>
            <FlexColumn gap={8}>
              <Typography className="edit-call-modal__header--info_sub">
                Линия
              </Typography>
              <Typography variant="h1">
                {editCallModalStore.form.lineName || "-"}
              </Typography>
            </FlexColumn>
          </FlexRow>
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
      className="edit-call-modal crm-modal"
    >
      <Form
        form={editCallModalStore.form}
        id={id}
        phone={editCallModalStore.form.clientPhone}
      />

      {editCallModalStore.form.audio_record && (
        <div className="audio-player">
          <ReactAudioPlayer
            src={`${editCallModalStore.form.audio_record}` as string}
            // showSkipControls
            // showFilledProgress
            // showJumpControls
            autoPlay={false}
            autoPlayAfterSrcChange={false}
          />
          <div className="audio-title">
            <span>
              {result} {year}, {time}
            </span>
          </div>
          <DownloadLink href={editCallModalStore.form.audio_record} />
        </div>
      )}

      <FlexRow>
        <Button onClick={handleCreateAppeal}>Создать обращение</Button>
        <Button onClick={handleSave}>Сохранить</Button>
      </FlexRow>

      {editCallModalStore.form.links.length !== 0 &&
        editCallModalStore.form.change_log.length !== 0 && (
          <div className="history_table">
            <H2>История</H2>
            {editCallModalStore.form.links.length !== 0 &&
              editCallModalStore.form.change_log.length !== 0 && (
                <StorySwitcher
                  handleSwitch={handleSwitch}
                  type={type}
                  length={editCallModalStore.form.change_log.length}
                  isHistory={editCallModalStore.form.change_log.length !== 0}
                  isAppeal={!!editCallModalStore.form.links.length}
                  linksLength={editCallModalStore.form.links.length}
                />
              )}
            {type === "connection" &&
              editCallModalStore.form.links.length !== 0 && (
                <CrmTable
                  headers={[
                    {
                      title: "Дата",
                    },
                    {
                      title: "Поле",
                    },
                    {
                      title: "Автор",
                    },
                  ]}
                  rows={editCallModalStore.form.links.map((item) => [
                    {
                      text: (
                        <span
                          className="connection"
                          onClick={() => {
                            handleLinkClick(String(item.id));
                          }}
                        >
                          {getFormattedDate(item.date, "dd.MM.yyyy HH:mm")}
                        </span>
                      ),
                    },
                    {
                      text: (
                        <span
                          className="connection"
                          onClick={() => {
                            handleLinkClick(String(item.id));
                          }}
                        >
                          {item.name}
                        </span>
                      ),
                    },

                    {
                      text: (
                        <span
                          className="connection"
                          onClick={() => {
                            handleLinkClick(String(item.id));
                          }}
                        >
                          {item.user}
                        </span>
                      ),
                    },
                  ])}
                />
              )}
            {type === "history" &&
              editCallModalStore.form.change_log.length !== 0 && (
                <CrmTable
                  headers={[
                    {
                      title: "Дата",
                    },
                    {
                      title: "Поле",
                    },
                    {
                      title: "Автор",
                    },
                  ]}
                  rows={editCallModalStore.form.change_log.map((item: any) => [
                    {
                      text: getFormattedDate(
                        item.updated_at,
                        "dd.MM.yyyy HH:mm"
                      ),
                    },
                    { text: item.field_name },
                    {
                      text: `${item.user.last_name} ${
                        item.user.first_name![0]
                      }. ${item.user.middle_name![0]}.`,
                    },
                  ])}
                />
              )}
          </div>
        )}
      {editCallModalStore.form.change_log.length === 0 &&
        editCallModalStore.form.links.length !== 0 && (
          <div className="history_table">
            <H2>История</H2>
            <StorySwitcher
              handleSwitch={handleSwitch}
              type={"connection"}
              length={editCallModalStore.form.links.length}
              isHistory={false}
              isAppeal={true}
              linksLength={editCallModalStore.form.links.length}
            />
            <CrmTable
              headers={[
                {
                  title: "Дата",
                },
                {
                  title: "Поле",
                },
                {
                  title: "Автор",
                },
              ]}
              rows={editCallModalStore.form.links.map((item) => [
                {
                  text: (
                    <span
                      className="connection"
                      onClick={() => {
                        handleLinkClick(String(item.id));
                      }}
                    >
                      {getFormattedDate(item.date, "dd.MM.yyyy HH:mm")}
                    </span>
                  ),
                },
                {
                  text: (
                    <span
                      className="connection"
                      onClick={() => {
                        handleLinkClick(String(item.id));
                      }}
                    >
                      {item.name}
                    </span>
                  ),
                },

                {
                  text: (
                    <span
                      className="connection"
                      onClick={() => {
                        handleLinkClick(String(item.id));
                      }}
                    >
                      {item.user}
                    </span>
                  ),
                },
              ])}
            />
          </div>
        )}
      {editCallModalStore.form.change_log.length !== 0 &&
        editCallModalStore.form.links.length === 0 && (
          <div className="history_table">
            <H2>История</H2>
            <StorySwitcher
              handleSwitch={handleSwitch}
              type={"history"}
              length={editCallModalStore.form.change_log.length}
              isHistory={true}
              isAppeal={false}
              linksLength={editCallModalStore.form.links.length}
            />
            <CrmTable
              headers={[
                {
                  title: "Дата",
                },
                {
                  title: "Поле",
                },
                {
                  title: "Автор",
                },
              ]}
              rows={editCallModalStore.form.change_log.map((item: any) => [
                { text: getFormattedDate(item.updated_at, "dd.MM.yyyy HH:mm") },
                { text: item.field_name },
                {
                  text: `${item.user.last_name} ${item.user.first_name![0]}. ${
                    item.user.middle_name![0]
                  }.`,
                },
              ])}
            />
          </div>
        )}
    </Modal>
  );
};

export default observer(EditCallModal);
