import "./styles.scss";
import React, { FC } from "react";
import TextField from "../../../../../components/TextField";
import Select from "../../../../../components/Select";
import { IUserFormProps } from "./types";
import { observer } from "mobx-react-lite";

const UserForm: FC<IUserFormProps> = ({ form, EditUserStore }) => {
  return (
    <div className="user-form">
      <TextField {...form.form.adapters.lastName} placeholder="Фамилия" />
      <TextField {...form.form.adapters.firstName} placeholder="Имя" />
      <TextField {...form.form.adapters.middleName} placeholder="Отчество" />
      <Select
        placeholder="Организация"
        value={form.form.values.organizationName}
        onChange={form.form.changeValue}
        name="organizationName"
        options={EditUserStore!.medicalOrganisations}
      />
      <Select
        placeholder="Статус"
        value={form.form.values.status}
        onChange={form.form.changeValue}
        name="status"
        options={EditUserStore!.status}
      />
      <TextField
        {...form.form.adapters.phone}
        placeholder="Телефон"
        mask={"8(999)999-99-99"}
      />
      <TextField {...form.form.adapters.email} placeholder="Email" />
      <TextField {...form.form.adapters.login} placeholder="Логин" />
      <TextField {...form.form.adapters.password} placeholder="Пароль" />
      <Select
        placeholder="Роль"
        value={form.form.values.role}
        onChange={form.form.changeValue}
        name="role"
        options={EditUserStore!.roles}
        errorMessage={form.form.errors.role}
      />
    </div>
  );
};

export default observer(UserForm);
