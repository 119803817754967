import { makeAutoObservable } from "mobx";
import { CallFormStore } from "../../Form/store";
import callsService from "../../../../../../services/calls";
import GlobalStatusStore from "../../../../../../stores/GlobalStatusStore";

export class EditCallModalStore {
  form: CallFormStore = new CallFormStore();
  loadCalls?: (type?: string) => void;

  constructor(loadCalls?: () => void) {
    makeAutoObservable(this);
    this.loadCalls = loadCalls;
  }

  onSave = (id: number) => {
    this.form.form.validate();
    if (this.form.form.isValid) {
      this.form.changeEditStatus(false);
      this.form.attribute?.attributes.forEach((item) => {
        item.changeIsEditState(false);
        item.reset();
      });
      return this.editCall(id);
    }
  };

  editCall = (id: number) => {
    const formData = new FormData();

    formData.append("clientPhone", this.form.clientPhone || "");
    formData.append("passToTfoms", String(this.form.passToTfoms || 0));
    formData.append("topicId", this.form.form.values.topic || "");
    formData.append("resultId", this.form.form.values.result || "");
    formData.append("cityId", this.form.form.values.city || "");
    formData.append("patientTypeId", this.form.form.values.socialStatus || "");
    formData.append("description", this.form.form.values.description || "");
    formData.append("clientFullName", this.form.form.values.clientName || "");
    formData.append("patientFullName", this.form.form.values.patientName || "");
    formData.append("answerText", this.form.form.values.shortAnswer || "");
    formData.append(
      "medOrganization",
      this.form.form.values.medicalOrganization || ""
    );
    formData.append("result", this.form.form.values.result || "");

    if (!GlobalStatusStore.callInfoId && GlobalStatusStore.callId) {
      formData.append("callId", String(GlobalStatusStore.callId));
    }

    if (this.form.subTopic) {
      formData.append(
        "subtopicId",
        this.form.subTopic.form.values.subTopic || ""
      );
    }
    if (this.form.attribute) {
      this.form.attribute.attributes.forEach((attribute, idx) => {
        if (attribute.form.values.attribute) {
          formData.append(
            `attributes[${idx}][attribute_id]`,
            String(attribute.attribute.id)
          );
          formData.append(
            `attributes[${idx}][attribute_value]`,
            String(attribute.form.values.attribute)
          );
        }
      });
    }

    return callsService.editCall(id, formData);
  };
}
