import { FlexColumn, FlexRow } from "@kit/kit-aiss";
import { observer } from "mobx-react";
import React from "react";
import Button from "../../../components/Button";
import LinesSelector from "../../../components/LinesSelector";
import Select from "../../../components/Select";
import TextField from "../../../components/TextField";
import { THEME_CATEGORIES } from "../constants";
import { ThemesStore } from "../store";
import Modal from "../../../components/Modal";

interface IAddThemePopupProps {
  themesStore: ThemesStore;
  isOpen: boolean;
  onClose: () => void;
}

const AddThemePopup: React.FC<IAddThemePopupProps> = ({
  themesStore,
  onClose,
  isOpen,
}) => {
  const closeHandler = () => {
    onClose();
    themesStore.addThemeForm.reset();
  };

  const handleSaveClick = () => {
    themesStore.checkCreate()?.then(() => onClose());
  };

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} title="Добавить новую тему">
      <FlexColumn gap={40}>
        <FlexColumn gap={20}>
          <TextField
            {...themesStore.addThemeForm.adapters.caption}
            placeholder="Введите название"
          />
          <Select
            options={THEME_CATEGORIES}
            {...themesStore.addThemeForm.adapters.category}
            placeholder="Раздел"
          />
          {!themesStore.addThemeForm.values.category && (
            <LinesSelector linesStore={themesStore.linesStore} />
          )}
        </FlexColumn>
        <FlexRow>
          <Button type="submit" onClick={handleSaveClick}>
            Сохранить
          </Button>
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
};

export default observer(AddThemePopup);
