import React, { FC } from "react";
import { FlexRow, H2 } from "@kit/kit-aiss";
import { IEditAppealModalProps } from "./types";
import { observer } from "mobx-react-lite";
import Form from "../Form";
import EditAppealModalHeading from "./components/EditAppealModalHeading";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";
import GlobalStatusStore from "../../../../../stores/GlobalStatusStore";
import callsService from "../../../../../services/calls";
import CrmTable from "../../../../../components/CrmTable";
import "./styles.scss";
import StorySwitcher from "../../../../../components/StorySwitcher";
import authStore from "../../../../AuthPage/store/authStore";
import sipControllerStore from "../../../../../stores/GlobalStatusStore/SipController";
import { getFormattedDate } from "../../../../../utils/date/getFormattedDate";

const EditAppealModal: FC<IEditAppealModalProps> = ({
  isOpen,
  onClose,
  appealId,
  editAppealStore,
  isAppealManager,
}) => {
  const [type, setType] = React.useState("connection");

  const handleEditModal = (id: number) => {
    editAppealStore
      .checkValid(id)
      ?.then(() => onClose())
      .then(() => editAppealStore.loadAppeal!());
  };

  const handleSwitch = (type: string) => {
    setType(type);
  };

  const handleToWorkdModal = (appealId: number) => {
    editAppealStore.form
      .takeToWorkStatus(appealId)
      .then(() => editAppealStore.loadAppeal!());
  };

  const handleProcessedModal = (appealId: number) => {
    editAppealStore.form
      .toProcessedStatus(appealId)
      .then(() => editAppealStore.editAppeal(appealId))
      .then(() => editAppealStore.form.loadAppeal(appealId))
      .then(() => {
        editAppealStore.loadAppeal!();
      });
  };

  const handleRestartAppeal = (appealId: number) => {
    editAppealStore
      .restartAppeal(appealId)
      .then(() =>
        editAppealStore
          .editAppeal(appealId)
          .then(() => editAppealStore.loadAppeal!())
      );
  };

  const handleLinkClick = (id: string | null) => {
    callsService.loadCall(String(id));
    GlobalStatusStore.getCallInfoId(Number(id));
    GlobalStatusStore.editCallModalState.open();
    onClose();
  };

  const handleRecall = () => {
    if (editAppealStore.form.form.values.clientPhone) {
      sipControllerStore.call(editAppealStore.form.form.values.clientPhone);
      GlobalStatusStore.setState("isOutGoing", false);
      onClose();
    }
  };

  return (
    <Modal
      title="Обращение"
      isOpen={isOpen}
      onClose={onClose}
      className="appeal-modal crm-modal"
    >
      {editAppealStore.form.expires && (
        <EditAppealModalHeading
          expires={editAppealStore.form.expires}
          date={editAppealStore.form.createDate || ""}
          isAppealManager={isAppealManager}
          restartAppeal={() => null}
          status={editAppealStore.form.status?.id || null}
          operatorComment={editAppealStore.form.form.values.operatorComment}
          lineName={editAppealStore.form.lineName}
        />
      )}
      {!editAppealStore.form.expires && (
        <EditAppealModalHeading
          expires={null}
          date={editAppealStore.form.createDate || ""}
          isAppealManager={isAppealManager}
          urgencyCategoryId={Number(
            editAppealStore.form.form.values.urgencyCategoryId
          )}
          restartAppeal={() => {
            handleRestartAppeal(appealId);
          }}
          status={editAppealStore.form.status?.id || null}
          operatorComment={editAppealStore.form.form.values.operatorComment}
          lineName={editAppealStore.form.lineName}
        />
      )}
      <Form
        isEdit
        isAppealManager={isAppealManager}
        appealId={appealId}
        appealsFormStore={editAppealStore.form}
        handleRecall={handleRecall}
      />
      <FlexRow>
        {isAppealManager && editAppealStore.form.status?.id === 1 && (
          <Button
            onClick={() => handleToWorkdModal(appealId)}
            disabled={!editAppealStore.form.expires}
          >
            Принять в обработку
          </Button>
        )}
        {isAppealManager && editAppealStore.form.status?.id === 5 && (
          <Button
            onClick={() => handleToWorkdModal(appealId)}
            disabled={!editAppealStore.form.expires}
          >
            Принять в обработку
          </Button>
        )}
        {isAppealManager && editAppealStore.form.status?.id === 2 && (
          <Button
            onClick={() => handleProcessedModal(appealId)}
            disabled={
              !editAppealStore.form.expires ||
              (!editAppealStore.form.form.values.appeal_manager_comment &&
                editAppealStore.form.status.id === 2)
            }
          >
            Обработано
          </Button>
        )}
        {isAppealManager && editAppealStore.form.status?.id === 3 && (
          <Button
            onClick={() => handleProcessedModal(appealId)}
            disabled={true}
          >
            Завершено
          </Button>
        )}

        {!isAppealManager && (
          <Button
            disabled={editAppealStore.isLoading}
            onClick={() => handleEditModal(appealId)}
          >
            Сохранить
          </Button>
        )}
      </FlexRow>

      {editAppealStore.form.call_id &&
        authStore.role[0] !== "appeals_manager" &&
        editAppealStore.form.change_log.length !== 0 && (
          <div className="history_table">
            <H2>История изменений</H2>
            <StorySwitcher
              handleSwitch={handleSwitch}
              type={type}
              length={editAppealStore.form.change_log.length}
              isHistory={editAppealStore.form.change_log.length !== 0}
              isAppeal={!!editAppealStore.form.call_id}
            />
            {type === "connection" && (
              <CrmTable
                headers={[
                  {
                    title: "Дата",
                  },
                  {
                    title: "Поле",
                  },
                  {
                    title: "Автор",
                  },
                ]}
                rows={[
                  [
                    {
                      text: (
                        <span
                          className="connection"
                          onClick={() => {
                            handleLinkClick(
                              String(editAppealStore.form.call.id)
                            );
                          }}
                        >
                          {getFormattedDate(
                            editAppealStore.form.call.data as string,
                            "dd.MM.yyyy HH:mm"
                          )}
                        </span>
                      ),
                    },
                    {
                      text: (
                        <span
                          className="connection"
                          onClick={() => {
                            handleLinkClick(
                              String(editAppealStore.form.call.id)
                            );
                          }}
                        >
                          {editAppealStore.form.call.type}
                        </span>
                      ),
                    },
                    {
                      text: (
                        <span
                          className="connection"
                          onClick={() => {
                            handleLinkClick(
                              String(editAppealStore.form.call.id)
                            );
                          }}
                        >
                          {`${editAppealStore.form.call.last_name} ${
                            editAppealStore.form.call.first_name![0]
                          }. ${editAppealStore.form.call.middle_name![0]}.`}
                        </span>
                      ),
                    },
                  ],
                ]}
              />
            )}
            {type === "history" && (
              <CrmTable
                headers={[
                  {
                    title: "Дата",
                  },
                  {
                    title: "Поле",
                  },
                  {
                    title: "Автор",
                  },
                ]}
                rows={editAppealStore.form.change_log.map((item: any) => [
                  {
                    text: getFormattedDate(item.updated_at, "dd.MM.yyyy HH:mm"),
                  },
                  { text: item.field_name },
                  {
                    text: `${item.user.last_name} ${item.user.first_name![0]} ${
                      item.user.middle_name![0]
                    }`,
                  },
                ])}
              />
            )}
          </div>
        )}
      {editAppealStore.form.change_log.length !== 0 &&
        authStore.role[0] !== "appeals_manager" &&
        !editAppealStore.form.call_id && (
          <div className="history_table">
            <H2>История изменений</H2>
            <StorySwitcher
              handleSwitch={handleSwitch}
              type={"history"}
              length={editAppealStore.form.change_log.length}
              isHistory={true}
              isAppeal={false}
            />

            <CrmTable
              headers={[
                {
                  title: "Дата",
                },
                {
                  title: "Поле",
                },
                {
                  title: "Автор",
                },
              ]}
              rows={editAppealStore.form.change_log.map((item: any) => [
                {
                  text: getFormattedDate(item.updated_at, "dd.MM.yyyy HH:mm"),
                },
                { text: item.field_name },
                {
                  text: `${item.user.last_name} ${item.user.first_name![0]} ${
                    item.user.middle_name![0]
                  }`,
                },
              ])}
            />
          </div>
        )}
      {editAppealStore.form.change_log.length === 0 &&
        authStore.role[0] !== "appeals_manager" &&
        editAppealStore.form.call_id && (
          <div className="history_table">
            <H2>История изменений</H2>
            <StorySwitcher
              handleSwitch={handleSwitch}
              type={"connection"}
              length={editAppealStore.form.change_log.length}
              isHistory={false}
              isAppeal={true}
            />

            <CrmTable
              headers={[
                {
                  title: "Дата",
                },
                {
                  title: "Поле",
                },
                {
                  title: "Автор",
                },
              ]}
              rows={[
                [
                  {
                    text: (
                      <span
                        className="connection"
                        onClick={() => {
                          handleLinkClick(String(editAppealStore.form.call.id));
                        }}
                      >
                        {getFormattedDate(
                          editAppealStore.form.call.data as string,
                          "dd.MM.yyyy HH:mm"
                        )}
                      </span>
                    ),
                  },
                  {
                    text: (
                      <span
                        className="connection"
                        onClick={() => {
                          handleLinkClick(String(editAppealStore.form.call.id));
                        }}
                      >
                        {editAppealStore.form.call.type}
                      </span>
                    ),
                  },
                  {
                    text: (
                      <span
                        className="connection"
                        onClick={() => {
                          handleLinkClick(String(editAppealStore.form.call.id));
                        }}
                      >
                        {`${editAppealStore.form.call.last_name} ${
                          editAppealStore.form.call.first_name![0]
                        }. ${editAppealStore.form.call.middle_name![0]}.`}
                      </span>
                    ),
                  },
                ],
              ]}
            />
          </div>
        )}
    </Modal>
  );
};

export default observer(EditAppealModal);
