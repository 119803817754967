import { makeAutoObservable, runInAction } from "mobx";
import appealsService from "../../../services/appeals";
import { IAppeal } from "../../Appeals/store/types";
import { EditAppealStore } from "../../Appeals/components/popups/EditAppealModal/store";
import { SortStore } from "../../../stores/SortStore";
import { APPEALS_SORT_ITEMS } from "../../Appeals/store";
import { getFormattedDate } from "../../../utils/date/getFormattedDate";
import shiftService from "../../../services/shifts";
import { formatISO } from "date-fns";

export class AppealsOperatorStore {
  appeals: { count: 0; appeals: IAppeal[] } = { count: 0, appeals: [] };
  editAppealStore: EditAppealStore;
  sortStore: SortStore<typeof APPEALS_SORT_ITEMS[number]>;
  isLoading = false;
  pageCount = 1;
  date: Date | null = new Date();

  statisticsBlock = {
    negativeCount: 0,
    approveCount: 0,
    operatorsCount: 0,
    rejectCount: 0,
    appealsOverdueCount: 0,
    appealsAcceptedCount: 0,
    pr: 0,
    processed: 0,
  };

  constructor() {
    makeAutoObservable(this);
    this.editAppealStore = new EditAppealStore(this.loadMyAppeals);
    this.sortStore = new SortStore(
      [...APPEALS_SORT_ITEMS],
      this.loadMyAppeals,
      this.resetCountOsSortClick
    );
  }

  loadAppeals = () => {
    appealsService.loadAppeals().then(({ data }) => {
      runInAction(() => {
        this.appeals = data.data;
      });
    });
  };

  onChangeDate = (date: Date | null) => {
    this.date = date;
    this.loadMyAppeals();
  };

  loadMyAppeals = () => {
    this.isLoading = true;
    const date =
      this.date === null
        ? formatISO(new Date()).split("T")
        : formatISO(this.date).split("T");

    shiftService.loadOperatorStatistics(date[0]).then(({ data }) => {
      runInAction(() => {
        this.statisticsBlock = {
          negativeCount: 0,
          approveCount: data.data.accepted_calls_count,
          operatorsCount: data.data.operators_count,
          rejectCount: data.data.declined_calls_count,
          appealsOverdueCount: data.data.appeals_overdue_count,
          appealsAcceptedCount: data.data.appeals_count,
          pr: data.data.appeals_processed_count,
          processed: data.data.appeals_in_progress_count,
        };
      });
    });

    appealsService
      .loadMyAppeals(
        this.sortStore.searchParams,
        1,
        this.pageCount * 10,
        (date[0] as unknown as string) || ""
      )
      .then(({ data }) => {
        runInAction(() => {
          if (data.data.appeals) {
            this.appeals.appeals = data.data.appeals;
            this.appeals.count = data.data.count;
          }
        });
      })
      .finally(() => {
        runInAction(() => (this.isLoading = false));
      });
  };

  reset = () => {
    this.appeals = { count: 0, appeals: [] };
    this.pageCount = 1;
    this.date = new Date();
  };

  incrementPageCount = () => {
    this.pageCount += 1;
  };

  resetCountOsSortClick = () => {
    this.appeals = { count: 0, appeals: [] };
    this.pageCount = 1;
  };

  get rows() {
    return (
      this.appeals.appeals.map((appeal) => [
        {
          text: getFormattedDate(appeal.created_at, "dd.MM.yyyy HH:mm"),
        },
        {
          text: appeal.responsible_user.number ?? "-",
        },
        {
          text: appeal.call_line?.short_name ?? "-",
        },
        {
          text: appeal.topic.title ?? "-",
        },
        {
          text: appeal.status?.label ?? "-",
        },
        {
          text: appeal.callback_grade.label ?? "-",
        },
        {
          text: appeal.contact_phone ?? "-",
        },
      ]) || []
    );
  }
}
