import "./styles.scss";

import { FlexRow } from "@kit/kit-aiss";
import React from "react";

import { ReactComponent as IconPlus } from "../../../../assets/svg/plus.svg";
import IconDividerVertical from "../../../../components/icons/IconDividerVertical";
import Typography from "../../../../components/Typography";
import Button from "../../../../components/Button";
import { DownloadButton } from "../../../../components/DownloadButton";
import { observer } from "mobx-react-lite";

export interface ITopBarProps {
  onAddButtonClick: () => void;
  operatorsActive: number;
  operatorsTotal: number;
  onDownload: () => void;
}

const TopBar: React.FC<ITopBarProps> = ({
  onAddButtonClick,
  operatorsActive,
  operatorsTotal,
  onDownload,
}) => {
  return (
    <FlexRow className="top-bar">
      <FlexRow gap={20}>
        <Typography variant="sub-text-medium" color="color3">
          Активных
        </Typography>
        <Typography variant="title2">{operatorsActive}</Typography>
        <IconDividerVertical height={24} />
        <Typography variant="sub-text-medium" color="color3">
          Всего
        </Typography>
        <Typography variant="title2">{operatorsTotal}</Typography>
      </FlexRow>
      <FlexRow gap={20} isNoFlexChildren>
        <Button
          className="top-bar__button"
          variant="transparent"
          size="small"
          onClick={onAddButtonClick}
        >
          <IconPlus />
          <Typography variant="sub-text-medium">Добавить оператора</Typography>
        </Button>
        <IconDividerVertical height={24} />
        <DownloadButton onClick={onDownload} />
      </FlexRow>
    </FlexRow>
  );
};

export default observer(TopBar);
