import { autorun, IReactionDisposer, makeAutoObservable } from "mobx";

class StorageSyncStore<T extends object> {
  private autorunDisposer?: IReactionDisposer;

  private store: T;
  private key: keyof T;
  private storageKey: string;

  constructor(store: T, key: keyof T, storageKey: string) {
    makeAutoObservable(this);
    this.store = store;
    this.key = key;
    this.storageKey = storageKey;
  }

  startSync = () => {
    if (this.autorunDisposer) return;

    this.autorunDisposer = autorun(() => {
      const value = this.store[this.key];
      if (value) {
        localStorage.setItem(this.storageKey, JSON.stringify(value));
      }
    });
  };

  stopSync = () => {
    this.autorunDisposer?.();
    this.autorunDisposer = undefined;
  };
}

export default StorageSyncStore;
