import { makeAutoObservable, runInAction } from "mobx";
import { FormStore } from "@kit/kit-aiss";
import { ISelectOption } from "@kit/kit-aiss/dist/components/Select/index.types";
import attributesService from "../../../../../../services/attributes";
import { number, object, string } from "yup";

const schema = object({
  title: string()
    .typeError("Введите название атрибута")
    .required("Введите название атрибута"),
  name: string().typeError("Введите вопрос").required("Введите вопрос"),
  type: number().required("Выберите тип поля"),
});

const defaultValues = {
  title: null,
  name: null,
  type: null,
};

export class AttributeFormStore {
  form = new FormStore(defaultValues, schema);
  fields: FieldsStore[] = [];
  id: number | null = null;
  options: { types: ISelectOption[] } = {
    types: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  init = (id: number | null) => {
    this.loadTypes();
    this.id = id;
  };

  reset = () => {
    this.form.reset();
    this.fields = [];
  };

  validate = () => {
    this.form.validate();
    if (this.fields.length) this.fields.forEach((field) => field.validate());
  };

  isValid = () => {
    return this.form.isValid && !this.fields.some((field) => !field.isValid());
  };

  addField = (option?: string) => {
    this.fields.push(new FieldsStore(option));
  };

  deleteField = (idx: number) => {
    this.fields.splice(idx, 1);
  };

  onChangeType = (value: number, name?: string) => {
    this.form.adapters.type.onChange(value, name);

    this.fields = [];
    if (this.form.values.type === 2) {
      this.addField();
    }
  };

  loadTypes = () => {
    attributesService.loadTypes().then(({ data }) => {
      runInAction(() => {
        this.options.types = data.data.map((type: any) => ({
          value: type.type,
          text: type.title,
        }));
      });
    });
  };

  deleteAttribute = () => {
    return attributesService.deleteAttribute(this.id || 0);
  };
}

const fieldSchema = object({
  option: string()
    .typeError("Введите название варианта")
    .required("Введите название варианта"),
});

class FieldsStore {
  form = new FormStore({ option: null }, fieldSchema);

  constructor(option?: string) {
    makeAutoObservable(this);
    this.form.changeValue(option || "", "option");
  }

  validate = () => {
    this.form.validate();
  };

  isValid = () => {
    return this.form.isValid;
  };
}
