import { makeAutoObservable } from "mobx";

class Subscribers {
  private items: (() => void)[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  subscribe = (subscriber: () => void) => {
    this.items.push(subscriber);
  };

  unsubscribe = (subscriber: () => void) => {
    const idx = this.items.findIndex(subscriber);
    this.items.splice(idx, 1);
  };

  notifyAll = () => {
    this.items.forEach((item) => item());
  };
}

export default Subscribers;
