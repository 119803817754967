import React, { FC } from "react";
import Modal from "../../../../../components/Modal";
import { ICreateUserProps } from "./types";
import UserForm from "../UserCreateForm";
import Button from "../../../../../components/Button";
import { FlexRow } from "@kit/kit-aiss";
import { observer } from "mobx-react-lite";

const CreateUser: FC<ICreateUserProps> = ({
  isOpen,
  onClose,
  CreateUserStore,
  store,
}) => {
  const handleEditClick = () => {
    CreateUserStore!.saveCreateUser().then(() => {
      if (CreateUserStore.form.form.isValid) {
        onClose();
        CreateUserStore.form.form.reset();
        store.loadUsers();
      }
    });
  };

  return (
    <Modal
      title="Добавить нового пользователя"
      isOpen={isOpen}
      onClose={onClose}
    >
      <UserForm form={CreateUserStore.form} CreateUserStore={CreateUserStore} />
      <FlexRow>
        <Button onClick={handleEditClick}>Сохранить</Button>
      </FlexRow>
    </Modal>
  );
};

export default observer(CreateUser);
