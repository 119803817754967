import { makeAutoObservable, runInAction } from "mobx";
import { IUsers } from "./types";
import usersService from "../../../services/users";
import { SortStore } from "../../../stores/SortStore";
import { getFormattedDate } from "../../../utils/date/getFormattedDate";
import FileSaver from "file-saver";

export const SHIFTS_SORT_ITEMS = ["last_name"] as const;

export class UsersStore {
  sortStore: SortStore<typeof SHIFTS_SORT_ITEMS[number]>;
  users: { count: 0; activeCount: 0; users: IUsers[] } = {
    count: 0,
    activeCount: 0,
    users: [],
  };
  isLoading = false;
  isActiveUsers = false;
  pageCount = 1;

  constructor() {
    this.sortStore = new SortStore([...SHIFTS_SORT_ITEMS], this.loadUsers);
    makeAutoObservable(this);
  }

  init = () => {
    this.loadUsers();
  };

  loadUsers = () => {
    runInAction(() => {
      this.isLoading = true;
    });

    usersService
      .loadUsers(this.sortStore.searchParams, 1, this.pageCount * 20)
      .then((res) => {
        const { data } = res.data;

        runInAction(() => {
          this.users = data;
        });
      });

    this.isLoading = false;
  };

  incrementPageCount = () => {
    this.pageCount += 1;
  };

  setIsActiveUsers = (value: boolean) => {
    this.isActiveUsers = value;
  };

  reset = () => {
    this.users = { count: 0, activeCount: 0, users: [] };
    this.isLoading = false;
    this.isActiveUsers = false;
  };

  downloadReport = () => {
    usersService
      .downloadUsersReport(this.sortStore.searchParams, 1, this.pageCount * 20)
      .then(({ data }) => {
        const fileName = "Пользователи.xlsx";
        FileSaver.saveAs(data, fileName);
      });
  };

  get rows() {
    return this.users.users.map((user) => [
      {
        text: `${user.last_name} ${user.first_name} ${user.middle_name}`,
      },
      {
        text: user.organization_name,
      },
      {
        text: user.position,
      },
      {
        text: getFormattedDate(user.last_activity!, "dd.MM.yyyy HH:mm") || "-",
      },
      {
        text: user.status ? "Активная" : "Неактивная",
      },
    ]);
  }
}
