import "./styles.scss";

import { FlexColumn, FlexRow, LoaderSpinner } from "@kit/kit-aiss";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import MainLayout from "../../components/layouts/MainLayout";
import AddThemePopup from "./components/AddThemePopup";
import themesStore from "./store";
import TopicItem from "./components/TopicItem";
import EditThemePopup from "./components/EditThemePopup";
import { generatePath, useNavigate } from "react-router-dom";
import { URL_SUB_THEMES } from "../../constants/urls";
import Typography from "../../components/Typography";
import Card from "../../components/Card";
import ButtonAdd from "../../components/ButtonAdd";

const ThemesPage: React.FC = () => {
  const navigate = useNavigate();
  const [isOpenAddThemePopup, setisOpenAddThemePopup] = useState(false);
  const [isOpenEditThemePopup, setisOpenEditThemePopup] = useState(false);
  const [editId, setEditId] = useState<null | number>(null);
  const [editedCaption, setEditedCaption] = useState("");
  const [deactivateStatus, setDeactivateStatus] = useState(false);
  const [tableType, setTableType] = useState<string | undefined>(undefined);

  useEffect(() => {
    themesStore.init();

    return () => {
      themesStore.reset();
    };
  }, []);

  const handleOpenPopup = () => {
    themesStore.addThemeForm.reset();
    themesStore.linesStore.form.reset();
    setisOpenAddThemePopup(true);
  };

  const handleClosePopup = () => {
    setisOpenAddThemePopup(false);
    themesStore.addThemeForm.reset();
  };

  const handleEdipOpenPopup = (
    caption: string,
    id: number | null,
    deactivated: boolean,
    tabletype?: string,
    lines?: number[]
  ) => {
    setEditedCaption(caption);
    setEditId(id);
    setisOpenEditThemePopup(true);
    setDeactivateStatus(deactivated);
    setTableType(tabletype);
    themesStore.linesStore.form.reset();

    if (lines) themesStore.linesStore.form.changeValueByKey(lines, "lines");
  };

  const handleCloseEditPopup = () => {
    setisOpenEditThemePopup(false);
  };

  const handleNavigate = (themId: number, type?: string) => {
    navigate(generatePath(URL_SUB_THEMES, { themId: String(themId), type }));
  };

  return (
    <MainLayout status={false}>
      <div className="themes-page">
        <FlexRow className="themes-page__header">
          <Typography variant="h1">Темы</Typography>
          <ButtonAdd onClick={handleOpenPopup} />
        </FlexRow>

        {themesStore.isLoading ? (
          <LoaderSpinner />
        ) : (
          <FlexColumn gap={20}>
            <Card className="topics">
              <FlexColumn gap={20}>
                <Typography variant="h4">Темы звонков</Typography>
                <div className="topics-count">
                  <span className="topics-count__active">
                    Активных
                    <span className="topics-count__active--number">
                      {
                        themesStore.topics.calls.filter(
                          (item) => !item.deactivated
                        ).length
                      }
                    </span>
                  </span>
                  <span className="topics-count__all">
                    Всего
                    <span className="topics-count__all--number">
                      {themesStore.topics.calls.length}
                    </span>
                  </span>
                </div>
                {themesStore.topics.calls.map((item, idx) => {
                  return (
                    <TopicItem
                      topic={item}
                      key={idx}
                      handleNavigate={handleNavigate}
                      handleEdipOpenPopup={handleEdipOpenPopup}
                      calls={"calls"}
                    />
                  );
                })}
              </FlexColumn>
            </Card>
            <Card className="topics">
              <FlexColumn gap={20}>
                <Typography variant="h4">Темы обращений</Typography>
                <div className="topics-count">
                  <span className="topics-count__active">
                    Активных
                    <span className="topics-count__active--number">
                      {
                        themesStore.topics.appeals.filter(
                          (item) => !item.deactivated
                        ).length
                      }
                    </span>
                  </span>
                  <span className="topics-count__all">
                    Всего
                    <span className="topics-count__all--number">
                      {themesStore.topics.appeals.length}
                    </span>
                  </span>
                </div>
                {themesStore.topics.appeals.map((item, idx) => {
                  return (
                    <TopicItem
                      topic={item}
                      key={idx}
                      handleNavigate={handleNavigate}
                      handleEdipOpenPopup={handleEdipOpenPopup}
                      appeals={"appeals"}
                    />
                  );
                })}
              </FlexColumn>
            </Card>
          </FlexColumn>
        )}
      </div>
      <AddThemePopup
        themesStore={themesStore}
        isOpen={isOpenAddThemePopup}
        onClose={handleClosePopup}
      />
      <EditThemePopup
        caption={editedCaption}
        themesStore={themesStore}
        topic={String(editId)}
        isOpen={isOpenEditThemePopup}
        onClose={handleCloseEditPopup}
        deactivated={deactivateStatus}
        tableType={tableType}
      />
    </MainLayout>
  );
};

export default observer(ThemesPage);
