import "./styles.scss";

import cn from "classnames";
import React from "react";

interface AvatarProps {
  lastName: string;
  firstName: string;
  className?: string;
  isInactive?: boolean;
}

const Avatar: React.FC<AvatarProps> = (props) => {
  return (
    <div
      className={cn(
        "avatar",
        props.isInactive && "avatar--inactive",
        props.className
      )}
    >
      {props.lastName[0]?.toLocaleUpperCase()}{" "}
      {props.firstName[0]?.toLocaleUpperCase()}
    </div>
  );
};

export default Avatar;
