import "./styles.scss";

import cn from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { URL_AUTH } from "../../../constants/urls";
import authStore from "../../../pages/AuthPage/store/authStore";
import Header from "../../Header";
import SideMenu from "../../SideMenu";
import Button from "../../Button";
import GlobalStatusStore from "../../../stores/GlobalStatusStore";
import sipControllerStore from "../../../stores/GlobalStatusStore/SipController";
import Caller from "../../Caller";

interface IMainLayoutProps {
  children: React.ReactNode;
  status: boolean;
  bgWhite?: boolean;
}

/** статус нужен для страницы оператора, чтобы красиво менять цвет, когда звонок поставлен на паузу в остальных случаях кидай false, братишка */

const MainLayout: React.FC<IMainLayoutProps> = ({
  children,
  status,
  bgWhite,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    authStore.checkAuth();
    if (!authStore.isAuth) {
      navigate(`/${URL_AUTH}`);
      authStore.logOut();
    }
  }, [authStore.isAuth]);

  useEffect(() => {
    scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [pathname]);

  return (
    <div className={cn("main-layout", status ? "paused" : "not-paused")}>
      <Header />
      <div className="container">
        <SideMenu />
        <div
          className={cn(
            "main-layout__body",
            bgWhite ? "main-layout__body--white" : ""
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default observer(MainLayout);
