import React, { ComponentProps, ReactNode } from "react";

import Typography from "../../Typography";

const BodyCell: React.FC<{
  children: ReactNode;
  color?: ComponentProps<typeof Typography>["color"];
}> = ({ children, color = "color1" }) => {
  return (
    <td>
      <Typography color={color}>{children}</Typography>
    </td>
  );
};

export default BodyCell;
