import "./styles.scss";

import { TextField as KitTextField } from "@kit/kit-aiss";
import {
  ITextFieldProps,
} from "@kit/kit-aiss/dist/components/TextField/index.types";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

const TextField: React.FC<ITextFieldProps> = (props) => {
  return (
    <KitTextField
      {...props}
      className={cn(props.className, "crm-text-field")}
    />
  );
};

export default observer(TextField);
