import React from "react";
import cn from "classnames";

import "./styles.scss";
import { observer } from "mobx-react";

interface ITabs {
  tabs: { text: string; value: string | null; active: boolean }[];
  getCellChartsData: (cellType: string | null) => void;
  setActiveTab: (id: number) => void;
}

const CellTabs: React.FC<ITabs> = ({
  tabs,
  getCellChartsData,
  setActiveTab,
}) => {
  const [activeId, setActiveId] = React.useState(0);

  const handleTabClick = (id: number) => {
    setActiveId(id);
    getCellChartsData(tabs[id].value);
    setActiveTab(id);
  };

  return (
    <div className="cell-tabs">
      {tabs.map((item, idx) => {
        return (
          <div
            className={cn(
              "cell-tabs__tab",
              activeId === idx && "cell-tabs__tab--active"
            )}
            key={idx}
            onClick={() => {
              handleTabClick(idx);
            }}
          >
            {item.text}
          </div>
        );
      })}
    </div>
  );
};

export default observer(CellTabs);
