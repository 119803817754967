import { makeAutoObservable, runInAction } from "mobx";
import { UserFormStore } from "../../UserForm/store";
import usersService from "../../../../../../services/users";
import { ISelectOption } from "@kit/kit-aiss/dist/components/Select/index.types";
import themasService from "../../../../../../services/themas";

export class EditUserStore {
  form: UserFormStore = new UserFormStore();
  isLoading = false;
  medicalOrganisations: ISelectOption[] = [];
  id: number | null = null;
  status = [
    {
      text: "Активный",
      value: true,
    },
    { text: "Неактивный", value: false },
  ];

  roles = [
    {
      text: "Администратор системы",
      value: "manager",
    },
    {
      text: "Работа с обращениями",
      value: "appeals_manager",
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.form.form.reset();
    this.isLoading = false;
    this.medicalOrganisations = [];
    this.id = null;
  };

  init = async (id: number) => {
    this.loadUser(id);
    this.loadOrganizations();
  };

  loadUser = async (id: number) => {
    runInAction(() => {
      this.id = id;
      this.isLoading = true;
    });
    await usersService
      .loadEditedUser(id)
      .then((res) => {
        const { data } = res.data;

        runInAction(() => {
          this.form.form.changeValue(data.first_name, "firstName");
          this.form.form.changeValue(data.last_name, "lastName");
          this.form.form.changeValue(data.middle_name, "middleName");
          this.form.form.changeValue(data.login, "login");
          this.form.form.changeValue(Boolean(data.status), "status");
          this.form.form.changeValue(
            data.organization_name,
            "organizationName"
          );
          this.form.form.changeValue(data.password, "password");
          this.form.form.changeValue(data.phone, "phone");
          this.form.form.changeValue(data.access_groups[0].slug, "role");
          this.form.form.changeValue(data.email, "email");
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  loadOrganizations = () => {
    themasService.loadOrganizations().then((res) => {
      const { data } = res.data;
      this.medicalOrganisations = data.map((item: string) => {
        const obj = { text: "", value: "" };
        obj.text = item;
        obj.value = item;

        return obj;
      });
    });
  };

  saveEditUser = async () => {
    this.form.form.validate();

    if (!this.form.form.isValid) return false;

    const formData = new FormData();
    formData.append("role", "appeals_manager");
    formData.append(
      "firstName",
      this.form.form.values.firstName as unknown as string
    );
    formData.append(
      "lastName",
      this.form.form.values.lastName as unknown as string
    );
    formData.append(
      "middleName",
      this.form.form.values.middleName as unknown as string
    );
    formData.append("login", this.form.form.values.login as unknown as string);
    formData.append(
      "organizationName",
      this.form.form.values.organizationName as unknown as string
    );
    formData.append(
      "status",
      Number(this.form.form.values.status) as unknown as string
    );
    formData.append("email", this.form.form.values.email as unknown as string);
    formData.append(
      "password",
      (this.form.form.values.password as unknown as string) || ""
    );
    formData.append(
      "phone_number",
      this.form.form.values.phone as unknown as string
    );
    formData.append("role", this.form.form.values.role as unknown as string);

    await usersService.saveEditUser(this.id!, formData).then(() => {
      return true;
    });
  };
}
