import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { FlexRow } from "@kit/kit-aiss";
import { ICreateAttributeModalProps } from "./types";
import Form from "../Form";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";

const CreateAttributeModal: FC<ICreateAttributeModalProps> = ({
  onClose,
  isOpen,
  store,
}) => {
  const createAttribute = () => {
    store.checkValidate()?.then(() => onClose());
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Добавить новый атрибут">
      <Form form={store.form} />
      <FlexRow>
        <Button onClick={createAttribute}>Сохранить</Button>
      </FlexRow>
    </Modal>
  );
};

export default observer(CreateAttributeModal);
