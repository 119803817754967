import { makeAutoObservable, runInAction } from "mobx";
import attributesService from "../../../services/attributes";
import { IAttribute } from "./types";
import { CreateAttributeModalStore } from "../components/popups/CreateAttributeModal/store";
import { EditAttributeModalStore } from "../components/popups/EditAttributeModal/store";

export class AttributesStore {
  attributes: IAttribute[] = [];
  createAttributeModalStore: CreateAttributeModalStore;
  editAttributeModalStore: EditAttributeModalStore;

  constructor() {
    makeAutoObservable(this);
    this.createAttributeModalStore = new CreateAttributeModalStore(
      this.loadAttributes
    );
    this.editAttributeModalStore = new EditAttributeModalStore(
      this.loadAttributes
    );
  }

  loadAttributes = () => {
    attributesService.loadAttributes().then(({ data }) => {
      runInAction(() => {
        this.attributes = data.data.map((attribute: any) => ({
          id: attribute.id,
          name: attribute.name,
          title: attribute.title,
          type: attribute.type,
          createdAt: attribute.created_at,
          updatedAt: attribute.updated_at,
          deactivatedAt: attribute.deactivated_at,
          options: attribute.options.map((option: any) => ({
            id: option.id,
            name: option.name,
            attributeId: option.attribute_id,
            createdAt: option.created_at,
            updatedAt: option.updated_at,
          })),
        }));
      });
    });
  };
}
