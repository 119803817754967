import { makeAutoObservable, runInAction } from "mobx";
import appealsService from "../../../services/appeals";
import { IAppeal } from "./types";
import { EditAppealStore } from "../components/popups/EditAppealModal/store";
import { getFormattedDate } from "../../../utils/date/getFormattedDate";
import { SortStore } from "../../../stores/SortStore";
import FileSaver from "file-saver";
import { formatISO } from "date-fns";
import shiftService from "../../../services/shifts";
import { FilterPopupStore } from "../FilterPopup/store";
import authStore from "../../AuthPage/store/authStore";

export const APPEALS_SORT_ITEMS = [
  "created_at",
  "responsible_user_number",
  "call_line_id",
  "topic_title",
  "urgency_category_id",
  //   "client_phone",
  "contact_phone",
] as const;

export class AppealsStore {
  appeals: { count: 0; appeals: IAppeal[] } = { count: 0, appeals: [] };
  editAppealStore: EditAppealStore;
  sortStore: SortStore<typeof APPEALS_SORT_ITEMS[number]>;
  isLoading = false;
  pageCount = 1;
  filterPopupStore: FilterPopupStore;
  date: Date | null = new Date();

  statisticsBlock = {
    negativeCount: 0,
    approveCount: 0,
    operatorsCount: 0,
    rejectCount: 0,
    appealsOverdueCount: 0,
    appealsAcceptedCount: 0,
  };

  appealStaticticBlock = {
    appeals_count: 0,
    appeals_created_count: 0,
    appeals_overdue_count: 0,
    appeals_processed_count: 0,
  };

  constructor() {
    makeAutoObservable(this);
    this.editAppealStore = new EditAppealStore(this.loadAppeals);
    this.sortStore = new SortStore(
      [...APPEALS_SORT_ITEMS],
      this.loadAppeals,
      this.resetCountOsSortClick
    );
    this.filterPopupStore = new FilterPopupStore(this.loadAppeals);
  }

  loadAppeals = () => {
    this.isLoading = true;
    const date = formatISO(this.date!).split("T");

    shiftService.loadShiftsStatistics(date[0]).then(({ data }) => {
      runInAction(() => {
        this.statisticsBlock = {
          negativeCount: data.data
            ? data.data.appeals_negative_grade_count
            : null,
          approveCount: data.data ? data.data.accepted_calls_count : null,
          operatorsCount: data.data ? data.data.operators_count : null,
          rejectCount: data.data ? data.data.declined_calls_count : null,
          appealsOverdueCount: data.data
            ? data.data.overdue_appeals_count
            : null,
          appealsAcceptedCount: data.data ? data.data.appeals_count : null,
        };
      });
    });

    const startDate = this.filterPopupStore.form.values.startDate
      ? formatISO(this.filterPopupStore.form.values.startDate).split("T")[0]
      : "";
    const endDate = this.filterPopupStore.form.values.endDate
      ? formatISO(this.filterPopupStore.form.values.endDate).split("T")[0]
      : "";

    appealsService
      .loadAppeals(
        this.sortStore.searchParams,
        1,
        this.pageCount * 10,
        authStore.role[0] === "appeals_manager" ? date[0] : "",
        authStore.role[0] !== "appeals_manager" ? startDate : "",
        authStore.role[0] !== "appeals_manager" ? endDate : "",
        this.filterPopupStore.form.values.thema as unknown as number,
        this.filterPopupStore.form.values.subThema as unknown as number,
        this.filterPopupStore.form.values.line as unknown as number,
        this.filterPopupStore.form.values.operator as unknown as number,
        this.filterPopupStore.form.values.status as unknown as number,
        this.filterPopupStore.form.values.grade as unknown as number
      )
      .then(({ data }) => {
        runInAction(() => {
          this.appeals.appeals = data.data.appeals;
          this.appeals.count = data.data.count;
        });
      })
      .finally(() => {
        runInAction(() => (this.isLoading = false));
      });
  };

  onChangeDate = (date: Date | null) => {
    this.date = date;
    this.loadAppeals();
    this.loadOperatorAppeals();
  };

  loadOperatorAppeals = () => {
    const date = formatISO(this.date!).split("T");
    shiftService.loadAppealsOperatorStatistic(date[0]).then((res) => {
      const { data } = res.data;
      if (!Array.isArray(data)) {
        runInAction(() => {
          this.appealStaticticBlock = {
            appeals_count: data.appeals_count || 0,
            appeals_created_count: data.appeals_processed_count || 0,
            appeals_overdue_count: data.appeals_overdue_count || 0,
            appeals_processed_count: data.appeals_in_progress_count || 0,
          };
        });
      }
    });
  };

  getRingsReport = () => {
    const date = formatISO(this.date!).split("T");
    const startDate = this.filterPopupStore.form.values.startDate
      ? formatISO(this.filterPopupStore.form.values.startDate).split("T")[0]
      : "";
    const endDate = this.filterPopupStore.form.values.endDate
      ? formatISO(this.filterPopupStore.form.values.endDate).split("T")[0]
      : "";

    appealsService
      .downloadReport(
        this.sortStore.searchParams,
        1,
        this.pageCount * 10,
        authStore.role[0] === "appeals_manager" ? date[0] : "",
        authStore.role[0] !== "appeals_manager" ? startDate : "",
        authStore.role[0] !== "appeals_manager" ? endDate : "",
        this.filterPopupStore.form.values.thema as unknown as number,
        this.filterPopupStore.form.values.subThema as unknown as number,
        this.filterPopupStore.form.values.line as unknown as number,
        this.filterPopupStore.form.values.operator as unknown as number,
        this.filterPopupStore.form.values.status as unknown as number,
        this.filterPopupStore.form.values.grade as unknown as number
      )
      .then(({ data }) => {
        const fileName = `Отчет по количеству обращений.csv`;
        FileSaver.saveAs(
          new Blob([data], { type: "text/csv;charset=utf-8" }),
          fileName
        );
      });
  };

  reset = () => {
    this.appeals = { count: 0, appeals: [] };
    this.pageCount = 1;
  };

  incrementPageCount = () => {
    this.pageCount += 1;
  };

  resetCountOsSortClick = () => {
    this.appeals = { count: 0, appeals: [] };
    this.pageCount = 1;
  };

  get rows() {
    return (
      this.appeals.appeals.map((appeal) => [
        {
          text: getFormattedDate(appeal.created_at, "dd.MM.yyyy HH:mm"),
        },
        {
          text: appeal.responsible_user.number ?? "-",
        },
        {
          text: appeal.call_line?.short_name ?? "-",
        },
        {
          text: appeal.topic.title ?? "-",
        },
        {
          text: appeal.status?.label ?? "-",
        },
        {
          text: appeal.callback_grade.label,
        },
        {
          text: appeal.contact_phone ?? "-",
        },
      ]) || []
    );
  }
}
