import "./styles.scss";
import React, { useEffect } from "react";
import MainLayout from "../../components/layouts/MainLayout";
import { observer } from "mobx-react-lite";
import { ShiftsStore } from "./store";
import StatisticBlock from "../Appeals/components/StatisticBlock";
import CrmTable from "../../components/CrmTable";
import Typography from "../../components/Typography";
import { ReactComponent as PrintIcon } from "../../assets/svg/print.svg";
import { ReactComponent as DownloadIcon } from "../../assets/svg/download-icon.svg";
import { ReactComponent as BlueVectorIcon } from "../../assets/svg/blue-vector.svg";
import { ReactComponent as TurquoiseVectorIcon } from "../../assets/svg/turquoise-vector.svg";
import Pagination from "../../components/Pagination";
import CrmCustomTable from "../../components/CrmCustomTable";
import { LoaderSpinner } from "@kit/kit-aiss";
import Avatar from "../../components/UserComponent/Avatar";
import { monthNames, weekDays } from "../../constants/months";
import { useNavigate } from "react-router-dom";

const store = new ShiftsStore();

const Shifts = () => {
  const navigate = useNavigate();
  const [today, setDate] = React.useState(new Date());

  const date =
    localStorage.getItem("shiftDate") && localStorage.getItem("shiftDate");

  useEffect(() => {
    if (date !== "null" && date !== null) {
      store.onPrintChangeDate(date as string);
      store.loadOperators(new Date(date as string));
      setDate(new Date(date as string));
    } else {
      store.init();
      store.loadOperators();
    }

    return () => {
      store.reset();
      localStorage.removeItem("shiftDate");
    };
  }, []);

  const handleChangeDate = (date: Date | null) => {
    store.onChangeDate(date);
    setDate(date as Date);
    store.loadOperators(date as Date);
  };

  const handlePlusDate = () => {
    setDate(new Date(today.setDate(today.getDate() + 1)));
    store.onChangeDate(new Date(today.setDate(today.getDate())));
    store.loadOperators(new Date(today.setDate(today.getDate())));
  };

  const handleMinusDate = () => {
    setDate(new Date(today.setDate(today.getDate() - 1)));
    store.onChangeDate(new Date(today.setDate(today.getDate())));
    store.loadOperators(new Date(today.setDate(today.getDate())));
  };

  const handleDownloadLinesReport = () => {
    store.downloadLinesReport(store.date);
  };

  const handlePrintClick = () => {
    navigate(`/shifts-print`);
    localStorage.setItem("shiftDate", store.date as unknown as string);
  };

  return (
    <MainLayout status={false}>
      <div className="shifts">
        <div className="shifts__pagination">
          <Pagination
            title={`${
              weekDays[today.getDay() - 1 > -1 ? today.getDay() - 1 : 6]
            }, ${today.getDate()} ${monthNames[today.getMonth()]}`}
            onNext={() => {
              handlePlusDate();
            }}
            onPrev={() => {
              handleMinusDate();
            }}
            date={store.date}
            onChangeDate={handleChangeDate}
            className="manager"
          />
          {/* <div className="shifts__pagination-button">
            <Button onClick={() => null}>
              <DownloadIcon />
              <Typography variant="sub-text-medium">
                Скачать отчёт по смене
              </Typography>
            </Button>
          </div> */}
        </div>
        {store.isLoading ? (
          <LoaderSpinner />
        ) : (
          <>
            <StatisticBlock
              negativeCount={store.statisticsBlock.negativeCount}
              approveCount={store.statisticsBlock.approveCount}
              operatorsCount={store.statisticsBlock.operatorsCount}
              rejectCount={store.statisticsBlock.rejectCount}
              appealsOverdueCount={store.statisticsBlock.appealsOverdueCount}
              appealsAcceptedCount={store.statisticsBlock.appealsAcceptedCount}
            />
            <div className="shifts__lines-table">
              <div className="shifts__lines-table-heading">
                <Typography variant="h4">
                  Линии ({store.lines?.length})
                </Typography>
                <div className="shifts__lines-table-heading-icons">
                  <PrintIcon onClick={handlePrintClick} />
                  <div className="shifts__lines-table-heading-icons__divider" />
                  <DownloadIcon onClick={handleDownloadLinesReport} />
                </div>
              </div>
              <CrmCustomTable
                isHoverBg
                isFullWidth
                headers={[
                  {
                    title: "Статус, номер линии",
                    width: 400,
                  },
                  {
                    title: "Операторы",
                  },
                  {
                    title: "В очереди",
                  },
                  {
                    title: "Время ожидания",
                  },
                  {
                    title: "Звонки",
                  },
                  {
                    title: "Обращения",
                  },
                  {
                    title: "Сбросы",
                  },
                ]}
                rows={store.lines.map((line) => [
                  {
                    text: (
                      <div className="table-cell">
                        {line.status ? (
                          <div className="svg">
                            <BlueVectorIcon />
                          </div>
                        ) : (
                          <div className="svg">
                            <TurquoiseVectorIcon />
                          </div>
                        )}
                        <Typography variant="h3">{line.number}</Typography>
                      </div>
                    ),
                  },
                  {
                    text: (
                      <Typography variant="h3">{line.operators}</Typography>
                    ),
                  },
                  {
                    text: <Typography variant="h3">{line.queue}</Typography>,
                  },
                  {
                    text: <Typography variant="h3">{line.time}</Typography>,
                  },
                  {
                    text: (
                      <div>
                        <Typography variant="h3">{line.calls}</Typography>
                      </div>
                    ),
                  },
                  {
                    text: (
                      <div>
                        <Typography variant="h3">{line.appeals}</Typography>
                      </div>
                    ),
                  },
                  {
                    text: (
                      <div>
                        <Typography variant="h3">{line.resets}</Typography>
                      </div>
                    ),
                  },
                ])}
              />
            </div>
            <div className="shifts__operators-table">
              <Typography variant="h4">Операторы</Typography>
              <CrmTable
                isHoverBg
                isFullWidth
                headers={[
                  {
                    title: "Статус",
                  },
                  {
                    title: "ФИО",
                  },
                  {
                    title: "Номер",
                  },
                  {
                    title: "Линии",
                    width: 300,
                  },
                  {
                    title: "Принято",
                  },
                  {
                    title: "Создано",
                  },
                  {
                    title: "Время на линии",
                  },
                  {
                    title: "Паузы",
                  },
                ]}
                rows={store.operatorsRows.map((row, idx) => [
                  {
                    text: (
                      <Avatar
                        firstName={store.operators[idx]?.firstName as string}
                        lastName={store.operators[idx]?.lastName as string}
                        isInactive={!store.operators[idx]?.status}
                      />
                    ),
                  },
                  ...row,
                ])}
              />
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default observer(Shifts);
