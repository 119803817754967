import { FlexColumn, FlexRow } from "@kit/kit-aiss";
import { observer } from "mobx-react";
import React from "react";
import Button from "../../../components/Button";
import DatePicker from "../../../components/DatePicker";
import Modal from "../../../components/Modal";
import Select from "../../../components/Select";
import { FilterPopupStore } from "./store";

interface IFilterProps {
  isOpen: boolean;
  onClose: () => void;
  store: FilterPopupStore;
}

const FilterPopup: React.FC<IFilterProps> = ({ isOpen, onClose, store }) => {
  React.useEffect(() => {
    store.init();

    // return () => {
    //   store.reset();
    // };
  }, []);

  const handleResetForm = () => {
    store.resetForm();
  };

  const handleSearchClick = () => {
    store.getRings();
    store.changeShowing(true);
    onClose();
  };

  const handleClose = () => {
    store.reset();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title="Фильтр обращений">
      <FlexColumn>
        <Select
          options={store.themasList}
          value={store.form.values.thema}
          onChange={store.onChangeTopic}
          placeholder={"Тема"}
        />
        {store.subThemasList.length !== 0 && (
          <Select
            options={store.subThemasList}
            {...store.form.adapters.subThema}
            placeholder={"Подтема"}
          />
        )}
        <FlexRow>
          <DatePicker
            name="startDate"
            value={store.form.values.startDate}
            placeholder={"Выбрать дату и время от"}
            onChange={store.form.changeValue}
          />
          <DatePicker
            name="endDate"
            value={store.form.values.endDate}
            placeholder={"Выбрать дату и время до"}
            onChange={store.form.changeValue}
          />
        </FlexRow>
        <Select
          options={store.operatorsList}
          {...store.form.adapters.operator}
          placeholder={"Оператор"}
        />
        <Select
          options={store.linesList}
          {...store.form.adapters.line}
          placeholder={"Линия"}
        />
        <Select
          options={store.statusesList}
          {...store.form.adapters.status}
          placeholder="Статус"
        />
        <Select
          options={store.gradesList}
          {...store.form.adapters.grade}
          placeholder="Оценка"
        />

        <FlexRow>
          <Button onClick={handleResetForm} variant="secondary">
            Очистить фильтр
          </Button>
          <Button onClick={handleSearchClick} variant="primary">
            Применить
          </Button>
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
};

export default observer(FilterPopup);
