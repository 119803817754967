import "./styles.scss";

import cn from "classnames";

import { Button as KitButton } from "@kit/kit-aiss";
import { ComponentPropsWithoutRef } from "react";

const Button: React.FC<ComponentPropsWithoutRef<typeof KitButton>> = (
  props
) => {
  return <KitButton {...props} className={cn(props.className, "crm-button")} />;
};

export default Button;
