import Button from "../Button";
import { ReactComponent as IconPlus } from "../../assets/svg/plus.svg";

export interface IButtonAddProps {
  onClick: () => void;
}

const ButtonAdd: React.FC<IButtonAddProps> = ({ onClick }) => {
  return (
    <Button variant="secondary" size="small" onClick={onClick}>
      <IconPlus style={{ margin: "-4px" }} />
      Добавить новую тему
    </Button>
  );
};

export default ButtonAdd;
