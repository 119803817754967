import { makeAutoObservable, runInAction } from "mobx";
import attributesService from "../../../../services/attributes";
import linesService from "../../../../services/lines";
import operatorsService from "../../../../services/operators";
import { FormStore } from "../../../../stores/FormStore";

export class FilterPopupStore {
  operatorsList: { value: number | null; text: string }[] = [];
  linesList: { value: number | null; text: string }[] = [];
  statusesList: { value: number | null; text: string }[] = [];
  getRecords: () => void;
  showReset = false;

  form = new FormStore({
    line: null,
    operator: null,
    startDate: new Date(),
    endDate: new Date(),
    status: null,
  });

  constructor(getRecords: () => void) {
    makeAutoObservable(this);
    this.getRecords = getRecords;
  }

  init = () => {
    this.getOperatorsList();
    this.getLines();
    this.getStatuses();
  };

  getOperatorsList = () => {
    operatorsService.loadOperators().then((res) => {
      const { data } = res.data;
      console.log("data", data);
      runInAction(() => {
        this.operatorsList = data.users.map((item: any) => {
          const obj = {
            text: "",
            value: null,
          };
          obj.text = `${item.last_name} ${item.first_name![0]}. ${
            item.middle_name !== null ? item.middle_name[0] + "." : ""
          }`;
          obj.value = item.id;

          return obj;
        });
        this.operatorsList.unshift({
          text: "Все",
          value: null,
        });
      });
    });
  };

  getLines = () => {
    linesService.loadLines().then((res) => {
      const { data } = res.data;
      const { items } = data;
      runInAction(() => {
        this.linesList = items.map((item: any) => {
          const obj = {
            text: "",
            value: null,
          };
          obj.text = item.short_name;
          obj.value = item.id;

          return obj;
        });
        this.linesList.unshift({
          text: "Все",
          value: null,
        });
      });
    });
  };

  getStatuses = () => {
    attributesService.loadRecordStatuses().then((res) => {
      const { data } = res.data;
      runInAction(() => {
        this.statusesList = data.map((item: any) => {
          const obj = {
            text: "",
            value: null,
          };

          obj.text = item.label;
          obj.value = item.id;

          return obj;
        });

        this.statusesList.unshift({
          text: "Все",
          value: null,
        });
      });
    });
  };

  changeShowing = (show: boolean) => {
    this.showReset = show;
  };

  reset = () => {
    this.operatorsList = [];
    this.linesList = [];
    this.form.reset("line");
    this.form.reset("operator");
    this.form.reset("status");
    this.form.changeValue(new Date(), "startDate");
    this.form.changeValue(new Date(), "endDate");
    this.showReset = false;
    this.getRecords();
  };

  resetForm = () => {
    this.form.reset("line");
    this.form.reset("operator");
    this.form.reset("status");
    this.form.changeValue(new Date(), "startDate");
    this.form.changeValue(new Date(), "endDate");
  };
}
