import { makeAutoObservable } from "mobx";
import { FormStore } from "@kit/kit-aiss";
import { validationSchema } from "../../CreateUser/store/validationSchema";

const defaultValues = {
  firstName: null,
  middleName: null,
  lastName: null,
  status: false,
  organizationName: null,
  login: null,
  password: null,
  phone: null,
  email: null,
  role: null,
};

export class UserFormStore {
  form = new FormStore(defaultValues, validationSchema);

  constructor() {
    makeAutoObservable(this);
  }
}
