export const DEFAULT_QUEUES = [
  {
    id: "704",
    number: "73432047676",
    caption: "Ед.регистратура",
    agents: [
      {
        id: "13150",
        password: null,
      },
      {
        id: "13107",
        password: null,
      },
      {
        id: "10013",
        password: null,
      },
      {
        id: "13123",
        password: null,
      },
      {
        id: "13159",
        password: null,
      },
      {
        id: "13137",
        password: null,
      },
      {
        id: "13151",
        password: null,
      },
      {
        id: "13108",
        password: null,
      },
      {
        id: "10029",
        password: null,
      },
      {
        id: "10020",
        password: null,
      },
      {
        id: "10021",
        password: null,
      },
      {
        id: "13156",
        password: null,
      },
      {
        id: "10037",
        password: null,
      },
      {
        id: "10030",
        password: null,
      },
      {
        id: "10035",
        password: null,
      },
      {
        id: "10025",
        password: null,
      },
      {
        id: "13144",
        password: null,
      },
      {
        id: "13142",
        password: null,
      },
      {
        id: "13182",
        password: null,
      },
      {
        id: "10032",
        password: null,
      },
      {
        id: "10010",
        password: null,
      },
      {
        id: "13155",
        password: null,
      },
      {
        id: "13175",
        password: null,
      },
      {
        id: "10005",
        password: null,
      },
      {
        id: "13178",
        password: null,
      },
      {
        id: "10038",
        password: null,
      },
      {
        id: "13147",
        password: null,
      },
      {
        id: "10004",
        password: null,
      },
      {
        id: "10023",
        password: null,
      },
      {
        id: "10017",
        password: null,
      },
      {
        id: "10007",
        password: null,
      },
      {
        id: "10027",
        password: null,
      },
      {
        id: "13001",
        password: null,
      },
      {
        id: "10003",
        password: null,
      },
      {
        id: "13188",
        password: null,
      },
      {
        id: "13152",
        password: null,
      },
      {
        id: "10006",
        password: null,
      },
      {
        id: "10028",
        password: null,
      },
      {
        id: "10040",
        password: null,
      },
      {
        id: "10012",
        password: null,
      },
      {
        id: "13148",
        password: null,
      },
      {
        id: "10022",
        password: null,
      },
      {
        id: "10015",
        password: null,
      },
      {
        id: "10033",
        password: null,
      },
      {
        id: "10041",
        password: null,
      },
      {
        id: "10031",
        password: null,
      },
      {
        id: "10018",
        password: null,
      },
      {
        id: "10034",
        password: null,
      },
      {
        id: "10019",
        password: null,
      },
      {
        id: "10014",
        password: null,
      },
      {
        id: "10036",
        password: null,
      },
      {
        id: "13161",
        password: null,
      },
      {
        id: "10024",
        password: null,
      },
      {
        id: "13157",
        password: null,
      },
      {
        id: "13153",
        password: null,
      },
      {
        id: "13160",
        password: null,
      },
      {
        id: "13163",
        password: null,
      },
      {
        id: "13162",
        password: null,
      },
      {
        id: "10039",
        password: null,
      },
      {
        id: "13158",
        password: null,
      },
      {
        id: "10026",
        password: null,
      },
      {
        id: "13154",
        password: null,
      },
    ],
  },
  {
    id: "705",
    number: "78003333118",
    caption: "Обл.Нарк.Больн.",
    agents: [
      {
        id: "13178",
        password: null,
      },
      {
        id: "13182",
        password: null,
      },
      {
        id: "13163",
        password: null,
      },
      {
        id: "13175",
        password: null,
      },
      {
        id: "13151",
        password: null,
      },
      {
        id: "13155",
        password: null,
      },
      {
        id: "13156",
        password: null,
      },
      {
        id: "13001",
        password: null,
      },
      {
        id: "13188",
        password: null,
      },
      {
        id: "13150",
        password: null,
      },
      {
        id: "13152",
        password: null,
      },
      {
        id: "13147",
        password: null,
      },
      {
        id: "13153",
        password: null,
      },
      {
        id: "13162",
        password: null,
      },
      {
        id: "13157",
        password: null,
      },
      {
        id: "13123",
        password: null,
      },
      {
        id: "13160",
        password: null,
      },
      {
        id: "13111",
        password: null,
      },
      {
        id: "13108",
        password: null,
      },
      {
        id: "13148",
        password: null,
      },
      {
        id: "13107",
        password: null,
      },
      {
        id: "13154",
        password: null,
      },
    ],
  },
  {
    id: "706",
    number: "73433629025",
    caption: "ТФОМС",
    agents: [
      {
        id: "13142",
        password: null,
      },
      {
        id: "10011",
        password: null,
      },
      {
        id: "13152",
        password: null,
      },
      {
        id: "13160",
        password: null,
      },
      {
        id: "13178",
        password: null,
      },
      {
        id: "13153",
        password: null,
      },
      {
        id: "13107",
        password: null,
      },
      {
        id: "13182",
        password: null,
      },
      {
        id: "13161",
        password: null,
      },
      {
        id: "13163",
        password: null,
      },
      {
        id: "13151",
        password: null,
      },
      {
        id: "13155",
        password: null,
      },
      {
        id: "13157",
        password: null,
      },
      {
        id: "13156",
        password: null,
      },
      {
        id: "13188",
        password: null,
      },
      {
        id: "13150",
        password: null,
      },
      {
        id: "13111",
        password: null,
      },
      {
        id: "13175",
        password: null,
      },
      {
        id: "13108",
        password: null,
      },
      {
        id: "13162",
        password: null,
      },
      {
        id: "13148",
        password: null,
      },
    ],
  },
  {
    id: "708",
    number: "73432208214",
    caption: "ТЕСТ CRM",
    agents: [
      {
        id: "10012",
        password: null,
      },
      {
        id: "10000",
        password: null,
      },
      {
        id: "10004",
        password: null,
      },
      {
        id: "10001",
        password: null,
      },
      {
        id: "838",
        password: null,
      },
      {
        id: "10005",
        password: null,
      },
      {
        id: "10038",
        password: null,
      },
      {
        id: "10041",
        password: null,
      },
      {
        id: "10099",
        password: null,
      },
      {
        id: "10010",
        password: null,
      },
      {
        id: "10023",
        password: null,
      },
      {
        id: "10016",
        password: null,
      },
      {
        id: "10007",
        password: null,
      },
      {
        id: "10031",
        password: null,
      },
      {
        id: "10021",
        password: null,
      },
      {
        id: "10034",
        password: null,
      },
      {
        id: "10013",
        password: null,
      },
      {
        id: "10011",
        password: null,
      },
      {
        id: "10003",
        password: null,
      },
      {
        id: "10039",
        password: null,
      },
      {
        id: "10042",
        password: null,
      },
      {
        id: "10032",
        password: null,
      },
      {
        id: "10028",
        password: null,
      },
      {
        id: "10037",
        password: null,
      },
      {
        id: "10036",
        password: null,
      },
      {
        id: "10002",
        password: null,
      },
      {
        id: "10040",
        password: null,
      },
      {
        id: "10018",
        password: null,
      },
      {
        id: "10006",
        password: null,
      },
      {
        id: "10022",
        password: null,
      },
      {
        id: "10015",
        password: null,
      },
      {
        id: "10033",
        password: null,
      },
      {
        id: "10017",
        password: null,
      },
      {
        id: "10029",
        password: null,
      },
      {
        id: "10027",
        password: null,
      },
      {
        id: "10030",
        password: null,
      },
      {
        id: "10020",
        password: null,
      },
      {
        id: "10035",
        password: null,
      },
      {
        id: "10019",
        password: null,
      },
      {
        id: "10014",
        password: null,
      },
      {
        id: "10024",
        password: null,
      },
      {
        id: "10025",
        password: null,
      },
      {
        id: "10026",
        password: null,
      },
    ],
  },
  {
    id: "700",
    number: "122",
    caption: "мобилизация и здравоохранение",
    agents: [
      {
        id: "13199",
        password: null,
      },
      {
        id: "13145",
        password: null,
      },
      {
        id: "13138",
        password: null,
      },
      {
        id: "13174",
        password: null,
      },
      {
        id: "13175",
        password: null,
      },
      {
        id: "13117",
        password: null,
      },
      {
        id: "13127",
        password: null,
      },
      {
        id: "13102",
        password: null,
      },
      {
        id: "13119",
        password: null,
      },
      {
        id: "13110",
        password: null,
      },
      {
        id: "13189",
        password: null,
      },
      {
        id: "13167",
        password: null,
      },
      {
        id: "13222",
        password: null,
      },
      {
        id: "13172",
        password: null,
      },
      {
        id: "13133",
        password: null,
      },
      {
        id: "13103",
        password: null,
      },
      {
        id: "13173",
        password: null,
      },
      {
        id: "13221",
        password: null,
      },
      {
        id: "13134",
        password: null,
      },
      {
        id: "13226",
        password: null,
      },
      {
        id: "13121",
        password: null,
      },
      {
        id: "13132",
        password: null,
      },
      {
        id: "13171",
        password: null,
      },
      {
        id: "13101",
        password: null,
      },
      {
        id: "13224",
        password: null,
      },
      {
        id: "13164",
        password: null,
      },
      {
        id: "13111",
        password: null,
      },
      {
        id: "13129",
        password: null,
      },
      {
        id: "13113",
        password: null,
      },
      {
        id: "13181",
        password: null,
      },
      {
        id: "13136",
        password: null,
      },
      {
        id: "13166",
        password: null,
      },
      {
        id: "13105",
        password: null,
      },
      {
        id: "13170",
        password: null,
      },
      {
        id: "13168",
        password: null,
      },
      {
        id: "13140",
        password: null,
      },
      {
        id: "13161",
        password: null,
      },
      {
        id: "13223",
        password: null,
      },
      {
        id: "13225",
        password: null,
      },
      {
        id: "13115",
        password: null,
      },
      {
        id: "13104",
        password: null,
      },
      {
        id: "13106",
        password: null,
      },
      {
        id: "13176",
        password: null,
      },
      {
        id: "13207",
        password: null,
      },
      {
        id: "13126",
        password: null,
      },
      {
        id: "13159",
        password: null,
      },
      {
        id: "13165",
        password: null,
      },
      {
        id: "13116",
        password: null,
      },
      {
        id: "13149",
        password: null,
      },
      {
        id: "13144",
        password: null,
      },
      {
        id: "13142",
        password: null,
      },
      {
        id: "13137",
        password: null,
      },
      {
        id: "13202",
        password: null,
      },
      {
        id: "13120",
        password: null,
      },
      {
        id: "13205",
        password: null,
      },
      {
        id: "13195",
        password: null,
      },
      {
        id: "13203",
        password: null,
      },
      {
        id: "13180",
        password: null,
      },
      {
        id: "13204",
        password: null,
      },
      {
        id: "13201",
        password: null,
      },
      {
        id: "13209",
        password: null,
      },
      {
        id: "13206",
        password: null,
      },
      {
        id: "13208",
        password: null,
      },
      {
        id: "13143",
        password: null,
      },
      {
        id: "13141",
        password: null,
      },
      {
        id: "13157",
        password: null,
      },
      {
        id: "13123",
        password: null,
      },
      {
        id: "13150",
        password: null,
      },
      {
        id: "13128",
        password: null,
      },
      {
        id: "13131",
        password: null,
      },
      {
        id: "13122",
        password: null,
      },
      {
        id: "13188",
        password: null,
      },
      {
        id: "13125",
        password: null,
      },
      {
        id: "13156",
        password: null,
      },
      {
        id: "13118",
        password: null,
      },
      {
        id: "13135",
        password: null,
      },
      {
        id: "13169",
        password: null,
      },
      {
        id: "13130",
        password: null,
      },
      {
        id: "13191",
        password: null,
      },
      {
        id: "13194",
        password: null,
      },
      {
        id: "13192",
        password: null,
      },
      {
        id: "13182",
        password: null,
      },
      {
        id: "13183",
        password: null,
      },
      {
        id: "13139",
        password: null,
      },
      {
        id: "13187",
        password: null,
      },
      {
        id: "13001",
        password: null,
      },
      {
        id: "13193",
        password: null,
      },
      {
        id: "13158",
        password: null,
      },
      {
        id: "13185",
        password: null,
      },
      {
        id: "13107",
        password: null,
      },
      {
        id: "13186",
        password: null,
      },
      {
        id: "13148",
        password: null,
      },
      {
        id: "13190",
        password: null,
      },
      {
        id: "13184",
        password: null,
      },
      {
        id: "13153",
        password: null,
      },
      {
        id: "13000",
        password: null,
      },
      {
        id: "13152",
        password: null,
      },
      {
        id: "13163",
        password: null,
      },
      {
        id: "13108",
        password: null,
      },
      {
        id: "13155",
        password: null,
      },
      {
        id: "13160",
        password: null,
      },
      {
        id: "13147",
        password: null,
      },
      {
        id: "13162",
        password: null,
      },
      {
        id: "13178",
        password: null,
      },
      {
        id: "13151",
        password: null,
      },
      {
        id: "13154",
        password: null,
      },
    ],
  },
  {
    id: "701",
    number: "122",
    caption: "здравоохранение",
    agents: [
      {
        id: "13184",
        password: null,
      },
      {
        id: "13180",
        password: null,
      },
      {
        id: "13187",
        password: null,
      },
      {
        id: "13189",
        password: null,
      },
      {
        id: "13186",
        password: null,
      },
      {
        id: "13192",
        password: null,
      },
      {
        id: "13195",
        password: null,
      },
      {
        id: "13190",
        password: null,
      },
      {
        id: "13193",
        password: null,
      },
      {
        id: "13181",
        password: null,
      },
      {
        id: "13183",
        password: null,
      },
      {
        id: "13194",
        password: null,
      },
      {
        id: "13191",
        password: null,
      },
      {
        id: "13001",
        password: null,
      },
      {
        id: "13185",
        password: null,
      },
      {
        id: "13182",
        password: null,
      },
      {
        id: "10004",
        password: null,
      },
      {
        id: "13188",
        password: null,
      },
    ],
  },
  {
    id: "709",
    number: "88001000153",
    caption: "Минздрав",
    agents: [
      {
        id: "10000",
        password: null,
      },
      {
        id: "10001",
        password: null,
      },
      {
        id: "10010",
        password: null,
      },
      {
        id: "10004",
        password: null,
      },
      {
        id: "10041",
        password: null,
      },
    ],
  },
  {
    id: "710",
    number: "88002340000",
    caption: "Здоровье Жителей Среднего Урала",
    agents: [
      {
        id: "13154",
        password: null,
      },
      {
        id: "13005",
        password: null,
      },
      {
        id: "13152",
        password: null,
      },
      {
        id: "13155",
        password: null,
      },
      {
        id: "13148",
        password: null,
      },
      {
        id: "13111",
        password: null,
      },
      {
        id: "13108",
        password: null,
      },
      {
        id: "13161",
        password: null,
      },
      {
        id: "13151",
        password: null,
      },
      {
        id: "13123",
        password: null,
      },
      {
        id: "13001",
        password: null,
      },
      {
        id: "13160",
        password: null,
      },
      {
        id: "13150",
        password: null,
      },
      {
        id: "13153",
        password: null,
      },
      {
        id: "13162",
        password: null,
      },
      {
        id: "13157",
        password: null,
      },
      {
        id: "13175",
        password: null,
      },
      {
        id: "13163",
        password: null,
      },
      {
        id: "13147",
        password: null,
      },
      {
        id: "13178",
        password: null,
      },
    ],
  },
  {
    id: "702",
    number: "117",
    caption: "мобилизация",
    agents: [
      {
        id: "10005",
        password: null,
      },
      {
        id: "10021",
        password: null,
      },
      {
        id: "13188",
        password: null,
      },
      {
        id: "13182",
        password: null,
      },
      {
        id: "13183",
        password: null,
      },
      {
        id: "13152",
        password: null,
      },
      {
        id: "10040",
        password: null,
      },
      {
        id: "10035",
        password: null,
      },
      {
        id: "13123",
        password: null,
      },
      {
        id: "13153",
        password: null,
      },
      {
        id: "13162",
        password: null,
      },
      {
        id: "13157",
        password: null,
      },
      {
        id: "10014",
        password: null,
      },
      {
        id: "13161",
        password: null,
      },
      {
        id: "13175",
        password: null,
      },
      {
        id: "13160",
        password: null,
      },
      {
        id: "13108",
        password: null,
      },
      {
        id: "13147",
        password: null,
      },
      {
        id: "13178",
        password: null,
      },
      {
        id: "13151",
        password: null,
      },
      {
        id: "13150",
        password: null,
      },
      {
        id: "13107",
        password: null,
      },
      {
        id: "13154",
        password: null,
      },
      {
        id: "13155",
        password: null,
      },
    ],
  },
  {
    id: "711",
    number: "122",
    caption: "122 по добавочным 1 и 2",
    agents: [
      {
        id: "13155",
        password: null,
      },
      {
        id: "13148",
        password: null,
      },
      {
        id: "13152",
        password: null,
      },
      {
        id: "13156",
        password: null,
      },
      {
        id: "13163",
        password: null,
      },
      {
        id: "13123",
        password: null,
      },
      {
        id: "13175",
        password: null,
      },
      {
        id: "13162",
        password: null,
      },
      {
        id: "13160",
        password: null,
      },
      {
        id: "13154",
        password: null,
      },
      {
        id: "13153",
        password: null,
      },
      {
        id: "13157",
        password: null,
      },
      {
        id: "13158",
        password: null,
      },
      {
        id: "13161",
        password: null,
      },
      {
        id: "13111",
        password: null,
      },
      {
        id: "13108",
        password: null,
      },
      {
        id: "13147",
        password: null,
      },
      {
        id: "13151",
        password: null,
      },
      {
        id: "13178",
        password: null,
      },
      {
        id: "13150",
        password: null,
      },
      {
        id: "13107",
        password: null,
      },
    ],
  },
  {
    id: "712",
    number: "3120003",
    caption: "MnZd3210003",
    agents: [
      {
        id: "13107",
        password: null,
      },
      {
        id: "13178",
        password: null,
      },
      {
        id: "13163",
        password: null,
      },
      {
        id: "13175",
        password: null,
      },
      {
        id: "13150",
        password: null,
      },
      {
        id: "13160",
        password: null,
      },
      {
        id: "13153",
        password: null,
      },
      {
        id: "13147",
        password: null,
      },
      {
        id: "13156",
        password: null,
      },
      {
        id: "13157",
        password: null,
      },
      {
        id: "13158",
        password: null,
      },
      {
        id: "13161",
        password: null,
      },
      {
        id: "13162",
        password: null,
      },
      {
        id: "13151",
        password: null,
      },
      {
        id: "13154",
        password: null,
      },
      {
        id: "13123",
        password: null,
      },
      {
        id: "13148",
        password: null,
      },
      {
        id: "13155",
        password: null,
      },
      {
        id: "13108",
        password: null,
      },
    ],
  },
];
