import { makeAutoObservable, runInAction } from "mobx";
import attributesService from "../../../../services/attributes";
import linesService from "../../../../services/lines";
import operatorsService from "../../../../services/operators";
import subThemasService from "../../../../services/subthemas";
import themasService from "../../../../services/themas";
import { FormStore } from "../../../../stores/FormStore";

export class FilterPopupStore {
  operatorsList: { value: number | null; text: string }[] = [];
  linesList: { value: number | null; text: string }[] = [];
  themasList: { value: number | null; text: string }[] = [];
  subThemasList: { value: number | null; text: string }[] = [];
  gradesList: { value: number | null; text: string }[] = [];
  statusesList: { value: number | null; text: string }[] = [];
  getRings: () => void;
  showReset = false;

  form = new FormStore({
    line: null,
    operator: null,
    thema: null,
    subThema: null,
    startDate: new Date(),
    endDate: new Date(),
    status: null,
    grade: null,
  });

  constructor(getRings: () => void) {
    makeAutoObservable(this);
    this.getRings = getRings;
  }

  init = () => {
    this.getThemas();
    this.getOperatorsList();
    this.getLines();
    this.getGrades();
    this.getStatuses();
  };

  getOperatorsList = () => {
    operatorsService.loadOperators().then((res) => {
      const { data } = res.data;
      runInAction(() => {
        this.operatorsList = data.users.map((item: any) => {
          const obj = {
            text: "",
            value: null,
          };
          obj.text = `${item.last_name} ${item.first_name![0]}. ${
            item.middle_name !== null ? item.middle_name[0] + "." : ""
          }`;
          obj.value = item.id;

          return obj;
        });
        this.operatorsList.unshift({
          text: "Все",
          value: null,
        });
      });
    });
  };

  getLines = () => {
    linesService.loadLines().then((res) => {
      const { data } = res.data;
      const { items } = data;
      runInAction(() => {
        this.linesList = items.map((item: any) => {
          const obj = {
            text: "",
            value: null,
          };
          obj.text = item.short_name;
          obj.value = item.id;

          return obj;
        });
        this.linesList.unshift({
          text: "Все",
          value: null,
        });
      });
    });
  };

  getThemas = () => {
    themasService.loadThemas("appeals", true).then((res) => {
      const { data } = res.data;

      runInAction(() => {
        this.themasList = data.map((item: any) => {
          const obj = {
            text: "",
            value: null,
          };

          obj.text = item.title;
          obj.value = item.id;

          return obj;
        });
        this.themasList.unshift({
          text: "Все",
          value: null,
        });
      });
    });
  };

  getGrades = () => {
    attributesService.loadGrades().then((res) => {
      const { data } = res.data;
      runInAction(() => {
        this.gradesList = data.map((item: any) => {
          const obj = {
            text: "",
            value: null,
          };

          obj.text = item.label;
          obj.value = item.id;

          return obj;
        });
        this.gradesList.unshift({
          text: "Все",
          value: null,
        });
      });
    });
  };

  getStatuses = () => {
    attributesService.loadAppealStatuses().then((res) => {
      const { data } = res.data;
      runInAction(() => {
        this.statusesList = data.map((item: any) => {
          const obj = {
            text: "",
            value: null,
          };

          obj.text = item.label;
          obj.value = item.id;

          return obj;
        });

        this.statusesList.unshift({
          text: "Все",
          value: null,
        });
      });
    });
  };

  onChangeTopic = (id: number) => {
    this.subThemasList = [];
    this.form.values.subThema = null;
    this.form.changeValue(id, "thema");
    subThemasService.loadSubThemas(String(id)).then((res) => {
      const { data } = res.data;
      if (data.length !== 0) {
        runInAction(() => {
          this.subThemasList =
            data.map((item: any) => {
              const obj = {
                text: "",
                value: null,
              };

              obj.text = item.title;
              obj.value = item.id;

              return obj;
            }) || [];
          this.subThemasList.unshift({
            text: "Все",
            value: null,
          });
        });
      }
    });
  };

  changeShowing = (show: boolean) => {
    this.showReset = show;
  };

  reset = () => {
    this.operatorsList = [];
    this.linesList = [];
    this.themasList = [];
    this.form.reset("line");
    this.form.reset("operator");
    this.form.reset("subThema");
    this.form.reset("thema");
    this.form.reset("status");
    this.form.changeValue(new Date(), "startDate");
    this.form.changeValue(new Date(), "endDate");
    this.showReset = false;
    this.form.reset("grade");
    this.form.reset("status");
    this.getRings();
  };

  resetForm = () => {
    this.form.reset("line");
    this.form.reset("operator");
    this.form.reset("subThema");
    this.form.reset("thema");
    this.form.reset("status");
    this.form.changeValue(new Date(), "startDate");
    this.form.changeValue(new Date(), "endDate");
  };
}
