import { observer } from "mobx-react";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";

import "./styles.scss";
import { Icon } from "./Icons";

interface IOption {
  name: string;
  value: string;
}

export interface IOptions {
  options: IOption[];
  state: boolean;
}

const SubSideMenu: React.FC<IOptions> = ({ options, state }) => {
  const location = useLocation();

  const [_, path] = location.pathname.split("/");

  const navigate = useNavigate();

  const idx = options.findIndex((item) => item.value === path);

  const [indx, setIndx] = useState(idx);

  const handleClickTab = (
    i: number,
    link: {
      name: string;
      value: string;
    }
  ) => {
    setIndx(i);
    navigate(`/${link.value}`);
  };

  return state ? (
    <div className="side-menu__subtabs">
      {options.map((item, idx) => {
        const isActivePage = path === item.value;

        return (
          <span
            className={cn(
              "side-menu__subtabs side-menu__subtabs-item",
              isActivePage
                ? `side-menu__tab--active`
                : `side-menu__tab--not-active`
            )}
            onClick={(e) => {
              e.stopPropagation();
              handleClickTab(idx, options[idx]);
            }}
            key={idx}
          >
            {Icon(item.value, indx === idx)}
            <span className="side-menu__subtabs-item--name">{item.name}</span>
          </span>
        );
      })}
    </div>
  ) : null;
};

export default observer(SubSideMenu);
