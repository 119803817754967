import "./styles.scss";

import { observer } from "mobx-react";
import React from "react";

import { ReactComponent as Logo } from "../../assets/svg/cover.svg";
import GlobalStatusStore from "../../stores/GlobalStatusStore";
import CallInfo from "../CallInfo";
import DateTimeComponent from "../DateTimeComponent";
import UserComponent from "../UserComponent";
import sipControllerStore from "../../stores/GlobalStatusStore/SipController";
import Button from "../Button";
import Caller from "../Caller";
import { ReactComponent as Phone } from "../../assets/svg/phone_white.svg";
import authStore from "../../pages/AuthPage/store/authStore";
import CallerReferrer from "../CallerReferrer";
import { toJS } from "mobx";

const Header: React.FC = () => {
  const [visible, setVisible] = React.useState(false);

  const [visibleReferrer, setVisibleReferrer] = React.useState(false);

  console.log(
    "sipControllerStore.sessions",
    toJS(sipControllerStore.session),
    toJS(sipControllerStore.supportSession)
  );

  const handleEndCall = () => {
    sipControllerStore.session?.terminate();
    GlobalStatusStore.endCall();
    GlobalStatusStore.setState("isAnswered", false);
    GlobalStatusStore.setState("isOutGoing", false);
    GlobalStatusStore.setState("isOutGoingRing", false);
    sipControllerStore.resetCall();
  };

  const handlePhoneClick = () => {
    setVisible(true);
    GlobalStatusStore.setState("isOutGoing", true);
  };

  const handleCancelPhoneClick = () => {
    setVisible(false);
    GlobalStatusStore.setState("isIncoming", false);
    GlobalStatusStore.setState("isOutGoing", false);
  };

  const handleReferrerClick = () => {
    setVisibleReferrer(true);
  };

  const handleCancelReferrerClick = () => {
    setVisibleReferrer(false);
  };

  const isCanCall =
    !GlobalStatusStore.states.paused &&
    !GlobalStatusStore.states.isAnswered &&
    !GlobalStatusStore.states.isCreateCall &&
    !GlobalStatusStore.states.isCreateAppeal &&
    !GlobalStatusStore.states.isOutGoing &&
    !GlobalStatusStore.states.isOutGoingRing &&
    !GlobalStatusStore.states.isIncoming;

  return (
    <div className="header">
      <div className="header__top">
        <div className="header__top-content">
          <div className="desktop header__top-content-block">
            <div className="desktop header__top-content-block-left">
              <Logo />
              <span className="desktop header__top-content-block-name">
                Служба 122
              </span>
            </div>
            <DateTimeComponent />
            {isCanCall && authStore.role[0] === "operator" && (
              <Button
                onClick={handlePhoneClick}
                className="header__call-button"
                size="small"
              >
                <Phone className="phone-pic" />
                Набрать номер
              </Button>
            )}
            {GlobalStatusStore.states.isAnswered && (
              <CallInfo
                endCall={handleEndCall}
                handleReferrerClick={handleReferrerClick}
                visibleReferrer={visibleReferrer}
              />
            )}

            <UserComponent />
          </div>
        </div>
      </div>
      {visible && (
        <Caller
          onClose={handleCancelPhoneClick}
          isOpen={GlobalStatusStore.states.isOutGoing}
        />
      )}
      {GlobalStatusStore.states.isAnswered && (
        <CallerReferrer
          onClose={handleCancelReferrerClick}
          isOpen={visibleReferrer}
        />
      )}
    </div>
  );
};

export default observer(Header);
