import "./styles.scss";

import React from "react";

import NumberSeparator from "../../utils/NumberSeparator/numberSeparator";
import { getParameterIcon, IType } from "../Parameters/getParameterIcon";
import { FlexRow, Typography } from "@kit/kit-aiss";

interface IParams {
  value: string;
  count: number;
  type: string;
}

const ParametersOperator: React.FC<{
  handleCall?: () => void;
  params: IParams[];
}> = ({ handleCall, params }) => {
  return (
    <div className="parameters-block">
      {params.map((parameter, idx) => {
        return (
          <div className="parameters-block__item" key={idx}>
            <div className="parameters-block__item-name">{parameter.value}</div>
            <div className="parameters-block__item-statistics">
              <div className="parameters-block__item-statistics--type">
                {getParameterIcon(parameter.type as IType)}
              </div>
              <FlexRow>
                <div className="parameters-block__item-statistics--number">
                  <Typography variant="h4" color="color1">
                    {NumberSeparator(parameter.count)}
                  </Typography>
                </div>
                {/* {parameter.type === "resets" && (
                  <Button
                    onClick={() => handleCall?.()}
                    size="small"
                    className="parameters-block__button"
                    disabled={GlobalStatusStore.paused}
                  >
                    Перезвонить
                  </Button>
                )} */}
              </FlexRow>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ParametersOperator;
