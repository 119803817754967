import { makeAutoObservable, runInAction, toJS } from "mobx";
import { CallFormStore } from "../../Form/store";
import callsService from "../../../../../../services/calls";
import sipControllerStore from "../../../../../../stores/GlobalStatusStore/SipController";
import authStore from "../../../../../AuthPage/store/authStore";
import { convertFormDataToObject } from "../../../../../../utils/convertFormDateToObject";
import { STORAGE_KEY_CREATE_CALL } from "../../../../../../constants/storage";
import StorageSyncStore from "../../../../../../stores/StorageSyncStore";
import GlobalStatusStore from "../../../../../../stores/GlobalStatusStore";
import recordsService from "../../../../../../services/records";

export class CreateCallModalStore {
  form: CallFormStore = new CallFormStore("create", this);
  loadCalls?: (type?: string) => void;
  isLoadings = { createCall: false };

  storageSync? = new StorageSyncStore(
    this,
    "formDataObject",
    STORAGE_KEY_CREATE_CALL
  );

  constructor(loadCalls?: () => void) {
    makeAutoObservable(this);
    this.loadCalls = loadCalls;
  }

  onSave = async () => {
    const res = await this.createCall();
    console.log("create call", res, toJS(this.formData));

    if (!res) return false;
    const { data } = res.data;

    sipControllerStore.resetOutgoingPhone();

    runInAction(() => {
      GlobalStatusStore.client_info!.city_id = data.city_id || "";
      GlobalStatusStore.client_info!.client_full_name = data.client_full_name;
      GlobalStatusStore.client_info!.client_phone = data.client_phone;
      GlobalStatusStore.client_info!.med_organization = data.med_organization;
      GlobalStatusStore.client_info!.patient_full_name = data.patient_full_name;
      GlobalStatusStore.client_info!.id = data.id;
    });
    return true;
  };

  get formData() {
    const formData = new FormData();

    formData.append("waitingTime", "0");
    formData.append("durationTime", "0");

    const clientPhone = sipControllerStore.session?.remoteUserTel;

    if (clientPhone) {
      formData.append("clientPhone", clientPhone || "");

      // todo: бэк принимает только числа
      //   formData.append("callId", sipControllerStore.xUid || "");
      formData.append("callId", String(sipControllerStore.callId || ""));
      //   formData.append("callLineId", sipControllerStore.xUid || "");
      //   formData.append("callLineId", (Math.random() * 100000).toFixed(0));
    } else if (!clientPhone && sipControllerStore.phone) {
      formData.append("clientPhone", sipControllerStore.phone || "");

      formData.append("callId", String(sipControllerStore.callId || ""));
    } else {
      const saved = this.getStoredForm();
      if (saved) {
        formData.append("clientPhone", saved.clientPhone || "");
        formData.append("callId", saved.callId || "");
      } else {
        formData.append("callId", String(sipControllerStore.callId || ""));
      }
    }
    sipControllerStore.reinviteTel && formData.delete("clientPhone");
    sipControllerStore.reinviteTel &&
      formData.append("clientPhone", sipControllerStore.reinviteTel!);
    formData.append("topicId", this.form.form.values.topic || "");
    formData.append("resultId", this.form.form.values.result || "");
    formData.append("cityId", this.form.form.values.city || "");
    formData.append("patientTypeId", this.form.form.values.socialStatus || "");
    formData.append("description", this.form.form.values.description || "");
    formData.append("clientFullName", this.form.form.values.clientName || "");
    formData.append("patientFullName", this.form.form.values.patientName || "");
    formData.append("answerText", this.form.form.values.shortAnswer || "");
    formData.append(
      "medOrganization",
      this.form.form.values.medicalOrganization || ""
    );
    formData.append("result", this.form.form.values.result || "");

    if (this.form.subTopic) {
      formData.append(
        "subtopicId",
        this.form.subTopic.form.values.subTopic || ""
      );
    }

    if (this.form.attribute) {
      this.form.attribute.attributes.forEach((attribute, idx) => {
        if (attribute.form.values.attribute) {
          formData.append(
            `attributes[${idx}][attribute_id]`,
            String(attribute.attribute.id)
          );
          formData.append(
            `attributes[${idx}][attribute_value]`,
            String(attribute.form.values.attribute)
          );
        }
      });
    }

    if (GlobalStatusStore.states.isOutGoing) {
      formData.append(
        "scrCallInfoId",
        String(GlobalStatusStore.srcCallId || "")
      );
    }

    if (sipControllerStore.isRedirected) {
      formData.append("passToTfoms", "1");
    }

    return formData;
  }

  get formDataObject() {
    return convertFormDataToObject(this.formData);
  }

  createCall = async () => {
    this.isLoadings.createCall = true;

    try {
      const res = await callsService.createCall(this.formData);
      this.storageSync?.stopSync();
      localStorage.removeItem(STORAGE_KEY_CREATE_CALL);
      authStore.getSideBarLinks();
      if (GlobalStatusStore.states.CallFromRecord) {
        const { data } = res.data;
        const formData = new FormData();
        formData.append("callInfoId", String(data.id));
        recordsService
          .saveEditedRecord(GlobalStatusStore.recordId, formData)
          .then(() => {
            GlobalStatusStore.setCallRecordId(null);
            GlobalStatusStore.setState("CallFromRecord", false);
          });
      }

      sipControllerStore.resetOutgoingPhone();
      return res;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.isLoadings.createCall = false));
    }
  };

  getStoredForm = () => {
    const savedString = localStorage.getItem(STORAGE_KEY_CREATE_CALL);
    return savedString ? JSON.parse(savedString) : null;
  };
}
