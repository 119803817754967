import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import MainLayout from "../../components/layouts/MainLayout";
import OperatorWorkTimeReport from "./OperatorWorkTimeReport";
import { ReportPageStore } from "./store";
import { FlexColumn } from "@kit/kit-aiss";
import Typography from "../../components/Typography";
import DatePicker from "../../components/DatePicker";
import Select from "../../components/Select";
import Button from "../../components/Button";
import { ReactComponent as PrintIcon } from "../../assets/svg/print.svg";
import { ReactComponent as DownloadIcon } from "../../assets/svg/download-icon.svg";
import { useNavigate } from "react-router-dom";

const store = new ReportPageStore();

const ReportsPage: React.FC = () => {
  const [openCalendars, setOpenCalendars] = useState(false);
  const navigate = useNavigate();

  const date1 =
    localStorage.getItem("startWorkDate") &&
    new Date(localStorage.getItem("startWorkDate") as string);
  const date2 =
    localStorage.getItem("endWorkDate") &&
    new Date(localStorage.getItem("endWorkDate") as string);

  useEffect(() => {
    if (date1 && date2) {
      store.operatorWorkTimeStore.form.changeValue(date1, "startDate");
      store.operatorWorkTimeStore.form.changeValue(date2, "endDate");
      store.operatorWorkTimeStore.loadReport();
    } else {
      localStorage.setItem(
        "startWorkDate",
        String(store.operatorWorkTimeStore.form.values.startDate)
      );
      localStorage.setItem(
        "endWorkDate",
        String(store.operatorWorkTimeStore.form.values.endDate)
      );
      store.init();
    }

    return () => {
      store.operatorWorkTimeStore.reset();
      localStorage.removeItem("startWorkDate");
      localStorage.removeItem("endWorkDate");
    };
  }, []);

  // TODO: функция для таблицы с отчетами, пригодится позже */
  const selectPeriodOfReports = () => {
    setOpenCalendars(!openCalendars);
  };

  const handleReportDownloadClick = () => {
    store.operatorWorkTimeStore.reportDownload();
  };

  const handlePrintClick = () => {
    navigate("/shifts-report-worktime");
  };

  return (
    <MainLayout status={false} bgWhite>
      <FlexColumn gap={24}>
        <div>
          <Typography variant="h1">Рабочее время</Typography>
        </div>
        <div className="table-block">
          <div className="table-block-top">
            <div className="table-block-top-date">
              <DatePicker
                name="startDate"
                placeholder="Дата начала"
                value={store.operatorWorkTimeStore.form.values.startDate}
                onChange={store.operatorWorkTimeStore.changeCalendarValue}
                errorMessage={store.operatorWorkTimeStore.form.errors.startDate}
              />
              <DatePicker
                name="endDate"
                placeholder="Дата конца"
                value={store.operatorWorkTimeStore.form.values.endDate}
                onChange={store.operatorWorkTimeStore.changeCalendarValue}
                errorMessage={store.operatorWorkTimeStore.form.errors.endDate}
              />
            </div>
            <div className="table-block-top-controllers">
              <Select
                className="select"
                options={store.operatorWorkTimeStore.periods}
                name="period"
                value={store.operatorWorkTimeStore.form.values.period}
                onChange={store.operatorWorkTimeStore.changePeriodValue}
                placeholder="Период"
                isSmall
                inputBg="color6"
              />
              <Button
                className="button"
                onClick={() => {
                  handleReportDownloadClick();
                }}
              >
                <DownloadIcon />
                Скачать отчёт
              </Button>
              <Button className="button" onClick={handlePrintClick}>
                <PrintIcon />
                Распечатать отчёт
              </Button>
            </div>
          </div>
        </div>
        <OperatorWorkTimeReport
          store={store.operatorWorkTimeStore}
          onDownload={selectPeriodOfReports}
        />
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(ReportsPage);
