import "./styles.scss";
import React, { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import TextField from "../../../../../components/TextField";
import Select from "../../../../../components/Select";
import { IFormProps } from "./types";
import LinesSelector from "../../../../../components/LinesSelector";

const Form: FC<IFormProps> = ({ store, isEdit, id }) => {
  useEffect(() => {
    store.init();
    if (isEdit && id) {
      store.loadOperator(id);
    }
  }, []);

  return (
    <div className="operator-form">
      <TextField {...store.form.adapters.name} placeholder="ФИО" />
      <TextField
        {...store.form.adapters.number}
        placeholder="Личный номер"
        maxLength={12}
        isDisabled
      />
      <Select
        {...store.form.adapters.graph}
        placeholder="График работы"
        options={[
          { text: "5/2", value: 1 },
          { text: "2/2", value: 2 },
        ]}
      />
      <Select
        {...store.form.adapters.status}
        placeholder="Статус"
        options={[
          { text: "Активный", value: true },
          { text: "Неактивный", value: false },
        ]}
      />
      <TextField
        {...store.form.adapters.phone_number}
        maxLength={11}
        placeholder="Номер телефона"
      />
      <TextField {...store.form.adapters.login} placeholder="Логин" />
      <TextField {...store.form.adapters.password} placeholder="Пароль" />
      <LinesSelector linesStore={store.linesStore} />
    </div>
  );
};

export default observer(Form);
