import React from "react";

interface IconCloseProps {
  color?: string;
  className?: string;
}

const IconClose: React.FC<IconCloseProps> = ({
  color = "#0063B0",
  className,
}) => {
  return (
    <svg
      className={className || undefined}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.6L14.4 0L8 6.4L1.6 0L0 1.6L6.4 8L0 14.4L1.6 16L8 9.6L14.4 16L16 14.4L9.6 8L16 1.6Z"
        fill={color}
      />
    </svg>
  );
};

export default IconClose;
