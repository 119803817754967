import { makeAutoObservable, runInAction } from "mobx";
import operatorsService from "../../../../../../services/operators";
import { OperatorFormStore } from "../../Form/store";

class OperatorCreateStore {
  isLoading = false;
  errorText = "";
  isOpen = false;
  operatorFormStore: OperatorFormStore = new OperatorFormStore();

  onSuccess: () => void;

  constructor(onSuccess: OperatorCreateStore["onSuccess"]) {
    makeAutoObservable(this);
    this.onSuccess = onSuccess;
  }

  init = () => {
    operatorsService.getOperatorNumber().then((res) => {
      const { data } = res.data;
      const { number } = data;
      this.operatorFormStore.form.changeValue(number, "number");
    });
  };

  reset = () => {
    this.operatorFormStore.reset();
    this.isLoading = false;
    this.resetError();
    this.isOpen = false;
  };

  sendForm = () => {
    if (!this.operatorFormStore.form.isValid) {
      this.operatorFormStore.form.validate();
      return;
    }

    const formData = new FormData();

    const [lastName, firstName, middleName] =
      this.operatorFormStore.form.values.name?.split(" ") || [];

    formData.append("firstName", firstName ?? "");
    if (middleName) {
      formData.append("middleName", middleName);
    }
    formData.append("lastName", lastName ?? "");
    formData.append("number", this.operatorFormStore.form.values.number ?? "");
    formData.append(
      "scheduleId",
      this.operatorFormStore.form.values.graph ?? ""
    );
    formData.append(
      "status",
      this.operatorFormStore.form.values.status ? "1" : "0"
    );
    formData.append(
      "phone",
      this.operatorFormStore.form.values.phone_number ?? ""
    );
    formData.append("login", this.operatorFormStore.form.values.login ?? "");
    formData.append(
      "password",
      this.operatorFormStore.form.values.password ?? ""
    );

    this.operatorFormStore.linesStore.form.values.lines?.forEach((line) => {
      formData.append("lines[]", String(line));
    });

    this.isLoading = true;
    this.resetError();

    return operatorsService
      .createOperator(formData)
      .then((res: any) => {
        if (res.data.status === "success") {
          this.closeModal();
          this.onSuccess();
          return true;
        }
        runInAction(() => {
          this.errorText = "Не удалось создать оператора";
        });
      })
      .catch(() => {
        runInAction(() => {
          this.errorText = "Не удалось создать оператора";
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  resetError = () => {
    this.errorText = "";
  };

  openModal = () => {
    this.isOpen = true;
  };

  closeModal = () => {
    this.isOpen = false;
    this.reset();
  };
}

export default OperatorCreateStore;
