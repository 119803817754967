import { string, object } from "yup";

const shape = {
  topic: string().min(1, "Необходимо заполнить поле"),
  clientName: string().nullable().notRequired(),
  patientName: string()
    .notRequired()
    .when("topic", {
      is: (val: unknown) => Number(val) === 127 || Number(val) === 130,
      then: string().min(3, "Необходимо указать ФИО"),
      otherwise: string().nullable().notRequired(),
    }),
  socialStatus: string().nullable().notRequired(),
  medicalOrganization: string()
    .notRequired()
    .when("topic", {
      is: (val: unknown) => Number(val) === 127 || Number(val) === 130,
      then: string().min(3, "Необходимо заполнить поле"),
    }),
  city: string()
    .nullable()
    .notRequired()
    .when("topic", {
      is: (val: unknown) =>
        [101, 329, 127, 323, 130, 326].includes(Number(val)),
      then: string().min(1, "Необходимо заполнить поле"),
    }),
  description: string().min(3, "Необходимо заполнить поле"),
  shortAnswer: string().min(3, "Необходимо заполнить поле"),
  result: string().min(1, "Необходимо заполнить поле"),
};

export const callSchema = object().shape(shape);

export const callSchemaEmpty = object().shape({
  ...shape,
  clientPhone: string().min(1, "Необходимо заполнить поле"),
  callLineId: string().min(1, "Необходимо заполнить поле"),
});
