import { COLOR_DIVIDER_VERTICAL_1 } from "../../../constants/colors";
import "./styles.scss";

import React from "react";

export interface IIconDividerVerticalProps {
  height?: number;
  color?: string;
}

const IconDividerVertical: React.FC<IIconDividerVerticalProps> = ({
  height,
  color,
}) => {
  return (
    <div
      className="icon-divider-vertical"
      style={{ height, background: color || COLOR_DIVIDER_VERTICAL_1 }}
    ></div>
  );
};

export default IconDividerVertical;
