import "./styles.scss";

import cn from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { ReactComponent as ArrowLeft } from "../../assets/svg/arrowLeft.svg";
import { ReactComponent as ArrowLeftWhite } from "../../assets/svg/arrowLeftWhite.svg";
import authStore from "../../pages/AuthPage/store/authStore";
import NumberSeparator from "../../utils/NumberSeparator/numberSeparator";
import { ReactComponent as IconLogout } from "../../assets/svg/logout.svg";

import SubSideMenu from "../SubSidemenu";

import GlobalStatusStore from "../../stores/GlobalStatusStore";
import Button from "../Button";
import Typography from "../Typography";
import { FlexColumn } from "@kit/kit-aiss";

const SideMenu: React.FC = () => {
  // пока не знаю как реализовать менюху, чтобы все правильно работало
  // перечень линков приходит с бэка

  const location = useLocation();

  const [_, path] = location.pathname.split("/");

  const navigate = useNavigate();

  const [opened, setIsOpened] = useState(true);

  useEffect(() => {
    if (path) {
      const currentOpenElementIdx = authStore.sideBar.findIndex((item) =>
        item.children?.some((subItem) => subItem.value === path)
      );
      if (currentOpenElementIdx > -1) {
        GlobalStatusStore.openMenu(currentOpenElementIdx);
      }
    }
  }, [path, authStore.sideBar]);

  const handleClickTab = (
    i: number,
    link: {
      name: string;
      value: string;
      count: number;
    }
  ) => {
    navigate(`/${link.value}`);
  };

  const handleClosedClick = () => {
    setIsOpened(false);
  };

  const handleOpenedClick = () => {
    setIsOpened(true);
  };

  const scrll = Object.values(GlobalStatusStore.opened).every(
    (i) => i === true
  );

  return (
    <aside
      className={cn(
        "side-menu",
        opened ? "opened" : "closed",
        scrll && "overlfow"
      )}
    >
      <div
        className={cn("side-menu__opener", opened ? "opened" : "closed")}
        onClick={opened ? handleClosedClick : handleOpenedClick}
      >
        <div className="side-menu__opener-arrow">
          {opened ? <ArrowLeft /> : <ArrowLeftWhite />}
        </div>
      </div>
      <div className="side-menu__tabs">
        {authStore.sideBar?.map((item, idx) => {
          const isActivePage = path === item.value;

          return (
            <div
              className={cn(
                "side-menu__tabs side-menu__tab",
                isActivePage
                  ? `side-menu__tab--active`
                  : `side-menu__tab--not-active`,
                authStore.sideBar.length > 2 && "divider",
                item.children && `side-menu__tab-children`
              )}
              key={idx}
              onClick={() => {
                !item.children && handleClickTab(idx, authStore.sideBar[idx]);
                item.children && GlobalStatusStore.toggleMenu(idx);
              }}
            >
              {item.children ? (
                <span
                  className={cn(
                    "side-menu__tab-dictionary",
                    GlobalStatusStore.opened[idx] ? "opened" : "closed"
                  )}
                >
                  {item.name}
                </span>
              ) : (
                <span className="side-menu__text">{item.name}</span>
              )}
              {item.children && (
                <SubSideMenu
                  options={item.children}
                  state={GlobalStatusStore.opened[idx]}
                />
              )}
              {item.count !== null && item.count > 0 && (
                <span className="side-menu__tab-count">
                  {NumberSeparator(item.count)}
                </span>
              )}
            </div>
          );
        })}
      </div>
      <FlexColumn className="side-menu__footer">
        <Button variant="inline" onClick={() => authStore.logOut()}>
          <Typography color="color3">Выйти</Typography>
          <IconLogout />
        </Button>
        <FlexColumn gap={10}>
          <Typography color="color3">Техподдержка</Typography>
          <Typography color="color3">8-000-000-00-00</Typography>
        </FlexColumn>
      </FlexColumn>
    </aside>
  );
};

export default observer(SideMenu);
