import { PrintBlock, PrintLayout, Table, Typography } from "@kit/kit-aiss";
import { observer } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ShiftsStore } from "../Shifts/store";
import "./styles.scss";

const store = new ShiftsStore();

const PrintPage: React.FC = () => {
  const date = localStorage.getItem("shiftDate");

  React.useEffect(() => {
    store.onPrintChangeDate(date as string);
  }, []);

  const navigate = useNavigate();

  const handleCancelPrintClick = () => {
    navigate(`/shifts`);
    localStorage.setItem("shiftDate", date as string);
  };

  return (
    <PrintLayout className="print-page">
      <PrintBlock onClickBackLink={handleCancelPrintClick}>
        <Table
          headers={[
            "Номер линии",
            "Операторы",
            "В очереди",
            "Время ожидания",
            "Звонки",
            "Обращения",
            "Сбросы",
          ]}
          content={store.lines.map((line, idx) => [
            [
              <div key={idx} className="first-elem">
                <Typography>{line.number}</Typography>
              </div>,
            ],
            [line.operators],
            [line.queue],
            [line.time],
            [line.calls],
            [line.appeals],
            [line.resets],
          ])}
        />
      </PrintBlock>
    </PrintLayout>
  );
};

export default observer(PrintPage);
