import React, { FC } from "react";
import { ICreateOperatorAppealsModalProps } from "./types";
import { FlexRow } from "@kit/kit-aiss";
import { CreateAppealStore } from "../../../../Appeals/components/popups/CreateAppealModal/store";
import Form from "../../../../Appeals/components/popups/Form";
import { observer } from "mobx-react-lite";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";
import authStore from "../../../../AuthPage/store/authStore";
import { STORAGE_KEY_CREATE_APPEAL } from "../../../../../constants/storage";
import "./styles.scss";
import GlobalStatusStore from "../../../../../stores/GlobalStatusStore";

const store = new CreateAppealStore("create");

const CreateOperatorAppealsModal: FC<ICreateOperatorAppealsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const handleCreate = () => {
    store.checkValid()?.then(() => {
      authStore.getSideBarLinks();
      GlobalStatusStore.pauseCallsTemporally();
      GlobalStatusStore.setState("isCreateAppeal", false);
      onClose();
    });
  };

  const handleClose = () => {
    store.storageSync.stopSync();
    GlobalStatusStore.pauseCallsTemporally();
    localStorage.removeItem(STORAGE_KEY_CREATE_APPEAL);
    GlobalStatusStore.setState("isCreateAppeal", false);
    onClose();
  };

  return (
    <Modal
      title="Обращение"
      isOpen={isOpen}
      onClose={handleClose}
      onAfterClose={store.reset}
    >
      <Form appealsFormStore={store.form} />
      <FlexRow>
        <Button onClick={handleCreate} disabled={store.isLoading}>
          Сохранить
        </Button>
      </FlexRow>
    </Modal>
  );
};

export default observer(CreateOperatorAppealsModal);
