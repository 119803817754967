import "./styles.scss";
import React, { FC } from "react";
import { IAttributeSelectedFieldProps } from "./types";
import { ReactComponent as ArrowIcon } from "../../../../../../assets/svg/smallArrow.svg";
import IconClose from "../../../../../../components/icons/IconClose";
import { Button } from "@kit/kit-aiss";

const AttributeSelectedField: FC<IAttributeSelectedFieldProps> = ({
  attribute,
  deleteItem,
  swapItems,
}) => {
  return (
    <div className="attribute-selected-field">
      <div className="attribute-selected-field__title">
        <span>{attribute.title}</span>
      </div>
      <div className="attribute-selected-field__icons">
        <ArrowIcon onClick={() => swapItems(attribute.id, "previous")} />
        <ArrowIcon
          onClick={() => swapItems(attribute.id, "next")}
          style={{ transform: "rotate(180deg)" }}
        />
      </div>
      <Button
        variant="transparent"
        className="operator-create__remove-line-button"
        onClick={() => deleteItem(attribute.id)}
      >
        <IconClose />
      </Button>
      {/*<DeleteIcon onClick={() => deleteItem(attribute.id)} />*/}
    </div>
  );
};

export default AttributeSelectedField;
