import { makeAutoObservable } from "mobx";
import authStore from "../../pages/AuthPage/store/authStore";

export class SortStore<T extends string> {
  sortFields: Map<
    T,
    {
      sortType?: "asc" | "desc";
      onSort: () => void;
    }
  > = new Map();
  onSort: (count?: number) => void;
  resCount: () => void;

  constructor(sortFields: T[], onSort: () => void, resCount?: () => void) {
    makeAutoObservable(this);

    this.onSort = onSort;
    this.resCount = resCount!;

    sortFields.forEach((field) => {
      this.sortFields.set(field, {
        onSort: () => {
          const currentField = this.sortFields.get(field);

          if (currentField) {
            currentField.sortType =
              currentField.sortType === "asc"
                ? "desc"
                : currentField.sortType === "desc"
                ? undefined
                : "asc";
            this.sortFields.forEach((field) => {
              if (field !== currentField) {
                field.sortType = undefined;
              }
            });
          }
          this.resCount && this.resCount();
          authStore.role[0] === "operator" && this.onSort(1);
          authStore.role[0] === "manager" && this.onSort();
          authStore.role[0] === "appeals_manager" && this.onSort();
        },
      });
    });
  }

  get searchParams() {
    const params = Array.from(this.sortFields.entries())
      .map((item) => [`sort[${item[0]}]`, item[1].sortType])
      .filter((param) => param[1]) as string[][];

    return new URLSearchParams(params);
  }
}
