export const convertFormDataToObject = (formData: FormData) => {
  const result: Record<string, FormDataEntryValue | FormDataEntryValue[]> = {};

  formData.forEach((value, key) => {
    if (Object.prototype.hasOwnProperty.call(result, key)) {
      let current = result[key];

      if (!Array.isArray(current)) {
        current = result[key] = [current];
      }
      current.push(value);
    } else {
      result[key] = value;
    }
  });

  return result;
};
