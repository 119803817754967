import { FormStore } from "@kit/kit-aiss";
import { ISelectOption } from "@kit/kit-aiss/dist/components/Select/index.types";
import { makeAutoObservable, runInAction } from "mobx";
import linesService from "../../../../../../services/lines";
import { createNewCallSchema } from "./validationSchema";

export class NewCallFormStore {
  form = new FormStore({ phone: "", line: "" }, createNewCallSchema);
  isLoadings = { create: false };

  lineOptions: (ISelectOption & { number: number })[] = [];

  constructor() {
    makeAutoObservable(this);
    this.init();
  }

  init = () => {
    this.getLineOptions();
  };

  getCallData = async () => {
    if (!this.form.isValid) {
      this.form.validate();
      return;
    }

    return {
      clientPhone: this.form.values.phone,
      callLineId: this.form.values.line,
    };
  };

  private getLineOptions = async () => {
    try {
      const res = await linesService.loadLines();
      runInAction(
        () =>
          (this.lineOptions =
            res.data?.data?.items?.map((line: any) => ({
              value: line.id,
              number: line.number,
              text: line.short_name,
              active: line.active,
            })) || [])
      );
    } catch (error) {
      this.lineOptions = [];
    }
  };

  get selectedLineNumber() {
    const values = this.form.values;
    return String(
      this.lineOptions.find((line) => line.value === values.line)?.number || ""
    );
  }
}
