import "./styles.scss";
import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layouts/MainLayout";
import { Button } from "@kit/kit-aiss";
import { ReactComponent as PlusIcon } from "../../assets/svg/plus.svg";
import { AttributesStore } from "./store";
import { observer } from "mobx-react-lite";
import AttributeItem from "./components/AttributeItem";
import CreateAttributeModal from "./components/popups/CreateAttributeModal";
import EditAttributeModal from "./components/popups/EditAttributeModal";
import { IAttribute } from "./store/types";
import Typography from "../../components/Typography";

const store = new AttributesStore();

const Attributes = () => {
  const [isOpenCreateAttributeModal, setIsOpenCreateAttributeModal] =
    useState<boolean>(false);
  const [isOpenEditAttributeModal, setIsOpenEditAttributeModal] =
    useState<boolean>(false);
  const [attribute, setAttribute] = useState<IAttribute | null>(null);

  useEffect(() => {
    store.loadAttributes();
  }, []);

  const openEdit = (attribute: IAttribute) => {
    setIsOpenEditAttributeModal(true);
    setAttribute(attribute);
  };

  return (
    <MainLayout status={false}>
      <div className="attributes">
        <div className="attributes__header">
          <Typography variant="h1">Атрибуты</Typography>
          <div className="attributes__header-sub">
            <div className="attributes__header-sub-controllers">
              <Button
                variant="secondary"
                type="submit"
                onClick={() => setIsOpenCreateAttributeModal(true)}
              >
                <PlusIcon />
                Добавить новый атрибут
              </Button>
              {/* <PrintIcon />
              <div className="attributes__header-sub-controllers__divider" />
              <DownloadIcon /> */}
            </div>
          </div>
        </div>
        <div className="attributes__items">
          {store.attributes.map((attribute) => (
            <AttributeItem
              key={attribute.id}
              openEdit={openEdit}
              attribute={attribute}
            />
          ))}
        </div>
      </div>
      {isOpenCreateAttributeModal && (
        <CreateAttributeModal
          store={store.createAttributeModalStore}
          isOpen={isOpenCreateAttributeModal}
          onClose={() => setIsOpenCreateAttributeModal(false)}
        />
      )}
      {isOpenEditAttributeModal && (
        <EditAttributeModal
          attribute={attribute}
          store={store.editAttributeModalStore}
          isOpen={isOpenEditAttributeModal}
          onClose={() => setIsOpenEditAttributeModal(false)}
        />
      )}
    </MainLayout>
  );
};

export default observer(Attributes);
