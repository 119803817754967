import "./styles.scss";

import { observer } from "mobx-react-lite";
import { TextArea as KitTextArea } from "@kit/kit-aiss";
import { FC } from "react";
import { TTextAreaProps } from "@kit/kit-aiss/src/components/TextArea/index.types";
import cn from "classnames";

const TextArea: FC<TTextAreaProps> = (props) => {
  return (
    <KitTextArea {...props} className={cn(props.className, "crm-text-area")} />
  );
};

export default observer(TextArea);
