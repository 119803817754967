import { makeAutoObservable } from "mobx";
import { LinesModalFormStore } from "../../components/LinesModalForm/store";
import linesService from "../../../../../../services/lines";
import { LinesStore } from "../../../../store";

export class EditLineModalStore {
  form = new LinesModalFormStore();
  linesStore: LinesStore;
  id = 0;

  constructor(linesStore: LinesStore) {
    makeAutoObservable(this);
    this.linesStore = linesStore;
  }

  init = (id: number) => {
    this.id = id;
  };

  onSave = () => {
    this.form.form.validate();
    if (this.form.form.isValid) {
      return this.editLine();
    }
  };

  editLine = () => {
    const formData = new FormData();
    formData.append("number", this.form.form.values.lineNumber || "");
    formData.append("shortName", this.form.form.values.shortTitle || "");
    formData.append(
      "active",
      this.form.form.values.status === "Активная" ? "1" : "0"
    );
    formData.append("_method", "put");

    return linesService
      .editLine(formData, this.id)
      .then(() => this.linesStore.loadLines());
  };
}
