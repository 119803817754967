import api from "../../api";

const loadLines = (params?: URLSearchParams) => {
  return api.get(`/api/call-line?page=1&limit=100`, { params });
};

const loadLine = (lineId: number) => {
  return api.get(`/api/call-line/${lineId}`);
};

//TODO запросы для графиков перетащить куда-нить потом. ну или не перетаскивать
//запросы для линейного графика звонков
const loadCallLine = (lineId: number) => {
  return lineId
    ? api.get(`/api/charts/call-info/line?callLineId=${lineId}`)
    : api.get(`/api/charts/call-info/line?callLineId=`);
};

//запросы для кругового графика звонков
const loadCircleChart = (period: string | null) => {
  return api.get(`/api/charts/call-info/circle?period=${period}`);
};

//запросы для линейного графика обращений
const loadAppealLine = (lineId: number) => {
  return lineId
    ? api.get(`/api/charts/appeals/line?callLineId=${lineId}`)
    : api.get(`/api/charts/appeals/line?callLineId=`);
};

//запросы для линейного графика обращений
const loadCircleAppealChart = (period: string | null) => {
  return api.get(`/api/charts/appeals/circle?period=${period}`);
};

const createLine = (formData: FormData) => {
  return api.post(`/api/call-line`, formData);
};

const editLine = (formData: FormData, id: number) => {
  return api.post(`/api/call-line/${id}`, formData);
};

const downloadLines = (params?: URLSearchParams) => {
  return api.get(`/api/call-line/download?page=1&limit=100`, {
    params,
    responseType: "blob",
  });
};

const linesService = {
  loadLines,
  createLine,
  editLine,
  loadLine,
  loadCallLine,
  loadCircleChart,
  loadAppealLine,
  loadCircleAppealChart,
  downloadLines,
};

export default linesService;
