import { Button } from "@kit/kit-aiss";
import { observer } from "mobx-react";
import React from "react";

import { ReactComponent as Lightning } from "../../assets/svg/lightning.svg";
import { ReactComponent as NoPhone } from "../../assets/svg/no_phone_white.svg";
import { ReactComponent as PhoneArrow } from "../../assets/svg/phone_arrow.svg";
import authStore from "../../pages/AuthPage/store/authStore";
import GlobalStatusStore from "../../stores/GlobalStatusStore";
import cn from "classnames";

import "./styles.scss";
import Timer from "./Timer";

interface ICallInfoProps {
  endCall: () => void;
  handleReferrerClick: () => void;
  visibleReferrer: boolean;
}

const CallInfoComponent: React.FC<ICallInfoProps> = ({
  endCall,
  handleReferrerClick,
  visibleReferrer,
}) => {
  return (
    <div className="voice-block">
      {/* <Timer /> */}
      <Lightning />
      <div className="voice-block__visualization" />
      <Button onClick={endCall} size="small">
        <NoPhone />
        <span>Завершить звонок</span>
      </Button>
      {GlobalStatusStore.states.isAnswered &&
        authStore.role[0] === "operator" && (
          <Button
            onClick={handleReferrerClick}
            className={cn(
              "header__call-button--refer",
              visibleReferrer && "active"
            )}
            size="small"
          >
            <PhoneArrow className="phone-arrow-pic" />
          </Button>
        )}
    </div>
  );
};

export default observer(CallInfoComponent);
