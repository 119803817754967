import { formatISO } from "date-fns";
import api from "../../api";

const loadShiftsStatistics = (date: string) => {
  return api.get(`api/shift/statistics?date=${date}`);
};

const loadOperatorsStatistics = (date?: Date) => {
  const commonDate = date ? new Date(date) : new Date();
  const parsedDate = formatISO(commonDate).split("T");
  return api.get(`api/user/statistics/operators?date=${parsedDate[0]}`);
};

const loadOperatorStatistics = (date?: string) => {
  return api.get(`api/user/me/statistic/operator?date=${date}`);
};

const loadLinesStatistics = (date?: Date) => {
  const commonDate = date ? new Date(date) : new Date();
  const parsedDate = formatISO(commonDate).split("T");
  return api.get(`api/shift/statistics/lines?date=${parsedDate[0]}`);
};

// const downloadReport = (date: string | Date | null) => {
//   const reportDate = formatISO(new Date(date as string)).split("T");
//   return api.get(`/api/user/statistics/operators?date=${reportDate[0]}`, {
//     responseType: "blob",
//   });
// };

const downloadLinesReport = (date: string | Date | null) => {
  const reportDate = formatISO(new Date(date as string)).split("T");
  return api.get(`/api/shift/statistics/lines/download?date=${reportDate[0]}`, {
    responseType: "blob",
  });
};

const loadAppealsOperatorStatistic = (date: string | Date | null) => {
  const reportDate = formatISO(new Date(date as string)).split("T");
  return api.get(
    `/api/user/me/statistic/appeals-manager?date=${reportDate[0]}`
  );
};

const userOnline = () => {
  return api.get(`/api/user/keep-online`);
};

const shiftService = {
  loadShiftsStatistics,
  loadOperatorsStatistics,
  loadAppealsOperatorStatistic,
  loadOperatorStatistics,
  //   downloadReport,
  downloadLinesReport,
  loadLinesStatistics,
  userOnline,
};

export default shiftService;
