import React from "react";
import cn from "classnames";
export interface ICardProps {
  children: React.ReactNode;
  className?: string;
}
import "./styles.scss";

const Card: React.FC<ICardProps> = ({ children, className }) => {
  return <div className={cn("crm-card", className)}>{children}</div>;
};

export default Card;
