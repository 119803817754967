import api from "../../api";
import { formatISO } from "date-fns";

const loadReports = () => {
  return api.get(`/api/exports/reports-list`);
};

const loadtWorkTimeReport = (values: {
  endDate: string | Date | null;
  startDate: string | Date | null;
  period: string | null;
}) => {
  const startDate = formatISO(new Date(values.startDate as string)).split("T");
  const endDate = formatISO(new Date(values.endDate as string)).split("T");
  return api.get(
    `/api/user/statistics/operators-online?startDate=${startDate[0]}&endDate=${endDate[0]}`
  );
};

const downloadWorkTimeReport = (values: {
  endDate: string | Date | null;
  startDate: string | Date | null;
  period: string | null;
}) => {
  const startDate = formatISO(new Date(values.startDate as string)).split("T");
  const endDate = formatISO(new Date(values.endDate as string)).split("T");

  return api.get(
    `/api/user/statistics/operators-online/download?startDate=${startDate[0]}&endDate=${endDate[0]}`,
    {
      responseType: "blob",
    }
  );
};

const loadReport = (
  url: string,
  values: {
    endDate: string | null;
    startDate: string | null;
    period: string | null;
  }
) => {
  const startDate = formatISO(new Date(values.startDate as string)).split("T");
  const endDate = formatISO(new Date(values.endDate as string)).split("T");

  return api.get(`${url}?startDate=${startDate[0]}&endDate=${endDate[0]}`, {
    responseType: "blob",
  });
};

const loadCallsReport = (values: {
  endDate: string | Date | null;
  startDate: string | Date | null;
  period: string | null;
}) => {
  const startDate = formatISO(new Date(values.startDate as string)).split("T");
  const endDate = formatISO(new Date(values.endDate as string)).split("T");

  return api.get(
    `/api/call-info-report/export-by-topic?startDate=${startDate[0]}&endDate=${endDate[0]}`
  );
};

const loadCallReportExport = (values: {
  endDate: string | Date | null;
  startDate: string | Date | null;
  period: string | null;
}) => {
  const startDate = formatISO(new Date(values.startDate as string)).split("T");
  const endDate = formatISO(new Date(values.endDate as string)).split("T");

  return api.get(
    `/api/call-info-report/download-by-topic?startDate=${startDate[0]}&endDate=${endDate[0]}`,
    {
      responseType: "blob",
    }
  );
};

const reportService = {
  loadReports,
  loadReport,
  loadCallsReport,
  loadtWorkTimeReport,
  loadCallReportExport,
  downloadWorkTimeReport,
};

export default reportService;
