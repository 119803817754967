let beepIntervalId: NodeJS.Timeout | null = null;
let oscillator: OscillatorNode | null;

const playBeep = (duration: number) => {
  // Create an AudioContext object
  const audioContext = new AudioContext();

  // Create an oscillator node
  oscillator = audioContext.createOscillator();

  // Set the oscillator frequency to 440Hz (A4)
  oscillator.frequency.setValueAtTime(440, audioContext.currentTime);

  // Create a gain node to control the volume
  const gainNode = audioContext.createGain();

  // Set the gain to 0.5 (half volume)
  gainNode.gain.setValueAtTime(0.5, audioContext.currentTime);

  // Connect the oscillator to the gain node and the gain node to the audio output
  oscillator.connect(gainNode);
  gainNode.connect(audioContext.destination);

  // Start the oscillator
  oscillator.start();
  oscillator.stop(audioContext.currentTime + duration);
};

/**
 *
 * @param duration длительность гудка в секундах
 * @param beepsInterval интервал между гудками в миллисекундах
 */
export const startCallBeeps = (duration = 1, beepsInterval = 4000) => {
  if (beepIntervalId || oscillator) {
    stopCallBeeps();
  }

  playBeep(duration);

  beepIntervalId = setInterval(() => {
    playBeep(duration);
  }, beepsInterval);
};

export const stopCallBeeps = () => {
  oscillator?.disconnect();
  if (beepIntervalId) {
    clearInterval(beepIntervalId);
    beepIntervalId = null;
    oscillator = null;
  }
};
