import "./styles.scss";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import MainLayout from "../../components/layouts/MainLayout";
import { UsersStore } from "./store";
import Typography from "../../components/Typography";
import { FlexColumn } from "@kit/kit-aiss";
import CrmTable from "../../components/CrmTable";
import CreateUser from "./components/popups/CreateUser";
import Button from "../../components/Button";
import EditUser from "./components/popups/EditUser";
import { ReactComponent as PlusIcon } from "../../assets/svg/add.svg";
import { ReactComponent as DownloadIcon } from "../../assets/svg/download-icon.svg";
import Avatar from "../../components/UserComponent/Avatar";
import { EditUserStore } from "./components/popups/EditUser/store";
import { CreateUserStore } from "./components/popups/CreateUser/store";
import InfiniteScroll from "react-infinite-scroll-component";

const store = new UsersStore();
const userStore = new EditUserStore();
const createUserStore = new CreateUserStore();

const Users = () => {
  const [isOpenCreateUser, setIsOpenCreateUser] = useState(false);
  const [isOpenEditUser, setIsOpenEditUser] = useState(false);

  useEffect(() => {
    store.loadUsers();

    return () => {
      store.reset();
    };
  }, []);

  const handleEditClick = (idx: number) => {
    const id = store.users.users[idx].id;
    userStore.init(id);
    setIsOpenEditUser(true);
  };

  const handleOpenCreateClick = () => {
    createUserStore.init();
    setIsOpenCreateUser(true);
  };

  const handleMoreLoad = () => {
    store.incrementPageCount();
    store.loadUsers();
  };

  const handleUsersReportDownloadClick = () => {
    store.downloadReport();
  };

  return (
    <MainLayout status={false}>
      <Typography variant="h1">Пользователи</Typography>

      <div className="users-table-block">
        <div className="users-table-block__heading">
          <div className="users-table-block__heading-sort">
            <div
              onClick={() => (store.isActiveUsers = true)}
              className="users-table-block__heading-sort-active"
            >
              <Typography>Активных</Typography>
              <Typography>{store.users.activeCount}</Typography>
            </div>
            <div className="users-table-block__heading-sort-divider" />
            <div
              onClick={() => (store.isActiveUsers = false)}
              className="users-table-block__heading-sort-all"
            >
              <Typography>Всего</Typography>
              <Typography>{store.users.count}</Typography>
            </div>
          </div>
          <div className="users-table-block__heading-buttons">
            <Button onClick={handleOpenCreateClick}>
              <PlusIcon />
              Добавить пользователя
            </Button>
            <div className="users-table-block__heading-buttons-icons">
              <div className="users-table-block__heading-buttons-icons-divider" />
              <DownloadIcon
                onClick={handleUsersReportDownloadClick}
                className="users-table-block__icon-button"
              />
            </div>
          </div>
        </div>
        <FlexColumn>
          <InfiniteScroll
            dataLength={(store.users.users.length as number) || 0}
            next={handleMoreLoad}
            hasMore={true}
            loader
          >
            <CrmTable
              isLoading={store.isLoading}
              isHoverBg
              headers={[
                { title: "Аватар", width: 120 },
                {
                  title: "ФИО",
                  width: 340,
                  ...store.sortStore.sortFields.get("last_name"),
                },
                {
                  title: "Организация",
                },
                {
                  title: "Роль",
                },
                {
                  title: "Последняя активность",
                  width: 200,
                },
                {
                  title: "Статус",
                },
              ]}
              rows={store.rows.map((row, idx) => [
                {
                  text: (
                    <Avatar
                      firstName={store.users.users[idx]?.first_name}
                      lastName={store.users.users[idx]?.last_name}
                      isInactive={!store.users.users[idx]?.status}
                    />
                  ),
                },
                ...row,
              ])}
              onRowClick={handleEditClick}
            />
          </InfiniteScroll>
        </FlexColumn>
      </div>
      {isOpenCreateUser && (
        <CreateUser
          isOpen={isOpenCreateUser}
          onClose={() => setIsOpenCreateUser(false)}
          store={store}
          CreateUserStore={createUserStore}
        />
      )}
      {isOpenEditUser && (
        <EditUser
          isOpen={isOpenEditUser}
          onClose={() => setIsOpenEditUser(false)}
          EditUserStore={userStore}
          store={store}
        />
      )}
    </MainLayout>
  );
};

export default observer(Users);
