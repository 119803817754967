import {
  IncomingAckEvent,
  IncomingEvent,
  OutgoingAckEvent,
  OutgoingEvent,
  RTCSession,
  SendingEvent,
} from "jssip/lib/RTCSession";
import { makeAutoObservable, runInAction } from "mobx";
import { CallsStore } from "../../pages/Calls/store";

import GlobalStatusStore from ".";
import sipControllerStore from "./SipController";
import callsService from "../../services/calls";
import {
  attendedReferCallInfoEventHandlers,
  referMethodInfoEventHandlers,
} from "./utils/infoLogs";
import { getReferTarget } from "./utils/sipConfig";
import { startCallBeeps, stopCallBeeps } from "../../utils/beeps";

const callsStore = new CallsStore();

export class SipSessionController {
  id: string | null = null;
  contact: string | null = null;
  accepted = false;

  private _session;

  get isIncomingSession() {
    return this._session.direction === "incoming";
  }

  get isOutGoingSession() {
    return this._session.direction === "outgoing";
  }

  get remoteUserTel() {
    return this._session.remote_identity.uri.user;
  }

  get lineId() {
    return this._session.local_identity.uri.user;
  }

  isEnded = () => {
    return this._session.isEnded();
  };

  constructor(session: RTCSession) {
    makeAutoObservable(this);
    this._session = session;
    this.id = this._session.id;
    this.contact = this._session.remote_identity.display_name;

    const remoteAudio = new window.Audio();
    remoteAudio.autoplay = true;

    // addInfoLogsToSessionEvents(this._session);

    this._session.on("progress", (e: any) => {
      console.log("progress", e, e.remote);

      if (e?.response?.status_code === 180) {
        startCallBeeps();
      } else {
        stopCallBeeps();
      }
    });

    this._session.on("peerconnection", (e) => {
      // addInfoLogsToPeerConnectionEvent(e);
      e.peerconnection.addEventListener("track", (e) => {
        console.log("track", e);
        console.log("track streamsLength", e.streams.length);

        stopCallBeeps();
        remoteAudio.srcObject = e.streams[0];
      });
    });

    this._session.on("sending", (e: SendingEvent) => {
      e.request.setHeader("X-UIDCRM", sipControllerStore.xUid as string);
    });

    this._session.on("accepted", (e: IncomingEvent | OutgoingEvent) => {
      console.log("accepted", e);
      stopCallBeeps();

      runInAction(() => {
        this.accepted = true;
      });
      GlobalStatusStore.setState("isOutGoingRing", false);
    });

    this._session.on("confirmed", (e: IncomingAckEvent | OutgoingAckEvent) => {
      console.log("confirmed", e);

      sipControllerStore.onAcceptCall()?.then((res) => {
        const { data } = res.data;
        sipControllerStore.setCallId(data.id);
      });

      GlobalStatusStore.createCallModalState.open();
      GlobalStatusStore.setState("isCreateCall", true);
      GlobalStatusStore.setState("isAnswered", true);
    });

    this._session.on("ended", (e) => {
      console.log("ended main session", e.originator, e.cause);
      stopCallBeeps();

      runInAction(() => {
        this.accepted = false;
        GlobalStatusStore.setState("isAnswered", false);
        GlobalStatusStore.setState("isOutGoing", false);
        GlobalStatusStore.setState("isSupported", false);
        GlobalStatusStore.setState("isIncoming", false);
        callsStore.getRingTableData("operator");
        GlobalStatusStore.endCall();
        sipControllerStore.resetSession();
        if (sipControllerStore.supportSession) {
          sipControllerStore.supportSession.terminate();
        }
      });
    });

    this._session.on("failed", (e) => {
      console.log(
        "failed main session",
        e,
        e.originator,
        e.cause,
        this._session
      );

      stopCallBeeps();

      GlobalStatusStore.setState("isOutGoing", false);
      GlobalStatusStore.setState("isOutGoingRing", false);
      GlobalStatusStore.setState("isSupported", false);
      GlobalStatusStore.setState("isIncoming", false);

      if (this.isOutGoingSession) {
        GlobalStatusStore.setState("isCreateCall", true);
        GlobalStatusStore.createCallModalState.open();

        const phone = this.isIncomingSession
          ? this._session.local_identity.uri.user
          : this._session.remote_identity.uri.user;

        const formData = new FormData();
        formData.append("phone", phone);
        formData.append("status", "2");
        formData.append("externalCallId", sipControllerStore.xUid || "");
        formData.append("type", "2");

        callsService.startCall(formData).then(({ data }) => {
          if (data) {
            sipControllerStore.setCallId(data.data.id);
          }
        });
      }

      sipControllerStore.resetSession();

      if (sipControllerStore.supportSession) {
        sipControllerStore.supportSession.terminate();
      }
    });

    this._session.on("reinvite", (e) => {
      console.log(
        "reinvite",
        e,
        e.request.getHeader("Call-line"),
        e.request.getHeader("X-QUEUE")
      );
      const [, SIP] = e.request.getHeader("P-Asserted-Identity").split(" ");
      const [, rawInfo] = SIP.split(":");
      const [tel] = rawInfo.split("@");

      //TODO: запросы
      runInAction(() => {
        sipControllerStore.reinviteTel = tel;
        const formData = new FormData();
        formData.append("phone", sipControllerStore.reinviteTel);
        callsService.responseCall(sipControllerStore.xUid as string, formData);
      });
    });

    // звук для исходящего звонка при переадресации
    if (this._session.connection) {
      this._session.connection.addEventListener("addstream", (e: any) => {
        console.log("addstream", e);

        stopCallBeeps();

        if (!remoteAudio.srcObject) {
          remoteAudio.srcObject = e.stream;
        }
      });
    }
  }

  //метод переадресации с оператора
  referMethod = (tel: string, onClose?: () => void) => {
    console.log("в методе рефера");

    const eventHandlers = {
      ...referMethodInfoEventHandlers,
      progress: (e: any) => {
        console.log("progress refer", e);

        sipControllerStore.setIsRedirected(true);
        GlobalStatusStore.setState("isOutGoing", true);
        GlobalStatusStore.setState("isAnswered", false);
        GlobalStatusStore.endCall();
        sipControllerStore.resetCall();
        onClose && onClose();
      },
    };

    this._session.refer(getReferTarget(tel), {
      eventHandlers,
    });
  };

  referWithSupportMethod = (tel: string) => {
    this._session.hold();
    sipControllerStore.callSupport(tel);
  };

  unhold = () => {
    this._session.unhold();
  };

  attendedReferCall = () => {
    const eventHandlers = {
      ...attendedReferCallInfoEventHandlers,
      accepted: function (e: any) {
        console.log("attendedReferCall accepted", e);
        e.request.setHeader("attendedRefer", true);
        sipControllerStore.setIsRedirected(true);
      },
    };

    const call1 = this._session;
    const call2 = sipControllerStore.supportSession?._session;

    call1?.unhold();

    if (call1 && call2) {
      call1.refer(call2.remote_identity.uri, {
        eventHandlers,
        replaces: call2,
      });
    }
    GlobalStatusStore.setState("isSupported", false);
  };

  answer = () => {
    this._session.answer({
      mediaConstraints: {
        audio: true,
        video: false,
      },
      pcConfig: {
        rtcpMuxPolicy: "negotiate" as any,
      },
    });

    GlobalStatusStore.getClientPhone(this.contact);
    GlobalStatusStore.setState("isAnswered", true);
  };

  terminate = () => {
    console.log("session.terminate", this._session.status);

    if (this._session.status !== 8) {
      this._session.terminate();
    }
  };

  isInProgress = () => this._session.isInProgress();
}
