import "./styles.scss";

import { observer } from "mobx-react-lite";
import React from "react";
import Button from "../Button";
import { IconClose } from "@kit/kit-aiss";

const ButtonRemove: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button variant="transparent" className="button-remove" onClick={onClick}>
      <IconClose />
    </Button>
  );
};

export default observer(ButtonRemove);
