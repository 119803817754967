import "./styles.scss";

import cn from "classnames";
import React, { ReactNode } from "react";

import { TYPOGRAPHY_VARIANT_MAP } from "./constants";

interface ITypographyProps {
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "span"
    | "title1"
    | "title2"
    | "title3"
    | "form-medium"
    | "text-medium"
    | "sub-text-medium"
    | "small-txt-medium";
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span" | "p" | "div";
  children?: ReactNode;
  color?:
    | "colorWhite"
    | "color1"
    | "color2"
    | "color3"
    | "color4"
    | "color5"
    | "color6"
    | "color7"
    | "color8"
    | "color9"
    | "color10"
    | "color11"
    | "color12";
  className?: string;
}

const Typography: React.FC<ITypographyProps> = ({
  variant = "span",
  as,
  children,
  color,
  className,
}) => {
  const Component = as || TYPOGRAPHY_VARIANT_MAP[variant];

  return (
    <Component
      className={cn(
        "typography",
        {
          [`typography--variant-${variant}`]: variant,
          [`typography--color-${color}`]: color,
        },
        className
      )}
    >
      {children}
    </Component>
  );
};

export default Typography;
