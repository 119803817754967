import { makeAutoObservable, runInAction } from "mobx";
import { AttributeBlockStore } from "../components/AttributeBlock/store";

export class AttributeStore {
  attributeBlockStore: AttributeBlockStore = new AttributeBlockStore();
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  init = (themeId: string, type: "theme" | "subTheme", subtopicId: string) => {
    this.isLoading = true;
    this.attributeBlockStore
      .loadAttributes()
      .then(() => this.attributeBlockStore.loadTopic(themeId, type, subtopicId))
      .finally(() => runInAction(() => (this.isLoading = false)));
  };
}
