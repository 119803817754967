import { makeAutoObservable, runInAction } from "mobx";
import { IAttribute } from "../../../../Atributes/store/types";
import attributesService from "../../../../../services/attributes";
import { FormStore } from "@kit/kit-aiss";
import themasService from "../../../../../services/themas";
import { ITopic } from "../../../../ThemesPage/store/index.types";
import subThemasService from "../../../../../services/subthemas";
import { ISubTheme } from "../../../../SubThemesPage/store/types";

export class AttributeBlockStore {
  form = new FormStore({ search: null });
  attributes: AttributeStore[] = [];
  attributesIds: number[] = [];
  selectedAttributes: IAttribute[] = [];
  topic: ITopic | null = null;
  subTopic: ISubTheme | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.attributes = [];
    this.attributesIds = [];
    this.selectedAttributes = [];
    this.form.reset();
  };

  get filteredAttributes() {
    if (String(this.form.values.search).length) {
      return this.attributes.filter((attribute) =>
        attribute.attribute.title
          .toLocaleLowerCase()
          .includes((this.form.values.search || "").toLocaleLowerCase())
      );
    } else {
      return this.attributes;
    }
  }

  swapItems = (id: number, swap: "next" | "previous") => {
    const idx = this.attributesIds.findIndex(
      (attributesId) => attributesId === id
    );

    if (swap === "next" && idx === this.attributesIds.length - 1) {
      [this.attributesIds[0], this.attributesIds[idx]] = [
        this.attributesIds[idx],
        this.attributesIds[0],
      ];
      [this.selectedAttributes[0], this.selectedAttributes[idx]] = [
        this.selectedAttributes[idx],
        this.selectedAttributes[0],
      ];
    } else if (swap === "previous" && idx === 0) {
      [
        this.attributesIds[idx],
        this.attributesIds[this.attributesIds.length - 1],
      ] = [
        this.attributesIds[this.attributesIds.length - 1],
        this.attributesIds[idx],
      ];
      [
        this.selectedAttributes[idx],
        this.selectedAttributes[this.selectedAttributes.length - 1],
      ] = [
        this.selectedAttributes[this.selectedAttributes.length - 1],
        this.selectedAttributes[idx],
      ];
    } else if (swap === "next") {
      [this.attributesIds[idx], this.attributesIds[idx + 1]] = [
        this.attributesIds[idx + 1],
        this.attributesIds[idx],
      ];
      [this.selectedAttributes[idx], this.selectedAttributes[idx + 1]] = [
        this.selectedAttributes[idx + 1],
        this.selectedAttributes[idx],
      ];
    } else if (swap === "previous") {
      [this.attributesIds[idx - 1], this.attributesIds[idx]] = [
        this.attributesIds[idx],
        this.attributesIds[idx - 1],
      ];
      [this.selectedAttributes[idx - 1], this.selectedAttributes[idx]] = [
        this.selectedAttributes[idx],
        this.selectedAttributes[idx - 1],
      ];
    }
  };

  deleteItem = (id: number) => {
    const idx = this.attributes.findIndex(
      (attribute) => attribute.attribute.id === id
    );
    const attributeIdIdx = this.attributesIds.findIndex(
      (attributesId) => attributesId === id
    );
    const selectedAttributeIdx = this.selectedAttributes.findIndex(
      (selectedAttribute) => selectedAttribute.id === id
    );

    this.attributesIds.splice(attributeIdIdx, 1);
    this.selectedAttributes.splice(selectedAttributeIdx, 1);
    this.attributes[idx].form.changeValue(false, "attribute");
  };

  changeValue = (attributeItem: IAttribute, value: boolean, name?: string) => {
    const idx = this.attributes.findIndex(
      (attribute) => attribute.attribute.id === attributeItem.id
    );
    const attributeIdIdx = this.attributesIds.findIndex(
      (attributesId) => attributesId === attributeItem.id
    );
    const selectedAttributeIdx = this.selectedAttributes.findIndex(
      (selectedAttribute) => selectedAttribute.id === attributeItem.id
    );

    if (!this.attributes[idx].form.values.attribute) {
      this.attributes[idx].form.adapters.attribute.onChange(value, name);
      this.attributesIds.push(attributeItem.id);
      this.selectedAttributes.push(attributeItem);
    } else if (this.attributes[idx].form.values.attribute) {
      this.attributes[idx].form.adapters.attribute.onChange(value, name);
      this.attributesIds.splice(attributeIdIdx, 1);
      this.selectedAttributes.splice(selectedAttributeIdx, 1);
    }
  };

  loadTopic = (
    themeId: string,
    type: "theme" | "subTheme",
    subThemeId?: string
  ) => {
    if (type === "theme") {
      themasService.loadTopic(themeId).then(({ data }) => {
        runInAction(() => {
          this.topic = {
            id: data.data.id,
            caption: data.data.title,
            parentId: data.data.parent_id,
            created: data.data.created_at,
            updated: data.data.updated_at,
            deactivated: data.data.deactivated_at,
          };
          this.selectedAttributes = data.data.attributes.map(
            (attribute: any) => ({
              id: attribute.id,
              createdAt: attribute.created_at,
              updatedAt: attribute.updated_at,
              deactivatedAt: attribute.deactivate_at,
              name: attribute.name,
              title: attribute.title,
              type: attribute.type,
            })
          );
          this.attributesIds = data.data.attributes.map(
            (attribute: any) => attribute.id
          );
          this.attributes.forEach((attribute, index) => {
            const idx = data.data.attributes.findIndex(
              (attr: any) => attr.id === attribute.attribute.id
            );
            if (idx !== -1) {
              this.attributes[index].form.changeValue(true, "attribute");
            }
          });
        });
      });
    } else if (type === "subTheme") {
      subThemasService
        .loadSubTheme(themeId, subThemeId || "")
        .then(({ data }) => {
          runInAction(() => {
            this.subTopic = {
              id: data.data.id,
              caption: data.data.title,
              parentId: data.data.parent_id,
              created: data.data.created_at,
              updated: data.data.updated_at,
              deactivated: data.data.deactivate_at,
            };
            this.selectedAttributes = data.data.attributes.map(
              (attribute: any) => ({
                id: attribute.id,
                createdAt: attribute.created_at,
                updatedAt: attribute.updated_at,
                deactivatedAt: attribute.deactivate_at,
                name: attribute.name,
                title: attribute.title,
                type: attribute.type,
              })
            );
            this.attributesIds = data.data.attributes.map(
              (attribute: any) => attribute.id
            );
            this.attributes.forEach((attribute, index) => {
              const idx = data.data.attributes.findIndex(
                (attr: any) => attr.id === attribute.attribute.id
              );
              if (idx !== -1) {
                this.attributes[index].form.changeValue(true, "attribute");
              }
            });
          });
        });
    }
  };

  loadAttributes = () => {
    return attributesService.loadAttributes().then(({ data }) => {
      runInAction(() => {
        this.attributes = data.data.map(
          (attribute: any) =>
            new AttributeStore({
              deactivatedAt: attribute.deactivated_at,
              id: attribute.id,
              name: attribute.name,
              title: attribute.title,
              type: attribute.type,
              createdAt: attribute.created_at,
              updatedAt: attribute.updated_at,
              options: attribute.options.map((option: any) => ({
                id: option.id,
                name: option.name,
                attributeId: option.attribute_id,
                createdAt: option.created_at,
                updatedAt: option.updated_at,
              })),
            })
        );
      });
    });
  };

  editTopic = (themeId: string) => {
    const formData = new FormData();

    formData.append("title", this.topic?.caption || "");
    formData.append("category", "calls");
    this.attributesIds.forEach((attributeId, idx) => {
      formData.append(`attributes[${idx}][id]`, `${String(attributeId)}`);
      formData.append(`attributes[${idx}][position]`, `${String(idx)}`);
    });

    themasService
      .editTheme(formData, themeId)
      .then(() => this.loadTopic(themeId, "theme"));
  };

  editSubtopic = (themeId: string, subThemeId: string) => {
    const formData = new FormData();

    formData.append("title", this.subTopic?.caption || "");
    formData.append("category", "calls");
    this.attributesIds.forEach((attributeId, idx) => {
      formData.append(`attributes[${idx}][id]`, `${String(attributeId)}`);
      formData.append(`attributes[${idx}][position]`, `${String(idx)}`);
    });

    subThemasService
      .editSubTheme(themeId, subThemeId, formData)
      .then(() => this.loadTopic(themeId, "subTheme", subThemeId));
  };
}

class AttributeStore {
  attribute: IAttribute;
  form = new FormStore({ attribute: false });

  constructor(attribute: IAttribute) {
    makeAutoObservable(this);
    this.attribute = attribute;
  }
}
