import api from "../../api";

const loadThemas = (category?: string, withDeactivated?: boolean) => {
  return api.get(
    `api/topic?category=${
      category ? category : "calls"
    }&filter[withDeactivated]=${withDeactivated}&sort[title]=asc`
  );
};

const loadTopic = (themeId: string) => {
  return api.get(`api/topic/${themeId}`);
};

const loadOrganizations = () => {
  return api.get(`/api/call-info/organizations`);
};

const createTheme = (form: FormData) => {
  return api.post("/api/topic", form);
};

const editTheme = (form: FormData, themeId: string) => {
  return api.post(`/api/topic/${themeId}`, form);
};

//ТОЛЬКО ДЕАКТИВАЦИЯ. НИКАКИХ УДАЛЕНИЙ БОЛЕЕ
const removeTheme = (themeId: string) => {
  return api.delete(`/api/topic/${themeId}`);
};

const deactivateTheme = (form: FormData) => {
  return api.post(`/api/topics-disable`, form);
};

const activateTheme = (form: FormData) => {
  return api.post(`/api/topics-enable`, form);
};

const themasService = {
  loadThemas,
  loadTopic,
  createTheme,
  editTheme,
  removeTheme,
  deactivateTheme,
  activateTheme,
  loadOrganizations,
};

export default themasService;
