import api from "../../api";

const loadAttributes = () => {
  return api.get("/api/attribute");
};

const loadAttribute = (id: number) => {
  return api.get(`/api/attribute/${id}`);
};

const loadTypes = () => {
  return api.get("/api/attribute-types");
};

const createAttribute = (formData: FormData) => {
  return api.post("/api/attribute", formData);
};

const editAttribute = (id: number, formData: FormData) => {
  return api.post(`/api/attribute/${id}`, formData);
};

const deleteAttribute = (id: number) => {
  return api.delete(`/api/attribute/${id}`);
};

const disableAttribute = (formData: FormData) => {
  return api.post(`/api/attributes-disable`, formData);
};

const enableAttribute = (formData: FormData) => {
  return api.post(`/api/attributes-enable`, formData);
};

const loadAppealStatuses = () => {
  return api.get(`/api/appeal/statuses`);
};

const loadGrades = () => {
  return api.get(`/api/appeal/grades`);
};

const loadCallStatuses = () => {
  return api.get(`/api/call-info/statuses`);
};

const loadReactions = () => {
  return api.get(`/api/call-info/reactions`);
};

const loadRecordStatuses = () => {
  return api.get(`/api/voicemail/statuses`);
};

const attributesService = {
  loadAttributes,
  loadAttribute,
  loadTypes,
  createAttribute,
  editAttribute,
  deleteAttribute,
  disableAttribute,
  enableAttribute,
  loadAppealStatuses,
  loadGrades,
  loadCallStatuses,
  loadReactions,
  loadRecordStatuses,
};

export default attributesService;
