import {
  URL_APPEALS,
  URL_AUTH,
  URL_CALLS,
  URL_OPERATORS,
  URL_SUB_THEMES,
  URL_SUB_TOPIC,
  URL_THEMES,
  URL_REPORT_CALLS,
  URL_REPORT_OPERATOR_WORK_TIME,
  URL_SHIFTS,
} from "../../../constants/urls";
import AuthPage from "../../../pages/AuthPage";
import OperatorsPage from "../../../pages/OperatorsPage";
import SubThemesPage from "../../../pages/SubThemesPage";
import ThemesPage from "../../../pages/ThemesPage";
import Lines from "../../../pages/Lines";
import { IDefaultRoutes } from "./types";
import Attributes from "../../../pages/Atributes";
import Attribute from "../../../pages/Attribute";
import Appeals from "../../../pages/Appeals";
import OperatorAppeals from "../../../pages/OperatorAppeals";
import AppealsView from "../../../pages/AppealsView";
import ReportOperatorsPage from "../../../pages/ReportCallsPage";
import ReportWorkTimePage from "../../../pages/ReportWorkTimePage";
import Shifts from "../../../pages/Shifts";
import Calls from "../../../pages/Calls";
import OperatorCalls from "../../../pages/OperatorCalls";
import Users from "../../../pages/Users";
import ReportExport from "../../../pages/ReportExport";
import Records from "../../../pages/Records";
import PrintPage from "../../../pages/PrintPage";
import ReportPrintPage from "../../../pages/Prints/CallCountPage";
import ReportWorkTimePrintPage from "../../../pages/Prints/WorkTimePage";

export const defaultRoutes: IDefaultRoutes[] = [
  {
    path: URL_AUTH,
    component: AuthPage,
  },
  {
    path: URL_CALLS,
    component: OperatorCalls,
  },
  // {
  //   path: URL_TREATMENTS,
  //   component: MainPage,
  // },
  {
    path: URL_THEMES,
    component: ThemesPage,
  },
  {
    path: URL_SUB_THEMES,
    component: SubThemesPage,
  },
  {
    path: URL_REPORT_CALLS,
    component: ReportOperatorsPage,
  },
  {
    path: URL_REPORT_OPERATOR_WORK_TIME,
    component: ReportWorkTimePage,
  },
  {
    path: URL_OPERATORS,
    component: OperatorsPage,
  },
  {
    path: "/calls-dashboard",
    component: Calls,
  },
  {
    path: "/lines",
    component: Lines,
  },
  {
    path: "/attributes",
    component: Attributes,
  },
  {
    path: URL_SUB_TOPIC,
    component: Attribute,
  },
  {
    path: URL_APPEALS,
    component: Appeals,
  },
  {
    path: "/my-appeals",
    component: OperatorAppeals,
  },
  {
    path: "/appeals-view",
    component: AppealsView,
  },
  {
    path: URL_SHIFTS,
    component: Shifts,
  },
  {
    path: "/users",
    component: Users,
  },
  {
    path: "/report-export",
    component: ReportExport,
  },
  {
    path: "/my-voicemail",
    component: Records,
  },
  {
    path: "/shifts-print",
    component: PrintPage,
  },
  {
    path: "/shifts-report-calls",
    component: ReportPrintPage,
  },
  {
    path: "/shifts-report-worktime",
    component: ReportWorkTimePrintPage,
  },
];
