import "./styles.scss";
import React, { useEffect, useState } from "react";
import { Button, FlexColumn } from "@kit/kit-aiss";
import { ReactComponent as DownloadIcon } from "../../assets/svg/download-icon.svg";
import { ReactComponent as PlusIcon } from "../../assets/svg/plus.svg";
import MainLayout from "../../components/layouts/MainLayout";
import { LinesStore } from "./store";
import CreateLineModal from "./components/poups/CreateLineModal";
import { observer } from "mobx-react-lite";
import EditLineModal from "./components/poups/EditLineModal";
import CrmTable from "../../components/CrmTable";
import Typography from "../../components/Typography";

const store = new LinesStore();

const Lines = () => {
  const [isOpenCreateLineModal, setIsOpenCreateLineModal] = useState(false);
  const [isOpenEditLineModal, setIsOpenEditLineModal] = useState(false);

  useEffect(() => {
    store.loadLines();
    return () => store.reset();
  }, []);

  const handleOpenEdit = (idx: number) => {
    setIsOpenEditLineModal(true);
    store.editLineModalStore.init(
      (store.isActiveLines ? store.activeLines : store.lines)[idx]?.id
    );
  };

  const handleDownloadClick = () => {
    store.downloadLines();
  };

  return (
    <MainLayout status={false}>
      <div className="lines">
        <FlexColumn gap={20} className="lines__block">
          <Typography variant="h1">Линии</Typography>
          <div className="lines__block-table">
            <div className="lines__block-table-sub">
              <div className="lines__block-table-sub-statuses">
                <div
                  className="lines__block-table-sub-statuses-status"
                  onClick={() => store.setIsActiveLines(false)}
                >
                  <span className="lines__block-table-sub-statuses-status__title">
                    Всего
                  </span>
                  <span className="lines__block-table-sub-statuses-status__count">
                    {store.lines.length}
                  </span>
                </div>
                <div className="lines__block-table-sub-statuses__divider" />
                <div
                  className="lines__block-table-sub-statuses-status"
                  onClick={() => store.setIsActiveLines(true)}
                >
                  <span className="lines__block-table-sub-statuses-status__title">
                    Активных
                  </span>
                  <span className="lines__block-table-sub-statuses-status__count">
                    {store.activeLines.length}
                  </span>
                </div>
              </div>
              <div className="lines__block-table-sub-controllers">
                <Button
                  variant="secondary"
                  type="submit"
                  onClick={() => setIsOpenCreateLineModal(true)}
                >
                  <PlusIcon />
                  Добавить новую линию
                </Button>
                {/* <PrintIcon /> */}
                <div className="lines__block-table-sub-controllers__divider" />
                <DownloadIcon onClick={handleDownloadClick} />
              </div>
            </div>

            <FlexColumn>
              <CrmTable
                isLoading={store.isLoading}
                isHoverBg
                headers={[
                  { title: "Очередь", width: 120 },
                  {
                    title: "Краткое название линии",
                    width: 340,
                  },
                  {
                    title: "Назначено",
                  },
                  {
                    title: "Принято звонков",
                  },
                  {
                    title: "Статус",
                    width: 200,
                    ...store.sortStore.sortFields.get("active"),
                  },
                  {
                    title: "Создана",
                    ...store.sortStore.sortFields.get("created_at"),
                  },
                ]}
                rows={store.rows}
                onRowClick={handleOpenEdit}
              />
            </FlexColumn>

            {isOpenCreateLineModal && (
              <CreateLineModal
                onClose={() => setIsOpenCreateLineModal(false)}
                isOpen={isOpenCreateLineModal}
                store={store.createLineModalStore}
              />
            )}
            {isOpenEditLineModal && (
              <EditLineModal
                onClose={() => setIsOpenEditLineModal(false)}
                isOpen={isOpenEditLineModal}
                store={store.editLineModalStore}
              />
            )}
          </div>
        </FlexColumn>
      </div>
    </MainLayout>
  );
};

export default observer(Lines);
