import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { URL_AUTH } from "./constants/urls";
import authStore from "./pages/AuthPage/store/authStore";
import { defaultRoutes } from "./router/routes/DefaultRoutes";

import "./styles/global.scss";
import CreateCallModal from "./pages/OperatorCalls/components/popups/CreateCallModal";
import CreateOperatorAppealsModal from "./pages/OperatorAppeals/components/popups/CreateOperatorAppealModal";
import EditCallModal from "./pages/OperatorCalls/components/popups/EditCallModal";

import GlobalStatusStore from "./stores/GlobalStatusStore";
import { EditCallModalStore } from "./pages/OperatorCalls/components/popups/EditCallModal/store";
import {
  STORAGE_KEY_CREATE_APPEAL,
  STORAGE_KEY_CREATE_CALL,
} from "./constants/storage";
import EditAppealModal from "./pages/Appeals/components/popups/EditAppealModal";
import { EditAppealStore } from "./pages/Appeals/components/popups/EditAppealModal/store";

import { LoaderSpinner } from "@kit/kit-aiss";
import { SipPhoneBlock } from "./components/SipPhoneBlock";

const editStore = new EditCallModalStore();
const appealEditStore = new EditAppealStore();

const App: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthRoute = location.pathname === `/${URL_AUTH}`;

  useEffect(() => {
    //TODO: возможно убрать
    //из-за отсутствия чекаута происходит редирект на логин
    //но при его наличии мы не попадаем на страницу логина
    // authStore.checkAuth();

    if (!authStore.isAuth && !isAuthRoute) {
      navigate(URL_AUTH);
      authStore.logOut();
    }

    if (authStore.isAuth) {
      authStore.getSideBarLinks().then(() => {
        if (isAuthRoute) navigate(`${authStore.sideBar[0].value}`);
      });
    }
  }, [authStore.isAuth, location.pathname]);

  const isAuth = authStore.isAuth;
  useEffect(() => {
    if (isAuth) {
      authStore.getSignin();
    }
  }, [isAuth]);

  const sipCredentials = authStore.user.user_asterisk_credentials;

  useEffect(() => {
    if (authStore.isAuth && sipCredentials) {
      if (localStorage.getItem(STORAGE_KEY_CREATE_CALL)) {
        GlobalStatusStore.createCallModalState.open();
      }
      if (localStorage.getItem(STORAGE_KEY_CREATE_APPEAL)) {
        GlobalStatusStore.createAppealModalState.open();
      }
    }
  }, [authStore.isAuth, sipCredentials]);

  useEffect(() => {
    if (GlobalStatusStore.callInfoId) {
      editStore.form.loadCall(GlobalStatusStore.callInfoId);
    }
  }, [GlobalStatusStore.callInfoId]);

  useEffect(() => {
    if (GlobalStatusStore.callId) {
      editStore.form.loadUncall(GlobalStatusStore.callId);
    }
  }, [GlobalStatusStore.callId]);

  useEffect(() => {
    if (GlobalStatusStore.appealId) {
      appealEditStore.form.loadAppeal(GlobalStatusStore.appealId);
    }
  }, [GlobalStatusStore.appealId]);

  const handleCreatePopupCloseAndGoAppeal = () => {
    GlobalStatusStore.createCallModalState.close();
    GlobalStatusStore.createAppealModalState.open();
    GlobalStatusStore.setState("isCreateCall", false);
    GlobalStatusStore.setState("isCreateAppeal", true);
  };

  const handleCloseCall = (bool?: boolean) => {
    GlobalStatusStore.createCallModalState.close(bool);
    navigate("/calls");
  };

  const handleCloseAppeal = () => {
    GlobalStatusStore.createAppealModalState.close();
    navigate("/my-appeals");
  };

  const handleCloseEditCall = () => {
    GlobalStatusStore.editCallModalState.close();
    authStore.role.at(0) === "operator" && navigate("/calls");
    authStore.role.at(0) === "manager" && navigate("/calls-dashboard");

    //TODO: не ресетится каллИд. пока закостылим
    GlobalStatusStore.resetCallId();
  };

  const handleCloseEditAppeal = () => {
    GlobalStatusStore.editAppealModalState.close();
    navigate("/my-appeals");
    authStore.role.at(0) === "manager" && navigate("/appeals");
  };

  const renderRoutes = useMemo(() => {
    return defaultRoutes.map(({ path, component: Component }) => {
      return (
        <Route key={path} caseSensitive path={path} element={<Component />} />
      );
    });
  }, []);

  //Это несущий консолелог, его нельзя убирать
  console.log(11111, sipCredentials && GlobalStatusStore.states.isOutGoingRing);

  if (authStore.isLogoutLoading || (authStore.isLoading && !isAuthRoute))
    return <LoaderSpinner />;

  return (
    <>
      <Routes>{renderRoutes}</Routes>
      {!isAuthRoute && sipCredentials && <SipPhoneBlock />}

      {GlobalStatusStore.createCallModalState.isOpen && (
        <CreateCallModal
          isOpen={GlobalStatusStore.createCallModalState.isOpen}
          onClose={handleCloseCall}
          onCloseAndAndGoAppeal={handleCreatePopupCloseAndGoAppeal}
        />
      )}

      {GlobalStatusStore.createAppealModalState.isOpen && (
        <CreateOperatorAppealsModal
          isOpen={GlobalStatusStore.createAppealModalState.isOpen}
          onClose={handleCloseAppeal}
        />
      )}
      {GlobalStatusStore.editCallModalState.isOpen && (
        <EditCallModal
          isOpen={GlobalStatusStore.editCallModalState.isOpen}
          onClose={handleCloseEditCall}
          id={GlobalStatusStore.callInfoId as number}
          editCallModalStore={editStore}
        />
      )}
      {GlobalStatusStore.editAppealModalState.isOpen && (
        <EditAppealModal
          isOpen={GlobalStatusStore.editAppealModalState.isOpen}
          onClose={handleCloseEditAppeal}
          appealId={GlobalStatusStore.appealId as number}
          editAppealStore={appealEditStore}
        />
      )}
    </>
  );
};

export default observer(App);

// import React from "react";
// import TextArea from "./components/TextArea";
// import { observer } from "mobx-react";
// import { Button, FormStore } from "@kit/kit-aiss";
// import * as yup from "yup";

// const s = yup.object().shape({
//   a: yup
//     .string()
//     .typeError("Необходимо заполнить поле")
//     .nullable()
//     .min(1, "Необходимо заполнить поле"),
// });

// interface F {
//   a: null | string;
// }

// const v: F = {
//   a: null,
// };

// const forms = [new FormStore(v, s), new FormStore(v, s), new FormStore(v, s)];

// function App() {
//   return (
//     <div>
//       {forms.map((form, i) => (
//         <TextArea key={i} {...form.adapts.a} />
//       ))}
//       <Button
//         onClick={() => {
//           for (const f of forms) {
//             f.validate();
//           }

//           const isValid = forms.every((f) => f.isValid);
//           console.log(isValid);
//         }}
//       >
//         13
//       </Button>
//     </div>
//   );
// }

// export default observer(App);
