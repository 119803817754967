import { FlexRow } from "@kit/kit-aiss";
import { observer } from "mobx-react";
import React from "react";
import GlobalStatusStore from "../../stores/GlobalStatusStore";
import sipControllerStore from "../../stores/GlobalStatusStore/SipController";
import Button from "../Button";
import Modal from "../Modal";
import TextField from "../TextField";
import Typography from "../Typography";
import { CallerStore } from "./store";

import "./styles.scss";
import { runInAction } from "mobx";

interface ICallModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const callerStore = new CallerStore();

const CallerReferrer: React.FC<ICallModalProps> = ({ onClose, isOpen }) => {
  React.useEffect(() => {
    return () => {
      callerStore.reset();
      onClose();
    };
  }, []);

  const handleRing = () => {
    callerStore.form.validate();
    if (!callerStore.form.isValid) return;

    if (callerStore.form.values.tel) {
      sipControllerStore.session?.referMethod(
        callerStore.form.values.tel,
        onClose
      );
    }
  };

  const makeSupport = () => {
    GlobalStatusStore.setState("isSupported", true);
    callerStore.form.validate();
    if (!callerStore.form.isValid) return;

    if (callerStore.form.values.tel)
      sipControllerStore.session?.referWithSupportMethod(
        callerStore.form.values.tel
      );
    GlobalStatusStore.setState("isOutGoing", true);
  };

  const makeSupportConnect = () => {
    sipControllerStore.session?.attendedReferCall();

    GlobalStatusStore.endCall();
    GlobalStatusStore.setState("isAnswered", false);
    GlobalStatusStore.setState("isOutGoing", false);
    onClose();
    sipControllerStore.resetCall();
  };

  // todo: тут нужно еще доработать
  const makeSupportConnectDecline = () => {
    const call1 = sipControllerStore.session;
    const call2 = sipControllerStore.supportSession;
    call1?.unhold();
    call2?.terminate();
    runInAction(() => {
      GlobalStatusStore.clientPhone = call1?.remoteUserTel || "";
    });
    GlobalStatusStore.setState("isCreateCall", true);
    GlobalStatusStore.setState("isSupported", false);
    GlobalStatusStore.setState("isAnswered", true);
    call2 && call2.terminate();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="caller-modal-referrer"
      id="caller-referrer"
    >
      <Typography variant="h2">Переадресация</Typography>

      <div className="callerReferrer">
        <TextField
          autoFocus
          {...callerStore.form.adapters.tel}
          isDisabled={GlobalStatusStore.states.isSupported}
        />
        <div className="buttons">
          <button
            onClick={() => {
              callerStore.setTel("1");
            }}
          >
            1
          </button>
          <button
            onClick={() => {
              callerStore.setTel("2");
            }}
          >
            2
          </button>
          <button
            onClick={() => {
              callerStore.setTel("3");
            }}
          >
            3
          </button>
          <button
            onClick={() => {
              callerStore.setTel("4");
            }}
          >
            4
          </button>
          <button
            onClick={() => {
              callerStore.setTel("5");
            }}
          >
            5
          </button>
          <button
            onClick={() => {
              callerStore.setTel("6");
            }}
          >
            6
          </button>
          <button
            onClick={() => {
              callerStore.setTel("7");
            }}
          >
            7
          </button>
          <button
            onClick={() => {
              callerStore.setTel("8");
            }}
          >
            8
          </button>
          <button
            onClick={() => {
              callerStore.setTel("9");
            }}
          >
            9
          </button>
          <button
            onClick={() => {
              callerStore.setTel("*");
            }}
          >
            *
          </button>
          <button
            onClick={() => {
              callerStore.setTel("0");
            }}
          >
            0
          </button>
          <button
            onClick={() => {
              callerStore.setTel("#");
            }}
          >
            #
          </button>
        </div>
        <FlexRow>
          {!GlobalStatusStore.states.isSupported && (
            <>
              <Button
                onClick={makeSupport}
                variant="secondary"
                size="medium"
                disabled={!callerStore.form.isValid}
              >
                <Typography>С сопровождением</Typography>
              </Button>
              <Button
                onClick={handleRing}
                variant={!callerStore.form.isValid ? "secondary" : "primary"}
                disabled={!callerStore.form.isValid}
              >
                <Typography
                  color={!callerStore.form.isValid ? "color1" : "colorWhite"}
                >
                  Позвонить
                </Typography>
              </Button>
            </>
          )}
          {GlobalStatusStore.states.isSupported && (
            <>
              <Button
                onClick={makeSupportConnect}
                variant="secondary"
                size="medium"
                disabled={!callerStore.form.isValid}
              >
                <Typography>Соединить</Typography>
              </Button>
              <Button onClick={makeSupportConnectDecline}>
                <Typography color="colorWhite">Завершить</Typography>
              </Button>
            </>
          )}
        </FlexRow>
      </div>
    </Modal>
  );
};

export default observer(CallerReferrer);
