import api from "../../api";

const loadAppeals = (
  params?: URLSearchParams,
  pageCount = 1,
  limit = 100,
  date?: string,
  startDate?: string,
  endDate?: string,
  topicId?: number,
  subtopicId?: number,
  callLineId?: number,
  operatorId?: number,
  status?: number,
  grade?: number
) => {
  return api.get(
    `/api/appeal?page=${pageCount}&limit=${limit}&startDate=${
      startDate || ""
    }&endDate=${endDate || ""}&topicId=${topicId || ""}&subtopicId=${
      subtopicId || ""
    }&callLineId=${callLineId || ""}&operatorId=${operatorId || ""}&status=${
      status || ""
    }&grade=${grade || ""}&date=${date}`,
    {
      params,
    }
  );
};

const loadMyAppeals = (
  params?: URLSearchParams,
  pageCount = 1,
  limit = 100,
  date?: string
) => {
  return api.get(
    `/api/appeal/my?page=${pageCount}&limit=${limit}&date=${date}`,
    // `/api/appeal/my?page=${pageCount}&limit=${limit}`,
    { params }
  );
};

const loadCreateForm = () => {
  return api.get("/api/appeal/forms/create-form");
};

const createAppeal = (formData: FormData) => {
  return api.post("/api/appeal", formData);
};

const editAppeal = (id: number, formData: FormData) => {
  return api.post(`/api/appeal/${id}`, formData);
};

const loadAppeal = (id: number) => {
  return api.get(`/api/appeal/${id}`);
};

const closeAppeal = (appealId: number) => {
  return api.post(`/api/appeal/${appealId}/close`);
};

const restartTimer = (appealId: number) => {
  return api.post(`/api/appeal/${appealId}/timer`);
};

const downloadReport = (
  params?: URLSearchParams,
  pageCount = 1,
  limit = 100,
  date?: string,
  startDate?: string,
  endDate?: string,
  topicId?: number,
  subtopicId?: number,
  callLineId?: number,
  operatorId?: number,
  status?: number,
  grade?: number
) => {
  return api.get(
    `/api/appeal-report/export-list?page=${pageCount}&limit=${limit}&startDate=${
      startDate || ""
    }&endDate=${endDate || ""}&topicId=${topicId || ""}&subtopicId=${
      subtopicId || ""
    }&callLineId=${callLineId || ""}&operatorId=${operatorId || ""}&status=${
      status || ""
    }&grade=${grade || ""}&date=${date}`,
    {
      params,
      responseType: "blob",
    }
  );
};

const takeToWorkStatus = (appealId: number) => {
  return api.post(`/api/appeal/${appealId}/status`, { status: 2 });
};

const toProcessedStatus = (appealId: number) => {
  return api
    .post(`/api/appeal/${appealId}/status`, { status: 3 })
    .then(() => closeAppeal(appealId));
};

const appealsService = {
  loadAppeals,
  loadCreateForm,
  createAppeal,
  editAppeal,
  loadAppeal,
  loadMyAppeals,
  closeAppeal,
  downloadReport,
  restartTimer,
  takeToWorkStatus,
  toProcessedStatus,
};

export default appealsService;
