import "./styles.scss";

import { observer } from "mobx-react";
import React from "react";

import { ITopic } from "../../store/index.types";
import Typography from "../../../../components/Typography";
import ButtonEditTheme from "../../../../components/ButtonEditTheme";
import cn from "classnames";

interface IProps {
  topic: ITopic;
  handleEdipOpenPopup: (
    caption: string,
    id: number | null,
    deactivated: boolean,
    type?: string,
    lines?: number[]
  ) => void;
  calls?: string;
  appeals?: string;
  handleNavigate: (themId: number, type?: string) => void;
}

const TopicItem: React.FC<IProps> = ({
  topic,
  handleEdipOpenPopup,
  handleNavigate,
  calls,
  appeals,
}) => {
  const handleTopicClick = () => {
    handleEdipOpenPopup(
      topic.caption,
      topic.id,
      Boolean(topic.deactivated),
      calls || appeals,
      topic.call_lines?.map((line) => line.id)
    );
  };

  return (
    <div className="topic-item">
      <button
        onClick={() => handleNavigate(topic.id || 1, calls || appeals)}
        className={cn("topic-item__name", topic.deactivated && "deactivated")}
      >
        <Typography
          variant="form-medium"
          color={(topic.deactivated && "colorWhite") || undefined}
        >
          {topic.caption}
        </Typography>
      </button>
      <ButtonEditTheme onClick={handleTopicClick} />
    </div>
  );
};

export default observer(TopicItem);
