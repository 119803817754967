import { observer } from "mobx-react-lite";
import authStore from "../../pages/AuthPage/store/authStore";
import { Sip } from "../SipPhone";
import SipPhoneRecall from "../SipPhoneRecall";
import GlobalStatusStore from "../../stores/GlobalStatusStore";
import sipControllerStore from "../../stores/GlobalStatusStore/SipController";
import { useEffect } from "react";

export const SipPhoneBlock = observer(() => {
  const sipCredentials = authStore.user.user_asterisk_credentials;

  useEffect(() => {
    if (sipCredentials) {
      sipControllerStore.init();
      return () => sipControllerStore.reset();
    }
  }, [sipCredentials]);

  useEffect(() => {
    if (!GlobalStatusStore.states.paused) {
      sipControllerStore.ua?.start();
    }

    if (GlobalStatusStore.states.paused) {
      sipControllerStore.ua?.stop();
    }
  }, [GlobalStatusStore.states.paused]);

  if (!sipCredentials) return null;

  const isCanCall =
    sipControllerStore.session !== null &&
    !GlobalStatusStore.states.paused &&
    !GlobalStatusStore.states.isAnswered &&
    !GlobalStatusStore.states.isCreateCall &&
    !GlobalStatusStore.states.isCreateAppeal &&
    !GlobalStatusStore.states.isOutGoing &&
    !GlobalStatusStore.states.isOutGoingRing &&
    GlobalStatusStore.states.isIncoming;

  return (
    <>
      <Sip isOpen={isCanCall} />
      {GlobalStatusStore.states.isOutGoingRing && <SipPhoneRecall />}
    </>
  );
});

SipPhoneBlock.displayName = "SipPhoneBlock";
