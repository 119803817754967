import { makeAutoObservable, runInAction } from "mobx";
import { ILine } from "./types";
import { CreateLineModalStore } from "../components/poups/CreateLineModal/store";
import linesService from "../../../services/lines";
import { EditLineModalStore } from "../components/poups/EditLineModal/store";
import { getFormattedDate } from "../../../utils/date/getFormattedDate";
import { SortStore } from "../../../stores/SortStore";
import FileSaver from "file-saver";

export const SORT_ITEMS = ["active", "created_at"] as const;

export class LinesStore {
  sortStore: SortStore<typeof SORT_ITEMS[number]>;

  isActiveLines = false;
  isLoading = false;
  lines: ILine[] = [];
  createLineModalStore = new CreateLineModalStore(this);
  editLineModalStore = new EditLineModalStore(this);

  constructor() {
    makeAutoObservable(this);
    this.sortStore = new SortStore([...SORT_ITEMS], this.loadLines);
  }

  reset = () => {
    this.lines = [];
    this.isActiveLines = false;
  };

  get activeLines() {
    return this.lines.filter((line) => line.active);
  }

  loadLines = () => {
    this.isLoading = true;
    linesService
      .loadLines(this.sortStore.searchParams)
      .then(({ data }) => {
        runInAction(() => {
          this.lines = data.data.items.map((line: any) => ({
            id: line.id,
            number: line.number,
            shortName: line.short_name,
            user_count: line.user_count,
            call_info_count: line.call_info_count,
            active: line.active,
            createdAt: line.created_at,
          }));
        });
      })
      .finally(() => runInAction(() => (this.isLoading = false)));
  };

  setIsActiveLines = (value: boolean) => {
    this.isActiveLines = value;
  };

  downloadLines = () => {
    linesService.downloadLines(this.sortStore.searchParams).then(({ data }) => {
      const fileName = "Линии.xlsx";
      FileSaver.saveAs(data, fileName);
    });
  };

  get rows() {
    return (this.isActiveLines ? this.activeLines : this.lines).map((line) => [
      {
        text: line.number ?? "-",
      },
      {
        text: line.shortName ?? "-",
      },
      {
        text: line.user_count ? `${line.user_count} операторов` : "-",
      },
      {
        text: line.call_info_count ?? "-",
      },
      {
        text: line.active ? "Активная" : "Неактивная",
      },
      {
        text: getFormattedDate(line.createdAt, "dd.MM.yyyy") ?? "-",
      },
    ]);
  }
}
