import "./styles.scss";
import React, { FC } from "react";
import LineCharts from "./components/LineCharts";
import { ChartsStore } from "./store";
import { observer } from "mobx-react-lite";
import CellChart from "./components/CellChart";

const store = new ChartsStore();

const ChartsBlock: FC = () => {
  return (
    <div className="charts-block">
      <div className="charts-block__statistic">
        <LineCharts store={store} />
      </div>
      <div className="charts-block__cell">
        <CellChart store={store} />
      </div>
    </div>
  );
};

export default observer(ChartsBlock);
