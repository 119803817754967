import { makeAutoObservable, runInAction } from "mobx";
import { FormStore } from "@kit/kit-aiss";
import { object, string } from "yup";
import linesService from "../../../../../../../services/lines";
import { ISelectOption } from "@kit/kit-aiss/dist/components/Select/index.types";
import { DEFAULT_QUEUES } from "./constants";
import asteriskService from "../../../../../../../services/asterisk";

const defaultValues = {
  lineNumber: null,
  shortTitle: null,
  status: null,
};

const schema = object({
  lineNumber: string().required("Выберите линию").typeError("Выберите линию"),
  shortTitle: string()
    .typeError("Введите краткое название")
    .required("Введите краткое название"),
  status: string().typeError("Выберите статус").required("Выберите статус"),
});

export class LinesModalFormStore {
  form = new FormStore(defaultValues, schema);
  options: ISelectOption[] = [
    {
      text: "Активная",
      value: "Активная",
    },
    {
      text: "Неактивная",
      value: "Неактивная",
    },
  ];

  queues: typeof DEFAULT_QUEUES = [];

  get queueOptions() {
    return (this.queues.length ? this.queues : DEFAULT_QUEUES).map((item) => ({
      text: item.caption,
      value: item.id,
    }));
  }

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.form.reset();
  };

  changeLine = (value: string) => {
    this.form.changeValue(value, "lineNumber");
  };

  loadForm = (lineId: number) => {
    linesService.loadLine(lineId).then(({ data }) => {
      this.form.adapters.lineNumber.onChange(
        String(data.data.number),
        "lineNumber"
      );
      this.form.adapters.shortTitle.onChange(
        data.data.short_name,
        "shortTitle"
      );
      this.form.adapters.status.onChange(
        data.data.active ? this.options[0].value : this.options[1].value,
        "status"
      );
    });
  };

  loadQueues = async () => {
    try {
      const { data } = await asteriskService.loadQueues();
      runInAction(() => {
        this.queues = data?.data?.queues || [];
      });
    } catch (error) {
      runInAction(() => {
        this.queues = [];
      });
    }
  };
}
