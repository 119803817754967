import "./styles.scss";
import React, { FC } from "react";
import { FlexRow } from "@kit/kit-aiss";
import LinesModalForm from "../components/LinesModalForm";
import { EditLineModalStore } from "./store";
import { observer } from "mobx-react-lite";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";

interface IEditLineModalProps {
  onClose: () => void;
  isOpen: boolean;
  store: EditLineModalStore;
}

const EditLineModal: FC<IEditLineModalProps> = ({ store, onClose, isOpen }) => {
  const editLine = () => {
    return store.onSave()?.then(() => onClose());
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Редактировать линию">
      <div className="edit-line-modal">
        <LinesModalForm lineId={store.id} isEdit store={store.form} />
        <FlexRow>
          <Button type="submit" onClick={editLine}>
            Сохранить
          </Button>
        </FlexRow>
      </div>
    </Modal>
  );
};

export default observer(EditLineModal);
