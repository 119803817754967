import { makeAutoObservable, runInAction } from "mobx";
import { object, string } from "yup";
import { FormStore } from "../../../stores/FormStore";
import { ITopic, TAddThemeForm } from "./index.types";
import themasService from "../../../services/themas";
import { VALIDATOR_ANY } from "../../../validation/validators";
import { LinesAccordionStore } from "../../../stores/LinesAccordionStore";

const schema = object({
  caption: string()
    .typeError("Введите название темы")
    .required("Введите название темы"),
  category: VALIDATOR_ANY,
});

export class ThemesStore {
  topics: { calls: ITopic[]; appeals: ITopic[] } = { calls: [], appeals: [] };
  isLoading = false;

  addThemeForm = new FormStore<TAddThemeForm>(
    {
      caption: null,
      category: null,
    },
    schema
  );

  linesStore = new LinesAccordionStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getThemes("calls");
    this.getThemes("appeals");
    this.linesStore.init();
  };

  getThemes = (category: "appeals" | "calls") => {
    this.isLoading = true;
    themasService
      .loadThemas(category, true)
      .then(({ data }) => {
        runInAction(() => {
          this.topics[category] = data.data.map((them: any) => ({
            id: them.id,
            caption: them.title,
            parentId: them.parent_id,
            created: them.created_at,
            updated: them.updated_at,
            deactivated: them.deactivated_at,
            call_lines: them.call_lines,
          }));
        });
      })
      .finally(() => runInAction(() => (this.isLoading = false)));
  };

  checkCreate = () => {
    this.addThemeForm.validate();
    if (this.addThemeForm.isValid) return this.createTheme();
  };

  createTheme = () => {
    const form = new FormData();
    form.append("title", this.addThemeForm.values.caption || "");
    form.append("category", this.addThemeForm.values.category || "calls");

    if (this.addThemeForm.values.category === null) {
      this.linesStore.form.values.lines?.forEach((line) => {
        form.append("callLinesIds[]", String(line));
      });
    }

    return themasService.createTheme(form).then(() => {
      this.init();
    });
  };

  getEditedTheme = (caption: string, tableType: string) => {
    this.addThemeForm.changeValue(caption, "caption");
    this.addThemeForm.changeValue(
      tableType === "appeals" ? tableType : null,
      "category"
    );
  };

  checkEdit = (id: string | null) => {
    this.addThemeForm.validate();
    if (this.addThemeForm.isValid) return this.editTheme(id);
  };

  editTheme = (id: string | null) => {
    const form = new FormData();
    form.append("title", this.addThemeForm.values.caption || "");
    form.append("category", this.addThemeForm.values.category || "calls");

    if (this.addThemeForm.values.category === null) {
      this.linesStore.form.values.lines?.forEach((line) => {
        form.append("callLinesIds[]", String(line));
      });
    }

    return themasService.editTheme(form, id || "").then(() => {
      this.init();
    });
  };

  disableTheme = (id: string | null, onClose: () => void) => {
    const formData = new FormData();
    formData.append("ids[]", id as string);
    themasService.deactivateTheme(formData).then(() => {
      this.init();
      onClose();
    });
  };

  enableTheme = (id: string | null, onClose: () => void) => {
    const formData = new FormData();
    formData.append("ids[]", id as string);
    themasService.activateTheme(formData).then(() => {
      this.init();
      onClose();
    });
  };

  reset = () => {
    this.addThemeForm.reset();
    this.topics.calls = [];
    this.topics.appeals = [];
    this.linesStore.reset();
  };
}

export default new ThemesStore();
