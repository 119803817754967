import "./styles.scss";
import React, { FC, useEffect } from "react";
import { IAttributeBlockProps } from "./types";
import { observer } from "mobx-react-lite";
import Typography from "../../../../components/Typography";
import { Button, CheckBox, FlexRow } from "@kit/kit-aiss";
import AttributeSelectedField from "./components/AttributeSelectedField";
import SearchField from "../../../../components/SearchField";

const AttributeBlock: FC<IAttributeBlockProps> = ({
  store,
  theme,
  themId,
  subThemId,
}) => {
  useEffect(() => {
    // if (theme === "theme") {
    //   store.loadTopic(themId || "", "theme");
    // } else if (theme === "subTheme") {
    //   store.loadTopic(themId || "", "subTheme", subThemId || "");
    // }
    return () => store.reset();
  }, []);

  return (
    <div className="attribute-block">
      <div className="attribute-block__items">
        {store.selectedAttributes.map((selectedAttribute) => (
          <AttributeSelectedField
            swapItems={store.swapItems}
            key={selectedAttribute.id}
            attribute={selectedAttribute}
            deleteItem={store.deleteItem}
          />
        ))}
      </div>
      <div className="attribute-block__sub">
        <div className="attribute-block__sub-heading">
          <Typography
            color="color1"
            variant="form-medium"
          >{`Выбрать атрибут для поля ${
            theme === "theme" ? "темы" : "подтемы"
          }`}</Typography>
          <Typography color="color1" variant="sub-text-medium">
            Из списка стандартных и созданных атрибутов
          </Typography>
        </div>
        <SearchField
          name="search"
          onChange={store.form.changeValue}
          value={store.form.values.search}
        />
        <div className="attribute-block__sub-checkboxes">
          {store.filteredAttributes
            .filter((item) => item.attribute.deactivatedAt === null)
            .map((attribute, idx) => (
              <CheckBox
                name="attribute"
                onChange={(value, key) =>
                  store.changeValue(attribute.attribute, value, key)
                }
                value={attribute.form.values.attribute}
                key={idx}
              >
                {attribute.attribute.title}
              </CheckBox>
            ))}
        </div>
      </div>
      <FlexRow className="attribute-block__button">
        <Button
          onClick={
            theme === "theme"
              ? () => store.editTopic(themId || "")
              : () => store.editSubtopic(themId || "", subThemId || "")
          }
        >
          Сохранить
        </Button>
      </FlexRow>
    </div>
  );
};

export default observer(AttributeBlock);
