import { makeAutoObservable } from "mobx";
import { FormStore } from "../../../stores/FormStore";
import GlobalStatusStore from "../../../stores/GlobalStatusStore";
import { VALIDATOR_CALL_PHONE } from "../../../validation/validators";

export class CallerStore {
  form = new FormStore({ tel: null }, VALIDATOR_CALL_PHONE);

  constructor() {
    makeAutoObservable(this);
  }

  setTel = (e: string) => {
    if (!GlobalStatusStore.states.isSupported) {
      if (this.form.values.tel === null) {
        this.form.changeValue("", "tel");
      }
      this.form.changeValue(this.form.values.tel + e, "tel");
    }
  };

  reset = () => {
    this.form.reset();

    GlobalStatusStore.setState("isSupported", false);
  };
}
