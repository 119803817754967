import { ReactComponent as HeadPhonesWhite } from "../../../assets/svg/headPhonesWhite.svg";
import { ReactComponent as HeadPhones } from "../../../assets/svg/headPhones.svg";
import { ReactComponent as Lightning } from "../../../assets/svg/lightning.svg";
import { ReactComponent as LightningGray } from "../../../assets/svg/lightningGray.svg";
import { ReactComponent as Folder } from "../../../assets/svg/folder.svg";
import { ReactComponent as FolderWhite } from "../../../assets/svg/folderWhite.svg";
import { ReactComponent as Agree } from "../../../assets/svg/agree.svg";
import { ReactComponent as AgreeGray } from "../../../assets/svg/agreeGray.svg";
import { ReactComponent as DictWhite } from "../../../assets/svg/dict_white.svg";
import { ReactComponent as DictGray } from "../../../assets/svg/dict_gray.svg";

export const Icon = (link: string, pathEqual: boolean) => {
  switch (link) {
    case "operators":
      return pathEqual ? <HeadPhonesWhite /> : <HeadPhones />;
    case "lines":
      return pathEqual ? (
        <div style={{ marginRight: "10px" }}>
          <Lightning />
        </div>
      ) : (
        <div style={{ marginRight: "10px" }}>
          <LightningGray />
        </div>
      );
    case "attributes":
      return pathEqual ? (
        <div style={{ marginRight: "11px" }}>
          <Agree />
        </div>
      ) : (
        <div style={{ marginRight: "11px" }}>
          <AgreeGray />
        </div>
      );
    case "topics":
      return pathEqual ? (
        <div style={{ marginRight: "4px" }}>
          <FolderWhite />
        </div>
      ) : (
        <div style={{ marginRight: "4px" }}>
          <Folder />
        </div>
      );
    case "users":
      return pathEqual ? (
        <div style={{ marginRight: "4px" }}>
          <DictWhite />
        </div>
      ) : (
        <div style={{ marginRight: "4px" }}>
          <DictGray />
        </div>
      );

    default:
      return;
  }
};
