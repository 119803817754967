import "./styles.scss";

import { observer } from "mobx-react-lite";
import React from "react";

import GlobalStatusStore from "../../stores/GlobalStatusStore";
import Button from "../Button";
import { ReactComponent as IconPause } from "../../assets/svg/pause.svg";
import { ReactComponent as IconLightning } from "../../assets/svg/lightning.svg";
import sipControllerStore from "../../stores/GlobalStatusStore/SipController";

const ButtonPauseCalls = () => {
  const handlePause = () => {
    sipControllerStore.ua?.stop();
    GlobalStatusStore.changeStatus();
    GlobalStatusStore.setState("paused", true);
  };

  const handleStart = () => {
    sipControllerStore.ua?.start();
    GlobalStatusStore.changeStatus();
    GlobalStatusStore.setState("paused", false);
  };

  return (
    <Button
      className="button-pause-calls"
      onClick={GlobalStatusStore.states.paused ? handleStart : handlePause}
    >
      {GlobalStatusStore.states.paused ? (
        <>
          <IconLightning />
          Вернуться на линии
        </>
      ) : (
        <>
          <IconPause />
          Поставить паузу
        </>
      )}
    </Button>
  );
};

export default observer(ButtonPauseCalls);
