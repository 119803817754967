import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { FlexColumn, FlexRow } from "@kit/kit-aiss";
import { OperatorEditProps } from "./types";
import Form from "../Form";
import Typography from "../../../../../components/Typography";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";

const OperatorEdit: FC<OperatorEditProps> = ({
  isOpen,
  onClose,
  id,
  operatorEditStore,
}) => {
  const handleSave = () => {
    operatorEditStore.saveForm(id)?.then(() => {
      onClose();
      operatorEditStore.operatorFormStore.reset();
      operatorEditStore.loadOperators();
    });
  };

  return (
    <Modal title="Редактировать оператора" isOpen={isOpen} onClose={onClose}>
      <FlexColumn gap={20}>
        <Form id={id} isEdit store={operatorEditStore.operatorFormStore} />
        <FlexRow>
          <Button onClick={handleSave}>
            <Typography variant="form-medium" color="colorWhite">
              Сохранить
            </Typography>
          </Button>
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
};

export default observer(OperatorEdit);
