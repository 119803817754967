import { AxiosResponse } from "axios";

const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

/** Извлекает имя файла из заголовка content-disposition ответа */
export const getFileNameFromDisposition = (
  disposition: string | AxiosResponse
) => {
  const _disposition =
    typeof disposition === "string"
      ? disposition
      : disposition.headers["content-disposition"];

  if (!_disposition) return null;

  let fileName = "";

  if (utf8FilenameRegex.test(_disposition)) {
    const utf8Name = utf8FilenameRegex.exec(_disposition)?.at(1);
    fileName = utf8Name ? decodeURIComponent(utf8Name) : "";
  } else {
    // prevent ReDos attacks by anchoring the ascii regex to string start and
    //  slicing off everything before 'filename='
    const filenameStart = _disposition.toLowerCase().indexOf("filename=");
    if (filenameStart >= 0) {
      const partialDisposition = _disposition.slice(filenameStart);
      const matches = asciiFilenameRegex.exec(partialDisposition);
      if (matches != null && matches[2]) {
        fileName = matches[2];
      }
    }
  }
  return fileName;
};
