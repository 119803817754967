import "./styles.scss";

import { Select as KitSelect } from "@kit/kit-aiss";
import { ISelectProps } from "@kit/kit-aiss/dist/components/Select/index.types";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

export type TCrmSelectProps = ISelectProps & {
  isSmall?: boolean;
  inputBg?: "color6";
};

const Select: React.FC<TCrmSelectProps> = (props) => {
  return (
    <KitSelect
      {...props}
      className={cn(
        "crm-select",
        props.isSmall && "crm-select--small",
        props.inputBg && `crm-select--${props.inputBg}`,
        props.className
      )}
    />
  );
};

export default observer(Select);
