import { FlexColumn } from "@kit/kit-aiss";
import { observer } from "mobx-react-lite";
import React from "react";
import Card from "../../../components/Card";
import CrmTable from "../../../components/CrmTable";
import OperatorWorkTimeReportStore from "./store";
import "./style.scss";
export interface IOperatorWorkTimeReportProps {
  store: OperatorWorkTimeReportStore;
  onDownload: () => void;
}

const OperatorWorkTimeReport: React.FC<IOperatorWorkTimeReportProps> = ({
  store,
}) => {
  return (
    <Card>
      <FlexColumn gap={20}>
        {/* <ReportHeader
          title="Отчет о рабочем времени операторов"
          onDownload={onDownload}
          onPrint={() => null}
        /> */}
        <CrmTable
          className="report-wokrtime"
          isEvenBg
          isLoading={store.isLoading}
          headers={[
            {
              title: "Дата",
              ...store.sortStore.sortFields.get("date"),
            },
            {
              title: "ФИО оператора",
              width: 150,
              ...store.sortStore.sortFields.get("fio"),
            },
            {
              title: "Время открытия смены",
              ...store.sortStore.sortFields.get("timeOpen"),
            },
            {
              title: "Время закрытия смены",
              ...store.sortStore.sortFields.get("timeClose"),
            },
            {
              title: "Время в перерыве",
              ...store.sortStore.sortFields.get("timeBreak"),
            },
            {
              title: "Время в работе (на линии)",
              ...store.sortStore.sortFields.get("timeOnLine"),
            },
            {
              title: "Количество принятых звонков",
              ...store.sortStore.sortFields.get("acceptedCalls"),
            },
            {
              title: "Количество пропущенных",
              ...store.sortStore.sortFields.get("missedCalls"),
            },
            {
              title: "Среднее время разговора",
              ...store.sortStore.sortFields.get("averageCallTime"),
            },
          ]}
          rows={store.pows}
        />
      </FlexColumn>
    </Card>
  );
};

export default observer(OperatorWorkTimeReport);
