import { makeAutoObservable } from "mobx";

import { ReportDownloadStore } from "../../../stores/ReportDownloadStore";
import OperatorWorkTimeReportStore from "../OperatorWorkTimeReport/store";

export class ReportPageStore {
  downloadStore = new ReportDownloadStore();
  operatorWorkTimeStore = new OperatorWorkTimeReportStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.operatorWorkTimeStore.init();
  };

  reset = () => {
    this.operatorWorkTimeStore.reset();
  };
}
