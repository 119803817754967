import "./styles.scss";

import React from "react";

const useDate = () => {
  const [today, setDate] = React.useState(new Date());

  const monthNames = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  React.useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="daytime-block">
      <div className="daytime-block__time">
        <span className="daytime-block__time-hours">{today.getHours()}</span>:
        <span className="daytime-block__time-minutes">
          {today.getMinutes() < 10
            ? `0${today.getMinutes()}`
            : today.getMinutes()}
        </span>
      </div>
      <div className="daytime-block__date">
        <span className="daytime-block__date-number">{today.getDate()}</span>
        <span className="daytime-block__date-month">
          {monthNames[today.getMonth()]}
        </span>
      </div>
    </div>
  );
};

export default useDate;
