import "./styles.scss";
import React from "react";
import { Modal as KitModal } from "@kit/kit-aiss";
import { FC } from "react";
import { IModalProps } from "@kit/kit-aiss/src/components/Modal/index.types";
import cn from "classnames";
import GlobalStatusStore from "../../stores/GlobalStatusStore";
import { observer } from "mobx-react";

const Modal: FC<IModalProps> = (props) => {
  return (
    <KitModal
      className={cn(
        "crm-modal",
        GlobalStatusStore.states.isAnswered && "called"
      )}
      {...props}
    />
  );
};

export default observer(Modal);
