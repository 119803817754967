import "./styles.scss";

import React, { FC, useEffect } from "react";
import { Button, FlexRow, TextField } from "@kit/kit-aiss";
import { EditSubThemeModalStore } from "./store";
import { observer } from "mobx-react";
import Modal from "../../../../components/Modal";
import { useOnConfirm } from "../../../../hooks/useOnConfirm";

interface IProps {
  item: string;
  isOpen: boolean;
  onClose: () => void;
  id: number;
  themId: string;
  loadSubThemas: (themId: string, tableType?: string) => Promise<void>;
  deactivated: boolean;
  tableType?: string;
}

const store = new EditSubThemeModalStore();

const EditSubThemeModal: FC<IProps> = ({
  isOpen,
  onClose,
  item,
  id,
  themId,
  loadSubThemas,
  deactivated,
  tableType,
}) => {
  useEffect(() => {
    store.init(item);
    return () => store.reset();
  }, []);

  const handleSave = () => {
    store.onSave(themId, String(id))?.then(() => {
      onClose();
      loadSubThemas(themId, tableType);
    });
  };

  const handleRemoveTheme = () => {
    store.disableSubTheme(id).then(() => {
      onClose();
      loadSubThemas(themId, tableType);
    });
  };

  const handleActivateTheme = () => {
    store.enableSubTheme(id).then(() => {
      onClose();
      loadSubThemas(themId, tableType);
    });
  };

  const { ModalConfirm, isShowConfirm } = useOnConfirm(
    "Вы уверены, что хотите деактивировать тему?",
    "Да, деактивировать тему",
    handleRemoveTheme
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Редактировать подтему">
      <TextField
        className="edit-sub-theme-modal__field"
        placeholder="Название подтемы"
        name="topic"
        onChange={store.form.changeValue}
        value={store.form.values.topic}
        errorMessage={store.form.errors.topic}
      />
      <FlexRow className="edit-sub-theme-modal__buttons">
        {!deactivated && (
          <Button className="deactivate" onClick={isShowConfirm}>
            Деактивировать подтему
          </Button>
        )}
        {deactivated && (
          <Button className="deactivate" onClick={handleActivateTheme}>
            Активировать подтему
          </Button>
        )}
        <Button type="submit" onClick={handleSave}>
          Сохранить
        </Button>
        {ModalConfirm}
      </FlexRow>
    </Modal>
  );
};

export default observer(EditSubThemeModal);
