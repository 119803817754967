import "./styles.scss";

import { Button } from "@kit/kit-aiss";
import React from "react";
import cn from "classnames";

export interface IIconButtonProps {
  onClick: () => void;
  icon: React.ReactNode;
  className?: string;
}
const IconButton: React.FC<IIconButtonProps> = ({
  onClick,
  icon,
  className,
}) => {
  return (
    <Button
      onClick={onClick}
      variant="inline"
      className={cn("icon-button", className)}
    >
      {icon}
    </Button>
  );
};

export default IconButton;
