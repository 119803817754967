import "./styles.scss";

import { FlexColumn, LoaderSpinner, WarningCard } from "@kit/kit-aiss";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import OperatorCreateStore from "./store";
import Typography from "../../../../../components/Typography";
import Form from "../Form";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";

export interface IOperatorCreateProps {
  isOpen: boolean;
  onClose: () => void;
  store: OperatorCreateStore;
}

const OperatorCreate: React.FC<IOperatorCreateProps> = ({
  isOpen,
  onClose,
  store,
}) => {
  useEffect(() => {
    store.init();

    return store.reset;
  }, []);

  return (
    <Modal title="Добавить нового оператора" isOpen={isOpen} onClose={onClose}>
      {store.isLoading ? (
        <LoaderSpinner />
      ) : (
        <FlexColumn gap={20} className="operator-create">
          {store.errorText && (
            <WarningCard type="red" onCloseClick={store.resetError}>
              {store.errorText}
            </WarningCard>
          )}
          <Form store={store.operatorFormStore} />
          <Button
            onClick={store.sendForm}
            size="medium"
            className="operator-create__save-button"
          >
            <Typography variant="form-medium" color="colorWhite">
              Сохранить
            </Typography>
          </Button>
        </FlexColumn>
      )}
    </Modal>
  );
};

export default observer(OperatorCreate);
