import api from "../../api";

const loadQueues = (params?: URLSearchParams) => {
  return api.get(`/api/asterisk/queues`, { params });
};

const asteriskService = {
  loadQueues,
};

export default asteriskService;
