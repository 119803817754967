import api from "../../api";
import sipControllerStore from "../../stores/GlobalStatusStore/SipController";

const loadCalls = (
  params?: URLSearchParams,
  pageCount = 1,
  date?: string,
  limit = 100,
  startDate?: string,
  endDate?: string,
  topicId?: number,
  subtopicId?: number,
  callLineId?: number,
  operatorId?: number,
  status?: number,
  reaction?: number
) => {
  return api.get(
    `/api/call-info?page=${pageCount}&limit=${limit}&date=${date}&startDate=${
      startDate || ""
    }&endDate=${endDate || ""}&topicId=${topicId || ""}&subtopicId=${
      subtopicId || ""
    }&callLineId=${callLineId || ""}&operatorId=${operatorId || ""}&status=${
      status || ""
    }&reaction=${reaction || ""}`,
    {
      params,
    }
  );
};

const loadCall = (id: string) => {
  return api.get(`/api/call-info/${id}`);
};

const loadCreateForm = (id?: number, type?: string) => {
  if (sipControllerStore.externalQueueId) {
    return api.get(
      `/api/call-info/forms/create-form?externalQueueId=${sipControllerStore.externalQueueId}`
    );
  } else if (type === "callId") {
    return api.get(`/api/call-info/forms/create-form?callId=${id}`);
  } else if (type === "callInfoId") {
    return api.get(`/api/call-info/forms/create-form?callInfoId=${id}`);
  } else if (type === "callLineId") {
    return api.get(`/api/call-info/forms/create-form?callLineId=${id}`);
  } else {
    return api.get(`/api/call-info/forms/create-form`);
  }
};

const createCall = (formData: FormData) => {
  return api.post(`/api/call-info`, formData);
};

const editCall = (id: number, formData: FormData) => {
  return id
    ? api.post(`/api/call-info/${id}`, formData)
    : api.post(`/api/call-info`, formData);
};

const startCall = (formData: FormData) => {
  return api.post(`/api/call`, formData);
};

const responseCall = (id: string, formData: FormData) => {
  return api.post(`/api/call/${id}`, formData);
};

const getNoCallForm = (id: string) => {
  return api.get(`/api/call/${id}`);
};

const loadStatuses = () => {
  return api.get(`/api/call/statuses`);
};

const downloadReport = (
  params?: URLSearchParams,
  pageCount = 1,
  date?: string,
  limit = 100,
  startDate?: string,
  endDate?: string,
  topicId?: number,
  subtopicId?: number,
  callLineId?: number,
  operatorId?: number,
  status?: number,
  reaction?: number
) => {
  return api.get(
    `/api/call-info-report/export-list?page=${pageCount}&limit=${limit}&date=${date}&startDate=${
      startDate || ""
    }&endDate=${endDate || ""}&topicId=${topicId || ""}&subtopicId=${
      subtopicId || ""
    }&callLineId=${callLineId || ""}&operatorId=${operatorId || ""}&status=${
      status || ""
    }&reaction=${reaction || ""}`,
    {
      params,
      responseType: "blob",
    }
  );
};

const getLineChartsData = (lineId: number | null) => {
  return api.get(
    `/api/charts/call-info/line${lineId ? `?callLineId=${lineId}` : ""}`
  );
};

const getCellChartsData = (cellType: string | null) => {
  return api.get(
    `/api/charts/call-info/line${cellType ? `?period=${cellType}` : ""}`
  );
};

const getLinks = (id: number) => {
  return api.get(`/api/call-info/${id}/links`);
};

const callsService = {
  loadCall,
  loadCalls,
  loadCreateForm,
  createCall,
  editCall,
  startCall,
  loadStatuses,
  responseCall,
  downloadReport,
  getLineChartsData,
  getCellChartsData,
  getNoCallForm,
  getLinks,
};

export default callsService;
