import "./styles.scss";
import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { IAttributeItem } from "./types";
import Typography from "../../../../components/Typography";
import ButtonEditTheme from "../../../../components/ButtonEditTheme";

const AttributeItem: FC<IAttributeItem> = ({ attribute, openEdit }) => {
  return (
    <div className={`attribute-item`}>
      <div
        className={`attribute-item__block ${
          attribute.deactivatedAt && "deactivated-attribute-item"
        }`}
      >
        <Typography
          variant="form-medium"
          color={attribute.deactivatedAt ? "colorWhite" : "color1"}
        >
          {attribute.title}
        </Typography>
      </div>
      <ButtonEditTheme onClick={() => openEdit(attribute)} />
    </div>
  );
};

export default observer(AttributeItem);
