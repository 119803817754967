import { PrintBlock, PrintLayout, Table } from "@kit/kit-aiss";
import { observer } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getFormattedDate } from "../../../utils/date/getFormattedDate";
import { ReportPageStore } from "../../ReportWorkTimePage/store";
// import "./styles.scss";

const store = new ReportPageStore();

const ReportPrintWorkTimePage: React.FC = () => {
  const date1 =
    localStorage.getItem("startWorkDate") !== null
      ? new Date(localStorage.getItem("startWorkDate") as string)
      : new Date();
  const date2 =
    localStorage.getItem("endWorkDate") !== null
      ? new Date(localStorage.getItem("endWorkDate") as string)
      : new Date();

  React.useEffect(() => {
    store.operatorWorkTimeStore.form.changeValue(date1, "startDate");
    store.operatorWorkTimeStore.form.changeValue(date2, "endDate");
    store.operatorWorkTimeStore.loadReport();

    return () => {
      store.reset();
    };
  }, []);

  const navigate = useNavigate();

  const handleCancelPrintClick = () => {
    navigate(`/report-operator-work-time`);
    localStorage.setItem(
      "startWorkDate",
      String(store.operatorWorkTimeStore.form.values.startDate)
    );
    localStorage.setItem(
      "endWorkDate",
      String(store.operatorWorkTimeStore.form.values.endDate)
    );
  };

  return (
    <PrintLayout className="print-page">
      <PrintBlock onClickBackLink={handleCancelPrintClick}>
        <Table
          headers={[
            "Дата",
            "ФИО",
            "Время открытия смены",
            "Время закрытия смены",
            "Время в перерыве",
            "Время в работе (на линии)",
            "Количество принятых звокнов",
            "Количество пропущ. звонков",
            "Среднее время разговора",
          ]}
          content={store.operatorWorkTimeStore.table!.map((line) => [
            [getFormattedDate(line.created_at as string, "dd.MM.yyyy HH:mm")],
            [`${line.user.last_name} ${line.user.first_name[0]}.`],
            [getFormattedDate(line.login_time as string, "dd.MM.yyyy HH:mm")],
            [getFormattedDate(line.logout_time as string, "dd.MM.yyyy HH:mm")],
            [line.break_time_seconds],
            [line.time_online_seconds],
            [line.calls_accepted_count],
            [line.calls_declined_count],
            [line.avg_call_duration],
          ])}
        />
      </PrintBlock>
    </PrintLayout>
  );
};

export default observer(ReportPrintWorkTimePage);
