import {
  IncomingAckEvent,
  IncomingEvent,
  OutgoingAckEvent,
  OutgoingEvent,
  RTCSession,
} from "jssip/lib/RTCSession";
import { makeAutoObservable, runInAction } from "mobx";

import GlobalStatusStore from ".";
import sipControllerStore from "./SipController";
import { startCallBeeps, stopCallBeeps } from "../../utils/beeps";

export class SipSupportSessionController {
  id: string | null = null;
  contact: string | null = null;
  accepted = false;

  _session;

  constructor(session: RTCSession) {
    makeAutoObservable(this);
    this._session = session;
    this.id = this._session.id;
    this.contact = this._session.remote_identity.display_name;

    const remoteAudio = new window.Audio();
    remoteAudio.autoplay = true;

    // addInfoLogsToSessionEvents(this._session);

    this._session.on("progress", (e: any) => {
      console.log("progress", e, e.remote);

      if (e?.response?.status_code === 180) {
        startCallBeeps();
      } else {
        stopCallBeeps();
      }
    });

    this._session.on("peerconnection", (e) => {
      // addInfoLogsToPeerConnectionEvent(e);
      e.peerconnection.addEventListener("track", (e) => {
        console.log("track support", e);
        console.log("track support streamsLength", e.streams.length);

        stopCallBeeps();
        remoteAudio.srcObject = e.streams[0];
      });
    });

    this._session.on("accepted", (e: IncomingEvent | OutgoingEvent) => {
      console.log("accepted support", e);
      stopCallBeeps();

      runInAction(() => {
        this.accepted = true;
      });
      GlobalStatusStore.setState("isOutGoingRing", false);
    });

    this._session.on("confirmed", (e: IncomingAckEvent | OutgoingAckEvent) => {
      console.log("confirmed support", e);
      GlobalStatusStore.setState("isAnswered", true);
    });

    this._session.on("ended", (e) => {
      console.log("ended support session", e.originator, e.cause);
      stopCallBeeps();

      if (sipControllerStore.session && !sipControllerStore.session.isEnded()) {
        runInAction(() => {
          GlobalStatusStore.clientPhone =
            sipControllerStore.session?.remoteUserTel || "";
          GlobalStatusStore.setState("isAnswered", true);
        });
      }
      GlobalStatusStore.setState("isSupported", false);

      sipControllerStore.resetSupportSession();
    });

    this._session.on("failed", (e) => {
      console.log(
        "failed support session",
        e,
        e.originator,
        e.cause,
        this._session
      );
      stopCallBeeps();

      if (sipControllerStore.session && !sipControllerStore.session.isEnded()) {
        runInAction(() => {
          GlobalStatusStore.clientPhone =
            sipControllerStore.session?.remoteUserTel || "";
          GlobalStatusStore.setState("isAnswered", true);
        });
      }
      GlobalStatusStore.setState("isSupported", false);

      sipControllerStore.resetSupportSession();
    });

    this._session.on("reinvite", (e) => {
      console.log("reinvite support", e);
    });

    // звук для исходящего звонка при переадресации
    if (this._session.connection) {
      this._session.connection.addEventListener("addstream", (e: any) => {
        stopCallBeeps();

        if (!remoteAudio.srcObject) {
          remoteAudio.srcObject = e.stream;
        }
      });
    }
  }

  terminate = () => {
    console.log("session.terminate support", this._session.status);

    if (this._session.status !== 8) {
      this._session.terminate();
    }
  };

  isInProgress = () => this._session.isInProgress();
}
