import "./styles.scss";

import React from "react";
import IconButton from "../IconButton";
import { ReactComponent as PenIcon } from "../../assets/svg/pensil.svg";

const ButtonEditTheme: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      icon={<PenIcon />}
      className="button-edit-theme"
    />
  );
};

export default ButtonEditTheme;
