import { makeAutoObservable } from "mobx";

import { ReportDownloadStore } from "../../../stores/ReportDownloadStore";
import CallsReportStore from "../CallsReport/store";

export class ReportPageStore {
  downloadStore = new ReportDownloadStore();
  callsStore = new CallsReportStore();

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.callsStore.init();
  };

  reset = () => {
    this.callsStore.reset();
  };
}
