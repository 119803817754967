export const URL_BASE = "";
export const URL_AUTH = URL_BASE + "api/auth/login";
export const URL_ME = URL_BASE + "api/user/me";
export const URL_SHIFTS = URL_BASE + "shifts";
export const URL_CALLS = URL_BASE + "/calls";
export const URL_APPEALS = URL_BASE + "/appeals";
export const URL_OPERATOR_APPEALS = URL_BASE + "/operator-appeals";
export const URL_REPORTS = URL_BASE + "/reports";
export const URL_REPORT_CALLS = URL_BASE + "/report-calls";
export const URL_REPORT_OPERATOR_WORK_TIME =
  URL_BASE + "/report-operator-work-time";
export const URL_TREATMENTS = URL_BASE + "/treatments";

export const URL_THEMES = URL_BASE + "topics/";
export const URL_SUB_THEMES = URL_BASE + "/topics/subtopics/:themId/:type";
export const URL_SUB_TOPIC =
  URL_BASE + "/topics/topic/:themId/subtopic/:subtopicId/:type";
export const URL_SUB_THEMES_EDIT = URL_BASE + "/topics/subtopics/:themId/edit";

export const URL_CARD_LIST = URL_BASE + "api/call-info";
export const URL_CREATE_FORM = URL_BASE + `api/call-info/forms/create-form`;
export const URL_SIDEBAR_LINKS = URL_BASE + "api/elements/sidebar";
export const URL_GET_EDIT_FORM = URL_BASE + "api/call-info/forms/update-form/";
export const URL_REPORTS_DOWNLOAD_DATA =
  URL_BASE + `api/call-info-report/export`;
export const URL_OPERATORS = URL_BASE + "/operators";

//пока не юзаются
export const URL_REFRESH_TOKEN_AUTH_MODULE = "";
export const URL_REFRESH_TOKEN = "";
export const URL_HEADER_THEMES = "/themes";
