import { makeAutoObservable, runInAction } from "mobx";
import { IReport } from "./types";
import { FormStore } from "@kit/kit-aiss";
import reportService from "../../../services/reports";
import { reportSchema } from "./validationSchema";
import FileSaver from "file-saver";
import { getFileNameFromDisposition } from "../../../utils/getFileNameFromDisposition";

export class ReportExportStore {
  reports: IReport[] = [];
  form = new FormStore(
    {
      startDate: null,
      endDate: null,
      period: null,
    },
    reportSchema
  );
  isLoading = false;

  periods = [
    { text: "За день", value: "day" },
    { text: "За неделю", value: "week" },
    { text: "За месяц", value: "month" },
    { text: "За год", value: "year" },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    // this.reports = reportsData;
    this.getReports();
  };

  getReports = () => {
    runInAction(() => {
      this.isLoading = true;
    });
    reportService
      .loadReports()
      .then((res) => {
        runInAction(() => {
          this.reports = res.data.data;
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  reportDownload = async (name: string, url: string, ext: string) => {
    this.form.validate();

    if (!this.form.isValid) return;

    await reportService.loadReport(url, this.form.values).then((response) => {
      const fileName = `${name}.${ext}`;

      FileSaver.saveAs(
        response.data,
        getFileNameFromDisposition(response) || fileName
      );
    });
  };

  changePeriodValue = (value: string) => {
    this.form.changeValue(value, "period");
    const now = new Date();
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const daysInMonth = new Date(year, month, 0).getDate();

    switch (value) {
      case "day":
        this.form.changeValue(new Date(), "startDate");
        this.form.changeValue(new Date(), "endDate");
        break;

      case "week":
        now.setDate(now.getDate() - 7);
        this.form.changeValue(now, "startDate");
        this.form.changeValue(new Date(), "endDate");
        break;

      case "month":
        now.setDate(now.getDate() - daysInMonth);
        this.form.changeValue(now, "startDate");
        this.form.changeValue(new Date(), "endDate");
        break;

      case "year":
        now.setDate(now.getDate() - 365);
        this.form.changeValue(now, "startDate");
        this.form.changeValue(new Date(), "endDate");
        break;
    }
  };

  downloadAllReports = () => {
    this.form.validate();

    if (!this.form.isValid) return;

    this.reports.forEach(async (report) => {
      await reportService
        .loadReport(report.url, this.form.values)
        .then((response) => {
          const { data } = response;
          const fileName = `${report.label}.${report.ext}`;
          FileSaver.saveAs(
            data,
            getFileNameFromDisposition(response) || fileName
          );
        });
    });
  };

  reset = () => {
    this.form.reset();
    this.isLoading = false;
    this.reports = [];
  };
}
