import * as yup from "yup";
import { VALIDATOR_ANY } from "../../../../../validation/validators";

const VALIDATOR_STRING_REQUIRED = yup
  .string()
  .typeError("Заполните поле")
  .required("Заполните поле");

const VALIDATOR_NUMBER_REQUIRED = VALIDATOR_STRING_REQUIRED.matches(
  /^\d*$/,
  `Только цифры`
).test(
  "is-not-first-zero",
  "Номер не может начинаться с 0",
  (value) => typeof value === "string" && value.length > 0 && value[0] !== "0"
);

const VALIDATOR_STRING_REQUIRED_NAME = yup
  .string()
  .typeError("Заполните поле")
  .matches(
    /^\S+ (\S+|\S+ \S+)$/,
    "Введите Фамилию Имя и Отчество (необязательно)"
  )
  .required("Заполните поле");

export const createOperatorValidationSchema = yup.object({
  name: VALIDATOR_STRING_REQUIRED_NAME,
  number: VALIDATOR_NUMBER_REQUIRED,
  graph: VALIDATOR_NUMBER_REQUIRED,
  status: VALIDATOR_STRING_REQUIRED,
  phone_number: VALIDATOR_STRING_REQUIRED,
  login: VALIDATOR_STRING_REQUIRED,
  password: VALIDATOR_STRING_REQUIRED,
  lines: VALIDATOR_ANY,
});

export const editOperatorValidationSchema = yup.object({
  name: VALIDATOR_STRING_REQUIRED_NAME,
  number: VALIDATOR_NUMBER_REQUIRED,
  graph: VALIDATOR_NUMBER_REQUIRED,
  status: VALIDATOR_STRING_REQUIRED,
  phone_number: VALIDATOR_STRING_REQUIRED,
  login: VALIDATOR_ANY,
  password: VALIDATOR_ANY,
  lines: VALIDATOR_ANY,
});
