import { observer } from "mobx-react-lite";
import { ReactComponent as DownloadIcon } from "../../assets/svg/download-icon.svg";
import { ComponentPropsWithoutRef, FC } from "react";
import cn from "classnames";
import styles from "./styles.module.scss";
export const DownloadLink: FC<ComponentPropsWithoutRef<"a">> = observer(
  ({ className, ...props }) => (
    <a
      target="_blank"
      rel="noreferrer"
      download
      className={cn(styles.downloadIcon, className)}
      {...props}
    >
      <DownloadIcon />
    </a>
  )
);
