import "./styles.scss";
import { DatePicker as CrmDatePicker } from "@kit/kit-aiss";
import { FC } from "react";
import { IDatePickerProps } from "@kit/kit-aiss/dist/components/DatePicker/index.types";

const DatePicker: FC<IDatePickerProps> = (props) => {
  return <CrmDatePicker className="crm-date-picker" {...props} />;
};

export default DatePicker;
