import "./styles.scss";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Typography from "../../components/Typography";
import EditAppealModal from "../Appeals/components/popups/EditAppealModal";
import MainLayout from "../../components/layouts/MainLayout";
import { AppealsOperatorStore } from "./store";
import Card from "../../components/Card";
import CrmTable from "../../components/CrmTable";
import { FlexColumn, FlexRow } from "@kit/kit-aiss";
import GlobalStatusStore from "../../stores/GlobalStatusStore";
import ButtonPauseCalls from "../../components/ButtonPauseCalls";
import TextField from "../../components/TextField";
import Button from "../../components/Button";
import sipControllerStore from "../../stores/GlobalStatusStore/SipController";
import StatisticBlock from "../AppealsView/components/StatisticBlock";
import InfiniteScroll from "react-infinite-scroll-component";
import Pagination from "../../components/Pagination";
import { monthNames, weekDays } from "../../constants/months";

const store = new AppealsOperatorStore();

const OperatorAppeals = () => {
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [tel, setTel] = useState("13000");
  const [isCall] = useState(false);

  useEffect(() => {
    store.loadMyAppeals();

    return () => {
      store.reset();
    };
  }, []);

  const handleCloseEdit = () => {
    setIsOpenEdit(false);
  };

  const handleOpenEdit = (idx: number) => {
    const id = store.appeals.appeals[idx]?.id;
    if (id) {
      setId(id);
      setIsOpenEdit(true);
    }
  };

  const handlePlusDate = (date: Date | null) => {
    const r = date!.setDate(date!.getDate() + 1);
    store.onChangeDate(new Date(r));
  };

  const handleMinusDate = (date: Date | null) => {
    const r = date!.setDate(date!.getDate() - 1);
    store.onChangeDate(new Date(r));
  };

  const handleMoreLoad = () => {
    store.incrementPageCount();
    store.loadMyAppeals();
  };

  const handleChangeDate = (date: Date | null) => {
    store.onChangeDate(date);
  };

  return (
    <MainLayout status={GlobalStatusStore.states.paused}>
      <FlexColumn gap={35} className={"operator-calls-page"}>
        <FlexRow className="operator-calls__header">
          <Typography variant="h1">Обращения</Typography>
          <Pagination
            title={`${
              weekDays[
                store.date!.getDay() - 1 > -1 ? store.date!.getDay() - 1 : 6
              ]
            }, ${store.date!.getDate()} ${monthNames[store.date!.getMonth()]}`}
            onNext={() => {
              handlePlusDate(store.date);
            }}
            onPrev={() => {
              handleMinusDate(store.date);
            }}
            date={store.date}
            onChangeDate={handleChangeDate}
            className="operator"
          />
        </FlexRow>
        <FlexColumn gap={20} className={"operator-calls"}>
          <FlexRow>
            {/*<ParametersOperator handleCall={() => setIsCall((prev) => !prev)} />*/}

            <StatisticBlock
              all={store.statisticsBlock.appealsAcceptedCount}
              approve={store.statisticsBlock.pr}
              overdue={store.statisticsBlock.appealsOverdueCount}
              processed={store.statisticsBlock.processed}
            />
            <ButtonPauseCalls />
          </FlexRow>
          {isCall && (
            <Card>
              <FlexRow isNoFlexChildren>
                <TextField value={tel} onChange={setTel} />
                <Button
                  className="operator-calls__button"
                  size="medium"
                  onClick={() => sipControllerStore.call(tel)}
                  disabled={GlobalStatusStore.states.paused}
                >
                  Позвонить
                </Button>
              </FlexRow>
            </Card>
          )}

          <Card>
            <div className="calls-table-heading">
              <Typography variant="h4">
                Все обращения за смену ({store.appeals?.count})
              </Typography>
            </div>
            <InfiniteScroll
              dataLength={(store.appeals.appeals?.length as number) || 0}
              next={handleMoreLoad}
              hasMore={true}
              loader
            >
              <CrmTable
                isLoading={store.isLoading}
                isHoverBg
                isFullWidth
                headers={[
                  {
                    title: "Дата",
                    width: 200,
                    ...store.sortStore.sortFields.get("created_at"),
                  },
                  {
                    title: "Оператор №",
                    width: 200,
                    // ...store.sortStore.sortFields.get(
                    //   "responsible_user_number"
                    // ),
                  },
                  {
                    title: "Линия",
                    width: 200,
                    ...store.sortStore.sortFields.get("call_line_id"),
                  },
                  {
                    title: "Тема обращения",
                    ...store.sortStore.sortFields.get("topic_title"),
                  },
                  {
                    title: "Статус",
                    width: 80,
                  },
                  {
                    title: "Оценка",
                  },
                  {
                    title: "Номер",
                  },
                ]}
                rows={store.rows}
                onRowClick={handleOpenEdit}
              />
            </InfiniteScroll>
          </Card>
        </FlexColumn>
      </FlexColumn>
      {isOpenEdit && (
        <EditAppealModal
          editAppealStore={store.editAppealStore}
          appealId={id || 0}
          isOpen={isOpenEdit}
          onClose={handleCloseEdit}
        />
      )}
    </MainLayout>
  );
};

export default observer(OperatorAppeals);
