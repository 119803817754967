import { formatISO } from "date-fns";
import FileSaver from "file-saver";
import { makeAutoObservable, runInAction } from "mobx";
import shiftService from "../../../services/shifts";
import { convertSecondsToHHMM } from "../../../utils/time/convertSecodnsToHHMM";

interface IOperator {
  status?: boolean;
  name?: string;
  number?: string;
  lines?: string;
  accept?: string;
  create?: string;
  time?: string;
  pause?: string;
  firstName?: string;
  lastName?: string;
}

interface ILines {
  status: boolean;
  number: string;
  operators: string;
  queue: string;
  time: string;
  calls: string;
  appeals: string;
  resets: string;
}

export class ShiftsStore {
  lines: ILines[] = [];
  operators: IOperator[] = [];
  isLoading = false;
  date: Date | null = new Date();

  statisticsBlock = {
    negativeCount: 0,
    approveCount: 0,
    operatorsCount: 0,
    rejectCount: 0,
    appealsOverdueCount: 0,
    appealsAcceptedCount: 0,
  };

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.loadLines();
    this.date = new Date();
  };

  reset = () => {
    this.operators = [];
    this.lines = [];
    this.statisticsBlock = {
      negativeCount: 0,
      approveCount: 0,
      operatorsCount: 0,
      rejectCount: 0,
      appealsOverdueCount: 0,
      appealsAcceptedCount: 0,
    };
    this.isLoading = false;
    this.date = null;
  };

  onChangeDate = (date: Date | null) => {
    this.date = date;
    this.loadLines();
    localStorage.setItem("shiftDate", String(date));
  };

  onPrintChangeDate = (date: string) => {
    this.date = new Date(date);

    localStorage.setItem("shiftDate", String(this.date));
    this.loadLines();
  };

  loadLines = () => {
    runInAction(() => {
      this.isLoading = true;
    });
    const date = this.date === null ? new Date() : this.date;
    shiftService
      .loadLinesStatistics(date)
      .then((res) => {
        const { data } = res.data;

        runInAction(() => {
          this.lines = data.map((item: any) => {
            const obj = {
              status: null,
              number: "",
              operators: null,
              queue: null,
              time: "",
              calls: null,
              appeals: null,
              resets: null,
            };
            obj.status = item.call_line.active;
            obj.number = item.call_line.short_name;
            obj.operators = item.total_operators_count;
            obj.queue = item.calls_in_queue_count;
            obj.time = convertSecondsToHHMM(item.pending_seconds);
            obj.calls = item.accepted_calls_count;
            obj.appeals = item.appeals_count;
            obj.resets = item.declined_calls_count;

            return obj;
          });
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });

    const commonDate = date ? new Date(date) : new Date();
    const parsedDate = formatISO(commonDate).split("T");

    shiftService.loadShiftsStatistics(parsedDate[0]).then((res) => {
      const { data } = res.data;

      runInAction(() => {
        if (data) {
          this.statisticsBlock = {
            negativeCount: data.appeals_negative_grade_count,
            approveCount: data.accepted_calls_count,
            operatorsCount: data.total_operators_count,
            rejectCount: data.declined_calls_count,
            appealsOverdueCount: data.overdue_appeals_count,
            appealsAcceptedCount: data.appeals_count,
          };
        }
      });
    });
  };

  loadOperators = (date?: Date) => {
    this.operators = [];
    shiftService.loadOperatorsStatistics(date).then(({ data }) => {
      console.log("data", data.data);

      runInAction(() => {
        data.data.forEach((item: any) => {
          const lines: string[] = [];
          item.user.call_lines.forEach((call_line: any) => {
            lines.push(` ${call_line.short_name}`);
          });

          this.operators.push({
            status: item.user.status,
            name: `${item.user.last_name} ${item.user.first_name[0]}. ${
              item.user.middle_name ? item.user.middle_name[0] + "." : ""
            }`,
            number: item.user.number,
            lines: lines.toString(),
            accept: item.calls_accepted_count,
            create: item.appeals_count,
            time: convertSecondsToHHMM(item.time_online_seconds || 0),
            pause: convertSecondsToHHMM(item.break_time_seconds || 0),
            firstName: item.user.first_name,
            lastName: item.user.last_name,
          });
        });
      });
    });
  };

  //   downloadShiftReport = async (date: string | Date | null) => {
  //     const fileName = `Отчет по количеству звонков на ${date}.csv`;

  //     await shiftService.downloadReport(date).then((res) => {
  //       FileSaver.saveAs(
  //         new Blob([res.data], { type: "text/csv;charset=utf-8" }),
  //         fileName
  //       );
  //     });
  //   };

  downloadLinesReport = async (date: string | Date | null) => {
    console.log("date", date, formatISO(date as Date));

    const reportDate = formatISO(date as Date).split("T");
    const fileName = `Линии ${reportDate[0]}.xlsx`;

    await shiftService.downloadLinesReport(date).then((res) => {
      FileSaver.saveAs(
        new Blob([res.data], { type: "text/xlsx;charset=utf-8" }),
        fileName
      );
    });
  };

  str_pad_left(string: number, pad: string, length: number) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  get linesRows() {
    return this.lines.map((line) => [
      {
        text: line.number,
      },
      {
        text: line.operators,
      },
      {
        text: line.queue,
      },
      {
        text: line.time,
      },
      {
        text: line.calls,
      },
      {
        text: line.appeals,
      },
      {
        text: line.resets,
      },
    ]);
  }

  get operatorsRows() {
    return this.operators.map((operator) => [
      {
        text: operator.name,
      },
      {
        text: operator.number,
      },
      {
        text: operator.lines,
      },
      {
        text: operator.accept,
      },
      {
        text: operator.create,
      },
      {
        text: operator.time,
      },
      {
        text: operator.pause,
      },
    ]);
  }
}
