import { observer } from "mobx-react";
import React from "react";
import cn from "classnames";

import "./styles.scss";

interface ISwitchProps {
  handleSwitch: (type: string) => void;
  type: string;
  length: number;
  isHistory: boolean;
  isAppeal: boolean;
  linksLength?: number;
}

const Switcher: React.FC<ISwitchProps> = ({
  type,
  length,
  handleSwitch,
  isHistory,
  isAppeal,
  linksLength,
}) => {
  const handleClickHistory = () => {
    handleSwitch("history");
  };

  const handleClickConnection = () => {
    handleSwitch("connection");
  };

  return (
    <div className="switcher">
      {isAppeal && (
        <div
          className={cn(
            "switcher-element",
            "divider",
            type === "connection" && "active"
          )}
          onClick={handleClickConnection}
        >
          <span className="switcher-element__text">Связь</span>
          <span className="switcher-element__quantity">{linksLength}</span>
        </div>
      )}
      {isHistory && (
        <div
          className={cn("switcher-element", type === "history" && "active")}
          onClick={handleClickHistory}
        >
          <span className="switcher-element__text">Изменения</span>
          <span className="switcher-element__quantity">{length}</span>
        </div>
      )}
    </div>
  );
};

export default observer(Switcher);
