import { FlexColumn, FlexRow } from "@kit/kit-aiss";
import { observer } from "mobx-react-lite";
import React from "react";
import { LinesAccordionStore } from "../../stores/LinesAccordionStore";
import Accordion from "../Accordion";
import ButtonRemove from "../ButtonRemove";
import Checkboxes from "../Checkboxes";
import SearchField from "../SearchField";
import TextField from "../TextField";

export interface ILinesSelectorProps {
  linesStore: LinesAccordionStore;
}

const LinesSelector: React.FC<ILinesSelectorProps> = ({ linesStore }) => {
  return (
    <>
      <Accordion
        headerText="Добавить линию"
        subHeaderText="Оператор получает звонки только с добавленных линий"
        withPaddings
      >
        <FlexColumn gap={20}>
          <SearchField
            value={linesStore.linesFilter}
            onChange={linesStore.changeLinesFilter}
            placeholder="Поиск по номеру или ФИО"
          />
          <Checkboxes
            options={linesStore.filteredLines}
            values={linesStore.form.values.lines || []}
            onChange={linesStore.changeLines}
          />
        </FlexColumn>
      </Accordion>
      {linesStore.selectedLines.map((line, idx) => (
        <FlexRow key={idx} gap={10}>
          <TextField
            placeholder="Добавлена линия"
            value={line.text}
            onChange={() => null} // readonly
            isDisabled
          />
          <ButtonRemove onClick={() => linesStore.changeLines(line.value)} />
        </FlexRow>
      ))}
    </>
  );
};

export default observer(LinesSelector);
