import { observer } from "mobx-react";
import React, { useRef } from "react";
import GlobalStatusStore from "../../stores/GlobalStatusStore";
import sipControllerStore from "../../stores/GlobalStatusStore/SipController";
import Button from "../Button";
import Modal from "../Modal";
import TextField from "../TextField";
import Typography from "../Typography";
import { CallerStore } from "./store";

import "./styles.scss";

interface ICallModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const callerStore = new CallerStore();

const Caller: React.FC<ICallModalProps> = ({ onClose, isOpen }) => {
  React.useEffect(() => {
    return () => {
      callerStore.reset();
    };
  }, []);

  const handleRing = () => {
    callerStore.form.validate();
    if (!callerStore.form.isValid) return;

    GlobalStatusStore.setState("isOutGoing", true);
    if (callerStore.form.values.tel)
      sipControllerStore.call(callerStore.form.values.tel);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="caller-modal"
      id="caller"
    >
      <Typography variant="h2">Набрать номер</Typography>

      <div className="caller">
        <TextField autoFocus {...callerStore.form.adapters.tel} />
        <div className="buttons">
          <button
            onClick={() => {
              callerStore.setTel("1");
            }}
          >
            1
          </button>
          <button
            onClick={() => {
              callerStore.setTel("2");
            }}
          >
            2
          </button>
          <button
            onClick={() => {
              callerStore.setTel("3");
            }}
          >
            3
          </button>
          <button
            onClick={() => {
              callerStore.setTel("4");
            }}
          >
            4
          </button>
          <button
            onClick={() => {
              callerStore.setTel("5");
            }}
          >
            5
          </button>
          <button
            onClick={() => {
              callerStore.setTel("6");
            }}
          >
            6
          </button>
          <button
            onClick={() => {
              callerStore.setTel("7");
            }}
          >
            7
          </button>
          <button
            onClick={() => {
              callerStore.setTel("8");
            }}
          >
            8
          </button>
          <button
            onClick={() => {
              callerStore.setTel("9");
            }}
          >
            9
          </button>
          <button
            onClick={() => {
              callerStore.setTel("*");
            }}
          >
            *
          </button>
          <button
            onClick={() => {
              callerStore.setTel("0");
            }}
          >
            0
          </button>
          <button
            onClick={() => {
              callerStore.setTel("#");
            }}
          >
            #
          </button>
        </div>
        <Button onClick={handleRing}>Позвонить</Button>
      </div>
    </Modal>
  );
};

export default observer(Caller);
