import { Button } from "@kit/kit-aiss";
import { observer } from "mobx-react-lite";
import React from "react";

import { ReactComponent as DownloadIcon } from "../../assets/svg/download-icon.svg";

interface IDownloadButtonProps {
  onClick: () => void;
}

export const DownloadButton: React.FC<IDownloadButtonProps> = observer(
  ({ onClick }) => {
    return (
      <Button className="download-button" onClick={onClick} variant="link">
        <DownloadIcon />
      </Button>
    );
  }
);

DownloadButton.displayName = "DownloadButton";
