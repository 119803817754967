export const TYPOGRAPHY_VARIANT_MAP = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  span: "span",
  title1: "span",
  title2: "span",
  title3: "span",
  "form-medium": "span",
  "text-medium": "span",
  "sub-text-medium": "span",
  "small-txt-medium": "span",
  default: "span",
} as const;
