import { makeAutoObservable } from "mobx";
import { AttributeFormStore } from "../../Form/store";
import attributesService from "../../../../../../services/attributes";
import { IAttribute } from "../../../../store/types";

export class EditAttributeModalStore {
  form: AttributeFormStore = new AttributeFormStore();
  id: number | null = null;
  loadAttributes: () => void;

  constructor(loadAttributes: () => void) {
    makeAutoObservable(this);
    this.loadAttributes = loadAttributes;
  }

  init = (attribute: IAttribute | null) => {
    this.id = attribute?.id || null;
    this.form.form.changeValue(attribute?.title || "", "title");
    this.form.form.changeValue(attribute?.name || "", "name");
    this.form.form.changeValue(attribute?.type || "", "type");
    attribute?.options.forEach((option) => {
      this.form.addField(option.name);
    });
  };

  loadAttribute = () => {
    return;
  };

  reset = () => {
    this.form.reset();
  };

  checkValidate = () => {
    this.form.validate();
    if (this.form.isValid()) {
      return this.editAttribute();
    }
  };

  editAttribute = () => {
    const formData = new FormData();
    formData.append("title", this.form.form.values.title || "");
    formData.append("name", this.form.form.values.name || "");
    formData.append("type", this.form.form.values.type || "");
    this.form.fields.forEach((field) => {
      formData.append("options[][name]", field.form.values.option || "");
    });

    return attributesService.editAttribute(this.id || 0, formData).then(() => {
      this.loadAttributes();
    });
  };

  disableAttribute = async (id?: number) => {
    const formData = new FormData();
    formData.append("ids[]", String(id));

    await attributesService.disableAttribute(formData).then(() => {
      this.loadAttributes();
    });
  };
  enableAttribute = async (id?: number) => {
    const formData = new FormData();
    formData.append("ids[]", String(id));

    await attributesService.enableAttribute(formData).then(() => {
      this.loadAttributes();
    });
  };
}
