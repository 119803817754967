import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layouts/MainLayout";
import { observer } from "mobx-react-lite";
import Typography from "../../components/Typography";

import Card from "../../components/Card";
import CrmTable from "../../components/CrmTable";
import { FlexColumn, FlexRow } from "@kit/kit-aiss";
import { AppealsStore } from "../Appeals/store";
import EditAppealModal from "../Appeals/components/popups/EditAppealModal";
import StatisticBlock from "./components/StatisticBlock";
import Pagination from "../../components/Pagination";
import { monthNames, weekDays } from "../../constants/months";
import InfiniteScroll from "react-infinite-scroll-component";

const store = new AppealsStore();

const Appeals = () => {
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [id, setId] = useState<number | null>(null);

  useEffect(() => {
    store.loadAppeals();
    store.loadOperatorAppeals();
  }, []);

  const handleOpenEdit = (idx: number) => {
    const id = store.appeals.appeals[idx]?.id;
    if (id) {
      setId(id);
      setIsOpenEdit(true);
    }
  };

  const handleCloseEdit = () => {
    setIsOpenEdit(false);
  };

  const handlePlusDate = (date: Date | null) => {
    const r = date!.setDate(date!.getDate() + 1);
    store.onChangeDate(new Date(r));
  };

  const handleMinusDate = (date: Date | null) => {
    const r = date!.setDate(date!.getDate() - 1);
    store.onChangeDate(new Date(r));
  };

  const handleChangeDate = (date: Date | null) => {
    store.onChangeDate(date);
  };

  const handleMoreLoad = () => {
    store.incrementPageCount();
    store.loadAppeals();
  };

  return (
    <MainLayout status={false}>
      <div>
        <FlexRow className="operator-calls__header">
          <Typography variant="h1">Обращения</Typography>
          <Pagination
            title={`${
              weekDays[
                store.date!.getDay() - 1 > -1 ? store.date!.getDay() - 1 : 6
              ]
            }, ${store.date!.getDate()} ${monthNames[store.date!.getMonth()]}`}
            onNext={() => {
              handlePlusDate(store.date);
            }}
            onPrev={() => {
              handleMinusDate(store.date);
            }}
            date={store.date}
            onChangeDate={handleChangeDate}
            className="operator"
          />
        </FlexRow>
      </div>
      <FlexColumn gap={20}>
        <StatisticBlock
          all={store.appealStaticticBlock.appeals_count}
          approve={store.appealStaticticBlock.appeals_created_count}
          overdue={store.appealStaticticBlock.appeals_overdue_count}
          processed={store.appealStaticticBlock.appeals_processed_count}
        />

        <Card>
          <InfiniteScroll
            dataLength={(store.appeals.appeals.length as number) || 0}
            next={handleMoreLoad}
            hasMore={true}
            loader
          >
            <CrmTable
              isLoading={store.isLoading}
              isHoverBg
              isFullWidth
              headers={[
                {
                  title: "Дата",
                  width: 200,
                  ...store.sortStore.sortFields.get("created_at"),
                },
                {
                  title: "Оператор №",
                  width: 200,
                  ...store.sortStore.sortFields.get("responsible_user_number"),
                },
                {
                  title: "Линия",
                  width: 200,
                  ...store.sortStore.sortFields.get("call_line_id"),
                },
                {
                  title: "Тема обращения",
                  ...store.sortStore.sortFields.get("topic_title"),
                },
                {
                  title: "Статус",
                  width: 80,
                },
                {
                  title: "Оценка",
                },
                {
                  title: "Номер",
                },
              ]}
              rows={store.rows}
              onRowClick={handleOpenEdit}
            />
          </InfiniteScroll>
        </Card>
      </FlexColumn>

      {isOpenEdit && (
        <EditAppealModal
          isAppealManager
          editAppealStore={store.editAppealStore}
          appealId={id || 0}
          isOpen={isOpenEdit}
          onClose={handleCloseEdit}
        />
      )}
    </MainLayout>
  );
};

export default observer(Appeals);
