import { TAuthStore } from "../../../pages/AuthPage/store/authStore";

export const SIP_SOCKET_URL =
  process.env.REACT_APP_BUILD_TARGET === "test"
    ? "wss://aster.aiwoo.info:8089/ws"
    : "wss://10.0.16.232:7777/ws";

export const getSipConfigUri = (authStore: TAuthStore) =>
  process.env.REACT_APP_BUILD_TARGET === "test"
    ? `sip:${authStore.user.number}@aster.aiwoo.info`
    : `sip:${authStore.user.user_asterisk_credentials?.login}@10.0.16.232`;

export const getSipConfigPassword = (authStore: TAuthStore) =>
  process.env.REACT_APP_BUILD_TARGET === "test"
    ? `${authStore.user.number}`
    : `${authStore.user.user_asterisk_credentials?.password}`;

export const getReferTarget = (tel: string) =>
  process.env.REACT_APP_BUILD_TARGET === "test"
    ? `sip:${tel}@aster.aiwoo.info`
    : `sip:${tel}@10.0.16.232`;

export const DEFAULT_CALL_CONFIG = {
  pcConfig: {
    rtcpMuxPolicy: "negotiate" as any, // Важно для хрома, чтоб работал multiplexing. Эту штуку обязательно нужно включить на астере.
  },
  mediaConstraints: {
    audio: true, // Поддерживаем только аудио
    video: false,
  },
  rtcOfferConstraints: {
    offerToReceiveAudio: true, // Принимаем только аудио
    offerToReceiveVideo: false,
  },
};
