import { makeAutoObservable, runInAction } from "mobx";
import appealsService from "../../../../../../services/appeals";
import { AppealsFormStore } from "../../Form/store";
import { getFormattedDate } from "../../../../../../utils/date/getFormattedDate";
import StorageSyncStore from "../../../../../../stores/StorageSyncStore";
import { convertFormDataToObject } from "../../../../../../utils/convertFormDateToObject";
import { STORAGE_KEY_CREATE_APPEAL } from "../../../../../../constants/storage";
import authStore from "../../../../../AuthPage/store/authStore";
import GlobalStatusStore from "../../../../../../stores/GlobalStatusStore";

export class CreateAppealStore {
  form: AppealsFormStore;
  type?: "create";
  isLoading = false;

  storageSync = new StorageSyncStore(
    this,
    "appealFormDataObject",
    STORAGE_KEY_CREATE_APPEAL
  );

  constructor(type?: "create") {
    makeAutoObservable(this);
    this.type = type;
    this.form = new AppealsFormStore(type, this);
  }

  checkValid = () => {
    this.form.validate();
    if (this.form.isValid()) {
      return this.createAppeal();
    }
  };

  get appealFormData() {
    const formData = new FormData();
    formData.append("patientName", this.form.form.values.patientName || "");
    formData.append("clientName", this.form.form.values.clientName || "");
    formData.append("description", this.form.form.values.description || "");
    formData.append("address", this.form.form.values.address || "");
    formData.append("organization", this.form.form.values.organization || "");
    formData.append("clientPhone", this.form.form.values.clientPhone || "");
    formData.append("contactPhone", this.form.form.values.contactPhone || "");
    formData.append("cityId", this.form.form.values.cityId || "");
    formData.append(
      "urgencyCategoryId",
      this.form.form.values.urgencyCategoryId || ""
    );
    formData.append("topicId", this.form.form.values.topic || "");
    formData.append("resultText", this.form.form.values.resultText || "");
    formData.append(
      "patientBirthDate",
      getFormattedDate(
        this.form.form.values.patientBirthDate || "",
        "yyyy-MM-dd"
      ) || ""
    );
    formData.append("callInfoId", String(GlobalStatusStore.client_info.id));
    if (
      this.form.subTopicStore &&
      this.form.subTopicStore.form.values.subTopic
    ) {
      formData.append(
        "subtopicId",
        this.form.subTopicStore.form.values.subTopic || ""
      );
    }

    return formData;
  }

  get appealFormDataObject() {
    return convertFormDataToObject(this.appealFormData);
  }

  createAppeal = () => {
    runInAction(() => {
      this.isLoading = true;
    });
    return appealsService
      .createAppeal(this.appealFormData)
      .then((res) => {
        // проверка, что обращение создано
        if (!res.data?.data?.created_at) return false;

        this.storageSync.stopSync();
        localStorage.removeItem(STORAGE_KEY_CREATE_APPEAL);
        runInAction(() => {
          this.isLoading = false;
        });
        authStore.getSideBarLinks();
        return true;
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  reset = () => {
    this.form.reset();
  };
}
