import api from "../../api";

const loadUsers = (params?: URLSearchParams, pageCount = 1, limit = 100) => {
  return api.get(
    `/api/user?page=${pageCount}&limit=${limit}&groups[]=appeals_manager&groups[]=manager&sort[last_name]=asc`,
    { params }
  );
  //   return api.get(`/api/user?groups[]=appeals_manager`);
};

const loadEditedUser = (id: number) => {
  return api.get(`/api/user/${id}`);
};

const saveEditUser = (id: number, formData: FormData) => {
  return api.post(`/api/user/${id}`, formData);
};

const saveCreatedUser = (formData: FormData) => {
  return api.post(`/api/user`, formData);
};

const downloadUsersReport = (
  params?: URLSearchParams,
  pageCount = 1,
  limit = 100
) => {
  return api.get(
    `/api/user/download?page=${pageCount}&limit=${limit}&groups[]=appeals_manager&groups[]=manager&sort[last_name]=asc`,
    { params, responseType: "blob" }
  );
};

const usersService = {
  loadUsers,
  loadEditedUser,
  saveEditUser,
  saveCreatedUser,
  downloadUsersReport,
};

export default usersService;
