import { ISelectOption } from "@kit/kit-aiss/dist/components/Select/index.types";
import { makeAutoObservable, runInAction } from "mobx";
import themasService from "../../../../../../services/themas";
import usersService from "../../../../../../services/users";
import { UserFormStore } from "../../UserForm/store";

export class CreateUserStore {
  form: UserFormStore = new UserFormStore();
  isLoading = false;
  medicalOrganisations: ISelectOption[] = [];
  id: number | null = null;
  status = [
    {
      text: "Активный",
      value: true,
    },
    { text: "Неактивный", value: false },
  ];

  roles = [
    {
      text: "Администратор системы",
      value: "manager",
    },
    {
      text: "Работа с обращениями",
      value: "appeals_manager",
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.loadOrganizations();
  };

  reset = () => {
    this.form.form.reset();
    this.isLoading = false;
    this.medicalOrganisations = [];
  };

  loadOrganizations = () => {
    runInAction(() => {
      this.isLoading = true;
    });
    themasService
      .loadOrganizations()
      .then((res) => {
        const { data } = res.data;
        runInAction(() => {
          this.medicalOrganisations = data.map((item: string) => {
            const obj = { text: "", value: "" };
            obj.text = item;
            obj.value = item;

            return obj;
          });
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  saveCreateUser = async () => {
    this.form.form.validate();

    if (!this.form.form.isValid) return false;

    const formData = new FormData();
    formData.append(
      "firstName",
      this.form.form.values.firstName as unknown as string
    );
    formData.append(
      "middleName",
      this.form.form.values.middleName as unknown as string
    );
    formData.append(
      "lastName",
      this.form.form.values.lastName as unknown as string
    );
    formData.append(
      "status",
      Number(this.form.form.values.status) as unknown as string
    );
    formData.append(
      "organizationName",
      this.form.form.values.organizationName as unknown as string
    );
    formData.append("login", this.form.form.values.login as unknown as string);
    formData.append(
      "password",
      this.form.form.values.password as unknown as string
    );
    formData.append("email", this.form.form.values.email as unknown as string);
    formData.append(
      "phone_number",
      this.form.form.values.phone as unknown as string
    );
    formData.append("role", this.form.form.values.role as unknown as string);

    await usersService.saveCreatedUser(formData).then(() => {
      return true;
    });
  };
}
