import { makeAutoObservable, runInAction } from "mobx";
import { ILineChartData } from "./types";
import linesService from "../../../../services/lines";

export class ChartsStore {
  lines: { text: string; value: number | null }[] = [];
  callsLineChartData: ILineChartData = { shiftTime: "", chartData: [] };
  value: number | null = null;

  period = [
    { text: "За смену", value: null, active: true },
    { text: "За неделю", value: "week", active: false },
    { text: "За месяц", value: "month", active: false },
  ];

  circleData: {
    totalCalls: number | null;
    values: {
      name: string;
      value: number | null;
      colors: { color: string; percent: string }[];
    }[];
  } = {
    totalCalls: null,
    values: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  loadCallsChartData = () => {
    this.loadCallsLineChartData(null);
    this.loadCallsCircleChartData(null);
    linesService.loadLines().then((res) => {
      const { data } = res.data;

      runInAction(() => {
        this.lines = data.items.map((item: any) => {
          const obj = { text: "", value: "" };
          obj.text = item.short_name;
          obj.value = item.id;

          return obj;
        });

        this.lines.unshift({ text: "Все линии", value: null });
      });
    });
  };

  changeLine = (id: number | null) => {
    this.value = id;
    this.loadCallsLineChartData(id!);
  };

  loadCallsLineChartData = (id: number | null) => {
    linesService.loadCallLine(id as number).then((res) => {
      const { data } = res.data;
      const { values, time } = data.chart;

      runInAction(() => {
        this.callsLineChartData!.chartData = values;
        this.callsLineChartData.shiftTime = time;
      });
    });
  };

  loadCallsCircleChartData = (cellType: string | null) => {
    linesService.loadCircleChart(cellType).then((res) => {
      const { data } = res.data;
      runInAction(() => {
        this.circleData = data.chart;
        this.circleData.totalCalls = data.totalCalls;
      });
    });
  };

  setActiveTab = (id: number) => {
    this.period.forEach((item) => (item.active = false));
    this.period[id].active = !this.period[id].active;
  };

  reset = () => {
    this.circleData = {
      totalCalls: null,
      values: [],
    };
    this.lines = [];
    this.callsLineChartData = { shiftTime: "", chartData: [] };
    this.value = null;
  };
}
