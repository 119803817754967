import "./styles.scss";

import { Accordion as KitAccordion } from "@kit/kit-aiss";
import { IAccordionProps } from "@kit/kit-aiss/dist/components/Accordion";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import ExpandButton from "../ExpandButton";

const Accordion: React.FC<IAccordionProps & { withPaddings?: boolean }> = (
  props
) => {
  return (
    <KitAccordion
      {...props}
      className={cn(
        props.className,
        "crm-accordion",
        props.withPaddings && "crm-accordion--with-paddings"
      )}
      classNameIsOpen={cn(props.className, "crm-accordion--open")}
      CustomExpandButton={(props) => <ExpandButton {...props} />}
    />
  );
};

export default observer(Accordion);
