import { makeAutoObservable } from "mobx";
import { AttributeFormStore } from "../../Form/store";
import attributesService from "../../../../../../services/attributes";

export class CreateAttributeModalStore {
  form: AttributeFormStore = new AttributeFormStore();
  loadAttributes: () => void;

  constructor(loadAttributes: () => void) {
    makeAutoObservable(this);
    this.loadAttributes = loadAttributes;
  }

  checkValidate = () => {
    this.form.validate();
    if (this.form.isValid()) {
      return this.createAttribute();
    }
  };

  createAttribute = () => {
    const formData = new FormData();
    formData.append("title", this.form.form.values.title || "");
    formData.append("name", this.form.form.values.name || "");
    formData.append("type", this.form.form.values.type || "");
    this.form.fields.forEach((field) => {
      formData.append("options[][name]", field.form.values.option || "");
    });

    return attributesService.createAttribute(formData).then(() => {
      this.loadAttributes();
      this.form.reset();
    });
  };
}
