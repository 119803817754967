import { TFilterValues } from "./../../components/ReportHeader/index";
import { FormStore } from "@kit/kit-aiss";
import {
  formatISO,
  endOfWeek,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
} from "date-fns";
import FileSaver from "file-saver";
import { makeAutoObservable } from "mobx";

import api from "../../api";
import { URL_REPORTS_DOWNLOAD_DATA } from "../../constants/urls";

export class ReportDownloadStore {
  formBeginDate = new FormStore({
    date: null,
  });
  formEndDate = new FormStore({
    date: null,
  });

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.formBeginDate.reset();
    this.formEndDate.reset();
  };

  handleDownloadFile = () => {
    return api
      .post(URL_REPORTS_DOWNLOAD_DATA, {
        dateFrom: formatISO(this.formBeginDate.values.date || new Date(), {
          representation: "date",
        }),
        dateTo: formatISO(this.formEndDate.values.date || new Date(), {
          representation: "date",
        }),
      })
      .then((res) => {
        const fileName = `Отчет по количеству звонков на ${this.formBeginDate.values.date}-${this.formEndDate.values.date}.csv`;
        FileSaver.saveAs(
          new Blob([res.data], { type: "text/csv;charset=utf-8" }),
          fileName
        );
      });
  };

  setDatesPeriod = (period?: TFilterValues) => {
    const dateToday = new Date();
    switch (period) {
      case "week":
        this.formBeginDate.changeValue(startOfWeek(dateToday), "date");
        this.formEndDate.changeValue(endOfWeek(dateToday), "date");
        break;

      case "month":
        this.formBeginDate.changeValue(startOfMonth(dateToday), "date");
        this.formEndDate.changeValue(endOfMonth(dateToday), "date");
        break;

      case "day":
        this.formBeginDate.changeValue(dateToday, "date");
        this.formEndDate.changeValue(dateToday, "date");
        break;

      case "year":
        this.formBeginDate.changeValue(startOfYear(dateToday), "date");
        this.formEndDate.changeValue(endOfYear(dateToday), "date");
        break;

      default:
        break;
    }
  };
}
