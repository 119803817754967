import api from "../../api";

const loadRecords = (
  params?: URLSearchParams,
  pageCount = 1,
  date?: string,
  limit = 100,
  startDate?: string,
  endDate?: string,
  callLineId?: number,
  operatorId?: number,
  status?: number
) => {
  return api.get(
    `/api/voicemail?page=${pageCount}&limit=${limit}&date=${date}&startDate=${
      startDate || ""
    }&endDate=${endDate || ""}&callLineId=${callLineId || ""}&operatorId=${
      operatorId || ""
    }&status=${status || ""}`,
    { params }
  );
  //   return api.get(`/api/voicemail`);
};

const playEvent = (id: number | null) => {
  const formData = new FormData();
  formData.append("status", String(2));

  return api.post(`/api/voicemail/${id}`, formData);
};

const loadRecord = (id: number | null) => {
  return api.get(`/api/voicemail/${id}`);
};

const saveEditedRecord = (id: number | null, formData: FormData) => {
  return api.post(`/api/voicemail/${id}`, formData);
};

const downloadRecordsStat = (
  params?: URLSearchParams,
  pageCount = 1,
  date?: string,
  limit = 100,
  startDate?: string,
  endDate?: string,
  callLineId?: number,
  operatorId?: number,
  status?: number
) => {
  return api.get(
    `/api/voicemail/download?page=${pageCount}&limit=${limit}&date=${date}&startDate=${
      startDate || ""
    }&endDate=${endDate || ""}&callLineId=${callLineId || ""}&operatorId=${
      operatorId || ""
    }&status=${status || ""}`,
    { params: params, responseType: "blob" }
  );
};

const recordsService = {
  loadRecords,
  loadRecord,
  saveEditedRecord,
  playEvent,
  downloadRecordsStat,
};

export default recordsService;
