export const INIT_RECORDS_STATISTIC = {
  negativeCount: 0,
  approveCount: 0,
  operatorsCount: 0,
  rejectCount: 0,
  appealsOverdueCount: 0,
  appealsAcceptedCount: 0,
  voicemail_count: 0,
  voicemail_new_count: 0,
  voicemail_processed_count: 0,
  voicemail_read_count: 0,
};
