import * as yup from "yup";

import {
  TEXT_ARRAY,
  TEXT_NUMBER_POSITIVE,
  TEXT_REQUIRED,
  TEXT_STRING,
} from "./errorTexts";

export const VALIDATOR_ANY = yup.mixed().notRequired();

export const VALIDATOR_STRING_REQUIRED = yup
  .string()
  .required(TEXT_REQUIRED)
  .typeError(TEXT_STRING);

export const VALIDATOR_STRING_NUMBERS_POSITIVE_REQUIRED = yup
  .string()
  .matches(/^\d+$/, TEXT_NUMBER_POSITIVE)
  .required(TEXT_REQUIRED)
  .typeError(TEXT_STRING);

export const VALIDATOR_ARRAY_NOT_EMPTY = yup
  .array()
  .typeError(TEXT_ARRAY)
  .min(1, TEXT_ARRAY);

export const VALIDATOR_CALL_PHONE = yup.object({
  tel: yup
    .string()
    .required("Введите номер телефона")
    .typeError("Введите номер телефона")
    .matches(/^[0-9]+$/, "Только цифры")
    .min(3, `Минимальная длина номера 3 символа`)
    .max(11, "Максимальная длина 11 символов"),
});
