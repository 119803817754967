import { ReactComponent as Active } from "../../assets/svg/active.svg";
import { ReactComponent as Appeals } from "../../assets/svg/appeals.svg";
import { ReactComponent as HeadPhones } from "../../assets/svg/headPhones.svg";
import { ReactComponent as Nophone } from "../../assets/svg/no_phone.svg";
import { ReactComponent as Overdue } from "../../assets/svg/overdue.svg";
import { ReactComponent as Pause } from "../../assets/svg/paused.svg";
import { ReactComponent as Phone } from "../../assets/svg/phone.svg";
import { ReactComponent as Negative } from "../../assets/svg/type_o_negative.svg";

export type IType =
  | "operators"
  | "calls"
  | "resets"
  | "appeals"
  | "overdue"
  | "o negative"
  | "active"
  | "pause";

export function getParameterIcon(type: IType | undefined) {
  switch (type) {
    case "operators":
      return <HeadPhones />;
    case "calls":
      return <Phone />;
    case "resets":
      return <Nophone />;
    case "appeals":
      return <Appeals />;
    case "overdue":
      return <Overdue />;
    case "o negative":
      return <Negative />;
    case "active":
      return <Active />;
    case "pause":
      return <Pause />;

    default:
      return <HeadPhones />;
  }
}
