import "./styles.scss";
import React, { FC, useEffect } from "react";
import { IFormProps } from "./types";
import { observer } from "mobx-react-lite";
import { ReactComponent as DeleteIcon } from "../../../../../assets/svg/delete.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/svg/plus.svg";
import TextField from "../../../../../components/TextField";
import Select from "../../../../../components/Select";

const Form: FC<IFormProps> = ({ form, isEdit, id, deleteAttribute }) => {
  useEffect(() => {
    form.init(id || null);
  }, []);

  return (
    <div className="attribute-form">
      {!isEdit && (
        <TextField
          placeholder="Название атрибута"
          name="title"
          onChange={form.form.changeValue}
          value={form.form.values.title}
          errorMessage={form.form.errors.title}
        />
      )}
      {isEdit && (
        <div className="attribute-form-edit__field">
          <TextField
            placeholder="Название атрибута"
            name="title"
            onChange={form.form.changeValue}
            value={form.form.values.title}
            errorMessage={form.form.errors.title}
          />
          <DeleteIcon onClick={deleteAttribute} />
        </div>
      )}
      <TextField
        placeholder="Вопрос в поле"
        name="name"
        onChange={form.form.changeValue}
        value={form.form.values.name}
        errorMessage={form.form.errors.name}
      />
      <Select
        placeholder="Тип поля"
        options={form.options.types}
        name="type"
        value={form.form.values.type}
        onChange={(value, name) => form.onChangeType(value, name)}
        errorMessage={form.form.errors.type}
        menuPortalTarget={document.body}
      />
      {!!form.fields.length && (
        <div className="attribute-form-options">
          {form.fields.map((field, idx) => (
            <div key={idx} className="attribute-form-options__field">
              <TextField
                onChange={field.form.changeValue}
                value={field.form.values.option}
                name="option"
                errorMessage={field.form.errors.option}
                placeholder={`Вариант ${idx + 1}`}
              />
              {idx !== 0 && (
                <DeleteIcon onClick={() => form.deleteField(idx)} />
              )}
            </div>
          ))}
        </div>
      )}
      {form.form.values.type === 2 && (
        <div onClick={() => form.addField()} className="attribute-form-button">
          <PlusIcon />
          <span className="attribute-form-button__text">Добавить вариант</span>
        </div>
      )}
    </div>
  );
};

export default observer(Form);
