import "./styles.scss";

import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { ComponentProps } from "react";

import BodyCell from "./BodyCell";
import HeaderCell from "./HeaderCell";
import Typography from "../Typography";

export interface ICrmTableProps {
  headers: {
    title: string;
    sortType?: "asc" | "desc";
    onSort?: () => void;
    width?: number;
  }[];
  rows: {
    text: React.ReactNode;
    color?: ComponentProps<typeof Typography>["color"];
  }[][];
  className?: string;
  isHoverBg?: boolean;
  isEvenBg?: boolean;
  isFullWidth?: boolean;
  onRowClick?: (idx: number) => void;
}

const CrmCustomTable: React.FC<ICrmTableProps> = ({
  headers,
  rows,
  className,
  isHoverBg,
  isEvenBg,
  isFullWidth,
  onRowClick,
}) => {
  return (
    <table
      className={cn(
        "crm-custom-table",
        isHoverBg && "crm-custom-table--hover-bg",
        isEvenBg && "crm-custom-table--even-bg",
        isFullWidth && "crm-custom-table--full-width",
        className
      )}
    >
      <thead>
        <tr>
          {headers.map((header, idx) => (
            <HeaderCell
              width={header.width}
              text={header.title}
              key={idx}
              sortType={header.sortType}
              onSort={header.onSort}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, idx) => (
          <tr
            key={idx}
            onClick={() => onRowClick?.(idx)}
            className={cn(
              "crm-table__body-row",
              !!onRowClick && "crm-table__body-row--clickable"
            )}
          >
            {row.map((item, idx) => (
              <BodyCell key={idx}>{item.text}</BodyCell>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default observer(CrmCustomTable);
