import "./styles.scss";
import React, { FC, useEffect } from "react";
import { ILinesModalFormProps } from "./types";
import { observer } from "mobx-react-lite";
import TextField from "../../../../../../components/TextField";
import Select from "../../../../../../components/Select";

const LinesModalForm: FC<ILinesModalFormProps> = ({
  store,
  isEdit,
  lineId,
}) => {
  useEffect(() => {
    if (isEdit && lineId) {
      store.loadForm(lineId);
    }
    store.loadQueues();
    return () => store.reset();
  }, []);

  return (
    <div className="lines-modal-form">
      <Select
        options={store.queueOptions}
        {...store.form.adapters.lineNumber}
        onChange={store.changeLine}
        placeholder="Очередь"
      />
      <TextField
        name="shortTitle"
        onChange={store.form.changeValue}
        value={store.form.values.shortTitle}
        errorMessage={store.form.errors.shortTitle}
        placeholder="Краткое название линии"
      />
      <Select
        options={store.options}
        value={store.form.values.status}
        errorMessage={store.form.errors.status}
        name="status"
        onChange={store.form.changeValue}
        placeholder="Статус"
      />
    </div>
  );
};

export default observer(LinesModalForm);
