import { makeAutoObservable } from "mobx";
import { FormStore } from "@kit/kit-aiss";
import {
  createOperatorValidationSchema,
  editOperatorValidationSchema,
} from "../validationSchema";

import operatorsService from "../../../../../../services/operators";
import { LinesAccordionStore } from "../../../../../../stores/LinesAccordionStore";
import { OperatorCreateForm } from "../types";

export class OperatorFormStore {
  isEdit = false;
  isLoading = false;
  form: FormStore<OperatorCreateForm>;

  linesStore = new LinesAccordionStore();

  constructor(isEdit?: boolean) {
    makeAutoObservable(this);
    if (isEdit) {
      this.isEdit = isEdit;
    }
    this.form = new FormStore<OperatorCreateForm>(
      {
        name: null,
        number: "",
        graph: null,
        status: null,
        phone_number: null,
        login: null,
        password: null,
      },
      !this.isEdit
        ? createOperatorValidationSchema
        : editOperatorValidationSchema
    );
  }

  init = () => {
    this.linesStore.init();
  };

  reset = () => {
    this.form.reset();
    this.isEdit = false;
    this.isLoading = false;
    this.linesStore.reset();
  };

  loadOperator = (id: number) => {
    operatorsService.loadOperator(id).then(({ data }) => {
      this.form.adapters.name.onChange(
        `${data.data.last_name} ${data.data.first_name}${
          data.data.middle_name ? ` ${data.data.middle_name}` : ""
        }`,
        "name"
      );
      this.form.adapters.number.onChange(data.data.number, "number");
      this.form.adapters.graph.onChange(data.data.schedule_id.id, "graph");
      this.form.adapters.phone_number.onChange(
        String(data.data.phone),
        "phone_number"
      );
      this.form.adapters.login.onChange(data.data.login, "login");
      this.form.adapters.status.onChange(Boolean(data.data.status), "status");
      const newLines = data.data.call_lines.map((line: any) => line.id);
      this.linesStore.form.adapters.lines.onChange(newLines, "lines");
    });
  };
}
