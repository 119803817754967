import "./index.scss";

import { Button, H1, TextField, WarningCard } from "@kit/kit-aiss";
import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { ReactComponent as Logo } from "../../assets/svg/logo-big.svg";
import NetworkWrapper from "../../components/NetworkWrapper";
import store from "./store/authStore";

const Login: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { form, isLoading, status, isAuth, errors } = store;

  useEffect(() => {
    const handlePressEnter = function (e: KeyboardEvent) {
      if (e.key === "Enter") {
        store.fetchAuth();
      }
    };
    document.addEventListener("keypress", handlePressEnter);
    return () => document.removeEventListener("keypress", handlePressEnter);
  }, []);

  return (
    <div className="login">
      <div className="login__card">
        <NetworkWrapper statuses={[status]} isLoading={isLoading}>
          <div className="login__card-logo">
            <Logo />
          </div>
          <H1 className="login__card-header">Авторизация</H1>

          <TextField
            {...form.adapters.login}
            placeholder="Логин"
            type="email"
          />
          <TextField
            {...form.adapters.password}
            placeholder="Пароль"
            type="password"
          />
          <Button type="submit" onClick={store.fetchAuth} size="small">
            Войти
          </Button>
          {!!errors.length && (
            <WarningCard type="red">
              {errors?.map((error, idx) => (
                <p key={idx}>{error}</p>
              ))}
            </WarningCard>
          )}
        </NetworkWrapper>
      </div>
    </div>
  );
};

export default observer(Login);
