import { makeAutoObservable, runInAction } from "mobx";

import { CallFormStore } from "../../../../pages/OperatorCalls/components/popups/Form/store";
import callsService from "../../../../services/calls";
import { STORAGE_KEY_CREATE_CALL } from "../../../../constants/storage";
import recordsService from "../../../../services/records";
import GlobalStatusStore from "../../../../stores/GlobalStatusStore";
import { convertFormDataToObject } from "../../../../utils/convertFormDateToObject";
import authStore from "../../../../pages/AuthPage/store/authStore";
import { ISelectOption } from "@kit/kit-aiss/dist/components/Select/index.types";
import linesService from "../../../../services/lines";
import { generateRandomX_UID } from "../../../../utils/generateXUID";

export class CreateEmptyCallModalStore {
  form: CallFormStore = new CallFormStore("createEmpty", this);
  loadCalls?: (type?: string) => void;
  isLoadings = { createCall: false };
  lineOptions: (ISelectOption & { number: number })[] = [];

  constructor(loadCalls?: () => void) {
    makeAutoObservable(this);
    this.loadCalls = loadCalls;
  }

  init = () => {
    this.getLineOptions();
  };

  onSave = async () => {
    const res = await this.createCall();
    return !!res;
  };

  get formData() {
    const formData = new FormData();

    formData.append("waitingTime", "0");
    formData.append("durationTime", "0");

    if ("clientPhone" in this.form.form.values) {
      formData.append("callLineId", this.form.form.values.callLineId || "");
      formData.append("clientPhone", this.form.form.values.clientPhone || "");
    }

    formData.append("topicId", this.form.form.values.topic || "");
    formData.append("resultId", this.form.form.values.result || "");
    formData.append("cityId", this.form.form.values.city || "");
    formData.append("patientTypeId", this.form.form.values.socialStatus || "");
    formData.append("description", this.form.form.values.description || "");
    formData.append("clientFullName", this.form.form.values.clientName || "");
    formData.append("patientFullName", this.form.form.values.patientName || "");
    formData.append("answerText", this.form.form.values.shortAnswer || "");
    formData.append(
      "medOrganization",
      this.form.form.values.medicalOrganization || ""
    );
    formData.append("result", this.form.form.values.result || "");

    if (this.form.subTopic) {
      formData.append(
        "subtopicId",
        this.form.subTopic.form.values.subTopic || ""
      );
    }

    if (this.form.attribute) {
      this.form.attribute.attributes.forEach((attribute, idx) => {
        if (attribute.form.values.attribute) {
          formData.append(
            `attributes[${idx}][attribute_id]`,
            String(attribute.attribute.id)
          );
          formData.append(
            `attributes[${idx}][attribute_value]`,
            String(attribute.form.values.attribute)
          );
        }
      });
    }

    return formData;
  }

  get formDataObject() {
    return convertFormDataToObject(this.formData);
  }

  startNewCall = async () => {
    const newCallFormData = new FormData();
    newCallFormData.append("phone", this.clientPhone || "");
    newCallFormData.append("externalCallId", generateRandomX_UID());
    newCallFormData.append("status", "1");
    newCallFormData.append("type", "1");
    newCallFormData.append("externalQueueId", this.selectedLineNumber);

    const res = await callsService.startCall(newCallFormData);
    return res.data.data.id;
  };

  createCall = async () => {
    this.isLoadings.createCall = true;

    try {
      const callId = await this.startNewCall();

      const formData = this.formData;
      formData.set("callId", callId);

      const res = await callsService.createCall(formData);
      localStorage.removeItem(STORAGE_KEY_CREATE_CALL);
      authStore.getSideBarLinks();

      if (GlobalStatusStore.states.CallFromRecord) {
        const { data } = res.data;
        const formData = new FormData();
        formData.append("callInfoId", String(data.id));
        recordsService
          .saveEditedRecord(GlobalStatusStore.recordId, formData)
          .then(() => {
            GlobalStatusStore.setCallRecordId(null);
            GlobalStatusStore.setState("CallFromRecord", false);
          });
      }

      return res;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.isLoadings.createCall = false));
    }
  };

  getStoredForm = () => {
    const savedString = localStorage.getItem(STORAGE_KEY_CREATE_CALL);
    return savedString ? JSON.parse(savedString) : null;
  };

  getLineOptions = async () => {
    try {
      const res = await linesService.loadLines();
      runInAction(
        () =>
          (this.lineOptions =
            res.data?.data?.items?.map((line: any) => ({
              value: line.id,
              number: line.number,
              text: line.short_name,
              active: line.active,
            })) || [])
      );
    } catch (error) {
      this.lineOptions = [];
    }
  };

  setCallData = (data: { clientPhone: string; callLineId: string }) => {
    if ("clientPhone" in this.form.form.values) {
      this.form.form.changeValue(data.clientPhone, "clientPhone");
      this.form.form.changeValue(data.callLineId, "callLineId");
    }
  };

  get selectedLineNumber() {
    const values = this.form.form.values;
    if (!("callLineId" in values)) return "";

    return String(
      this.lineOptions.find((line) => line.value === values.callLineId)
        ?.number || ""
    );
  }

  get clientPhone() {
    if (!("clientPhone" in this.form.form.values)) return;
    return this.form.form.values.clientPhone;
  }
}
