import "./styles.scss";

import { Checkboxes as KitCheckboxes } from "@kit/kit-aiss";
import { IProps } from "@kit/kit-aiss/dist/components/Checkboxes/index.types";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

const Checkboxes: React.FC<IProps> = (props) => {
  return (
    <KitCheckboxes
      {...props}
      className={cn(props.className, "crm-checkboxes")}
    />
  );
};

export default observer(Checkboxes);
