import { FlexColumn } from "@kit/kit-aiss";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import MainLayout from "../../components/layouts/MainLayout";
import Typography from "../../components/Typography";
import OperatorsPageStore from "./store";
import CrmTable from "../../components/CrmTable";
import Avatar from "../../components/UserComponent/Avatar";
import Card from "../../components/Card";
import TopBar from "./components/TopBar";
import OperatorCreate from "./components/popups/OperatorCreate";
import OperatorEdit from "./components/popups/OperatorEdit";

const store = new OperatorsPageStore();

const OperatorsPage = () => {
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [operatorId, setOperatorId] = useState<number>(0);
  const [isOpenCreate, setIsOpenCreate] = useState(false);

  useEffect(() => {
    store.init();
    return store.reset;
  }, []);

  const handleOpenEdit = (idx: number) => {
    const id = store.operators.operators[idx].id;
    if (id) {
      setOperatorId(id);
      setIsOpenEdit(true);
    }
  };

  const handleOpenCreate = () => {
    setIsOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setIsOpenCreate(false);
  };

  return (
    <MainLayout status={false}>
      <FlexColumn gap={20}>
        <Typography variant="h1">Операторы</Typography>
        <Card>
          <FlexColumn gap={20}>
            <TopBar
              onAddButtonClick={handleOpenCreate}
              operatorsActive={store.operators.activeCount}
              operatorsTotal={store.operators.count}
              onDownload={store.downloadOperatorsReport}
            />
            <CrmTable
              isLoading={store.isLoading}
              isFullWidth
              isHoverBg
              headers={[
                { title: "Статус" },
                {
                  title: "ФИО",
                  width: 340,
                  ...store.sortStore.sortFields.get("last_name"),
                },
                {
                  title: "Номер",
                },
                {
                  title: "График",
                },
                {
                  title: "Линии",
                  width: 200,
                  ...store.sortStore.sortFields.get("call_line.short_name"),
                },
                {
                  title: "Последняя активность",
                  ...store.sortStore.sortFields.get("last_activity"),
                },
                {
                  title: "Статус",
                  ...store.sortStore.sortFields.get("status"),
                },
              ]}
              rows={store.rows.map((row, idx) => [
                {
                  text: (
                    <Avatar
                      firstName={store.operators.operators[idx]?.first_name}
                      lastName={store.operators.operators[idx]?.last_name}
                      isInactive={!store.operators.operators[idx]?.status}
                    />
                  ),
                },
                ...row,
              ])}
              onRowClick={handleOpenEdit}
            />
          </FlexColumn>
        </Card>
      </FlexColumn>
      {isOpenCreate && (
        <OperatorCreate
          isOpen={isOpenCreate}
          onClose={handleCloseCreate}
          store={store.createStore}
        />
      )}
      {isOpenEdit && (
        <OperatorEdit
          isOpen={isOpenEdit}
          operatorEditStore={store.operatorEditStore}
          id={operatorId}
          onClose={() => setIsOpenEdit(false)}
        />
      )}
    </MainLayout>
  );
};

export default observer(OperatorsPage);
