import { FlexColumn } from "@kit/kit-aiss";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import Button from "../../../../Button";
import { NewCallFormStore } from "./store";
import Select from "../../../../Select";
import TextField from "../../../../TextField";

interface INewCallFormProps {
  onSelected: (res: { clientPhone: string; callLineId: string }) => void;
}

export const NewCallForm: FC<INewCallFormProps> = observer(({ onSelected }) => {
  const [store] = useState(() => new NewCallFormStore());

  const handleSelect = async () => {
    const res = await store.getCallData();
    if (!res) return;
    onSelected(res);
  };

  return (
    <FlexColumn>
      <Select
        {...store.form.adapters.line}
        options={store.lineOptions || []}
        placeholder="Линия"
      />
      <TextField
        {...store.form.adapters.phone}
        placeholder="Номер телефона, с которого поступил звонок"
        maxLength={11}
      />
      <Button onClick={handleSelect} disabled={store.isLoadings.create}>
        Дальше
      </Button>
    </FlexColumn>
  );
});

NewCallForm.displayName = "NewCallForm";
