const HH_MM_START = 11;
const HH_MM_END = 16;

export const convertSecondsToHHMM = (seconds?: number) => {
  if (!seconds) return "00:00";
  /** округляем до минуты, мол, чтобы показывалось, что хотя бы одну минуту чел говорил
   * если разговор длился меньше минуты
   */
  if (seconds < 60) seconds = 60;

  return new Date(seconds * 1000).toISOString().slice(HH_MM_START, HH_MM_END);
};
