import { makeAutoObservable, runInAction } from "mobx";

import operatorsService from "../../../services/operators";
import { SortStore } from "../../../stores/SortStore";
import { getPersonFullName } from "../../../utils/getPersonFullName";
import { IOperator } from "../types";
import OperatorCreateStore from "../components/popups/OperatorCreate/store";
import { OperatorEditStore } from "../components/popups/OperatorEdit/store";
import { getFormattedDate } from "../../../utils/date/getFormattedDate";
import FileSaver from "file-saver";

export const SORT_ITEMS = [
  "last_name",
  "first_name",
  "middle_name",
  "phone",
  "call_line.short_name",
  "last_activity",
  "status",
] as const;

class OperatorsPageStore {
  createStore: OperatorCreateStore;
  operatorEditStore: OperatorEditStore;
  isLoading = false;

  sortStore: SortStore<typeof SORT_ITEMS[number]>;

  operators: { count: 0; activeCount: 0; operators: IOperator[] } = {
    count: 0,
    activeCount: 0,
    operators: [],
  };

  get rows() {
    return this.operators.operators.map((operator) => [
      {
        text:
          getPersonFullName(
            operator.last_name,
            operator.first_name,
            operator.middle_name
          ) ?? "-",
      },
      {
        text: operator.number ?? "-",
      },
      {
        text: operator.schedule_id.schedule,
      },
      {
        text:
          operator.call_lines.map((line) => line.short_name).join(", ") ?? "-",
      },
      {
        text:
          getFormattedDate(operator.last_activity!, "dd.MM.yyyy HH:mm") ?? "-",
      },
      {
        text: operator.status ? "Активен" : "Неактивен",
      },
    ]);
  }

  constructor() {
    makeAutoObservable(this);
    this.createStore = new OperatorCreateStore(this.loadOperators);
    this.sortStore = new SortStore([...SORT_ITEMS], this.loadOperators);
    this.operatorEditStore = new OperatorEditStore(this.loadOperators);
  }

  init = () => {
    this.loadOperators();
  };

  reset = () => {
    this.operators = { count: 0, activeCount: 0, operators: [] };
    this.createStore.reset();
  };

  loadOperators = () => {
    this.isLoading = true;
    operatorsService
      .loadOperators(this.sortStore.searchParams)
      .then((res) => {
        runInAction(() => {
          this.operators.operators = res.data.data.users || [];
          this.operators.count = res.data.data.count;
          this.operators.activeCount = res.data.data.activeCount;
        });
      })
      .catch(() => {
        this.reset();
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  downloadOperatorsReport = () => {
    operatorsService.downloadOperatorsReport().then(({ data }) => {
      const fileName = "Операторы.xlsx";
      FileSaver.saveAs(data, fileName);
    });
  };
}

export default OperatorsPageStore;
