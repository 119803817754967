import JsSIP from "jssip";
import { IncomingRTCSessionEvent, OutgoingRTCSessionEvent } from "jssip/lib/UA";
import { makeAutoObservable, runInAction } from "mobx";

import { SipSessionController } from "./SipSessionController";
import callsService from "../../services/calls";
import authStore from "../../pages/AuthPage/store/authStore";
import GlobalStatusStore from ".";
import { addInfoLogsToUA } from "./utils/infoLogs";
import { generateRandomX_UID } from "../../utils/generateXUID";
import {
  DEFAULT_CALL_CONFIG,
  SIP_SOCKET_URL,
  getSipConfigPassword,
  getSipConfigUri,
} from "./utils/sipConfig";
import { SipSupportSessionController } from "./SipSupportSessionController";

class SipControllerStore {
  ua: JsSIP.UA | null = null;
  xUid: string | null = null;
  externalQueueId: string | null = null;

  session: SipSessionController | null = null;
  supportSession: SipSupportSessionController | null = null;

  callId: number | null = null;
  callLine?: { number?: number; short_name?: string; id: number };
  reinviteTel: string | null = null;
  isRedirected = false;

  phone: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    console.log("!!!!!!! init");

    const socket = new JsSIP.WebSocketInterface(SIP_SOCKET_URL);

    const configuration = {
      register_expires: 60,
      sockets: [socket],
      uri: getSipConfigUri(authStore),
      password: getSipConfigPassword(authStore),
    };

    this.ua = new JsSIP.UA(configuration);

    addInfoLogsToUA(this.ua);

    this.ua.on(
      "newRTCSession",
      (e: IncomingRTCSessionEvent | OutgoingRTCSessionEvent) => {
        const transferID = e.request.getHeader("X-Transfer-ID");
        console.log("newRTCSession", e, transferID);

        if (transferID === "support") {
          this.supportSession = new SipSupportSessionController(e.session);
          return;
        }

        const canAcceptIncomingCall =
          !this.session &&
          !GlobalStatusStore.states.isCreateCall &&
          !GlobalStatusStore.states.isCreateAppeal;

        if (!canAcceptIncomingCall && e.originator === "remote") {
          console.log("busy, terminate incoming session");
          // 486 - busy
          e.session.terminate({ status_code: 486 });
          return;
        }

        runInAction(() => {
          this.xUid = e.request.getHeader("X-UID") || generateRandomX_UID();
          this.externalQueueId = e.request.getHeader("X-QUEUE");

          this.session = new SipSessionController(e.session);

          GlobalStatusStore.setState(
            e.originator === "remote" ? "isIncoming" : "isOutGoingRing",
            true
          );
        });
        this.onStartCall();
      }
    );

    // @ts-expect-error: x
    this.ua.on("registrationExpiring", (e: any) => {
      console.log("registrationExpiring", e);
      this.ua?.register();
    });

    this.ua.start();
  };

  onStartCall = async () => {
    const formData = new FormData();
    formData.append("phone", this.session?.remoteUserTel || "");
    formData.append("externalCallId", String(this.xUid));
    formData.append("status", "3");
    formData.append("type", this.session?.isIncomingSession ? "1" : "2");
    formData.append("externalQueueId", (this.externalQueueId as string) || "");

    console.log(
      "!!!!!!!onStartCall isSupported",
      GlobalStatusStore.states.isSupported
    );

    try {
      const res = await callsService.startCall(formData);
      this.setCallLine(res.data?.data?.call_line);
      console.log("start call res", res.data);
    } catch (error) {
      this.resetCall();
    }
  };

  onAcceptCall = () => {
    const formData = new FormData();
    formData.append("status", "1");

    try {
      return callsService.responseCall(String(this.xUid), formData);
    } catch (error) {
      this.resetCall();
    }
  };

  resetSession = () => {
    this.session = null;
    this.resetOutgoingPhone();
  };

  resetSupportSession = () => {
    this.supportSession = null;
  };

  onDeclinedCall = () => {
    const formData = new FormData();
    formData.append("status", "2");

    callsService.responseCall(String(this.xUid), formData);
  };

  setCallId = (id: number) => {
    this.callId = id;
  };

  setCallLine = (line: this["callLine"]) => {
    this.callLine = line;
  };

  setIsRedirected = (isRedirected: this["isRedirected"]) => {
    this.isRedirected = isRedirected;
  };

  reset = () => {
    console.log("reset sip store");

    this.ua?.stop();
    this.ua = null;
    this.callId = null;
    this.externalQueueId = null;
    this.xUid = null;
    this.resetCall();
    this.phone = null;
    this.reinviteTel = null;
    this.isRedirected = false;
  };

  resetOutgoingPhone = () => {
    this.phone = null;
  };

  call = (tel: string) => {
    this.phone = tel;
    this.ua?.call(tel, DEFAULT_CALL_CONFIG);
  };

  callSupport = (tel: string) => {
    this.phone = tel;
    this.ua?.call(tel, {
      ...DEFAULT_CALL_CONFIG,
      extraHeaders: ["X-Transfer-ID: support"],
    });
  };

  recall = (tel: string, externalQueueId?: string) => {
    this.phone = tel;
    this.ua?.call(tel, {
      ...DEFAULT_CALL_CONFIG,
      extraHeaders: externalQueueId ? [`X-QUEUE: ${externalQueueId}`] : [],
    });
  };

  resetCall = () => {
    console.log("resetCall");

    this.ua?.terminateSessions();
    this.phone = null;
    this.xUid = null;
    this.reinviteTel = null;
    this.session = null;
    this.callLine = undefined;
  };

  resetReinviteTel = () => {
    this.reinviteTel = null;
  };
}

const sipControllerStore = new SipControllerStore();
export default sipControllerStore;
