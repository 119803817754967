import React, { FC } from "react";
import Modal from "../../../../../components/Modal";
import { observer } from "mobx-react-lite";
import { IEditUserProps } from "./types";
import UserForm from "../UserForm";
import { FlexRow, LoaderSpinner } from "@kit/kit-aiss";
import Button from "../../../../../components/Button";

const EditUser: FC<IEditUserProps> = ({
  isOpen,
  onClose,
  EditUserStore,
  store,
}) => {
  React.useEffect(() => {
    return () => {
      EditUserStore!.reset();
    };
  }, []);

  const handleEditClick = () => {
    EditUserStore!.saveEditUser().then(() => {
      if (EditUserStore.form.form.isValid) {
        onClose();
        store.loadUsers();
      }
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Редактировать пользователя">
      {EditUserStore!.isLoading ? (
        <LoaderSpinner />
      ) : (
        <UserForm form={EditUserStore.form} EditUserStore={EditUserStore} />
      )}
      <FlexRow>
        <Button onClick={handleEditClick}>Сохранить</Button>
        <Button variant="secondary" onClick={() => onClose()}>
          Отмена
        </Button>
      </FlexRow>
    </Modal>
  );
};

export default observer(EditUser);
