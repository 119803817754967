import React, { FC } from "react";
import { ISubTheme } from "../../store/types";
import { observer } from "mobx-react";
import ButtonEditTheme from "../../../../components/ButtonEditTheme";
import cn from "classnames";

interface IProps {
  item: ISubTheme;
  openEditModal: (id: number, caption: string, deactivated: boolean) => void;
  goNextPage: (themeId: number, subThemeId: number) => void;
}

const SubThemeItem: FC<IProps> = ({ item, openEditModal, goNextPage }) => {
  return (
    <div className="topic-item">
      <span
        onClick={() => goNextPage(item.parentId, item.id)}
        className={cn("topic-item__name", item.deactivated && "deactivated")}
      >
        {item.caption}
      </span>
      <ButtonEditTheme
        onClick={() =>
          openEditModal(item.id, item.caption, Boolean(item.deactivated))
        }
      />
    </div>
  );
};

export default observer(SubThemeItem);
