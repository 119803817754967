import { FlexColumn, FlexRow } from "@kit/kit-aiss";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import Button from "../../../components/Button";
import LinesSelector from "../../../components/LinesSelector";
import Select from "../../../components/Select";
import TextField from "../../../components/TextField";
import { useOnConfirm } from "../../../hooks/useOnConfirm";
import { THEME_CATEGORIES } from "../constants";

import { ThemesStore } from "../store";
import Modal from "../../../components/Modal";

interface IAddThemePopupProps {
  isOpen: boolean;
  onClose: () => void;
  topic: string | null;
  caption: string;
  themesStore: ThemesStore;
  deactivated: boolean;
  tableType: string | undefined;
}

const AddThemePopup: React.FC<IAddThemePopupProps> = ({
  isOpen,
  onClose,
  themesStore,
  caption,
  topic,
  deactivated,
  tableType,
}) => {
  useEffect(() => {
    if (isOpen) themesStore.getEditedTheme(caption, tableType as string);
  }, [isOpen]);

  const handleSaveClick = () => {
    themesStore.checkEdit(topic)?.then(() => onClose());
  };

  const handleRemoveTheme = () => {
    themesStore.disableTheme(topic, onClose);
  };

  const handleActivateTheme = () => {
    themesStore.enableTheme(topic, onClose);
  };

  const { ModalConfirm, isShowConfirm } = useOnConfirm(
    "Вы уверены, что хотите деактивировать тему?",
    "Да, деактивировать тему",
    handleRemoveTheme
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Редактировать тему">
      <FlexColumn gap={40}>
        <FlexColumn gap={20}>
          <TextField
            {...themesStore.addThemeForm.adapters.caption}
            placeholder="Введите название"
          />
          <Select
            options={THEME_CATEGORIES}
            {...themesStore.addThemeForm.adapters.category}
            placeholder="Раздел"
          />
          {!themesStore.addThemeForm.values.category && (
            <LinesSelector linesStore={themesStore.linesStore} />
          )}
        </FlexColumn>
        <FlexRow>
          {deactivated && (
            <Button
              className="deactivate"
              type="submit"
              onClick={handleActivateTheme}
            >
              Активировать тему
            </Button>
          )}
          {!deactivated && (
            <Button
              className="deactivate"
              type="submit"
              onClick={isShowConfirm}
            >
              Деактивировать тему
            </Button>
          )}
          <Button type="submit" onClick={handleSaveClick}>
            Сохранить
          </Button>

          {ModalConfirm}
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
};

export default observer(AddThemePopup);
