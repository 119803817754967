import { makeAutoObservable, runInAction } from "mobx";
import { AppealsFormStore } from "../../Form/store";
import { getFormattedDate } from "../../../../../../utils/date/getFormattedDate";
import appealsService from "../../../../../../services/appeals";

export class EditAppealStore {
  form = new AppealsFormStore();
  loadAppeal?: () => void;
  isLoading = false;

  constructor(loadAppeal?: () => void) {
    makeAutoObservable(this);
    this.loadAppeal = loadAppeal;
  }

  checkValid = (id: number) => {
    this.form.validate();
    if (this.form.isValid()) {
      return this.editAppeal(id);
    }
  };

  editAppeal = async (id: number) => {
    this.isLoading = true;
    try {
      const formData = new FormData();
      formData.append("patientName", this.form.form.values.patientName || "");
      formData.append("clientName", this.form.form.values.clientName || "");
      formData.append("description", this.form.form.values.description || "");
      formData.append("address", this.form.form.values.address || "");
      formData.append("organization", this.form.form.values.organization || "");
      formData.append("clientPhone", this.form.form.values.clientPhone || "");
      formData.append("contactPhone", this.form.form.values.contactPhone || "");
      formData.append("cityId", this.form.form.values.cityId || "");
      formData.append(
        "urgencyCategoryId",
        this.form.form.values.urgencyCategoryId || ""
      );
      formData.append("resultText", this.form.form.values.resultText || "");
      formData.append("topicId", this.form.form.values.topic || "");
      formData.append(
        "patientBirthDate",
        getFormattedDate(
          this.form.form.values.patientBirthDate || "",
          "yyyy-MM-dd"
        ) || ""
      );
      if (
        this.form.subTopicStore &&
        this.form.subTopicStore.form.values.subTopic
      ) {
        formData.append(
          "subtopicId",
          this.form.subTopicStore.form.values.subTopic || ""
        );
      }
      if (this.form.form.values.appeal_manager_comment) {
        formData.append(
          "appealManagerComment",
          this.form.form.values.appeal_manager_comment
        );
      }
      if (this.form.form.values.operatorComment) {
        formData.append(
          "operatorComment",
          this.form.form.values.operatorComment
        );
      }

      return await appealsService.editAppeal(id, formData);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  closeAppeal = (appealId: number) => {
    return appealsService.closeAppeal(appealId);
  };

  restartAppeal = async (appealId: number) => {
    await appealsService.restartTimer(appealId).then((res) => {
      runInAction(() => {
        this.form.expires = res.data.data.expires;
      });
    });
  };
}
