import "./styles.scss";

import { Button, H2, Modal } from "@kit/kit-aiss";
import { observer } from "mobx-react-lite";

import { ReactComponent as Ring } from "../../assets/svg/ring.svg";
import sipControllerStore from "../../stores/GlobalStatusStore/SipController";
import GlobalStatusStore from "../../stores/GlobalStatusStore";

import { toJS } from "mobx";

export const SipPhoneRecall = () => {
  const handleDeclinedCall = () => {
    sipControllerStore.onDeclinedCall();
    sipControllerStore.session?.terminate();
    GlobalStatusStore.setState("isOutGoing", false);
    GlobalStatusStore.setState("isOutGoingRing", false);
  };

  console.log("sipControllerStore.sessions", toJS(sipControllerStore.session));

  return (
    <Modal
      isOpen={GlobalStatusStore.states.isOutGoingRing}
      onClose={() => null}
      className={"sip-phone"}
    >
      <div className="ring-modal">
        <Ring />
        <H2>{sipControllerStore.session?.remoteUserTel}</H2>
        <div className="ring-modal__buttons">
          <Button onClick={handleDeclinedCall} className="end">
            Отклонить
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default observer(SipPhoneRecall);
