import "./styles.scss";
import React, { useEffect } from "react";
import {
  ComposedChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  Line,
  ResponsiveContainer,
} from "recharts";
import { observer } from "mobx-react-lite";
import Typography from "../../../../../components/Typography";
import { ChartsStore } from "../../store";
import Select from "../../../../../components/Select";
import { FlexColumn, FlexRow } from "@kit/kit-aiss";

interface IStore {
  store: ChartsStore;
}

const LineCharts: React.FC<IStore> = ({ store }) => {
  let intrv: NodeJS.Timer | undefined;
  useEffect(() => {
    intrv = setInterval(() => {
      store.loadCallsLineChartData(store.value);
    }, 10000);

    store.loadCallsChartData();

    return () => {
      clearInterval(intrv);
    };
  }, []);

  return (
    <div className="line-chart">
      <div className="line-chart-text">
        <FlexRow>
          <FlexColumn>
            <Typography variant="sub-text-medium">
              От начала смены прошло
            </Typography>
            <Typography variant="h2">
              {store.callsLineChartData?.shiftTime}
            </Typography>
          </FlexColumn>
          <Select
            className="chart"
            options={store.lines}
            value={store.value}
            onChange={store.changeLine}
          />
        </FlexRow>
      </div>
      <ResponsiveContainer width="100%" height={230}>
        <ComposedChart
          width={730}
          height={250}
          data={store.callsLineChartData?.chartData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorDeclined" x1="0" y1="0" x2="0" y2="1">
              <stop stopColor="#FF5A8C" stopOpacity={0.8} />
              {/* <stop offset="95%" stopColor="#FF5A8C" stopOpacity={0} /> */}
            </linearGradient>
            <linearGradient id="colorAccepted" x1="0" y1="0" x2="0" y2="1">
              <stop stopColor="#4A66C9" />
              {/* <stop offset="54.32%" stopColor="#2D72DA" />
              <stop offset="72.03%" stopColor="#4A66C9" />
              <stop offset="104.62%" stopColor="#72598B" /> */}
            </linearGradient>
            <linearGradient id="colorAppeals" x1="0" y1="0" x2="0" y2="1">
              {/* <stop offset="-4.41%" stopColor="#4ECBC3" />
              <stop offset="68.33%" stopColor="#2798BD" /> */}
              <stop stopColor="#2899D8" />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" scale={"point"} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="declined"
            stroke="#FF5A8C"
            fillOpacity={1}
            fill="url(#colorDeclined)"
            name="Сбросы"
          />

          <Line
            type="monotone"
            dataKey="accepted"
            stroke="url(#colorAccepted)"
            fillOpacity={1}
            fill="url(#colorAccepted)"
            name="Принято"
          />
          <Line
            type="monotone"
            dataKey="appeals"
            stroke="url(#colorAppeals)"
            fillOpacity={1}
            fill="url(#colorAppeals)"
            name="С обращениями"
          />
        </ComposedChart>
      </ResponsiveContainer>
      <div className="line-chart-description">
        <div className="line-chart-description-block">
          <div className="line-chart-description-block-dot accepted" />
          <Typography variant="sub-text-medium">Принято</Typography>
        </div>
        <div className="line-chart-description-block">
          <div className="line-chart-description-block-dot grade" />
          <Typography variant="sub-text-medium">С обращениями</Typography>
        </div>
        <div className="line-chart-description-block">
          <div className="line-chart-description-block-dot declined" />
          <Typography variant="sub-text-medium">Сбросы</Typography>
        </div>
      </div>
    </div>
  );
};

export default observer(LineCharts);
