import "./styles.scss";

import { FlexRow } from "@kit/kit-aiss";
import React from "react";
import cn from "classnames";
import { ReactComponent as SortArrows } from "../../../assets/svg/sort_arrows.svg";
import Typography from "../../../components/Typography";

export interface IHeaderCellProps {
  text: string;
  width?: number;
  sortType?: "asc" | "desc";
  onSort?: () => void;
}

const HeaderCell: React.FC<IHeaderCellProps> = ({
  text,
  width,
  sortType,
  onSort,
}) => {
  return (
    <th
      className={cn("header-cell", onSort && "header-cell--clickable")}
      style={{
        width: width ? `${width}px` : undefined,
        maxWidth: width ? `${width}px` : undefined,
      }}
      onClick={onSort}
    >
      <FlexRow gap={10} className="header-cell__row">
        <Typography color="color2" variant="sub-text-medium">
          {text}
        </Typography>
        {onSort && (
          <SortArrows
            className={cn(
              "header-cell__sort-icon",
              sortType && `header-cell__sort-icon--${sortType}`
            )}
          />
        )}
      </FlexRow>
    </th>
  );
};

export default HeaderCell;
