import "./styles.scss";
import React, { FC, useEffect, useState } from "react";
import Typography from "../../../../../../../components/Typography";
import { IEditAppealModalHeadingProps } from "./types";
import { getFormattedDate } from "../../../../../../../utils/date/getFormattedDate";
import { observer } from "mobx-react-lite";
import { convertMilliseconds } from "../../../../../../../utils/time/convertMilliseconds";
import cn from "classnames";
import { Button } from "@kit/kit-aiss";
import { ReactComponent as Reload } from "../../../../../../../assets/svg/reload.svg";

const EditAppealModalHeading: FC<IEditAppealModalHeadingProps> = ({
  date,
  expires,
  isAppealManager,
  urgencyCategoryId,
  restartAppeal,
  status,
  lineName,
}) => {
  const [milliseconds, setMilliseconds] = useState(0);
  const [value, setValue] = useState<number | boolean>(0);
  let intrv: NodeJS.Timer | undefined;

  useEffect(() => {
    timerValue(urgencyCategoryId);
    const tick = (
      expires: {
        days: number;
        hours: number;
        minutes: number;
        months: number;
        seconds: number;
      } | null
    ) => {
      if (expires === null) {
        return;
      }
      const days = Math.floor(expires.days * 1000 * 60 * 60 * 24);
      const hours = Math.floor(expires.hours * 1000 * 60 * 60);
      const minutes = Math.floor(expires.minutes * 1000 * 60);
      const seconds = Math.floor(expires.seconds * 1000);
      setMilliseconds(days + hours + minutes + seconds);
      if (status !== 3) {
        intrv = setInterval(() => {
          setMilliseconds((prevTime) => prevTime - 1000);
        }, 1000);
      }
    };
    if (expires) tick(expires);

    if (status === 3) {
      clearInterval(intrv);
    }

    return () => clearInterval(intrv);
  }, [urgencyCategoryId, status]);

  const timerValue = (urgencyCategoryId: number | null | undefined) => {
    if (urgencyCategoryId) {
      switch (urgencyCategoryId) {
        case 1:
          return setValue(3);
        case 2:
          return setValue(24);
        case 3:
          return setValue(72);
        default:
          return setValue(false);
      }
    }
  };

  return (
    <div className="edit-appeal-modal-heading">
      <div className="edit-appeal-modal-heading__item">
        <Typography variant="small-txt-medium" color="color3">
          Дата/Время
        </Typography>
        <Typography variant="h1">
          {getFormattedDate(date, "dd.MM.yyyy, HH:mm")}
        </Typography>
      </div>
      <div className="edit-appeal-modal-heading__item">
        <Typography variant="small-txt-medium" color="color3">
          Линия
        </Typography>
        <Typography variant="h1">{lineName}</Typography>
      </div>
      <div
        className={cn(
          "edit-appeal-modal-heading__item",
          !milliseconds && "edit-appeal-modal-heading__item-ended"
        )}
      >
        <div className="edit-appeal-modal-heading__item-timer">
          <Typography variant="small-txt-medium" color="color3">
            Таймер срочности
          </Typography>
          <Typography
            variant="h1"
            className={cn(
              "edit-appeal-modal-heading__item-clocks",
              !milliseconds && "edit-appeal-modal-heading__item-clocks--ended",
              status === 3 && "edit-appeal-modal-heading__item--closed"
            )}
          >
            {convertMilliseconds(milliseconds)}
          </Typography>
        </div>
        {!isAppealManager && !milliseconds && (
          <Button
            // disabled={!operatorComment}
            size="small"
            onClick={restartAppeal}
          >
            <Reload />
            <span>{value} часа</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default observer(EditAppealModalHeading);
