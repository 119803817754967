import "./styles.scss";
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import MainLayout from "../../components/layouts/MainLayout";
import Typography from "../../components/Typography";
import { FlexColumn } from "@kit/kit-aiss";
import { ReportExportStore } from "./store";
import { ReactComponent as DownloadIcon } from "../../assets/svg/download-icon.svg";
import Button from "../../components/Button";
import DatePicker from "../../components/DatePicker";
import Select from "../../components/Select";

const store = new ReportExportStore();

const ReportExport = () => {
  useEffect(() => {
    store.init();

    return () => {
      store.reset();
    };
  }, []);

  const handleReportDownloadClick = (
    name: string,
    url: string,
    ext: string
  ) => {
    store.reportDownload(name, url, ext);
  };

  return (
    <MainLayout status={false}>
      <FlexColumn gap={24}>
        <div>
          <Typography variant="h1">Экспорт отчетов</Typography>
        </div>
        <div className="table-block">
          <div className="table-block-top">
            <div className="table-block-top-date">
              <DatePicker
                name="startDate"
                placeholder="Дата начала"
                value={store.form.values.startDate}
                onChange={store.form.changeValue}
                errorMessage={store.form.errors.startDate}
              />
              <DatePicker
                name="endDate"
                placeholder="Дата конца"
                value={store.form.values.endDate}
                onChange={store.form.changeValue}
                errorMessage={store.form.errors.endDate}
              />
            </div>
            <div className="table-block-top-controllers">
              <Select
                className="select"
                options={store.periods}
                name="period"
                value={store.form.values.period}
                onChange={store.changePeriodValue}
                placeholder="Период"
                isSmall
                inputBg="color6"
              />
              {/* <Button className="button" onClick={() => null}>
                <PrintIcon />
                Распечатать отчёт
              </Button> */}
            </div>
          </div>
          <div className="table-block-items">
            {store.reports?.map((report, idx) => {
              return (
                <div
                  className="report-item"
                  key={idx}
                  onClick={() => {
                    handleReportDownloadClick(
                      report.label,
                      report.url,
                      report.ext
                    );
                  }}
                >
                  <div className="report-item-block">
                    <Typography variant="title2">{report.label}</Typography>
                  </div>
                  <DownloadIcon />
                </div>
              );
            })}
          </div>
          <Button onClick={store.downloadAllReports}>Скачать все</Button>
        </div>
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(ReportExport);
