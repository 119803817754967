import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import MainLayout from "../../components/layouts/MainLayout";
import { ReportPageStore } from "./store";
import CallssReport from "./CallsReport";
import { TFilterValues } from "../../components/ReportHeader";
import { FlexColumn } from "@kit/kit-aiss";
import Typography from "../../components/Typography";
import DatePicker from "../../components/DatePicker";
import Button from "../../components/Button";
import Select from "../../components/Select";
import { ReactComponent as PrintIcon } from "../../assets/svg/print.svg";
import { ReactComponent as DownloadIcon } from "../../assets/svg/download-icon.svg";
import { useNavigate } from "react-router-dom";

const store = new ReportPageStore();

const ReportsPage: React.FC = () => {
  const navigate = useNavigate();

  const [openCalendars, setOpenCalendars] = useState(false);

  const date1 =
    localStorage.getItem("startCallDate") &&
    new Date(localStorage.getItem("startCallDate") as string);
  const date2 =
    localStorage.getItem("startCallDate") &&
    new Date(localStorage.getItem("endCallDate") as string);

  useEffect(() => {
    if (date1 && date2) {
      store.callsStore.form.changeValue(date1, "startDate");
      store.callsStore.form.changeValue(date2, "endDate");
      store.callsStore.loadReport();
    } else {
      localStorage.setItem(
        "startCallDate",
        String(store.callsStore.form.values.startDate)
      );
      localStorage.setItem(
        "endCallDate",
        String(store.callsStore.form.values.endDate)
      );
      store.init();
    }

    return () => {
      store.reset();
      localStorage.removeItem("startCallDate");
      localStorage.removeItem("endCallDate");
    };
  }, []);

  // TODO: функция для таблицы с отчетами, пригодится позже */
  const selectPeriodOfReports = (period: TFilterValues) => {
    store.downloadStore.setDatesPeriod(period);
    setOpenCalendars(!openCalendars);
  };

  const handleReportDownloadClick = () => {
    store.callsStore.downloadReport();
  };

  const handlePrintClick = () => {
    navigate(`/shifts-report-calls`);
  };

  return (
    <MainLayout status={false} bgWhite>
      <FlexColumn gap={24}>
        <div>
          <Typography variant="h1">Количество звонков</Typography>
        </div>
        <div className="table-block">
          <div className="table-block-top">
            <div className="table-block-top-date">
              <DatePicker
                name="startDate"
                placeholder="Дата начала"
                value={store.callsStore.form.values.startDate}
                onChange={store.callsStore.changeCalendarValue}
                errorMessage={store.callsStore.form.errors.startDate}
              />
              <DatePicker
                name="endDate"
                placeholder="Дата конца"
                value={store.callsStore.form.values.endDate}
                onChange={store.callsStore.changeCalendarValue}
                errorMessage={store.callsStore.form.errors.endDate}
              />
            </div>
            <div className="table-block-top-controllers">
              <Select
                className="select"
                options={store.callsStore.periods}
                name="period"
                value={store.callsStore.form.values.period}
                onChange={store.callsStore.changePeriodValue}
                placeholder="Период"
                isSmall
                inputBg="color6"
              />
              <Button
                className="button"
                onClick={() => {
                  handleReportDownloadClick();
                }}
              >
                <DownloadIcon />
                Скачать отчёт
              </Button>
              <Button className="button" onClick={handlePrintClick}>
                <PrintIcon />
                Распечатать отчёт
              </Button>
            </div>
          </div>
        </div>

        <CallssReport
          store={store.callsStore}
          onDownload={selectPeriodOfReports}
        />
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(ReportsPage);
