import React, { useEffect } from "react";
import MainLayout from "../../components/layouts/MainLayout";
import { AttributeStore } from "./store";
import AttributeBlock from "./components/AttributeBlock";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../components/Button";
import { FlexColumn, FlexRow, LoaderSpinner } from "@kit/kit-aiss";
import Typography from "../../components/Typography";
import "./styles.scss";

const store = new AttributeStore();

const Attribute = () => {
  const { themId, subtopicId, type } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    store.init(themId || "", "subTheme", subtopicId || "");
  }, []);

  return (
    <MainLayout bgWhite status={false}>
      <FlexColumn gap={20}>
        <FlexRow isNoFlexChildren className="subthemes_back">
          <Button
            onClick={() => navigate(`/topics/subtopics/${themId}/${type}`)}
            variant="inline"
          >
            <Typography color="color3">Назад к подтемам</Typography>
          </Button>
        </FlexRow>
        {store.isLoading ? (
          <LoaderSpinner />
        ) : (
          <AttributeBlock
            subThemId={subtopicId}
            themId={themId}
            theme="subTheme"
            store={store.attributeBlockStore}
          />
        )}
      </FlexColumn>
    </MainLayout>
  );
};

export default observer(Attribute);
