import "./styles.scss";
import React, { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import Select from "../../../../../components/Select";
import TextField from "../../../../../components/TextField";
import { IAppealFormProps } from "./types";
import DatePicker from "../../../../../components/DatePicker";
import TextArea from "../../../../../components/TextArea";
import { FlexRow } from "@kit/kit-aiss";
import Button from "../../../../../components/Button";
import cn from "classnames";
import ReactAudioPlayer from "react-h5-audio-player";
import { getFormattedDate } from "../../../../../utils/date/getFormattedDate";
import { DownloadLink } from "../../../../../components/DownloadLink";

const Form: FC<IAppealFormProps> = ({
  appealsFormStore,
  isEdit,
  appealId,
  isAppealManager,
  handleRecall,
}) => {
  useEffect(() => {
    appealsFormStore.init();
    if (isEdit) appealsFormStore.loadAppeal(appealId || 0);

    return () => {
      appealsFormStore.reset();
    };
  }, []);

  const [date, time] = appealsFormStore.created_at.split(" ");
  const [year, ,] = date.split("-");

  const result = getFormattedDate(date as unknown as Date, "dd MMMM");

  return (
    <div className="appeals-form">
      {!isAppealManager && isEdit && (
        <>
          <TextField
            placeholder="№ оператора"
            name="operatorNumber"
            onChange={() => null}
            value={appealsFormStore.form.values.operatorNumber}
            errorMessage={appealsFormStore.form.errors.operatorNumber}
            isDisabled
          />
          <FlexRow className="appeals-form__grade">
            <TextField
              placeholder="Оценка"
              name="callback_grade"
              onChange={() => null}
              value={appealsFormStore.form.values.callback_grade}
              errorMessage={appealsFormStore.form.errors.callback_grade}
              isDisabled
              className={cn(
                "appeals-form__grade--textfield",
                appealsFormStore.form.values.callback_grade ===
                  "Отрицательная" && "red"
              )}
            />
            {appealsFormStore.form.values.callback_grade === "Отрицательная" &&
              handleRecall && (
                <Button onClick={handleRecall}>Перезвонить</Button>
              )}
          </FlexRow>
        </>
      )}
      <Select
        placeholder="Тема обращения"
        name="topic"
        value={appealsFormStore.form.values.topic}
        onChange={(value, name) => appealsFormStore.onChangeTopic(value, name)}
        options={appealsFormStore.options.topics}
        errorMessage={appealsFormStore.form.errors.topic}
        disabled={isAppealManager}
      />
      {appealsFormStore.subTopicStore &&
        appealsFormStore.options.subTopics.length !== 0 && (
          <Select
            placeholder="Подтема обращения"
            name="subTopic"
            value={appealsFormStore.subTopicStore.form.values.subTopic}
            onChange={appealsFormStore.subTopicStore.form.changeValue}
            options={appealsFormStore.options.subTopics}
            disabled={isAppealManager}
          />
        )}
      <TextArea
        placeholder="Суть проблемы"
        name="description"
        onChange={appealsFormStore.form.changeValue}
        value={appealsFormStore.form.values.description}
        errorMessage={appealsFormStore.form.errors.description}
        isDisabled={isAppealManager}
      />
      <TextField
        placeholder="ФИО обратившегося"
        name="clientName"
        value={appealsFormStore.form.values.clientName}
        onChange={appealsFormStore.form.changeValue}
        errorMessage={appealsFormStore.form.errors.clientName}
        isDisabled={isAppealManager}
      />
      <TextField
        placeholder="ФИО пациента"
        name="patientName"
        value={appealsFormStore.form.values.patientName}
        onChange={appealsFormStore.form.changeValue}
        errorMessage={appealsFormStore.form.errors.patientName}
        isDisabled={isAppealManager}
      />
      <DatePicker
        placeholder="Дата рождения"
        name="patientBirthDate"
        value={appealsFormStore.form.values.patientBirthDate as unknown as Date}
        onChange={appealsFormStore.form.changeValue}
        errorMessage={appealsFormStore.form.errors.patientBirthDate}
        disabled={isAppealManager}
      />
      <Select
        placeholder="Город"
        name="cityId"
        value={appealsFormStore.form.values.cityId}
        onChange={appealsFormStore.form.changeValue}
        options={appealsFormStore.options.city}
        errorMessage={appealsFormStore.form.errors.cityId}
        disabled={isAppealManager}
      />
      <TextField
        placeholder="Адрес проживания"
        name="address"
        value={appealsFormStore.form.values.address}
        onChange={appealsFormStore.form.changeValue}
        errorMessage={appealsFormStore.form.errors.address}
        isDisabled={isAppealManager}
      />
      <Select
        placeholder="МО по обращению"
        name="organization"
        value={appealsFormStore.form.values.organization}
        onChange={appealsFormStore.form.changeValue}
        errorMessage={appealsFormStore.form.errors.organization}
        disabled={isAppealManager}
        options={appealsFormStore.options.medicalOrganisations}
      />
      <TextField
        placeholder="Номер телефона, с которого поступил звонок"
        name="clientPhone"
        value={appealsFormStore.form.values.clientPhone}
        onChange={appealsFormStore.form.changeValue}
        errorMessage={appealsFormStore.form.errors.clientPhone}
        isDisabled={isAppealManager}
        maxLength={11}
      />
      <TextField
        placeholder="Контактный номер для связи"
        name="contactPhone"
        value={appealsFormStore.form.values.contactPhone}
        onChange={appealsFormStore.form.changeValue}
        errorMessage={appealsFormStore.form.errors.contactPhone}
        isDisabled={isAppealManager}
        maxLength={11}
      />
      <TextArea
        placeholder="Результат звонка"
        name="resultText"
        value={appealsFormStore.form.values.resultText}
        onChange={appealsFormStore.form.changeValue}
        //!!!!TODO: не допилено
        errorMessage={appealsFormStore.form.errors.resultText}
        isDisabled={isAppealManager}
      />
      <Select
        placeholder="Категория срочности"
        name="urgencyCategoryId"
        value={appealsFormStore.form.values.urgencyCategoryId}
        onChange={appealsFormStore.form.changeValue}
        options={appealsFormStore.options.urgencyCategory}
        errorMessage={appealsFormStore.form.errors.urgencyCategoryId}
        disabled={isAppealManager}
      />

      {!isAppealManager && appealsFormStore.status?.id && (
        <TextField
          placeholder="Комментарий оператора"
          name="operatorComment"
          value={appealsFormStore.form.values.operatorComment}
          onChange={appealsFormStore.form.changeValue}
          errorMessage={appealsFormStore.form.errors.operatorComment}
        />
      )}
      {(appealsFormStore.status?.id === 2 ||
        appealsFormStore.status?.id === 3) && (
        <TextField
          placeholder={
            isAppealManager
              ? "Комментарий"
              : "Комментарий специалиста по работе с обращениями"
          }
          name="appeal_manager_comment"
          value={appealsFormStore.form.values.appeal_manager_comment}
          onChange={appealsFormStore.form.changeValue}
          errorMessage={appealsFormStore.form.errors.appeal_manager_comment}
          isDisabled={appealsFormStore.status?.id === 3}
        />
      )}
      {appealsFormStore.audio_record && (
        <div className="audio-player">
          <ReactAudioPlayer
            src={appealsFormStore.audio_record as string}
            // showSkipControls
            // showFilledProgress
            // showJumpControls
            autoPlay={false}
            autoPlayAfterSrcChange={false}
          />
          <div className="audio-title">
            <span>
              {result} {year}, {time}
            </span>
          </div>
          <DownloadLink href={appealsFormStore.audio_record} />
        </div>
      )}
    </div>
  );
};

export default observer(Form);
