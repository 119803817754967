import "./styles.scss";

import { Empty as KitEmpty } from "@kit/kit-aiss";
import { observer } from "mobx-react-lite";
import React, { ComponentPropsWithoutRef } from "react";

// todo: добавить в кит Empty className
const Empty: React.FC<ComponentPropsWithoutRef<typeof KitEmpty>> = (props) => {
  return <KitEmpty {...props} />;
};

export default observer(Empty);
