import "./styles.scss";
import React, { FC } from "react";
import Typography from "../../../../components/Typography";
import { ReactComponent as AppealsIcon } from "../../../../assets/svg/appeals.svg";
import { ReactComponent as ClockIcon } from "../../../../assets/svg/clock.svg";
import { ReactComponent as CheckMarkIcon } from "../../../../assets/svg/check-mark.svg";

interface IProps {
  all: number;
  approve: number;
  overdue: number;
  processed?: number;
}

const StatisticBlock: FC<IProps> = ({ all, approve, overdue, processed }) => {
  return (
    <div className="view-statistic-block">
      <div className="view-statistic-block__sub">
        <Typography variant="sub-text-medium" color="color2">
          Всего
        </Typography>
        <div className="view-statistic-block__sub-icon">
          <AppealsIcon />
          <Typography variant="h4" color="color1">
            {all}
          </Typography>
        </div>
      </div>
      <div className="view-statistic-block__sub">
        <Typography variant="sub-text-medium" color="color2">
          Обработано
        </Typography>
        <div className="view-statistic-block__sub-icon">
          <CheckMarkIcon />
          <Typography variant="h4" color="color1">
            {approve}
          </Typography>
        </div>
      </div>
      <div className="view-statistic-block__sub">
        <Typography variant="sub-text-medium" color="color2">
          В обработке
        </Typography>
        <div className="view-statistic-block__sub-icon">
          <ClockIcon />
          <Typography variant="h4" color="color1">
            {processed}
          </Typography>
        </div>
      </div>
      <div className="view-statistic-block__sub">
        <Typography variant="sub-text-medium" color="color2">
          Просроченных
        </Typography>
        <div className="view-statistic-block__sub-icon">
          <ClockIcon />
          <Typography variant="h4" color="color1">
            {overdue}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default StatisticBlock;
