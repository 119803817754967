import "./styles.scss";

import { observer } from "mobx-react-lite";
import React, { ComponentPropsWithoutRef } from "react";

import { ReactComponent as IconSearch } from "../../assets/svg/search.svg";
import TextField from "../TextField";

const SearchField: React.FC<ComponentPropsWithoutRef<typeof TextField>> = (
  props
) => {
  return (
    <div className="crm-search-field">
      <TextField {...props} />
      <IconSearch />
    </div>
  );
};

export default observer(SearchField);
