import { makeAutoObservable } from "mobx";
import { LinesModalFormStore } from "../../components/LinesModalForm/store";
import linesService from "../../../../../../services/lines";
import { LinesStore } from "../../../../store";

export class CreateLineModalStore {
  form = new LinesModalFormStore();
  linesStore: LinesStore;

  constructor(linesStore: LinesStore) {
    makeAutoObservable(this);
    this.linesStore = linesStore;
  }

  checkValid = () => {
    this.form.form.validate();
    if (this.form.form.isValid) {
      return this.createLine();
    }
  };

  createLine = () => {
    const formData = new FormData();
    formData.append("number", this.form.form.values.lineNumber || "");
    formData.append("shortName", this.form.form.values.shortTitle || "");
    formData.append(
      "active",
      this.form.form.values.status === "Активная" ? "true" : "false"
    );

    return linesService
      .createLine(formData)
      .then(() => this.linesStore.loadLines());
  };
}
