import "./styles.scss";

import { FlexColumn, FlexRow } from "@kit/kit-aiss";
import { observer } from "mobx-react";
import React from "react";
import Card from "../../components/Card";
import CrmTable from "../../components/CrmTable";
import MainLayout from "../../components/layouts/MainLayout";
import Typography from "../../components/Typography";
import { ReactComponent as DownloadIcon } from "../../assets/svg/download-icon.svg";
import { ReactComponent as FilterIcon } from "../../assets/svg/filter.svg";
import { ReactComponent as FilterWhiteIcon } from "../../assets/svg/filter_white.svg";
import Pagination from "../../components/Pagination";
import authStore from "../AuthPage/store/authStore";
import { monthNames, weekDays } from "../../constants/months";

import store from "./store";
import EditRecordModal from "./components";
import StatisticBlock from "../Appeals/components/StatisticBlock";
import GlobalStatusStore from "../../stores/GlobalStatusStore";
import InfiniteScroll from "react-infinite-scroll-component";
import Button from "../../components/Button";
import FilterPopup from "./FilterPopup";
import ButtonPauseCalls from "../../components/ButtonPauseCalls";
import { RecordsStatisticBlock } from "./components/RecordsStatisticBlock";

const RecordsPage: React.FC = () => {
  React.useEffect(() => {
    const type = authStore.role[0] === "operator" ? "operator" : "";
    store.init(type);

    return () => {
      store.reset();
      store.filterPopupStore.reset();
    };
  }, []);

  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [recordId, setRecordId] = React.useState<null | number>(null);
  const [isOpenFilter, setIsOpenFilterPopup] = React.useState(false);

  const handleOpenEditModal = (idx: number) => {
    const id = store.records[idx].id;

    if (id) {
      setRecordId(id);
      setIsOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    GlobalStatusStore.setState("CallFromRecord", false);
  };

  const handlePlusDate = (date: Date | null) => {
    const r = date!.setDate(date!.getDate() + 1);
    store.onChangeDate(new Date(r));
  };

  const handleMinusDate = (date: Date | null) => {
    const r = date!.setDate(date!.getDate() - 1);
    store.onChangeDate(new Date(r));
  };

  const handleChangeDate = (date: Date | null) => {
    store.onChangeDate(date);
  };

  const handleMoreLoad = () => {
    const type = authStore.role[0] === "operator" ? "operator" : "";
    store.incrementPageCount();
    store.getRecords(type);
  };

  const handleOpenFilterModal = () => {
    setIsOpenFilterPopup(true);
  };

  const handleCloseFilterModal = () => {
    setIsOpenFilterPopup(false);
  };

  const handleDownloadReports = () => {
    const operator = authStore.role[0] === "operator" ? "operator" : "";

    store.downloadRecords(operator);
  };

  return (
    <MainLayout status={GlobalStatusStore.states.paused}>
      <FlexColumn gap={35} className="records-page">
        <FlexRow className="operator-calls__header">
          <Typography variant="h1">Голосовая почта</Typography>

          {authStore.role[0] === "operator" && (
            <Pagination
              title={`${
                weekDays[
                  store.date!.getDay() - 1 > -1 ? store.date!.getDay() - 1 : 6
                ]
              }, ${store.date!.getDate()} ${
                monthNames[store.date!.getMonth()]
              }`}
              onNext={() => {
                handlePlusDate(store.date);
              }}
              onPrev={() => {
                handleMinusDate(store.date);
              }}
              date={store.date}
              onChangeDate={handleChangeDate}
              className="operator"
            />
          )}
        </FlexRow>
        {authStore.role[0] === "manager" && (
          <StatisticBlock
            negativeCount={store.statisticsBlock.negativeCount}
            approveCount={store.statisticsBlock.approveCount}
            operatorsCount={store.statisticsBlock.operatorsCount}
            rejectCount={store.statisticsBlock.rejectCount}
            appealsOverdueCount={store.statisticsBlock.appealsOverdueCount}
            appealsAcceptedCount={store.statisticsBlock.appealsAcceptedCount}
          />
        )}
        {authStore.role[0] === "operator" && (
          <FlexRow>
            <RecordsStatisticBlock
              items={[
                { type: "all", value: store.statisticsBlock.voicemail_count },
                {
                  type: "processed",
                  value: store.statisticsBlock.voicemail_processed_count,
                },
                {
                  type: "read",
                  value: store.statisticsBlock.voicemail_read_count,
                },
                {
                  type: "newRecords",
                  value: store.statisticsBlock.voicemail_new_count,
                },
              ]}
            />
            <div style={{ display: "flex", justifyContent: "end" }}>
              <ButtonPauseCalls />
            </div>
          </FlexRow>
        )}
        <Card>
          <div className="records-table-heading">
            <Typography variant="h4">
              Все сообщения за смену ({store.records?.length})
            </Typography>
            <div className="records-table-heading-icons">
              {authStore.role[0] === "manager" && (
                <>
                  {store.filterPopupStore.showReset && (
                    <Button
                      onClick={store.filterPopupStore.reset}
                      variant="secondary"
                    >
                      Сбросить фильтры
                    </Button>
                  )}
                  <Button
                    onClick={handleOpenFilterModal}
                    variant={
                      store.filterPopupStore.showReset ? "primary" : "secondary"
                    }
                    size="small"
                    className="records-table-heading-icons__button"
                  >
                    {!store.filterPopupStore.showReset && <FilterIcon />}
                    {store.filterPopupStore.showReset && <FilterWhiteIcon />}
                    <Typography
                      color={
                        store.filterPopupStore.showReset
                          ? "colorWhite"
                          : "color1"
                      }
                    >
                      Фильтры
                    </Typography>
                  </Button>
                </>
              )}
              {/* <PrintIcon /> */}
              {authStore.role.at(0) !== "operator" && (
                <>
                  <div className="records-table-heading-icons__divider" />
                  <DownloadIcon
                    className="records-table-heading__download"
                    onClick={handleDownloadReports}
                  />
                </>
              )}
            </div>
          </div>
          <InfiniteScroll
            dataLength={(store.records.length as number) || 0}
            next={handleMoreLoad}
            hasMore={true}
            loader
          >
            <CrmTable
              isLoading={store.isLoading}
              headers={[
                {
                  title: "Дата",
                  width: 200,
                  ...store.sortStore.sortFields.get("created_at"),
                },
                {
                  title: "Линия",
                  width: 200,
                  ...store.sortStore.sortFields.get("call_line_id"),
                },
                {
                  title: "Голосовое сообщение",
                  width: 400,
                },
                {
                  title: "Статус",
                  width: 150,
                  ...store.sortStore.sortFields.get("status"),
                },
                {
                  title: "Реакция",
                  width: 200,
                  ...store.sortStore.sortFields.get("reaction"),
                },
                {
                  title: "Номер",
                  width: 180,
                },
              ]}
              rows={store.rows}
              onRowClick={handleOpenEditModal}
            />
          </InfiniteScroll>
        </Card>
      </FlexColumn>
      {isOpenModal && (
        <EditRecordModal
          onClose={handleCloseModal}
          isOpen={isOpenModal}
          recordId={recordId}
          getRecords={store.getRecords}
        />
      )}
      {isOpenFilter && (
        <FilterPopup
          isOpen={isOpenFilter}
          onClose={handleCloseFilterModal}
          store={store.filterPopupStore}
        />
      )}
    </MainLayout>
  );
};

export default observer(RecordsPage);
