import {
  IncomingDTMFEvent,
  IncomingEvent,
  IncomingInfoEvent,
  OutgoingDTMFEvent,
  OutgoingEvent,
  OutgoingInfoEvent,
  PeerConnectionEvent,
  RTCSession,
} from "jssip/lib/RTCSession";
import JsSIP from "jssip";
import { IncomingMessageEvent, OutgoingMessageEvent } from "jssip/lib/UA";

//!!! здесь должны быть только логи

export const addInfoLogsToSessionEvents = (session: RTCSession) => {
  session.on("connecting", (e) => {
    console.log("connecting", e);
  });

  session.on("progress", (e: IncomingEvent | OutgoingEvent) => {
    console.log("progress", e);
  });

  session.on("newDTMF", (e: IncomingDTMFEvent | OutgoingDTMFEvent) => {
    console.log("newDTMF", e);
  });
  session.on("newInfo", (e: IncomingInfoEvent | OutgoingInfoEvent) => {
    console.log("newInfo", e);
  });
  session.on("hold", (e) => {
    console.log("hold", e);
  });
  session.on("unhold", (e) => {
    console.log("unhold", e);
  });
  session.on("muted", (e) => {
    console.log("muted", e);
  });
  session.on("unmuted", (e) => {
    console.log("unmuted", e);
  });

  session.on("update", (e) => {
    console.log("update", e);
  });
  session.on("refer", (e) => {
    console.log("refer", e);
  });
  session.on("replaces", (e) => {
    console.log("replaces", e);
  });
  session.on("sdp", (e) => {
    console.log("sdp", e);
  });
  session.on("icecandidate", () => {
    //   console.log("icecandidate", e);
  });
  session.on("getusermediafailed", (e: any) => {
    console.log("getusermediafailed", e);
  });
  session.on("peerconnection:createofferfailed", (e: any) => {
    console.log("peerconnection:createofferfailed", e);
  });
  session.on("peerconnection:createanswerfailed", (e: any) => {
    console.log("peerconnection:createanswerfailed", e);
  });
  session.on("peerconnection:setlocaldescriptionfailed", (e: any) => {
    console.log("peerconnection:setlocaldescriptionfailed", e);
  });
  session.on("peerconnection:setremotedescriptionfailed", (e: any) => {
    console.log("peerconnection:setremotedescriptionfailed", e);
  });
};

export const addInfoLogsToPeerConnectionEvent = (e: PeerConnectionEvent) => {
  console.log("peerconnection", e);
  e.peerconnection.addEventListener("connectionstatechange", () => {
    // console.log("connectionstatechange", e);
  });
  e.peerconnection.addEventListener("datachannel", (e) => {
    console.log("datachannel", e);
  });
  e.peerconnection.addEventListener("icecandidate", () => {
    // console.log("icecandidate", e);
  });
  e.peerconnection.addEventListener("icecandidateerror", (e) => {
    console.log("icecandidateerror", e);
  });
  e.peerconnection.addEventListener("iceconnectionstatechange", () => {
    // console.log("iceconnectionstatechange", e);
  });
  e.peerconnection.addEventListener("icegatheringstatechange", () => {
    // console.log("icegatheringstatechange", e);
  });
  e.peerconnection.addEventListener("negotiationneeded", (e) => {
    console.log("negotiationneeded", e);
  });
  e.peerconnection.addEventListener("signalingstatechange", (e) => {
    console.log("signalingstatechange", e);
  });
};

export const attendedReferCallInfoEventHandlers = {
  requestSucceeded: function () {
    console.log("attendedReferCall Ле сервис ин работа с сопровождением");
  },
  requestFailed: function () {
    console.log("attendedReferCall Transferencia fallo");
  },
  trying: function (e: any) {
    console.log("attendedReferCall trying", e);
  },

  progress: function (e: any) {
    console.log("attendedReferCall progress", e);
  },
  failed: function (e: any) {
    console.log("attendedReferCall failed", e);
  },
};

export const referMethodInfoEventHandlers = {
  requestSucceeded: function () {
    console.log("Ле сервис ин работа");
  },
  requestFailed: function () {
    console.log("Transferencia fallo");
  },
  trying: function (e: any) {
    console.log("trying", e);
  },
  accepted: function (e: any) {
    console.log("accepted", e);
  },
  failed: function (e: any) {
    console.log("failed", e);
  },
};

export const addInfoLogsToUA = (ua: JsSIP.UA) => {
  ua.on("connecting", (e) => {
    console.log("connecting", e);
  });

  ua.on("connected", (e) => {
    console.log("connected", e);
  });
  ua.on("disconnected", (e) => {
    console.log("disconnected", e);
  });

  ua.on("registered", (e) => {
    console.log("registered", e);
  });
  ua.on("unregistered", (e) => {
    console.log("unregistered", e);
  });
  ua.on("registrationFailed", (e) => {
    console.log("registrationFailed", e);
  });
  ua.on("newMessage", (e: IncomingMessageEvent | OutgoingMessageEvent) => {
    console.log("newMessage", e);
  });
  ua.on("sipEvent", (e) => {
    console.log("sipEvent", e);
  });
};
