import "./index.scss";

import { LoaderSpinner, WarningCard } from "@kit/kit-aiss";
import React from "react";

import { INetworkWrapperProps } from "./types";

const NetworkWrapper: React.FC<INetworkWrapperProps> = (props) => {
  const { isLoading, children, statuses } = props;

  if (isLoading) {
    return <LoaderSpinner />;
  }

  const statusMessages = {
    500: "Сервер не отвечает",
    404: "Данные не найдены",
    403: "Доступ запрещен",
    400: "Невалидная форма, заполните все данные",
  };

  //   if (statuses.includes(403)) {
  //     return (
  //       <WarningCard type="red">
  //         <h1 className="warning-card__header">Ошибка</h1>
  //         <p className="warning-card__content">Доступ запрещен</p>
  //       </WarningCard>
  //     );
  //   }

  const statusList = Object.keys(statusMessages).map(
    Number
  ) as (keyof typeof statusMessages)[];

  const filteredStatuses = statuses.filter(
    (status) =>
      status && statusList.includes(status as keyof typeof statusMessages)
  ) as (keyof typeof statusMessages)[];

  return (
    <>
      {filteredStatuses.length > 0 && (
        <WarningCard type="red" header="">
          <h1 className="warning-card__header">Ошибка</h1>
          {filteredStatuses.map((status, key) => (
            <p className="warning-card__content" key={key}>
              {statusMessages[status]}
            </p>
          ))}
        </WarningCard>
      )}
      {children}
    </>
  );
};

export default NetworkWrapper;
