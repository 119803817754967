import { FormStore } from "@kit/kit-aiss";
import { TCheckboxesValue } from "@kit/kit-aiss/dist/components/Checkboxes/index.types";
import { makeAutoObservable, runInAction } from "mobx";
import { ILine } from "../../pages/OperatorsPage/components/popups/Form/types";
import linesService from "../../services/lines";

export class LinesAccordionStore {
  isLoading = false;

  form = new FormStore<{ lines: number[] }>({ lines: [] });

  lines: ILine[] = [];
  linesFilter = "";

  constructor() {
    makeAutoObservable(this);
  }

  init = () => this.loadLines();

  reset = () => {
    this.isLoading = false;
    this.linesFilter = "";
    this.lines = [];
    this.form.reset();
  };

  get filteredLines() {
    return this.lines?.filter(
      (line) =>
        line.text
          .toLocaleLowerCase()
          .includes(this.linesFilter.toLocaleLowerCase()) && line.active
    );
  }

  get selectedLines() {
    return this.lines.filter((line) =>
      this.form.values.lines?.includes(line.value)
    );
  }

  changeLines = (value: TCheckboxesValue) => {
    const newLines = this.lines.reduce<number[]>(
      (acc, cur) =>
        this.form.values.lines?.includes(cur.value)
          ? cur.value === value
            ? acc
            : [...acc, cur.value]
          : cur.value === value
          ? [...acc, cur.value]
          : acc,
      []
    );

    this.form.changeValueByKey(newLines, "lines");
  };

  changeLinesFilter = (value: string) => {
    this.linesFilter = value;
  };

  loadLines = () => {
    this.isLoading = true;
    linesService
      .loadLines()
      .then(({ data }: any) => {
        runInAction(() => {
          this.lines =
            data?.data?.items?.map((line: any) => ({
              value: line.id,
              number: line.number,
              text: line.short_name,
              active: line.active,
            })) || [];
        });
      })
      .catch(() => {
        runInAction(() => {
          this.lines = [];
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };
}
