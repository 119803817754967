import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layouts/MainLayout";
import { observer } from "mobx-react-lite";
import Typography from "../../components/Typography";
import { CallsStore } from "./store";
import Card from "../../components/Card";
import CrmTable from "../../components/CrmTable";
import EditCallModal from "../OperatorCalls/components/popups/EditCallModal";

import { FlexColumn, FlexRow } from "@kit/kit-aiss";
import { ReactComponent as FilterIcon } from "../../assets/svg/filter.svg";
import { ReactComponent as FilterWhiteIcon } from "../../assets/svg/filter_white.svg";
import { ReactComponent as DownloadIcon } from "../../assets/svg/download-icon.svg";
import "./styles.scss";
import StatisticBlock from "../Appeals/components/StatisticBlock";
import ChartsBlock from "./ChartsBlock";
import GlobalStatusStore from "../../stores/GlobalStatusStore";
import InfiniteScroll from "react-infinite-scroll-component";
import Button from "../../components/Button";
import FilterPopup from "./FilterPopup";
import authStore from "../AuthPage/store/authStore";
import CreateEmptyCallModal from "../../components/modals/CreateEmptyCallModal";

const store = new CallsStore();

const Calls = () => {
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const [isOpenCreatePopup, setIsOpenCreatePopup] = useState(false);
  const [callInfoId, setCallInfoId] = useState<number>(0);
  const [isOpenFilter, setIsOpenFilterPopup] = React.useState(false);

  useEffect(() => {
    store.init();
    return () => {
      store.reset();
      store.filterPopupStore.reset();
    };
  }, []);

  useEffect(() => {
    GlobalStatusStore.editCallModalState.closeSubscribers.subscribe(
      store.getRingTableData
    );

    return () => {
      GlobalStatusStore.editCallModalState.closeSubscribers.unsubscribe(
        store.getRingTableData
      );
    };
  }, []);

  const handleOpenEditModal = (idx: number) => {
    const id = store.table?.calls[idx].call_info_id;
    const idd = store.table?.calls[idx].call_id;

    if (id) {
      setCallInfoId(id);
      setIsOpenEditPopup(true);
    }
    if (!id && idd) {
      GlobalStatusStore.getCallId(idd);
      GlobalStatusStore.editCallModalState.open();
    }
  };

  const handleCloseEditModal = () => {
    const editedAttr = store.editCallModalStore.form.attribute?.attributes.some(
      (item) => {
        return item.isEditAttribute === true;
      }
    );

    if (store.editCallModalStore.form.isEdit || editedAttr) {
      if (
        confirm(
          "У вас остались несохраненные изменения. Вы точно хотите закрыть модальное окно?"
        )
      ) {
        setIsOpenEditPopup(false);
      }
    } else {
      setIsOpenEditPopup(false);
    }
  };

  const handleMoreLoad = () => {
    store.incrementPageCount();
    store.getRingTableData();
  };

  const handleOpenFilterModal = () => {
    setIsOpenFilterPopup(true);
  };

  const handleCloseFilterModal = () => {
    setIsOpenFilterPopup(false);
  };

  const handleDownloadReport = () => {
    const operator = authStore.role[0] === "operator" ? "operator" : "";

    store.getRingsReport(operator);
  };

  const handleCreateCall = () => {
    setIsOpenCreatePopup(true);
  };

  const handleCloseCreateCall = (isSuccess?: boolean) => {
    setIsOpenCreatePopup(false);
    if (isSuccess) store.getRingTableData();
  };

  return (
    <MainLayout status={false}>
      <FlexColumn gap={35} className="calls-page">
        <FlexRow isNoFlexChildren className="calls-page__header">
          <Typography variant="h1">Звонки</Typography>
          <Button onClick={handleCreateCall}>Создать новый звонок</Button>
        </FlexRow>
        <StatisticBlock
          negativeCount={store.statisticsBlock.negativeCount}
          approveCount={store.statisticsBlock.approveCount}
          operatorsCount={store.statisticsBlock.operatorsCount}
          rejectCount={store.statisticsBlock.rejectCount}
          appealsOverdueCount={store.statisticsBlock.appealsOverdueCount}
          appealsAcceptedCount={store.statisticsBlock.appealsAcceptedCount}
        />
        <ChartsBlock />
        <Card>
          <div className="calls-table-heading">
            <Typography variant="h4">
              Все звонки за смену ({store.table?.count})
            </Typography>
            <div className="calls-table-heading-icons">
              {store.filterPopupStore.showReset && (
                <Button
                  onClick={store.filterPopupStore.reset}
                  variant="secondary"
                >
                  Сбросить фильтры
                </Button>
              )}
              <Button
                onClick={handleOpenFilterModal}
                variant={
                  store.filterPopupStore.showReset ? "primary" : "secondary"
                }
                size="small"
                className="calls-table-heading-icons__button"
              >
                {!store.filterPopupStore.showReset && <FilterIcon />}
                {store.filterPopupStore.showReset && <FilterWhiteIcon />}
                <Typography
                  color={
                    store.filterPopupStore.showReset ? "colorWhite" : "color1"
                  }
                >
                  Фильтры
                </Typography>
              </Button>
              {/* <PrintIcon /> */}
              <div className="calls-table-heading-icons__divider" />
              <DownloadIcon
                className="calls-table-heading__download"
                onClick={handleDownloadReport}
              />
            </div>
          </div>
          <InfiniteScroll
            dataLength={(store.table?.calls.length as number) || 0}
            next={handleMoreLoad}
            hasMore={true}
            loader
          >
            <CrmTable
              isLoading={store.isLoading}
              isHoverBg
              isFullWidth
              headers={[
                {
                  title: "Дата",
                  width: 180,
                  ...store.sortStore.sortFields.get("created_at"),
                },
                {
                  title: "Оператор №",
                  width: 150,
                  ...store.sortStore.sortFields.get("responsible_user_number"),
                },
                {
                  title: "Линия",
                },
                {
                  title: "Тема звонка",
                  width: 400,
                  ...store.sortStore.sortFields.get("topic_title"),
                },
                {
                  title: "Статус",
                  width: 150,
                  ...store.sortStore.sortFields.get("status"),
                },
                {
                  title: "Реакция",
                },
                {
                  title: "Номер",
                  width: 150,
                },
              ]}
              rows={store.rows}
              onRowClick={handleOpenEditModal}
            />
          </InfiniteScroll>
        </Card>
      </FlexColumn>

      {isOpenEditPopup && (
        <EditCallModal
          editCallModalStore={store.editCallModalStore}
          isOpen={isOpenEditPopup}
          id={callInfoId}
          onClose={handleCloseEditModal}
        />
      )}
      {isOpenCreatePopup && (
        <CreateEmptyCallModal
          store={store.createEmptyCallModalStore}
          isOpen={isOpenCreatePopup}
          onClose={handleCloseCreateCall}
          onCloseAndAndGoAppeal={handleCloseCreateCall}
        />
      )}

      {isOpenFilter && (
        <FilterPopup
          isOpen={isOpenFilter}
          onClose={handleCloseFilterModal}
          store={store.filterPopupStore}
        />
      )}
    </MainLayout>
  );
};

export default observer(Calls);
