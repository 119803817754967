import "./styles.scss";
import React, { FC } from "react";
import { FlexRow } from "@kit/kit-aiss";
import LinesModalForm from "../components/LinesModalForm";
import { CreateLineModalStore } from "./store";
import { observer } from "mobx-react-lite";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";

interface ICreateLineModalProps {
  onClose: () => void;
  isOpen: boolean;
  store: CreateLineModalStore;
}

const CreateLineModal: FC<ICreateLineModalProps> = ({
  store,
  onClose,
  isOpen,
}) => {
  const createLine = () => {
    store.checkValid()?.then(() => onClose());
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Добавить новую линию">
      <div className="create-line-modal">
        <LinesModalForm store={store.form} />
        <FlexRow>
          <Button type="submit" onClick={createLine}>
            Сохранить
          </Button>
        </FlexRow>
      </div>
    </Modal>
  );
};

export default observer(CreateLineModal);
