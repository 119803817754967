import styles from "./styles.module.scss";
import React, { FC } from "react";
import Typography from "../../../../components/Typography";
import { ReactComponent as AppealsIcon } from "../../../../assets/svg/appeals.svg";
import { ReactComponent as ClockIcon } from "../../../../assets/svg/clock.svg";
import { ReactComponent as CheckMarkIcon } from "../../../../assets/svg/check-mark.svg";
import { ReactComponent as HeadPhonesIcon } from "../../../../assets/svg/headPhones.svg";
import { observer } from "mobx-react-lite";

export interface IRecordsStatisticBlockProps {
  items: { type: "all" | "processed" | "read" | "newRecords"; value: number }[];
}

const RECORDS_STATISTIC_ITEMS = [
  { name: "Всего", type: "all", icon: <AppealsIcon /> },
  { name: "Обработано", type: "processed", icon: <CheckMarkIcon /> },
  { name: "Прослушано", type: "read", icon: <HeadPhonesIcon /> },
  { name: "Новое", type: "newRecords", icon: <ClockIcon /> },
] as const;

export const RecordsStatisticBlock: FC<IRecordsStatisticBlockProps> = observer(
  ({ items }) => {
    return (
      <div className={styles.recordsStatisticBlock}>
        {items.map((item, idx) => {
          const statisticItem = RECORDS_STATISTIC_ITEMS.find(
            (statisticItem) => statisticItem.type === item.type
          );
          if (!statisticItem) return;
          return (
            <div className={styles.item} key={idx}>
              <Typography variant="sub-text-medium" color="color2">
                {statisticItem.name}
              </Typography>
              <div className={styles.body}>
                {statisticItem.icon}
                <Typography variant="h4" color="color1">
                  {item.value}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
);

RecordsStatisticBlock.displayName = "RecordsStatisticBlock";
