import * as yup from "yup";
import { VALIDATOR_STRING_REQUIRED } from "../../../../../../validation/validators";

export const validationSchema = yup.object({
  firstName: VALIDATOR_STRING_REQUIRED,
  middleName: yup.string().nullable().notRequired(),
  lastName: VALIDATOR_STRING_REQUIRED,
  status: yup.string().nullable().notRequired(),
  organizationName: VALIDATOR_STRING_REQUIRED,
  login: yup.string().nullable().notRequired(),
  password: yup.string().nullable().notRequired(),
  phone: yup.string().nullable().notRequired(),
  email: yup.string().nullable().notRequired(),
  role: VALIDATOR_STRING_REQUIRED,
});
