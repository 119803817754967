import { FormStore } from "@kit/kit-aiss";
import { formatISO } from "date-fns";
import FileSaver from "file-saver";
import { makeAutoObservable, runInAction } from "mobx";

import reportService from "../../../../services/reports";
import { SortStore } from "../../../../stores/SortStore";
import { reportSchema } from "../../store/reportSchema";

// заменить когда сделают бэк
const SORT_ITEMS = [
  "themeCalls",
  "countCalls",
  "accepteCalls",
  "missedCalls",
  "averageWaitToCallTime",
  "averageCallTime",
  "sumAllTalks",
] as const;

class CallsReportStore {
  //   rows: ICrmTableProps["rows"] = [];
  sortStore: SortStore<typeof SORT_ITEMS[number]>;

  form = new FormStore(
    {
      startDate: new Date(),
      endDate: new Date(),
      period: null,
    },
    reportSchema
  );
  isLoading = false;

  table: {
    avg_duration_time: string;
    avg_waiting_time: string;
    discard_calls_count: number;
    success_calls_count: number;
    sum_duration_time: string;
    topic_id: number;
    topic_title: string;
    total_count: number;
  }[] = [];

  periods = [
    { text: "За день", value: "day" },
    { text: "За неделю", value: "week" },
    { text: "За месяц", value: "month" },
    { text: "За год", value: "year" },
  ];

  constructor() {
    makeAutoObservable(this);
    this.sortStore = new SortStore([...SORT_ITEMS], this.loadReport);
  }

  init = () => {
    this.loadReport();
  };

  changePeriodValue = (value: string) => {
    this.form.changeValue(value, "period");
    const now = new Date();
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const daysInMonth = new Date(year, month, 0).getDate();

    switch (value) {
      case "day":
        this.form.changeValue(new Date(), "startDate");
        this.form.changeValue(new Date(), "endDate");
        localStorage.setItem(
          "startCallDate",
          String(this.form.values.startDate)
        );
        localStorage.setItem("endCallDate", String(this.form.values.endDate));
        localStorage.setItem("callPeriod", value);
        break;

      case "week":
        now.setDate(now.getDate() - 7);
        this.form.changeValue(now, "startDate");
        this.form.changeValue(new Date(), "endDate");
        localStorage.setItem(
          "startCallDate",
          String(this.form.values.startDate)
        );
        localStorage.setItem("endCallDate", String(this.form.values.endDate));
        localStorage.setItem("callPeriod", value);
        break;

      case "month":
        now.setDate(now.getDate() - daysInMonth);
        this.form.changeValue(now, "startDate");
        this.form.changeValue(new Date(), "endDate");
        localStorage.setItem(
          "startCallDate",
          String(this.form.values.startDate)
        );
        localStorage.setItem("endCallDate", String(this.form.values.endDate));
        localStorage.setItem("callPeriod", value);
        break;

      case "year":
        now.setDate(now.getDate() - 365);
        this.form.changeValue(now, "startDate");
        this.form.changeValue(new Date(), "endDate");
        localStorage.setItem(
          "startCallDate",
          String(this.form.values.startDate)
        );
        localStorage.setItem("endCallDate", String(this.form.values.endDate));
        localStorage.setItem("callPeriod", value);
        break;
    }
    this.loadReport();
  };

  changeCalendarValue = (value: Date | null, name?: string) => {
    this.form.changeValue(value, name);

    if (name === "startDate") {
      localStorage.setItem("startCallDate", String(this.form.values.startDate));
    } else if (name === "endDate") {
      localStorage.setItem("endCallDate", String(this.form.values.endDate));
    }

    this.loadReport();
  };

  downloadReport = async () => {
    this.form.validate();

    if (!this.form.isValid) return;

    const startDate = formatISO(
      new Date(String(this.form.values.startDate))
    ).split("T");
    const endDate = formatISO(new Date(String(this.form.values.endDate))).split(
      "T"
    );

    await reportService
      .loadCallReportExport(this.form.values)
      .then((response) => {
        const fileName = `Количество звонков ${startDate[0]} ${endDate[0]}.xlsx`;

        FileSaver.saveAs(response.data, fileName);
      });
  };

  loadReport = async () => {
    runInAction(() => {
      this.isLoading = true;
    });
    await reportService
      .loadCallsReport(this.form.values)
      .then((res) => {
        const { data } = res.data;
        runInAction(() => {
          this.table = data;
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  reset = () => {
    this.form.reset();
    this.isLoading = false;
  };

  get rows() {
    return this.table.map((call) => [
      {
        text: call.topic_title,
      },
      { text: call.total_count },
      {
        text: call.success_calls_count,
      },
      { text: call.discard_calls_count },
      {
        text: call.avg_waiting_time,
      },
      {
        text: call.avg_duration_time,
      },
      {
        text: call.sum_duration_time,
      },
    ]);
  }
}

export default CallsReportStore;
