import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layouts/MainLayout";
import { observer } from "mobx-react-lite";
import Typography from "../../components/Typography";
import StatisticBlock from "./components/StatisticBlock";
import { AppealsStore } from "./store";
import EditAppealModal from "./components/popups/EditAppealModal";
import CrmTable from "../../components/CrmTable";
import Card from "../../components/Card";
import { FlexColumn } from "@kit/kit-aiss";
import { ReactComponent as DownloadIcon } from "../../assets/svg/download-icon.svg";
import { ReactComponent as FilterIcon } from "../../assets/svg/filter.svg";
import { ReactComponent as FilterWhiteIcon } from "../../assets/svg/filter_white.svg";
import "./styles.scss";
import ChartsBlock from "./components/ChartsBlock";
import GlobalStatusStore from "../../stores/GlobalStatusStore";
import InfiniteScroll from "react-infinite-scroll-component";
import Button from "../../components/Button";
import FilterPopup from "./FilterPopup";

const store = new AppealsStore();

const Appeals = () => {
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [isOpenFilter, setIsOpenFilterPopup] = React.useState(false);

  useEffect(() => {
    store.loadAppeals();

    return () => {
      store.reset();
      store.filterPopupStore.reset();
    };
  }, []);

  const handleOpenEdit = (idx: number) => {
    const id = store.appeals.appeals[idx]?.id;
    if (id) {
      setId(id);
      setIsOpenEdit(true);
    }
  };

  const handleCloseEdit = () => {
    setIsOpenEdit(false);
    GlobalStatusStore.resetClientInfo();
  };

  const handleMoreLoad = () => {
    store.incrementPageCount();
    store.loadAppeals();
  };

  const handleOpenFilterModal = () => {
    setIsOpenFilterPopup(true);
  };

  const handleCloseFilterModal = () => {
    setIsOpenFilterPopup(false);
  };

  return (
    <MainLayout status={false}>
      <div>
        <Typography variant="h1">Обращения</Typography>
      </div>
      <FlexColumn gap={20}>
        <StatisticBlock
          negativeCount={store.statisticsBlock.negativeCount}
          approveCount={store.statisticsBlock.approveCount}
          operatorsCount={store.statisticsBlock.operatorsCount}
          rejectCount={store.statisticsBlock.rejectCount}
          appealsOverdueCount={store.statisticsBlock.appealsOverdueCount}
          appealsAcceptedCount={store.statisticsBlock.appealsAcceptedCount}
        />
        <ChartsBlock />

        <Card>
          <div className="appeals-table-heading">
            <Typography variant="h4">
              Все обращения ({store.appeals.count})
            </Typography>
            <div className="appeals-table-heading-icons">
              {/* <PrintIcon /> */}
              {store.filterPopupStore.showReset && (
                <Button
                  onClick={store.filterPopupStore.reset}
                  variant="secondary"
                >
                  Сбросить фильтры
                </Button>
              )}
              <Button
                onClick={handleOpenFilterModal}
                variant={
                  store.filterPopupStore.showReset ? "primary" : "secondary"
                }
                size="small"
                className="appeals-table-heading-icons__button"
              >
                {!store.filterPopupStore.showReset && <FilterIcon />}
                {store.filterPopupStore.showReset && <FilterWhiteIcon />}
                <Typography
                  color={
                    store.filterPopupStore.showReset ? "colorWhite" : "color1"
                  }
                >
                  Фильтры
                </Typography>
              </Button>
              <div className="appeals-table-heading-icons__divider" />
              <DownloadIcon
                className="appeals-table-heading__download"
                onClick={store.getRingsReport}
              />
            </div>
          </div>
          <InfiniteScroll
            dataLength={(store.appeals.appeals.length as number) || 0}
            next={handleMoreLoad}
            hasMore={true}
            loader
          >
            <CrmTable
              isLoading={store.isLoading}
              isHoverBg
              isFullWidth
              headers={[
                {
                  title: "Дата",
                  width: 200,
                  ...store.sortStore.sortFields.get("created_at"),
                },
                {
                  title: "Оператор №",
                  width: 200,
                  ...store.sortStore.sortFields.get("responsible_user_number"),
                },
                {
                  title: "Линия",
                  width: 200,
                  ...store.sortStore.sortFields.get("call_line_id"),
                },
                {
                  title: "Тема обращения",
                  ...store.sortStore.sortFields.get("topic_title"),
                },
                {
                  title: "Статус",
                  width: 80,
                },
                {
                  title: "Оценка",
                },
                {
                  title: "Номер",
                },
              ]}
              rows={store.rows}
              onRowClick={handleOpenEdit}
            />
          </InfiniteScroll>
        </Card>
      </FlexColumn>
      {isOpenEdit && (
        <EditAppealModal
          editAppealStore={store.editAppealStore}
          appealId={id || 0}
          isOpen={isOpenEdit}
          onClose={handleCloseEdit}
        />
      )}
      {isOpenFilter && (
        <FilterPopup
          isOpen={isOpenFilter}
          onClose={handleCloseFilterModal}
          store={store.filterPopupStore}
        />
      )}
    </MainLayout>
  );
};

export default observer(Appeals);
