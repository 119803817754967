import "./styles.scss";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import MainLayout from "../../components/layouts/MainLayout";
import { SubThemesStore } from "./store";
import { useNavigate, useParams } from "react-router-dom";
import SubThemeItem from "./components/SubThemeItem";
import { Button, FlexColumn, LoaderSpinner } from "@kit/kit-aiss";
import CreateSubThemeModal from "./components/CreateSubThemeModal";
import { ReactComponent as PlusIcon } from "../../assets/svg/add.svg";
import EditSubThemeModal from "./components/EditSubThemeModal";
import AttributeBlock from "../Attribute/components/AttributeBlock";
import { URL_THEMES } from "../../constants/urls";

const store = new SubThemesStore();

const SubThemesPage = () => {
  const navigate = useNavigate();
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [editedCaption, setEditedCaption] = useState("");
  const [deactivateStatus, setDeactivateStatus] = useState(false);
  const [editId, setEditId] = useState<null | number>(null);
  const { themId, type } = useParams();

  useEffect(() => {
    store.init(themId || "", "theme", type as string);
  }, []);

  const goBack = () => {
    navigate("/" + URL_THEMES);
  };

  const goSubtopic = (themeId: number, subThemeId: number) => {
    navigate(`/topics/topic/${themeId}/subtopic/${subThemeId}/${type}`);
  };

  const openCreateModal = () => {
    setIsOpenCreateModal(true);
  };

  const closeCreateModal = () => {
    setIsOpenCreateModal(false);
  };

  const openEditModal = (id: number, caption: string, deactivated: boolean) => {
    setEditId(id);
    setEditedCaption(caption);
    setIsOpenEditModal(true);
    setDeactivateStatus(deactivated);
  };

  const closeEditModal = () => {
    setIsOpenEditModal(false);
  };

  return (
    <MainLayout bgWhite status={false}>
      <div className="sub-themes-page-heading">
        <div className="sub-themes-page-heading__back" onClick={goBack}>
          {/* <ArrowIcon /> */}
          <span className="sub-themes-page-heading__text">
            Назад к списку тем
          </span>
        </div>
        <Button variant="secondary" type="submit" onClick={openCreateModal}>
          <PlusIcon />
          Добавить новую подтему
        </Button>
      </div>
      {store.isLoading ? (
        <LoaderSpinner />
      ) : (
        <>
          <FlexColumn gap={20}>
            {store.subThemes.map((item, idx) => {
              return (
                <SubThemeItem
                  goNextPage={goSubtopic}
                  openEditModal={openEditModal}
                  item={item}
                  key={idx}
                />
              );
            })}
          </FlexColumn>

          {store.subThemes.length === 0 && (
            <AttributeBlock
              themId={themId}
              theme="theme"
              store={store.attributeBlockStore}
            />
          )}
        </>
      )}
      {isOpenCreateModal && (
        <CreateSubThemeModal
          loadSubThemas={store.loadSubThemas}
          themId={themId || ""}
          isOpen={isOpenCreateModal}
          onClose={closeCreateModal}
        />
      )}
      {isOpenEditModal && (
        <EditSubThemeModal
          loadSubThemas={store.loadSubThemas}
          item={editedCaption}
          themId={themId || ""}
          id={editId || 0}
          isOpen={isOpenEditModal}
          onClose={closeEditModal}
          deactivated={deactivateStatus}
          tableType={type}
        />
      )}
    </MainLayout>
  );
};

export default observer(SubThemesPage);
