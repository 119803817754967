import "./styles.scss";
import React, { FC } from "react";
import Typography from "../../../../components/Typography";
import { ReactComponent as NegativeIcon } from "../../../../assets/svg/negative.svg";
import { ReactComponent as OverdueIcon } from "../../../../assets/svg/overdue.svg";
import { ReactComponent as HeadPhonesIcon } from "../../../../assets/svg/headPhones.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/svg/phone.svg";
import { ReactComponent as NoPhoneIcon } from "../../../../assets/svg/no_phone.svg";
import { ReactComponent as AppealsIcon } from "../../../../assets/svg/appeals.svg";
import { IStatisticBlockProps } from "./types";

const StatisticBlock: FC<IStatisticBlockProps> = ({
  negativeCount,
  operatorsCount,
  approveCount,
  rejectCount,
  appealsAcceptedCount,
  appealsOverdueCount,
}) => {
  return (
    <div className="statistic-block">
      <div className="statistic-block__item">
        <Typography variant="sub-text-medium" color="color2">
          Операторов на линиях
        </Typography>
        <div className="statistic-block__item-count">
          <HeadPhonesIcon />
          <Typography variant="h4">{operatorsCount}</Typography>
        </div>
      </div>
      <div className="statistic-block__item">
        <Typography variant="sub-text-medium" color="color2">
          Принято звонков
        </Typography>
        <div className="statistic-block__item-count">
          <PhoneIcon />
          <Typography variant="h4">{approveCount}</Typography>
        </div>
      </div>
      <div className="statistic-block__item">
        <Typography variant="sub-text-medium" color="color2">
          Сбросов
        </Typography>
        <div className="statistic-block__item-count">
          <NoPhoneIcon />
          <Typography variant="h4">{rejectCount}</Typography>
        </div>
      </div>
      <div className="statistic-block__item">
        <Typography variant="sub-text-medium" color="color2">
          Обращений принято
        </Typography>
        <div className="statistic-block__item-count">
          <AppealsIcon />
          <Typography variant="h4">{appealsAcceptedCount}</Typography>
        </div>
      </div>
      <div className="statistic-block__item">
        <Typography variant="sub-text-medium" color="color2">
          Обращений просрочено
        </Typography>
        <div className="statistic-block__item-count">
          <OverdueIcon />
          <Typography variant="h4">{appealsOverdueCount}</Typography>
        </div>
      </div>
      <div className="statistic-block__item">
        <Typography variant="sub-text-medium" color="color2">
          Негативные оценки
        </Typography>
        <div className="statistic-block__item-count">
          <NegativeIcon />
          <Typography variant="h4">{negativeCount}</Typography>
        </div>
      </div>
    </div>
  );
};

export default StatisticBlock;
