import "./styles.scss";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { IPaginationProps } from "./types";
import Typography from "../Typography";
import { ReactComponent as ArrowIcon } from "../../assets/svg/smallArrow.svg";
import DatePickerIcon from "../DatePickerIcon";
import cn from "classnames";

const Pagination: FC<IPaginationProps> = ({
  onNext,
  onPrev,
  title,
  date,
  onChangeDate,
  className,
}) => {
  return (
    // <div className={cn(`pagination`, className)}>
    //   <div className="pagination__block">
    //     <div className="pagination__block-icon" onClick={onPrev}>
    //       <ArrowIcon style={{ transform: "rotate(-90deg)" }} />
    //     </div>
    //     <div className="pagination__block-container">
    //       <Typography className="pagination__block-container--text">
    //         {title}
    //       </Typography>
    //       <DatePickerIcon value={date} onChange={onChangeDate} />
    //     </div>
    //     <div className="pagination__block-icon" onClick={onNext}>
    //       <ArrowIcon style={{ transform: "rotate(90deg)" }} />
    //     </div>
    //   </div>
    // </div>

    <div className="pagination">
      {className === "manager" && (
        <>
          <div className={cn("pagination__block", className)}>
            <div
              className={cn("pagination__block-icon", className)}
              onClick={onPrev}
            >
              <ArrowIcon style={{ transform: "rotate(-90deg)" }} />
            </div>
            <Typography variant="h1">{title}</Typography>
            <div
              className={cn("pagination__block-icon", className)}
              onClick={onNext}
            >
              <ArrowIcon style={{ transform: "rotate(90deg)" }} />
            </div>
          </div>
          <div className="pagination__divider" />
          <DatePickerIcon value={date} onChange={onChangeDate} />
        </>
      )}
      {className === "operator" && (
        <>
          <div className={cn("pagination__block", className)}>
            <div
              className={cn("pagination__block-icon", className)}
              onClick={onPrev}
            >
              <ArrowIcon style={{ transform: "rotate(-90deg)" }} />
            </div>
            <div className="pagination__block-container">
              <Typography className="pagination__block-container--text">
                {title}
              </Typography>
              <DatePickerIcon value={date} onChange={onChangeDate} />
            </div>
            <div
              className={cn("pagination__block-icon", className)}
              onClick={onNext}
            >
              <ArrowIcon style={{ transform: "rotate(90deg)" }} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(Pagination);
