import { formatISO } from "date-fns";
import api from "../../api";

const loadOperators = (params?: URLSearchParams) => {
  return api.get(`/api/user`, { params });
};

const loadOperator = (id: number) => {
  return api.get(`/api/user/operator/${id}`);
};

const getOperatorNumber = () => {
  return api.get(`/api/user/new-number`);
};

const createOperator = (formData: FormData) => {
  return api.post(`/api/user/operator`, formData);
};

const editOperator = (formData: FormData, id: number) => {
  return api.post(`/api/user/operator/${id}`, formData);
};

const changeCallState = (state: boolean, id: number) => {
  return !state
    ? api.post(`/api/user/operator/${id}/disable`)
    : api.post(`/api/user/operator/${id}/enable`);
};

const getSideBarLinks = (date?: Date) => {
  const commonDate = date ? new Date(date) : new Date();
  const parsedDate = formatISO(commonDate).split("T");
  return api.get(`api/elements/sidebar?date=${parsedDate[0]}`);
};

const logOut = () => {
  return api.post(`/api/auth/logout`);
};

const downloadOperatorsReport = () => {
  return api.get("api/user/operators/download", { responseType: "blob" });
};

const operatorsService = {
  loadOperators,
  loadOperator,
  createOperator,
  editOperator,
  changeCallState,
  getOperatorNumber,
  getSideBarLinks,
  logOut,
  downloadOperatorsReport,
};

export default operatorsService;
