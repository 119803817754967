import { makeAutoObservable, runInAction } from "mobx";
import { formatISO } from "date-fns";
import { SortStore } from "../../../stores/SortStore";
import recordsService from "../../../services/records";
import shiftService from "../../../services/shifts";
import { FilterPopupStore } from "../FilterPopup/store";
import FileSaver from "file-saver";
import { getFormattedDate } from "../../../utils/date/getFormattedDate";
import { INIT_RECORDS_STATISTIC } from "../constants";

export const RINGS_SORT_ITEMS = [
  "created_at",
  "call_line_id",
  "status",
  "reaction",
] as const;

class RecordStore {
  sortStore: SortStore<typeof RINGS_SORT_ITEMS[number]>;
  records: {
    recorded_at: string;
    call_line: any;
    name: string;
    status: { id: number; label: string };
    reaction: string;
    client_phone: string;
    call_info_id: number | null;
    appeal_id: number | null;
    id: number | null;
  }[] = [];

  statisticsBlock = { ...INIT_RECORDS_STATISTIC };

  isLoading = false;
  date: Date | null = new Date();
  filterPopupStore: FilterPopupStore;
  pageCount = 1;

  constructor() {
    this.sortStore = new SortStore([...RINGS_SORT_ITEMS], this.getRecords);
    makeAutoObservable(this);
    this.filterPopupStore = new FilterPopupStore(this.getRecords);
  }

  init = (type?: string) => {
    if (type?.length) {
      this.getRecords(type);
    } else {
      this.getRecords();
    }
  };

  onChangeDate = (date: Date | null) => {
    this.date = date;
    this.getRecords("operator");
  };

  getRecords = (type?: string) => {
    runInAction(() => {
      this.isLoading = true;
    });

    const date =
      this.date === null
        ? formatISO(new Date()).split("T")
        : formatISO(this.date).split("T");

    const startDate = this.filterPopupStore.form.values.startDate
      ? formatISO(this.filterPopupStore.form.values.startDate).split("T")[0]
      : "";
    const endDate = this.filterPopupStore.form.values.endDate
      ? formatISO(this.filterPopupStore.form.values.endDate).split("T")[0]
      : "";

    recordsService
      .loadRecords(
        this.sortStore.searchParams,
        1,
        type ? (date[0] as unknown as string) : "",
        this.pageCount * 20,
        !type ? startDate : "",
        !type ? endDate : "",
        this.filterPopupStore.form.values.line as unknown as number,
        this.filterPopupStore.form.values.operator as unknown as number,
        this.filterPopupStore.form.values.status as unknown as number
      )
      .then((res) => {
        const { data } = res.data;

        runInAction(() => {
          this.records = data;
        });
      })
      .finally(() => {
        runInAction(() => (this.isLoading = false));
      });

    shiftService.loadShiftsStatistics(date[0]).then(({ data }) => {
      runInAction(() => {
        const statistics = data?.data;
        if (!statistics) {
          this.statisticsBlock = { ...INIT_RECORDS_STATISTIC };
          return;
        }

        this.statisticsBlock = {
          negativeCount: statistics.appeals_negative_grade_count || 0,
          approveCount: statistics.accepted_calls_count || 0,
          operatorsCount: statistics.operators_count || 0,
          rejectCount: statistics.declined_calls_count || 0,
          appealsOverdueCount: statistics.overdue_appeals_count || 0,
          appealsAcceptedCount: statistics.appeals_count || 0,
          voicemail_count: statistics.voicemail_count || 0,
          voicemail_new_count: statistics.voicemail_new_count || 0,
          voicemail_processed_count: statistics.voicemail_processed_count || 0,
          voicemail_read_count: statistics.voicemail_read_count || 0,
        };
      });
    });
  };

  reset = () => {
    this.records = [];
    this.filterPopupStore.reset();
    this.date = new Date();
    this.pageCount = 1;
    this.statisticsBlock = { ...INIT_RECORDS_STATISTIC };
  };

  incrementPageCount = () => {
    this.pageCount += 1;
  };

  resetCountOsSortClick = () => {
    this.records = [];
    this.pageCount = 1;
  };

  downloadRecords = (type?: string) => {
    const date =
      this.date === null
        ? formatISO(new Date()).split("T")
        : formatISO(this.date).split("T");

    const startDate = this.filterPopupStore.form.values.startDate
      ? formatISO(this.filterPopupStore.form.values.startDate).split("T")[0]
      : "";
    const endDate = this.filterPopupStore.form.values.endDate
      ? formatISO(this.filterPopupStore.form.values.endDate).split("T")[0]
      : "";

    recordsService
      .downloadRecordsStat(
        this.sortStore.searchParams,
        1,
        type ? (date[0] as unknown as string) : "",
        this.pageCount * 20,
        !type ? startDate : "",
        !type ? endDate : "",
        this.filterPopupStore.form.values.line as unknown as number,
        this.filterPopupStore.form.values.operator as unknown as number,
        this.filterPopupStore.form.values.status as unknown as number
      )
      .then((res) => {
        const fileName = `Записи.xlsx`;
        FileSaver.saveAs(res.data, fileName);
      });
  };

  get rows() {
    return this.records.map((record) => [
      {
        text: getFormattedDate(record.recorded_at, "dd.MM.yyyy HH:mm"),
      },
      {
        text: record.call_line?.short_name || "-",
      },
      { text: record.name || "-" },
      {
        text: record.status.label || "-",
      },
      {
        text:
          (record.appeal_id && "Обращение") ||
          (record.call_info_id && "Перезвон") ||
          "-",
      },
      {
        text: record.client_phone,
      },
    ]);
  }
}

export default new RecordStore();
