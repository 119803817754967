import { makeAutoObservable } from "mobx";
import { FormStore } from "@kit/kit-aiss";
import { object, string } from "yup";
import subThemasService from "../../../../../services/subthemas";

const schema = object({
  topic: string()
    .typeError("Введите название подтемы")
    .required("Введите название подтемы"),
});

export class CreateSubThemeModalStore {
  fields: FieldStore[] = [];
  id = "";

  constructor() {
    makeAutoObservable(this);
  }

  init = (themId: string) => {
    this.id = themId;
    this.addField();
  };

  reset = () => {
    this.fields = [];
  };

  addField = () => {
    this.fields.push(new FieldStore());
  };

  deleteField = (idx: number) => {
    this.fields.splice(idx, 1);
  };

  onCreate = () => {
    this.fields.map((field) => field.form.validate());
    if (this.fields.some((field) => field.form.isValid === true)) {
      return this.createSubTheme();
    }
  };

  createSubTheme = () => {
    const requests = this.fields.map((field) => {
      const formData = new FormData();
      formData.append("title", field.form.values.topic || "");
      return subThemasService.createSubTheme(this.id, formData);
    });

    return Promise.all(requests);
  };
}

class FieldStore {
  form = new FormStore({ topic: null }, schema);

  constructor() {
    makeAutoObservable(this);
  }
}
