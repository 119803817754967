import { makeAutoObservable, runInAction } from "mobx";

import api from "../../../api";
import { URL_AUTH, URL_ME } from "../../../constants/urls";
import operatorsService from "../../../services/operators";
import { FormStore } from "../../../stores/FormStore";
import GlobalStatusStore from "../../../stores/GlobalStatusStore";
import sipControllerStore from "../../../stores/GlobalStatusStore/SipController";
import { LOGIN_ERROR_TEXT, LOGIN_ERROR_TEXT_UNKNOWN } from "./constants";
import { ISipUser } from "./types";
import { authValidateSchema } from "./validate";

class AuthStore {
  isLoading = false;
  isLogoutLoading = false;
  status: number | null = null;
  isAuth = false;
  errors: string[] = [];
  role: string[] = [];
  user: ISipUser = {
    first_name: "",
    last_name: "",
    middle_name: "",
    position: "",
    number: 0,
    id: null,
  };

  sideBar: {
    name: string;
    value: string;
    count: number;
    children?: { name: string; value: string; count: number }[];
  }[] = [
    {
      name: "",
      value: "",
      count: 0,
      children: [],
    },
  ];

  form = new FormStore(
    {
      login: "",
      password: "",
    },
    authValidateSchema
  );

  constructor() {
    makeAutoObservable(this);
  }

  checkAuth = () => {
    if (localStorage.getItem("token")) {
      this.isAuth = true;
    } else {
      this.isAuth = false;
    }
  };

  fetchAuth = async () => {
    this.status = null;
    this.isAuth = false;

    if (!this.form.isValid) {
      this.form.validate();
      return;
    }

    this.isLoading = true;

    const formData = new FormData();
    formData.append("login", this.form.values.login);
    formData.append("password", this.form.values.password);

    try {
      const res = await api.post(URL_AUTH, formData);
      const errors = res.data?.errors;

      // todo: переделать когда бэк будет возвращать нормальные коды ошибок
      runInAction(() => {
        if (
          res.status === 200 &&
          res.data.data?.access_token &&
          res.data.data?.refresh_token
        ) {
          this.getSideBarLinks();
          const { access_token, refresh_token } = res.data.data;

          localStorage.setItem("token", access_token);
          localStorage.setItem("refresh", refresh_token);

          this.isAuth = true;
        } else if (res.status === 200 && Array.isArray(errors)) {
          const errorText = errors.join(", ");
          this.form.setError(errorText, "login");
          this.form.setError(errorText, "password");
        } else if (
          res.status === 200 &&
          typeof res.data === "string" &&
          res.data.includes("NotFoundHttpException")
        ) {
          this.form.setError(LOGIN_ERROR_TEXT, "login");
          this.form.setError(LOGIN_ERROR_TEXT, "password");
        } else {
          this.form.setError(LOGIN_ERROR_TEXT_UNKNOWN, "login");
          this.form.setError(LOGIN_ERROR_TEXT_UNKNOWN, "password");
        }
        this.status = res.status;
      });
    } catch (_) {
      runInAction(() => {
        this.form.setError(LOGIN_ERROR_TEXT_UNKNOWN, "login");
        this.form.setError(LOGIN_ERROR_TEXT_UNKNOWN, "password");
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  getSignin = async () => {
    const res = await api.get(URL_ME);

    const { data, status } = res.data;

    if (status === "success") {
      runInAction(() => {
        this.user = data;
        this.role = data.role;
        GlobalStatusStore.setState("paused", Boolean(data.disabled));
      });
    } else {
      operatorsService.logOut();
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      sipControllerStore.ua?.terminateSessions();
      window.location.reload();
    }
  };

  getSideBarLinks = async (date?: Date) => {
    await operatorsService.getSideBarLinks(date).then((res) => {
      const { data } = res.data;

      if (!Array.isArray(data)) return;

      runInAction(() => {
        // todo: удалить когда добавят апи для отчетов
        if (Array.isArray(data)) {
          this.sideBar = data;

          const reports = this.sideBar.find(
            (item) => item.value === "report-summary"
          );
          if (reports) {
            reports.children = [
              { name: "Количество звонков", value: "report-calls", count: 0 },
              {
                name: "Рабочее время",
                value: "report-operator-work-time",
                count: 0,
              },
              {
                name: "Экспорт отчетов",
                value: "report-export",
                count: 0,
              },
            ];
            return;
          }
        }

        this.sideBar = data;
      });
    });
  };

  logOut = async () => {
    this.isLogoutLoading = true;
    try {
      await operatorsService.logOut();
    } finally {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      sipControllerStore.ua?.terminateSessions();
      this.resetStore();
      runInAction(() => {
        this.isAuth = false;
        this.isLogoutLoading = false;
      });
    }
    window.location.replace(`/${URL_AUTH}`);
  };

  resetStore = () => {
    this.form.reset();
    this.isLoading = false;
    this.isLogoutLoading = false;
    this.errors = [];
    this.role = [];

    this.user = {
      first_name: "",
      last_name: "",
      middle_name: "",
      position: "",
      number: 0,
      id: null,
    };
    this.user.user_asterisk_credentials = undefined;

    this.sideBar = [
      {
        name: "",
        value: "",
        count: 0,
        children: [],
      },
    ];
  };
}

export type TAuthStore = AuthStore;
export default new AuthStore();
