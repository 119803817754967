import { makeAutoObservable, runInAction } from "mobx";
import { OperatorFormStore } from "../../Form/store";
import operatorsService from "../../../../../../services/operators";

export class OperatorEditStore {
  isLoading = false;
  errorText = "";
  operatorFormStore: OperatorFormStore = new OperatorFormStore(true);
  loadOperators: () => void;

  constructor(loadOperators: () => void) {
    makeAutoObservable(this);
    this.loadOperators = loadOperators;
  }

  saveForm = (id: number) => {
    if (!this.operatorFormStore.form.isValid) {
      this.operatorFormStore.form.validate();
      return;
    }

    const formData = new FormData();

    const [lastName, firstName, middleName] =
      this.operatorFormStore.form.values.name?.split(" ") || [];

    formData.append("firstName", firstName ?? "");
    if (middleName) {
      formData.append("middleName", middleName);
    }
    formData.append("lastName", lastName ?? "");
    formData.append("number", this.operatorFormStore.form.values.number ?? "");
    formData.append("login", this.operatorFormStore.form.values.login ?? "");
    formData.append(
      "password",
      this.operatorFormStore.form.values.password ?? ""
    );
    formData.append(
      "scheduleId",
      this.operatorFormStore.form.values.graph ?? ""
    );
    formData.append(
      "status",
      this.operatorFormStore.form.values.status ? "1" : "0"
    );
    formData.append(
      "phone",
      this.operatorFormStore.form.values.phone_number ?? ""
    );

    this.operatorFormStore.linesStore.form.values.lines?.forEach((line) => {
      formData.append("lines[]", String(line));
    });

    this.isLoading = true;
    this.resetError();

    return operatorsService
      .editOperator(formData, id)
      .then((res: any) => {
        if (res.data.status === "success") {
          return true;
        }
        runInAction(() => {
          this.errorText = "Не удалось создать оператора";
        });
      })
      .catch(() => {
        runInAction(() => {
          this.errorText = "Не удалось создать оператора";
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  resetError = () => {
    this.errorText = "";
  };
}
