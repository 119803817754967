import { makeAutoObservable, runInAction } from "mobx";
import { FormStore, VALIDATOR_STRING_REQUIRED } from "@kit/kit-aiss";
import subThemasService from "../../../../../../services/subthemas";
import themasService from "../../../../../../services/themas";
import { ISelectOption } from "@kit/kit-aiss/dist/components/Select/index.types";
import appealsService from "../../../../../../services/appeals";
import { object, string } from "yup";
import { CreateAppealStore } from "../../CreateAppealModal/store";
import { STORAGE_KEY_CREATE_APPEAL } from "../../../../../../constants/storage";
import { stringToNumber } from "../../../../../../utils/stringToNumber";
import GlobalStatusStore from "../../../../../../stores/GlobalStatusStore";

const defaultValues = {
  topic: "",
  patientName: "",
  clientName: null,
  description: "",
  address: "",
  organization: "",
  clientPhone: null,
  contactPhone: "",
  urgencyCategoryId: "",
  patientBirthDate: "",
  cityId: "",
  appeal_manager_comment: null,
  operatorComment: null,
  operatorNumber: null,
  callback_grade: null,
  resultText: "",
};

const appealSchema = object({
  topic: VALIDATOR_STRING_REQUIRED,
  patientName: string()
    .required("Необходимо указать ФИО")
    .typeError("Необходимо указать ФИО"),
  clientName: string().nullable().notRequired(),
  description: string()
    .required("Укажите суть проблемы")
    .typeError("Укажите суть проблемы"),
  address: string().required("Укажите адрес").typeError("Укажите адрес"),
  organization: string()
    .required("Необходимо выбрать организацию")
    .typeError("Необходимо выбрать организацию"),
  clientPhone: string().nullable().notRequired(),
  contactPhone: string()
    .required("Укажите контактный номер")
    .typeError("Укажите контактный номер"),
  urgencyCategoryId: VALIDATOR_STRING_REQUIRED,
  patientBirthDate: string()
    .required("Необходимо указать дату")
    .typeError("Необходимо указать дату"),
  resultText: string()
    .required("Необходимо заполнить поле")
    .typeError("Необходимо заполнить поле")
    .typeError("Необходимо указать дату"),
  cityId: string().required("Необходимо заполнить поле"),
  appeal_manager_comment: string().nullable().notRequired(),
  operatorComment: string().nullable().notRequired(),
  operatorNumber: string().nullable().notRequired(),
  callback_grade: string().nullable().notRequired(),
});

export interface IExpires {
  days: number;
  hours: number;
  minutes: number;
  months: number;
  seconds: number;
}

export class AppealsFormStore {
  form = new FormStore(defaultValues, appealSchema);
  subTopicStore: SubTopicStore | null = null;
  createDate: string | null = null;
  expires: IExpires | null = null;
  status: { id: number; label: string } | null = null;
  expiredAt: string | null = null;
  call_id: null | string = null;
  audio_record: string | null = null;
  created_at = "";
  options: {
    topics: ISelectOption[];
    subTopics: ISelectOption[];
    urgencyCategory: ISelectOption[];
    city: ISelectOption[];
    medicalOrganisations: ISelectOption[];
  } = {
    topics: [],
    subTopics: [],
    urgencyCategory: [],
    city: [],
    medicalOrganisations: [],
  };

  call: {
    data: string | null;
    first_name: string | null;
    last_name: string | null;
    id: number | null;
    type: string;
    middle_name: string | null;
  } = {
    data: null,
    first_name: null,
    last_name: null,
    id: null,
    type: "",
    middle_name: null,
  };

  type?: "create";
  createAppealStore?: CreateAppealStore;
  change_log: any = [];
  lineName = "";

  constructor(type?: "create", createAppealStore?: CreateAppealStore) {
    makeAutoObservable(this);
    this.type = type;
    this.createAppealStore = createAppealStore;
  }

  init = async () => {
    await this.loadTopics();
    await this.loadOrganizations();

    this.loadForm().then(() => {
      if (
        this.type === "create" &&
        localStorage.getItem(STORAGE_KEY_CREATE_APPEAL)
      ) {
        this.loadAppealFromStorage().then(() => {
          this.createAppealStore?.storageSync.startSync();
        });
      } else if (this.type === "create") {
        this.createAppealStore?.storageSync.startSync();
      }
    });
  };

  reset = () => {
    this.form.reset();
    this.subTopicStore?.form.reset();
    this.expires = null;
    this.status = null;
    this.expiredAt = null;
    this.call_id = null;
    this.change_log = [];
    this.audio_record = null;
    this.created_at = "";
  };

  validate = () => {
    this.form.validate();
  };

  isValid = () => {
    return this.form.isValid;
  };

  loadAppeal = (id: number) => {
    appealsService.loadAppeal(id).then(({ data }) => {
      runInAction(() => {
        const appealData = data?.data;
        this.call_id = appealData?.call_info ? appealData?.call_info.id : null;
        this.audio_record = appealData?.audio_record;
        this.created_at = appealData?.call_info?.call
          ? appealData?.call_info?.call?.created_at
          : "";
        this.change_log = appealData?.change_log;
        this.onChangeTopic(
          appealData?.topic_id,
          "topic",
          appealData?.subtopic_id
        );
        this.form.changeValue(
          appealData?.responsible_user?.number,
          "operatorNumber"
        );
        this.form.changeValue(
          appealData?.callback_grade?.label,
          "callback_grade"
        );
        this.form.changeValue(appealData?.patient_name, "patientName");
        this.form.changeValue(appealData?.client_name, "clientName");
        this.form.changeValue(appealData?.description, "description");
        this.form.changeValue(appealData?.address, "address");
        this.form.changeValue(appealData?.organization, "organization");
        this.form.changeValue(appealData?.client_phone, "clientPhone");
        this.form.changeValue(appealData?.contact_phone, "contactPhone");
        this.form.changeValue(appealData?.result_text, "resultText");
        this.form.changeValue(
          appealData?.appeal_manager_comment,
          "appeal_manager_comment"
        );
        this.form.changeValue(appealData?.operator_comment, "operatorComment");
        this.form.changeValue(
          appealData?.urgency_category_id,
          "urgencyCategoryId"
        );
        this.form.changeValue(
          new Date(appealData?.patient_birth_date),
          "patientBirthDate"
        );

        this.form.changeValue(appealData?.city_id, "cityId");
        this.createDate = appealData?.created_at;
        this.expires = appealData?.expires;
        this.status = appealData?.status;
        this.expiredAt = appealData?.expired_at;
        this.call.data = appealData?.created_at;
        this.call.first_name = appealData?.responsible_user?.first_name;
        this.call.last_name = appealData?.responsible_user?.last_name;
        this.call.middle_name = appealData?.responsible_user?.middle_name;
        this.call.id = appealData?.call_info?.id;
        this.call.type = "Звонок";
        this.lineName = appealData?.call_line?.short_name;
        GlobalStatusStore.setClientInfo(appealData);
      });
    });
  };

  loadAppealFromStorage = async () => {
    const savedFormString = localStorage.getItem(STORAGE_KEY_CREATE_APPEAL);
    if (!savedFormString) return;

    const savedForm = JSON.parse(savedFormString);

    const patientBirthDate = savedForm.patientBirthDate
      ? new Date(savedForm.patientBirthDate)
      : null;

    this.form.setValues({
      topic: stringToNumber(savedForm.topicId),
      patientName: savedForm.patientName,
      clientName: savedForm.clientName,
      description: savedForm.description,
      address: savedForm.address,
      organization: savedForm.organization,
      clientPhone: savedForm.clientPhone,
      contactPhone: savedForm.contactPhone,
      urgencyCategoryId: stringToNumber(savedForm.urgencyCategoryId),
      patientBirthDate,
      cityId: stringToNumber(savedForm.cityId),
    });

    if (savedForm.topicId) {
      const subtopicId = stringToNumber(savedForm.subtopicId);

      await this.changeSubtopic(subtopicId || undefined);
    }

    this.createDate = savedForm.created_at;
    this.expires = savedForm.expires;
  };

  loadForm = () => {
    return appealsService
      .loadCreateForm()
      .then(({ data }) => {
        const urgencyCategoryOptions = data.data.fields.find(
          (field: any) => field.name === "urgencyCategoryId"
        ).options;
        const cityOptions = data.data.fields.find(
          (field: any) => field.name === "cityId"
        ).options;
        runInAction(() => {
          this.options.urgencyCategory = urgencyCategoryOptions.map(
            (option: any) => ({
              text: option.label,
              value: option.value,
            })
          );
          this.options.city = cityOptions.map((option: any) => ({
            text: option.label,
            value: option.value,
          }));

          return true;
        });
      })
      .then(() => {
        console.log(
          "GlobalStatusStore.client_info?.city_id",
          GlobalStatusStore.client_info?.city_id
        );

        this.form.setValues({
          patientName: GlobalStatusStore.client_info?.patient_full_name,
          clientName: GlobalStatusStore.client_info?.client_full_name,
          organization: GlobalStatusStore.client_info?.med_organization,
          clientPhone: GlobalStatusStore.client_info?.client_phone,
          cityId: GlobalStatusStore.client_info?.city_id,
        });
      });
  };

  loadTopics = () => {
    return themasService.loadThemas("appeals", true).then(({ data }) => {
      runInAction(() => {
        this.options.topics =
          data.data
            ?.filter((item: any) => !item.deactivated_at)
            .map((topic: any) => ({
              text: topic.title,
              value: topic.id,
            })) || [];
      });
    });
  };

  loadOrganizations = async () => {
    return await themasService.loadOrganizations().then(({ data }) => {
      runInAction(() => {
        this.options.medicalOrganisations = data.data.map((item: any) => {
          const obj = { text: "", value: "" };

          obj.text = item;
          obj.value = item;

          return obj;
        });
      });
    });
  };

  changeSubtopic = (id?: number) => {
    if (!this.form.values.topic) return;

    return subThemasService
      .loadSubThemas(this.form.values.topic)
      .then(({ data }) => {
        runInAction(() => {
          this.subTopicStore = new SubTopicStore(id);
          this.options.subTopics =
            data.data
              ?.filter((item: any) => !item.deactivated_at)
              .map((subTopic: any) => ({
                text: subTopic.title,
                value: subTopic.id,
              })) || [];
        });
      });
  };

  onChangeTopic = (value: string, name?: string, subtopicId?: number) => {
    this.form.adapters.topic.onChange(value, name);
    if (subtopicId) {
      this.changeSubtopic(subtopicId);
    } else this.changeSubtopic();
  };

  takeToWorkStatus = async (id: number) => {
    await appealsService.takeToWorkStatus(id).then(() => this.loadAppeal(id));
  };

  toProcessedStatus = async (id: number) => {
    // await appealsService.toProcessedStatus(id).then(() => this.loadAppeal(id));
    await appealsService.toProcessedStatus(id);
  };
}

class SubTopicStore {
  form = new FormStore({ subTopic: null });

  constructor(subTopicValue?: number) {
    makeAutoObservable(this);
    if (subTopicValue) {
      this.form.changeValue(subTopicValue, "subTopic");
    }
  }
}
