import "./styles.scss";

import { Button, H2, Modal } from "@kit/kit-aiss";
import { observer } from "mobx-react-lite";
import { FC } from "react";

import { ReactComponent as Ring } from "../../assets/svg/ring.svg";
import sipControllerStore from "../../stores/GlobalStatusStore/SipController";

import GlobalStatusStore from "../../stores/GlobalStatusStore";
import ReactAudioPlayer from "react-h5-audio-player";
import { toJS } from "mobx";

const source = require("../../assets/sounds/ring.mp3");

export const Sip: FC<{
  isOpen: boolean;
}> = observer(({ isOpen }) => {
  const handleApplyCall = () => {
    sipControllerStore.session?.answer();
    GlobalStatusStore.setState("isAnswered", true);
    GlobalStatusStore.setState("isCreateCall", true);
    GlobalStatusStore.setState("isIncoming", false);
  };

  const handleDeclinedCall = () => {
    sipControllerStore.onDeclinedCall();
    GlobalStatusStore.setState("isAnswered", false);
    GlobalStatusStore.setState("isIncoming", false);
    sipControllerStore.session?.terminate();
    sipControllerStore.resetReinviteTel();
  };

  const lineName = sipControllerStore.session?.isIncomingSession
    ? sipControllerStore.callLine?.short_name ||
      (sipControllerStore.callLine?.number &&
        `Линия №${sipControllerStore.callLine?.number}`) ||
      "Линия не определена"
    : sipControllerStore.session?.remoteUserTel;

  console.log(
    "SIP",
    { isOpen },
    toJS(sipControllerStore.callLine),
    sipControllerStore.session?.isIncomingSession,
    lineName
  );
  return (
    <Modal isOpen={isOpen} onClose={() => null} className={"sip-phone"}>
      <ReactAudioPlayer
        src={source}
        //   showSkipControls
        //   showFilledProgress
        //   showJumpControls
        autoPlay
        loop
      />
      <div className="ring-modal">
        <Ring />
        <H2>{lineName}</H2>
        <div className="ring-modal__buttons">
          <Button onClick={handleDeclinedCall} className="end">
            Отклонить
          </Button>
          {sipControllerStore.session?.isIncomingSession && (
            <Button onClick={handleApplyCall}>Ответить</Button>
          )}
        </div>
      </div>
    </Modal>
  );
});

export default Sip;
