import { makeAutoObservable } from "mobx";
import Subscribers from "../Subscribers";

class ModalStateStore {
  isOpen = false;

  successSubscribers = new Subscribers();
  closeSubscribers = new Subscribers();

  constructor() {
    makeAutoObservable(this);
  }

  close = (isSuccess?: boolean) => {
    this.isOpen = false;
    if (isSuccess) {
      this.successSubscribers.notifyAll();
    }
    this.closeSubscribers.notifyAll();
  };

  open = () => {
    this.isOpen = true;
  };
}

export default ModalStateStore;
