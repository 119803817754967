import { makeAutoObservable, toJS } from "mobx";
import authStore from "../../pages/AuthPage/store/authStore";
import operatorsService from "../../services/operators";
import shiftService from "../../services/shifts";
import ModalStateStore from "./ModalStateStore";
import sipControllerStore from "./SipController";

export type IStateType =
  | "paused"
  | "isAnswered"
  | "isIncoming"
  | "isCreateCall"
  | "isCreateAppeal"
  | "isOutGoing"
  | "isOutGoingRing"
  | "isInProgress"
  | "CallFromRecord"
  | "isSupported";
class GlobalStatusStore {
  //   paused = false;
  //   called = false;
  clientPhone: string | null = null;
  opened: Record<number, boolean> = {};
  callInfoId: number | null = null;
  callId: number | null = null;
  appealId: number | null = null;
  recordId: number | null = null;
  //   recall = false;
  srcCallId: null | number = null;
  //   notEnded = false;
  //   outgoing = false;
  /** для обращения */
  client_info: {
    client_full_name: null | string;
    client_phone: null | string;
    patient_full_name: null | string;
    med_organization: null | string;
    city_id: null | number;
    id: number | null;
  } = {
    client_full_name: null,
    client_phone: null,
    patient_full_name: null,
    med_organization: null,
    city_id: null,
    id: null,
  };

  states = {
    paused: false,
    isAnswered: false,
    isIncoming: false,
    isCreateCall: false,
    isCreateAppeal: false,
    isOutGoing: false,
    isOutGoingRing: false,
    isInProgress: false,
    CallFromRecord: false,
    isSupported: false,
  };

  createCallModalState = new ModalStateStore();
  createAppealModalState = new ModalStateStore();
  editCallModalState = new ModalStateStore();
  editAppealModalState = new ModalStateStore();

  timer = 0;

  constructor() {
    makeAutoObservable(this);
  }

  openMenu = (idx: number) => {
    this.opened[idx] = true;
  };

  toggleMenu = (idx: number) => {
    this.opened[idx] = !this.opened[idx];
  };

  closeMenu = (idx: number) => {
    this.opened[idx] = false;
  };

  setOpened = (opened: Record<number, boolean>) => {
    this.opened = opened;
  };

  startTimer = () => {
    this.timer += 1;

    if (this.timer === 14400) {
      authStore.logOut();
    }
  };

  checkOnline = () => {
    shiftService.userOnline();
  };

  stopTimer = () => {
    this.timer = 0;
  };

  changeStatus = async () => {
    await operatorsService.changeCallState(
      this.states.paused,
      authStore.user.id as number
    );
  };

  endCall = () => {
    this.srcCallId = null;
    this.callInfoId = null;
  };

  resetCallId = () => {
    this.callId = null;
    this.callInfoId = null;
  };

  getClientPhone = (phone: string | null) => {
    this.clientPhone = phone;
  };

  getCallInfoId = (id: number) => {
    this.callInfoId = id;
  };

  getCallId = (id: number) => {
    this.callId = id;
  };

  setAppealId = (id: number) => {
    this.appealId = id;
  };

  setState = (type: IStateType, state: boolean) => {
    this.states[type] = state;

    console.log("setState", toJS(this.states));
  };

  reset = () => {
    this.callInfoId = null;
    this.callId = null;
    this.appealId = null;
    this.srcCallId = null;
    this.states = {
      paused: false,
      isAnswered: false,
      isIncoming: false,
      isCreateCall: false,
      isCreateAppeal: false,
      isOutGoing: false,
      isOutGoingRing: false,
      isInProgress: false,
      CallFromRecord: false,
      isSupported: false,
    };
  };

  setClientInfo = (client: any) => {
    this.client_info.city_id = client.city_id || "";
    this.client_info.client_full_name = client.client_name;
    this.client_info.client_phone = client.client_phone;
    this.client_info.med_organization = client.organization;
    this.client_info.patient_full_name = client.patient_name;
    this.client_info.id = client.id;
  };

  setCallRecordId = (id: number | null) => {
    this.recordId = id;
  };

  resetClientInfo = () => {
    this.client_info = {
      client_full_name: null,
      client_phone: null,
      patient_full_name: null,
      med_organization: null,
      city_id: null,
      id: null,
    };
  };

  pauseCallsTemporally = () => {
    console.log("pauseCallsTemporally", toJS(this.states));

    if (this.states.isAnswered) return;

    sipControllerStore.ua?.stop();
    console.log("start 5 second pause");
    setTimeout(() => {
      console.log("finish 5 second pause");
      sipControllerStore.ua?.start();
    }, 5000);
  };
}

export default new GlobalStatusStore();
