import { FlexColumn } from "@kit/kit-aiss";
import { observer } from "mobx-react-lite";
import React from "react";

import Card from "../../../components/Card";
import CrmTable from "../../../components/CrmTable";
import { IReportHeaderProps } from "../../../components/ReportHeader";
import OperatorsReportStore from "./store";
import "./style.scss";

export interface IOperatorsReportProps {
  store: OperatorsReportStore;
  onDownload: IReportHeaderProps["onDownload"];
}

const CallssReport: React.FC<IOperatorsReportProps> = ({ store }) => {
  return (
    <Card>
      <FlexColumn gap={20}>
        {/* <ReportHeader
          title="Отчет по звонкам"
          onDownload={onDownload}
          onPrint={() => null}
        /> */}
        <CrmTable
          isLoading={store.isLoading}
          className="report-calls"
          isEvenBg
          headers={[
            {
              title: "Тематика звонка",
              width: 200,
              ...store.sortStore.sortFields.get("themeCalls"),
            },
            {
              title: "Количество поступивших звонков",
              ...store.sortStore.sortFields.get("countCalls"),
            },
            {
              title: "Количество принятых звонков",
              ...store.sortStore.sortFields.get("accepteCalls"),
            },
            {
              title: "Количество потерянных звонков",
              ...store.sortStore.sortFields.get("missedCalls"),
            },
            {
              title: "Среднее время ожидания ответа оператора",
              ...store.sortStore.sortFields.get("averageWaitToCallTime"),
            },
            {
              title: "Средняя продолжит. разговора",
              ...store.sortStore.sortFields.get("averageCallTime"),
            },
            {
              title: "Общая длительность разговоров",
              ...store.sortStore.sortFields.get("sumAllTalks"),
            },
          ]}
          rows={store.rows}
        />
      </FlexColumn>
    </Card>
  );
};

export default observer(CallssReport);
