import { formatISO } from "date-fns";
import FileSaver from "file-saver";
import { makeAutoObservable, runInAction } from "mobx";

import { ICrmTableProps } from "../../../../components/CrmTable";
import reportService from "../../../../services/reports";
import { FormStore } from "../../../../stores/FormStore";
import { SortStore } from "../../../../stores/SortStore";
import { getFormattedDate } from "../../../../utils/date/getFormattedDate";
import { ITableString } from "../../store/types";
import { reportSchema } from "./reportSchema";
import { convertSecondsToHHMM } from "../../../../utils/time/convertSecodnsToHHMM";

// заменить когда сделают бэк
const SORT_ITEMS = [
  "date",
  "fio",
  "timeOpen",
  "timeClose",
  "timeBreak",
  "timeOnLine",
  "acceptedCalls",
  "missedCalls",
  "averageCallTime",
] as const;

class OperatorWorkTimeReportStore {
  rows: ICrmTableProps["rows"] = [];
  sortStore: SortStore<typeof SORT_ITEMS[number]>;

  table: ITableString[] | null = [];

  form = new FormStore(
    {
      startDate: new Date(),
      endDate: new Date(),
      period: null,
    },
    reportSchema
  );
  isLoading = false;

  periods = [
    { text: "За день", value: "day" },
    { text: "За неделю", value: "week" },
    { text: "За месяц", value: "month" },
    { text: "За год", value: "year" },
  ];

  constructor() {
    makeAutoObservable(this);
    this.sortStore = new SortStore([...SORT_ITEMS], this.loadReport);
  }

  init = () => {
    this.loadReport();
  };

  loadReport = () => {
    runInAction(() => {
      this.isLoading = true;
    });

    reportService
      .loadtWorkTimeReport(this.form.values)
      .then((res) => {
        const { data } = res.data;

        runInAction(() => {
          this.table = data;
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  changeCalendarValue = (value: Date | null, name?: string) => {
    this.form.changeValue(value, name);

    if (name === "startDate") {
      localStorage.setItem("startWorkDate", String(this.form.values.startDate));
    }
    if (name === "endDate") {
      localStorage.setItem("endWorkDate", String(this.form.values.endDate));
    }

    this.loadReport();
  };

  changePeriodValue = (value: string) => {
    this.form.changeValue(value, "period");
    const now = new Date();
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const daysInMonth = new Date(year, month, 0).getDate();

    switch (value) {
      case "day":
        this.form.changeValue(new Date(), "startDate");
        this.form.changeValue(new Date(), "endDate");
        localStorage.setItem(
          "startWorkDate",
          String(this.form.values.startDate)
        );
        localStorage.setItem("endWorkDate", String(this.form.values.endDate));
        break;

      case "week":
        now.setDate(now.getDate() - 7);
        this.form.changeValue(now, "startDate");
        this.form.changeValue(new Date(), "endDate");
        localStorage.setItem(
          "startWorkDate",
          String(this.form.values.startDate)
        );
        localStorage.setItem("endWorkDate", String(this.form.values.endDate));
        break;

      case "month":
        now.setDate(now.getDate() - daysInMonth);
        this.form.changeValue(now, "startDate");
        this.form.changeValue(new Date(), "endDate");
        localStorage.setItem(
          "startWorkDate",
          String(this.form.values.startDate)
        );
        localStorage.setItem("endWorkDate", String(this.form.values.endDate));
        break;

      case "year":
        now.setDate(now.getDate() - 365);
        this.form.changeValue(now, "startDate");
        this.form.changeValue(new Date(), "endDate");
        localStorage.setItem(
          "startWorkDate",
          String(this.form.values.startDate)
        );
        localStorage.setItem("endWorkDate", String(this.form.values.endDate));
        break;
    }

    this.loadReport();
  };

  reportDownload = async () => {
    this.form.validate();

    if (!this.form.isValid) return;

    const startDate = formatISO(
      new Date(String(this.form.values.startDate))
    ).split("T");
    const endDate = formatISO(new Date(String(this.form.values.endDate))).split(
      "T"
    );

    await reportService
      .downloadWorkTimeReport(this.form.values)
      .then((response) => {
        const fileName = `Рабочее время ${startDate[0]} ${endDate[0]}.xlsx`;

        FileSaver.saveAs(response.data, fileName);
      });
  };

  get pows() {
    return (
      this.table?.map((call) => [
        { text: getFormattedDate(call.created_at as string, "dd.MM.yyyy") },
        {
          text: `${call.user.last_name} ${call.user.first_name} ${call.user.middle_name}`,
        },
        {
          text: getFormattedDate(call.login_time as string, "dd.MM.yyyy HH:mm"),
        },
        {
          text: getFormattedDate(
            call.logout_time as string,
            "dd.MM.yyyy HH:mm"
          ),
        },
        { text: convertSecondsToHHMM(call.break_time_seconds) },
        { text: convertSecondsToHHMM(call.time_online_seconds) },
        { text: call.calls_accepted_count },
        { text: call.calls_declined_count },
        { text: convertSecondsToHHMM(call.avg_call_duration as number) },
      ]) || []
    );
  }

  reset = () => {
    this.table = [];
    this.form.reset();
    this.form.values.endDate = new Date();
    this.form.values.startDate = new Date();
  };
}

export default OperatorWorkTimeReportStore;
