import "./styles/reset.scss";
import "@kit/kit-aiss/dist/style.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalStatusStore from "./stores/GlobalStatusStore";

let intrv: NodeJS.Timer | undefined;

//добавляем разлогин, который сработает через 4 часа неактивного поведения

window.addEventListener("DOMContentLoaded", () => {
  setInterval(() => {
    GlobalStatusStore.checkOnline();
  }, 60000);
});

window.addEventListener("blur", () => {
  intrv = setInterval(() => {
    GlobalStatusStore.startTimer();
  }, 1000);
});

window.addEventListener("focus", () => {
  clearInterval(intrv);
  GlobalStatusStore.stopTimer();
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter basename="/">
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
