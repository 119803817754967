import "./styles.scss";

import React from "react";

/** Добавляет отступ, если число больше 999 */

const NumberSeparator = (count: number | undefined) => {
  return (
    <React.Fragment>
      {count && String(count).length > 3 ? (
        <span className="separation-block">
          {/* 
          исходим из того, что больше 999 999 число быть не может, потому
          без рекурсий и прочего
          */}
          <span className="separation-block__thousand">
            <span>{String(count).substring(0, String(count).length - 3)}</span>
          </span>
          <span>{String(count).substring(String(count).length - 3)}</span>
        </span>
      ) : (
        <span className="separation-block">{count}</span>
      )}
    </React.Fragment>
  );
};

export default NumberSeparator;
