import "./styles.scss";

import { observer } from "mobx-react";
import React, { useEffect } from "react";

import authStore from "../../pages/AuthPage/store/authStore";
import Avatar from "./Avatar";

const UserComponent: React.FC = () => {
  return (
    <div className="user-block">
      <div className="user-block__info">
        <div className="user-block__info-username">
          {authStore.user.last_name} {authStore.user.first_name?.[0] || ""}.{" "}
          {authStore.user.middle_name?.[0] || ""}.
        </div>
        <div className="user-block__info-position">
          {authStore.user.position}
        </div>
      </div>
      <div className="user-block__avatar">
        <Avatar
          lastName={authStore.user.last_name}
          firstName={authStore.user.first_name}
        />
      </div>
    </div>
  );
};

export default observer(UserComponent);
