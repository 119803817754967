import "./styles.scss";
import React, { FC, useEffect } from "react";
import { Cell, Pie, PieChart } from "recharts";
import { observer } from "mobx-react-lite";
import Typography from "../../../../../components/Typography";
import { ChartsStore } from "../../store";
import CellTabs from "./CellTabs";

interface IProps {
  calls?: boolean;
  store: ChartsStore;
}

const CellChart: FC<IProps> = ({ calls, store }) => {
  let intrv: NodeJS.Timer | undefined;
  useEffect(() => {
    intrv = setInterval(() => {
      store.loadCallsCircleChartData(
        store.period.find((item) => item.active)?.value as string
      );
    }, 10000);

    store.loadCallsChartData();

    return () => {
      clearInterval(intrv);
    };
  }, []);

  return (
    <div className="cell-chart">
      <div className="cell-chart-description">
        {!calls ? (
          <div className="cell-chart-description-appeals">
            {/* <Typography variant="sub-text-medium">Оценки обращений</Typography> */}
            <CellTabs
              tabs={store.period}
              getCellChartsData={store.loadCallsCircleChartData}
              setActiveTab={store.setActiveTab}
            />
            {/* <div className="cell-chart-description-appeals-estimates">
              <div className="cell-chart-description-appeals-estimates-estimate">
                <NegativeIcon />
                <Typography variant="h3">12</Typography>
              </div>
              <div className="cell-chart-description-appeals-estimates-estimate">
                <CoolIcon />
                <Typography variant="h3">234</Typography>
              </div>
            </div> */}
            <Typography variant="sub-text-medium">
              {`Звонков ${store.period
                .find((item) => item.active)
                ?.text.toLocaleLowerCase()}`}
            </Typography>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="cell-chart-block">
        <div className="cell-chart-block-text">
          <Typography variant="h1">{store.circleData.totalCalls}</Typography>
        </div>
        <PieChart width={174} height={174}>
          <defs>
            {store.circleData.values.map((entry, index) => (
              <linearGradient key={index} id={`myGradient${index}`}>
                {entry.colors.map((color) => {
                  if (entry.name === "Сбросы") {
                    return (
                      <stop
                        key={color.percent}
                        offset={color.percent}
                        stopColor={"#ff5a8c"}
                      />
                    );
                  }
                  if (entry.name === "Принято") {
                    return (
                      <stop
                        key={color.percent}
                        offset={color.percent}
                        stopColor={"#4a66c9"}
                      />
                    );
                  }
                  if (entry.name === "С обращениями") {
                    return (
                      <stop
                        key={color.percent}
                        offset={color.percent}
                        stopColor={"#2798bd"}
                      />
                    );
                  }
                })}
              </linearGradient>
            ))}
          </defs>
          <Pie
            dataKey="value"
            nameKey="name"
            data={store.circleData.values}
            cx="50%"
            cy="50%"
            outerRadius={87}
            innerRadius={82}
          >
            {store.circleData.values.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={`url(#myGradient${index})`} />
            ))}
          </Pie>
        </PieChart>
        <div className="cell-chart-block-description">
          {store.circleData.values.map((el) => (
            <div className="cell-chart-block-description-element" key={el.name}>
              <div
                className={`cell-chart-block-description-element-dot ${
                  el.name === "Принято" && "created"
                } ${el.name === "С обращениями" && "graded"} ${
                  el.name === "Сбросы" && "overdue"
                } ${el.name === "В обработке" && "process"} `}
              />
              <Typography variant="sub-text-medium">{el.name}</Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(CellChart);
