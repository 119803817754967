import "./styles.scss";

import React from "react";
import { observer } from "mobx-react-lite";
import Modal from "../../../components/Modal";
import Typography from "../../../components/Typography";
import { FlexColumn, FlexRow, LoaderSpinner } from "@kit/kit-aiss";

import { EditModalRecordStore } from "./store";
import TextField from "../../../components/TextField";
import TextArea from "../../../components/TextArea";
import Button from "../../../components/Button";
import sipControllerStore from "../../../stores/GlobalStatusStore/SipController";
import GlobalStatusStore from "../../../stores/GlobalStatusStore";
import { ReactComponent as Play } from "../../../assets/svg/play_gray.svg";
import ReactAudioPlayer from "react-h5-audio-player";
import { getFormattedDate } from "../../../utils/date/getFormattedDate";

import RecordStore from "../store";
import authStore from "../../AuthPage/store/authStore";
import { DownloadLink } from "../../../components/DownloadLink";

interface IEditRecordModalProps {
  onClose: () => void;
  isOpen: boolean;
  recordId: null | number;
  getRecords: (type?: string) => void;
}

const store = new EditModalRecordStore();

const EditRecordModal: React.FC<IEditRecordModalProps> = ({
  onClose,
  isOpen,
  recordId,
  getRecords,
}) => {
  React.useEffect(() => {
    store.init(recordId);

    return () => {
      store.reset();
    };
  }, []);

  const handleSaveForm = () => {
    const operator = authStore.role[0];
    GlobalStatusStore.setState("CallFromRecord", false);
    store.saveEditedForm().then(() => {
      onClose();
      operator === "operator"
        ? RecordStore.init("operator")
        : RecordStore.init();
    });
  };

  const onPlayEvent = () => {
    const type = authStore.role[0] === "operator" ? "operator" : "";
    store.onPlayEvent(recordId).then(() => {
      getRecords(type);
    });
  };

  const [date, time] = store.record.recorded_at.split(" ");
  const [year, ,] = date.split("-");

  const result = getFormattedDate(date as unknown as Date, "dd MMMM");

  const handleCall = (tel: string) => {
    sipControllerStore.call(tel);
    GlobalStatusStore.setState("isOutGoing", true);
    GlobalStatusStore.setState("CallFromRecord", true);
    GlobalStatusStore.setCallRecordId(recordId as number);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <FlexRow>
          <FlexColumn gap={20}>
            <FlexRow className="edit-record-modal__header" gap={18}>
              <Play className="edit-record-modal__header--logo" />
              <Typography
                variant="h2"
                className="edit-record-modal__header--title"
              >
                Голосовое сообщение
              </Typography>
            </FlexRow>
            <FlexRow className="edit-record-modal__header--info">
              <FlexColumn gap={10}>
                <Typography
                  color="color3"
                  className="edit-record-modal__header--info_sub"
                >
                  Дата/Время
                </Typography>
                <Typography variant="h1">
                  {getFormattedDate(date, "dd.MM")}.{year}
                </Typography>
              </FlexColumn>
              <FlexColumn gap={10}>
                <Typography className="edit-record-modal__header--info_sub">
                  Линия
                </Typography>
                <Typography variant="h1">{store.record.lineName}</Typography>
              </FlexColumn>
            </FlexRow>
          </FlexColumn>
        </FlexRow>
      }
      className="edit-record-modal crm-modal"
    >
      {store.isLoading ? (
        <LoaderSpinner />
      ) : (
        <FlexColumn gap={20}>
          {store.record.audio_record && (
            <div className="audio-player">
              <ReactAudioPlayer
                src={store.record.audio_record as string}
                autoPlay={false}
                autoPlayAfterSrcChange={false}
                onPlay={onPlayEvent}
              />
              <div className="audio-title">
                <span>
                  {result} {year}, {time}
                </span>
              </div>
              <DownloadLink href={store.record.audio_record} />
            </div>
          )}
          <TextField
            {...store.form.adapters.user}
            placeholder={"Оператор, обработавший голосовое сообщение"}
            isDisabled
          />
          <FlexRow isNoFlexChildren className="edit-record-modal__recall">
            <TextField
              className="edit-record-modal__recall--input"
              {...store.form.adapters.clientPhone}
              maxLength={12}
              placeholder="Номер телефона, с которого поступил звонок"
            />
            {authStore.role[0] !== "manager" && (
              <Button
                className="operator-calls__button"
                size="medium"
                onClick={() => {
                  handleCall(
                    store.form.values.clientPhone as unknown as string
                  );
                }}
                disabled={GlobalStatusStore.states.paused}
              >
                Позвонить
              </Button>
            )}
          </FlexRow>
          <TextArea
            {...store.form.adapters.comment}
            placeholder={"Суть проблемы"}
          />
          <Button
            className="operator-calls__button"
            size="medium"
            onClick={handleSaveForm}
          >
            Сохранить
          </Button>
        </FlexColumn>
      )}
    </Modal>
  );
};

export default observer(EditRecordModal);
