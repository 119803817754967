import React, { FC, useEffect } from "react";
import { FlexRow, Modal } from "@kit/kit-aiss";
import { IEditAttributeModalProps } from "./types";
import Form from "../Form";
import Button from "../../../../../components/Button";

const EditAttributeModal: FC<IEditAttributeModalProps> = ({
  isOpen,
  onClose,
  store,
  attribute,
}) => {
  useEffect(() => {
    store.init(attribute);
    return () => store.reset();
  }, []);

  const editAttribute = () => {
    store.checkValidate()?.then(() => onClose());
  };

  const deleteAttribute = () => {
    store.form.deleteAttribute().then(() => {
      onClose();
      store.loadAttributes();
    });
  };

  const disableAttribute = (id?: number) => {
    store.disableAttribute(id).then(() => {
      onClose();
    });
  };

  const enableAttribute = (id?: number) => {
    store.enableAttribute(id).then(() => {
      onClose();
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Редактировать атрибут">
      <Form
        deleteAttribute={deleteAttribute}
        isEdit
        id={attribute?.id}
        form={store.form}
      />
      <FlexRow>
        {attribute?.deactivatedAt && (
          <Button
            onClick={() => {
              enableAttribute(attribute?.id);
            }}
            className="deactivate"
          >
            Активировать атрибут
          </Button>
        )}
        {!attribute?.deactivatedAt && (
          <Button
            onClick={() => {
              disableAttribute(attribute?.id);
            }}
            className="deactivate"
          >
            Деактивировать атрибут
          </Button>
        )}
        <Button onClick={editAttribute}>Сохранить</Button>
      </FlexRow>
    </Modal>
  );
};

export default EditAttributeModal;
