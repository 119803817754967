import { makeAutoObservable, runInAction } from "mobx";
import { SortStore } from "../../../stores/SortStore";
import { IRingTable } from "./types";
import { getFormattedDate } from "../../../utils/date/getFormattedDate";
import callsService from "../../../services/calls";
import { EditCallModalStore } from "../../OperatorCalls/components/popups/EditCallModal/store";
import FileSaver from "file-saver";
import shiftService from "../../../services/shifts";
import { formatISO } from "date-fns";
import { FilterPopupStore } from "../FilterPopup/store";
import authStore from "../../AuthPage/store/authStore";
import { CreateEmptyCallModalStore } from "../../../components/modals/CreateEmptyCallModal/store";

export const RINGS_SORT_ITEMS = [
  "created_at",
  "responsible_user_number",
  "topic_title",
  "status",
  "contact_phone",
] as const;

export const getRingStatus = (status: string | boolean) => {
  switch (status) {
    case "pending":
      return "В ожидании";
    case "declined":
      return "Сброс";
    case "success":
      return "Принят";
    default:
      return "-";
  }
};

export class CallsStore {
  isLoading = false;
  table: IRingTable | null = { count: null, calls: [] };
  sortStore: SortStore<(typeof RINGS_SORT_ITEMS)[number]>;

  editCallModalStore: EditCallModalStore;
  createEmptyCallModalStore = new CreateEmptyCallModalStore(() =>
    this.getRingTableData()
  );

  pageCount = 1;
  date: Date | null = new Date();
  filterPopupStore: FilterPopupStore;

  statisticsBlock = {
    negativeCount: 0,
    approveCount: 0,
    operatorsCount: 0,
    rejectCount: 0,
    appealsOverdueCount: 0,
    appealsAcceptedCount: 0,
  };

  operatorStatistics: { value: string; count: number; type: string }[] = [];

  constructor() {
    makeAutoObservable(this);
    this.filterPopupStore = new FilterPopupStore(this.getRingTableData);
    this.sortStore = new SortStore(
      [...RINGS_SORT_ITEMS],
      this.getRingTableData,
      this.resetCountOsSortClick
    );
    this.editCallModalStore = new EditCallModalStore(this.getRingTableData);
  }

  init = (type?: string) => {
    this.getRingTableData(type);
    this.createEmptyCallModalStore.init();
  };

  getRingTableData = (type?: string) => {
    this.isLoading = true;

    const date =
      this.date === null
        ? formatISO(new Date()).split("T")
        : formatISO(this.date).split("T");

    const startDate = this.filterPopupStore.form.values.startDate
      ? formatISO(this.filterPopupStore.form.values.startDate).split("T")[0]
      : "";
    const endDate = this.filterPopupStore.form.values.endDate
      ? formatISO(this.filterPopupStore.form.values.endDate).split("T")[0]
      : "";

    callsService
      .loadCalls(
        this.sortStore.searchParams,
        1,
        type ? (date[0] as unknown as string) : "",
        this.pageCount * 20,
        !type ? startDate : "",
        !type ? endDate : "",
        this.filterPopupStore.form.values.thema as unknown as number,
        this.filterPopupStore.form.values.subThema as unknown as number,
        this.filterPopupStore.form.values.line as unknown as number,
        this.filterPopupStore.form.values.operator as unknown as number,
        this.filterPopupStore.form.values.status as unknown as number,
        this.filterPopupStore.form.values.reaction as unknown as number
      )
      .then(({ data }) => {
        runInAction(() => {
          this.table!.count = data.data.count;
          this.table!.calls = data.data.calls;
        });
      })
      .finally(() => runInAction(() => (this.isLoading = false)));

    const commonDate =
      this.date === null
        ? formatISO(new Date()).split("T")
        : formatISO(new Date(this.date)).split("T");

    if (!authStore.role.includes("operator")) {
      shiftService.loadShiftsStatistics(commonDate[0]).then(({ data }) => {
        runInAction(() => {
          //TODO: блок статистики проверить что данные приходят или если не приходят, то рисуются нули!
          this.operatorStatistics = [];
          this.statisticsBlock = {
            negativeCount: data.data.appeals_negative_grade_count,
            approveCount: data.data ? data.data.accepted_calls_count : null,
            operatorsCount: data.data ? data.data.operators_count : null,
            rejectCount: data.data ? data.data.declined_calls_count : null,
            appealsOverdueCount: data.data
              ? data.data.overdue_appeals_count
              : null,
            appealsAcceptedCount: data.data ? data.data.appeals_count : null,
          };
          this.operatorStatistics.push({
            value: "Сбросы на линиях",
            count: data.data.declined_calls_count,
            type: "resets",
          });
          this.operatorStatistics.push({
            value: "Активные линии",
            count: data.data.active_lines_count,
            type: "active",
          });
          this.operatorStatistics.push({
            value: "Звонков в очереди",
            count: data.data.pending_calls_count,
            type: "calls",
          });
        });
      });
    }

    shiftService.loadOperatorStatistics(commonDate[0]).then((res: any) => {
      const { data } = res.data;

      if (type) {
        runInAction(() => {
          this.operatorStatistics = [];
          this.operatorStatistics.push({
            value: "Сбросы на линиях",
            count: data.calls_declined_count,
            type: "resets",
          });
          this.operatorStatistics.push({
            value: "Активные линии",
            count: data.active_lines_count,
            type: "active",
          });
          this.operatorStatistics.push({
            value: "Звонков в очереди",
            count: data.calls_pending_count,
            type: "calls",
          });
        });
      }
    });
  };

  getRingsReport = (type?: string) => {
    const date =
      this.date === null
        ? formatISO(new Date()).split("T")
        : formatISO(this.date).split("T");

    const startDate = this.filterPopupStore.form.values.startDate
      ? formatISO(this.filterPopupStore.form.values.startDate).split("T")[0]
      : "";
    const endDate = this.filterPopupStore.form.values.endDate
      ? formatISO(this.filterPopupStore.form.values.endDate).split("T")[0]
      : "";

    callsService
      .downloadReport(
        this.sortStore.searchParams,
        1,
        type ? (date[0] as unknown as string) : "",
        this.pageCount * 20,
        !type ? startDate : "",
        !type ? endDate : "",
        this.filterPopupStore.form.values.thema as unknown as number,
        this.filterPopupStore.form.values.subThema as unknown as number,
        this.filterPopupStore.form.values.line as unknown as number,
        this.filterPopupStore.form.values.operator as unknown as number,
        this.filterPopupStore.form.values.status as unknown as number,
        this.filterPopupStore.form.values.reaction as unknown as number
      )
      .then(({ data }) => {
        const fileName = `Отчет по количеству звонков.csv`;
        FileSaver.saveAs(data, fileName);
      });
  };

  reset = () => {
    this.table = { calls: [], count: null };
    this.pageCount = 1;
    this.operatorStatistics = [];
    this.date = new Date();
  };

  incrementPageCount = () => {
    this.pageCount += 1;
  };

  resetCountOsSortClick = () => {
    this.table = { calls: [], count: null };
    this.pageCount = 1;
    this.operatorStatistics = [];
  };

  onChangeDate = (date: Date | null, type?: string) => {
    this.date = date;
    this.getRingTableData(type);
  };

  get rows() {
    return (
      this.table?.calls.map((call) => [
        {
          text: getFormattedDate(call.date, "dd.MM.yyyy HH:mm"),
        },
        {
          text: call.operator ?? "-",
        },
        {
          text: call.line ?? "-",
        },
        {
          text: call.theme ?? "-",
        },
        {
          text: getRingStatus(call.status) ?? "-",
        },
        {
          text: call.reaction ?? "-",
        },
        {
          text: call.phone ?? "-",
        },
      ]) || []
    );
  }
}
