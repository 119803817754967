import { PrintBlock, PrintLayout, Table, Typography } from "@kit/kit-aiss";
import { observer } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReportPageStore } from "../../ReportCallsPage/store";
import "./styles.scss";

const store = new ReportPageStore();

const ReportPrintPage: React.FC = () => {
  const date1 = new Date(localStorage.getItem("startCallDate") as string);
  const date2 = new Date(localStorage.getItem("endCallDate") as string);

  React.useEffect(() => {
    store.callsStore.form.changeValue(date1, "startDate");
    store.callsStore.form.changeValue(date2, "endDate");
    store.callsStore.loadReport();

    return () => {
      store.reset();
    };
  }, []);

  const navigate = useNavigate();

  const handleCancelPrintClick = () => {
    navigate(`/report-calls`);
    localStorage.setItem(
      "startCallDate",
      String(store.callsStore.form.values.startDate)
    );
    localStorage.setItem(
      "endCallDate",
      String(store.callsStore.form.values.endDate)
    );
  };

  return (
    <PrintLayout className="print-page">
      <PrintBlock onClickBackLink={handleCancelPrintClick}>
        <Table
          headers={[
            "Тематика звонка",
            "Количество поступивших звонков",
            "Количество принятых звонков",
            "Количество потерянных звонков",
            "Среднее время ожидания ответа оператора",
            "Средняя продолжит. разговора",
            "Общая длительность разговоров",
          ]}
          content={store.callsStore.table.map((line, idx) => [
            [
              <div key={idx} className="first-elem">
                <Typography>{line.topic_title}</Typography>
              </div>,
            ],
            [line.total_count],
            [line.success_calls_count],
            [line.discard_calls_count],
            [line.avg_waiting_time],
            [line.avg_duration_time],
            [line.sum_duration_time],
          ])}
        />
      </PrintBlock>
    </PrintLayout>
  );
};

export default observer(ReportPrintPage);
